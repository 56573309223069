/* src\components\Test\Test.svelte generated by Svelte v3.57.0 */
import {
	SvelteElement,
	add_location,
	append_dev,
	attribute_to_object,
	component_subscribe,
	destroy_each,
	detach_dev,
	dispatch_dev,
	element,
	flush,
	globals,
	init as init_1,
	insert_dev,
	listen_dev,
	noop,
	run_all,
	safe_not_equal,
	set_custom_element_data,
	set_data_dev,
	space,
	text,
	validate_each_argument,
	validate_slots,
	validate_store
} from "../../../web_modules/svelte/internal.js";

const { console: console_1 } = globals;
import bc from '../../bcShim.js';
import { v4 as uuidv4 } from '../../../web_modules/uuid.js';
import { onMount, tick } from '../../../web_modules/svelte.js';

import {
	attachNormalize,
	dispatchMountedEvent,
	handleComponentInitAttribute,
	handleComponentProps,
	handleComponentShowAttribute
} from '../../utilities/componentUtils.js';

import { get_current_component } from '../../../web_modules/svelte/internal.js';
import { applicationReady } from '../../utilities/store.js';
import { getComponentText, createEvent } from '../../utilities/generalUtils.js';
const file = "src\\components\\Test\\Test.svelte";

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[18] = list[i];
	child_ctx[20] = i;
	return child_ctx;
}

// (2089:4) {:else}
function create_else_block(ctx) {
	let loading_spinner;

	const block = {
		c: function create() {
			loading_spinner = element("loading-spinner");
			set_custom_element_data(loading_spinner, "text", "Loading...");
			add_location(loading_spinner, file, 2089, 8, 40254);
		},
		m: function mount(target, anchor) {
			insert_dev(target, loading_spinner, anchor);
		},
		p: noop,
		d: function destroy(detaching) {
			if (detaching) detach_dev(loading_spinner);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_else_block.name,
		type: "else",
		source: "(2089:4) {:else}",
		ctx
	});

	return block;
}

// (2072:4) {#if ready}
function create_if_block(ctx) {
	let ul;
	let t0;
	let bc_button0;
	let t1;
	let bc_button1;
	let mounted;
	let dispose;
	let each_value = /*items*/ ctx[1];
	validate_each_argument(each_value);
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	const block = {
		c: function create() {
			ul = element("ul");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			t0 = space();
			bc_button0 = element("bc-button");
			t1 = space();
			bc_button1 = element("bc-button");
			add_location(ul, file, 2073, 4, 39945);
			set_custom_element_data(bc_button0, "text", "Fire event");
			add_location(bc_button0, file, 2084, 4, 40080);
			set_custom_element_data(bc_button1, "text", "Fire event external");
			add_location(bc_button1, file, 2086, 4, 40157);
		},
		m: function mount(target, anchor) {
			insert_dev(target, ul, anchor);

			for (let i = 0; i < each_blocks.length; i += 1) {
				if (each_blocks[i]) {
					each_blocks[i].m(ul, null);
				}
			}

			insert_dev(target, t0, anchor);
			insert_dev(target, bc_button0, anchor);
			insert_dev(target, t1, anchor);
			insert_dev(target, bc_button1, anchor);

			if (!mounted) {
				dispose = [
					listen_dev(bc_button0, "click", /*setDataviewGuid*/ ctx[3], false, false, false, false),
					listen_dev(bc_button1, "click", /*fireEvent*/ ctx[4], false, false, false, false)
				];

				mounted = true;
			}
		},
		p: function update(ctx, dirty) {
			if (dirty & /*items, metadata*/ 6) {
				each_value = /*items*/ ctx[1];
				validate_each_argument(each_value);
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(ul, null);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value.length;
			}
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(ul);
			destroy_each(each_blocks, detaching);
			if (detaching) detach_dev(t0);
			if (detaching) detach_dev(bc_button0);
			if (detaching) detach_dev(t1);
			if (detaching) detach_dev(bc_button1);
			mounted = false;
			run_all(dispose);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block.name,
		type: "if",
		source: "(2072:4) {#if ready}",
		ctx
	});

	return block;
}

// (2076:4) {#each items as item, index }
function create_each_block(ctx) {
	let li;
	let t0_value = /*item*/ ctx[18][/*metadata*/ ctx[2].idProperty] + "";
	let t0;
	let t1;

	const block = {
		c: function create() {
			li = element("li");
			t0 = text(t0_value);
			t1 = space();
			add_location(li, file, 2076, 4, 39992);
		},
		m: function mount(target, anchor) {
			insert_dev(target, li, anchor);
			append_dev(li, t0);
			append_dev(li, t1);
		},
		p: function update(ctx, dirty) {
			if (dirty & /*items, metadata*/ 6 && t0_value !== (t0_value = /*item*/ ctx[18][/*metadata*/ ctx[2].idProperty] + "")) set_data_dev(t0, t0_value);
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(li);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_each_block.name,
		type: "each",
		source: "(2076:4) {#each items as item, index }",
		ctx
	});

	return block;
}

function create_fragment(ctx) {
	let div;

	function select_block_type(ctx, dirty) {
		if (/*ready*/ ctx[0]) return create_if_block;
		return create_else_block;
	}

	let current_block_type = select_block_type(ctx, -1);
	let if_block = current_block_type(ctx);

	const block = {
		c: function create() {
			div = element("div");
			if_block.c();
			this.c = noop;
			add_location(div, file, 2070, 0, 39915);
		},
		l: function claim(nodes) {
			throw new Error("options.hydrate only works if the component was compiled with the `hydratable: true` option");
		},
		m: function mount(target, anchor) {
			insert_dev(target, div, anchor);
			if_block.m(div, null);
		},
		p: function update(ctx, [dirty]) {
			if (current_block_type === (current_block_type = select_block_type(ctx, dirty)) && if_block) {
				if_block.p(ctx, dirty);
			} else {
				if_block.d(1);
				if_block = current_block_type(ctx);

				if (if_block) {
					if_block.c();
					if_block.m(div, null);
				}
			}
		},
		i: noop,
		o: noop,
		d: function destroy(detaching) {
			if (detaching) detach_dev(div);
			if_block.d();
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_fragment.name,
		type: "component",
		source: "",
		ctx
	});

	return block;
}

function instance($$self, $$props, $$invalidate) {
	let $applicationReady;
	validate_store(applicationReady, 'applicationReady');
	component_subscribe($$self, applicationReady, $$value => $$invalidate(11, $applicationReady = $$value));
	let { $$slots: slots = {}, $$scope } = $$props;
	validate_slots('test-tag', slots, []);
	let { show = 'true' } = $$props;
	let { init = 'true' } = $$props;
	let { dataviewguid = '' } = $$props;
	let { whatever = '' } = $$props;

	// Base variables
	//================================================================
	let component = get_current_component();

	let firstInitialized = false;
	let subscribeGuid = uuidv4();
	let ready = false;
	const generalText = getComponentText('general');
	let mounted = false;
	let canReload = true;

	// Component Variables
	//================================================================
	let items = [];

	let metadata = null;

	// HTML Mount & Component initialization
	//================================================================
	onMount(() => {
		attachNormalize(component);

		tick().then(() => {
			dispatchMountedEvent(component);
			$$invalidate(10, mounted = true);
		});

		return () => {
			bc.messageService.unsubscribeComponent(subscribeGuid);
		};
	});

	const initComponent = async () => {
		$$invalidate(9, firstInitialized = true);

		// clean up html and wait
		$$invalidate(0, ready = false);

		await tick();
		$$invalidate(2, metadata = await bc.parameterService.getMetadata(dataviewguid));

		const dataPromise = await bc.metadataManager.fetchJsonData({
			filters: [],
			resourceName: metadata.resourceName,
			inlinecount: 'allpages',
			top: 5,
			offset: 0,
			orderBy: []
		});

		if (dataPromise) {
			console.log(dataPromise['Items']);
			$$invalidate(1, items = dataPromise['Items']);
		}

		$$invalidate(0, ready = true);
	};

	/////////////////////////////////////////////////////
	// Init when canReload is true
	////////////////////////////////////////////////////
	const applyProps = afterInit => {
		let shouldInit = afterInit && firstInitialized || !afterInit && !firstInitialized;

		if (shouldInit) {
			handleComponentProps(
				{
					ready: $applicationReady,
					componentMounted: mounted,
					init,
					firstInitialized,
					canReload,
					handler: initComponent
				},
				dataviewguid
			); // komma separated, e.g. }, dataviewguid, entityguid, measurementtype, measurementprogram, filterguid, rltype, rldirection, rlfromitemguid)
		}
	};

	//  $: dataviewguid, entityguid, measurementtype, measurementprogram, filterguid, rltype, rldirection, rlfromitemguid, applyProps(true)
	/////////////////////////////////////////////////////
	// Init when canReload is false
	////////////////////////////////////////////////////
	/*
    $: if ($applicationReady && mounted && init) {
        componentInitAndAttributeHandler({
            mounted: mounted,
            handler: initComponent,
            init: init,
            firstInitialized: firstInitialized,
            canReload: canReload,
        })
    }
*/
	// Component functions
	//================================================================
	const setDataviewGuid = () => {
		$$invalidate(5, dataviewguid = 'e5faa54e-f27b-4135-a1d8-093d06dcaf9d');
	};

	const fireEvent = () => {
		let event = createEvent('save', dataviewguid); // parameter goes to event.detail. detail: "ee71890a-c837-4dab-a12a-8ffd6ef6a629"
		component.dispatchEvent(event);
	};

	const writable_props = ['show', 'init', 'dataviewguid', 'whatever'];

	

	$$self.$$set = $$props => {
		if ('show' in $$props) $$invalidate(6, show = $$props.show);
		if ('init' in $$props) $$invalidate(7, init = $$props.init);
		if ('dataviewguid' in $$props) $$invalidate(5, dataviewguid = $$props.dataviewguid);
		if ('whatever' in $$props) $$invalidate(8, whatever = $$props.whatever);
	};

	$$self.$capture_state = () => ({
		bc,
		uuidv4,
		onMount,
		tick,
		attachNormalize,
		dispatchMountedEvent,
		handleComponentInitAttribute,
		handleComponentProps,
		handleComponentShowAttribute,
		get_current_component,
		applicationReady,
		getComponentText,
		createEvent,
		show,
		init,
		dataviewguid,
		whatever,
		component,
		firstInitialized,
		subscribeGuid,
		ready,
		generalText,
		mounted,
		canReload,
		items,
		metadata,
		initComponent,
		applyProps,
		setDataviewGuid,
		fireEvent,
		$applicationReady
	});

	$$self.$inject_state = $$props => {
		if ('show' in $$props) $$invalidate(6, show = $$props.show);
		if ('init' in $$props) $$invalidate(7, init = $$props.init);
		if ('dataviewguid' in $$props) $$invalidate(5, dataviewguid = $$props.dataviewguid);
		if ('whatever' in $$props) $$invalidate(8, whatever = $$props.whatever);
		if ('component' in $$props) $$invalidate(12, component = $$props.component);
		if ('firstInitialized' in $$props) $$invalidate(9, firstInitialized = $$props.firstInitialized);
		if ('subscribeGuid' in $$props) subscribeGuid = $$props.subscribeGuid;
		if ('ready' in $$props) $$invalidate(0, ready = $$props.ready);
		if ('mounted' in $$props) $$invalidate(10, mounted = $$props.mounted);
		if ('canReload' in $$props) canReload = $$props.canReload;
		if ('items' in $$props) $$invalidate(1, items = $$props.items);
		if ('metadata' in $$props) $$invalidate(2, metadata = $$props.metadata);
	};

	if ($$props && "$$inject" in $$props) {
		$$self.$inject_state($$props.$$inject);
	}

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*show*/ 64) {
			$: if (show) {
				handleComponentShowAttribute(show, component);
			}
		}

		if ($$self.$$.dirty & /*show*/ 64) {
			$: console.log(show); // jedes mal, wenn sich show ändert
		}

		if ($$self.$$.dirty & /*init, firstInitialized, mounted, $applicationReady*/ 3712) {
			$: handleComponentInitAttribute(init, firstInitialized, mounted, $applicationReady, applyProps);
		}

		if ($$self.$$.dirty & /*dataviewguid*/ 32) {
			$: (dataviewguid, applyProps(true)); // wenn sich diese variable ändert, wird Komponente neu geladen (s. applyProps), Komma-separated, e.g.
		}
	};

	return [
		ready,
		items,
		metadata,
		setDataviewGuid,
		fireEvent,
		dataviewguid,
		show,
		init,
		whatever,
		firstInitialized,
		mounted,
		$applicationReady
	];
}

class Test extends SvelteElement {
	constructor(options) {
		super();
		const style = document.createElement('style');
		style.textContent = `100%{left:0;right:0}100%{left:0;right:0}100%{opacity:1}100%{opacity:1}45%{-webkit-transform:scale(var(--fa-beat-scale, 1.25));transform:scale(var(--fa-beat-scale, 1.25))}45%{-webkit-transform:scale(var(--fa-beat-scale, 1.25));transform:scale(var(--fa-beat-scale, 1.25))}50%{opacity:1;-webkit-transform:scale(var(--fa-beat-fade-scale, 1.125));transform:scale(var(--fa-beat-fade-scale, 1.125))}50%{opacity:1;-webkit-transform:scale(var(--fa-beat-fade-scale, 1.125));transform:scale(var(--fa-beat-fade-scale, 1.125))}100%{-webkit-transform:rotate(360deg);transform:rotate(360deg)}100%{-webkit-transform:rotate(360deg);transform:rotate(360deg)}`;
		this.shadowRoot.appendChild(style);

		init_1(
			this,
			{
				target: this.shadowRoot,
				props: attribute_to_object(this.attributes),
				customElement: true
			},
			instance,
			create_fragment,
			safe_not_equal,
			{
				show: 6,
				init: 7,
				dataviewguid: 5,
				whatever: 8
			},
			null
		);

		if (options) {
			if (options.target) {
				insert_dev(options.target, this, options.anchor);
			}

			if (options.props) {
				this.$set(options.props);
				flush();
			}
		}
	}

	static get observedAttributes() {
		return ["show", "init", "dataviewguid", "whatever"];
	}

	get show() {
		return this.$$.ctx[6];
	}

	set show(show) {
		this.$$set({ show });
		flush();
	}

	get init() {
		return this.$$.ctx[7];
	}

	set init(init) {
		this.$$set({ init });
		flush();
	}

	get dataviewguid() {
		return this.$$.ctx[5];
	}

	set dataviewguid(dataviewguid) {
		this.$$set({ dataviewguid });
		flush();
	}

	get whatever() {
		return this.$$.ctx[8];
	}

	set whatever(whatever) {
		this.$$set({ whatever });
		flush();
	}
}

customElements.define("test-tag", Test);
export default Test;
import {writable} from "../../web_modules/svelte/store.js";
export const messageCache = writable(new Map());
import {parameterService} from "./parameterService.js";
export const createMessageService = () => {
  let myCache = [];
  let called = new Map();
  let myNewCache;
  messageCache.subscribe((value) => {
    myNewCache = value;
  });
  let appartenanceCache = new Map();
  const addToList = (idSubscriber, topic) => {
    let compEnty = appartenanceCache.get(idSubscriber);
    if (compEnty) {
      if (!compEnty.includes(topic)) {
        compEnty.push(topic);
      }
    } else {
      appartenanceCache.set(idSubscriber, [topic]);
    }
  };
  const removeFromList = (idSubscriber, topic) => {
    let compEnty = appartenanceCache.get(idSubscriber);
    if (compEnty) {
      let index = compEnty.indexOf(topic);
      if (index !== -1) {
        compEnty.splice(index, 1);
      }
    }
  };
  return {
    newCache: myNewCache,
    appartenance: appartenanceCache,
    cache: myCache,
    called,
    subscribe: (topic, idSubscriber, callback) => {
      let entry = myNewCache.get(topic);
      if (entry) {
        let hasSameFn = false;
        for (let i = 0; i < entry.length; i++) {
          if (entry[i].fn === callback) {
            if (entry[i].id === idSubscriber) {
              hasSameFn = true;
              break;
            }
          }
        }
        if (!hasSameFn) {
          entry.push({id: idSubscriber, fn: callback});
          addToList(idSubscriber, topic);
          myNewCache.delete(topic);
          myNewCache.set(topic, entry);
        }
      } else {
        myNewCache.set(topic, [{id: idSubscriber, fn: callback}]);
        addToList(idSubscriber, topic);
      }
      messageCache.set(myNewCache);
    },
    publish: (topic, ...args) => {
      try {
        let entry = myNewCache.get(topic);
        if (entry) {
          console.log("Topic " + topic + " has " + entry.length + " entries", entry);
          for (let i = 0; i < entry.length; i++) {
            entry[i].fn(...args);
          }
        }
      } catch (error) {
        console.error("error in publish", topic, args);
        console.error(error);
      }
    },
    savePublish: (topic, args) => {
      try {
        let publishEvent = false;
        let value = parameterService.get(topic);
        if (value === null) {
          publishEvent = true;
        } else {
          if (value !== args) {
            publishEvent = true;
          }
        }
        parameterService.set(topic, args);
        if (publishEvent) {
          let entry = myNewCache.get(topic);
          if (entry) {
            for (let i = 0; i < entry.length; i++) {
              entry[i].fn(args);
            }
          }
        }
      } catch (error) {
        console.error("error in publish", topic, args);
        console.error(error);
      }
    },
    publishCallback: (topic, callback, args) => {
      let entry = myNewCache.get(topic);
      if (entry) {
        for (let i = 0; i < entry.length; i++) {
          callback(entry[i].fn(args));
        }
      }
    },
    unsubscribe: (topic, idSubscriber) => {
      let entry = myNewCache.get(topic);
      if (entry) {
        let newValue = [];
        for (let i = 0; i < entry.length; i++) {
          if (entry[i].id !== idSubscriber) {
            newValue.push(entry[i]);
          }
        }
        if (newValue.length === 0) {
          myNewCache.delete(topic);
        } else {
          myNewCache.set(topic, newValue);
        }
      }
      removeFromList(idSubscriber, topic);
      messageCache.set(myNewCache);
    },
    unsubscribeComponent: (idSubscriber) => {
      if (idSubscriber !== void 0 && idSubscriber !== null && idSubscriber !== "") {
        let componentEventsArray = appartenanceCache.get(idSubscriber);
        if (componentEventsArray) {
          for (let i = 0; i < componentEventsArray.length; i++) {
            let entry = myNewCache.get(componentEventsArray[i]);
            if (entry) {
              for (let j = entry.length - 1; j >= 0; j--) {
                if (entry[j].id === idSubscriber) {
                  entry.splice(j, 1);
                }
              }
            }
            if (entry.length === 0) {
              myNewCache.delete(componentEventsArray[i]);
            }
          }
          appartenanceCache.delete(idSubscriber);
          messageCache.set(myNewCache);
        }
      }
    }
  };
};
export const messageService = createMessageService();

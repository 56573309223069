/* src\components\ObjectTable\FilterDropDown\DropDownList\DropDownList.svelte generated by Svelte v3.57.0 */
import {
	SvelteElement,
	add_location,
	append_dev,
	attr_dev,
	attribute_to_object,
	component_subscribe,
	destroy_each,
	detach_dev,
	dispatch_dev,
	element,
	flush,
	globals,
	init as init_1,
	insert_dev,
	listen_dev,
	noop,
	safe_not_equal,
	set_custom_element_data,
	set_data_dev,
	space,
	text,
	validate_each_argument,
	validate_slots,
	validate_store
} from "../../../../../web_modules/svelte/internal.js";

const { console: console_1 } = globals;
import bc from '../../../../bcShim.js';
import { v4 as uuidv4 } from '../../../../../web_modules/uuid.js';
import { onMount, tick } from '../../../../../web_modules/svelte.js';

import {
	attachNormalize,
	componentInitAndAttributeHandler,
	dispatchMountedEvent,
	handleComponentShowAttribute
} from "../../../../utilities/componentUtils.js";

import { get_current_component } from "../../../../../web_modules/svelte/internal.js";
import { applicationReady } from "../../../../utilities/store.js";
import { createEvent, getComponentText } from "../../../../utilities/generalUtils.js";
const file = "src\\components\\ObjectTable\\FilterDropDown\\DropDownList\\DropDownList.svelte";

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[21] = list[i].value;
	child_ctx[22] = list[i].guid;
	return child_ctx;
}

// (2092:8) {#if bc.parameterService.appParameters.userId && isactorlist === 'true'}
function create_if_block_1(ctx) {
	let li;
	let span;
	let mounted;
	let dispose;

	const block = {
		c: function create() {
			li = element("li");
			span = element("span");
			span.textContent = `${/*generalText*/ ctx[3].loggedInUser}`;
			attr_dev(span, "class", "font-medium roboto-medium");
			add_location(span, file, 2098, 16, 39768);
			add_location(li, file, 2092, 12, 39541);
		},
		m: function mount(target, anchor) {
			insert_dev(target, li, anchor);
			append_dev(li, span);

			if (!mounted) {
				dispose = listen_dev(li, "click", /*click_handler_1*/ ctx[13], false, false, false, false);
				mounted = true;
			}
		},
		p: noop,
		d: function destroy(detaching) {
			if (detaching) detach_dev(li);
			mounted = false;
			dispose();
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block_1.name,
		type: "if",
		source: "(2092:8) {#if bc.parameterService.appParameters.userId && isactorlist === 'true'}",
		ctx
	});

	return block;
}

// (2103:8) {#if isloading === 'true'}
function create_if_block(ctx) {
	let li;
	let span;
	let t0_value = /*generalText*/ ctx[3].loading + "";
	let t0;
	let t1;
	let fa_icon;

	const block = {
		c: function create() {
			li = element("li");
			span = element("span");
			t0 = text(t0_value);
			t1 = space();
			fa_icon = element("fa-icon");
			set_custom_element_data(fa_icon, "icon", "far fa-circle-notch fa-spin");
			set_custom_element_data(fa_icon, "size", "0.9");
			set_custom_element_data(fa_icon, "unit", "rem");
			set_custom_element_data(fa_icon, "color", "#817f7f");
			add_location(fa_icon, file, 2105, 40, 40073);
			attr_dev(span, "class", "font-medium roboto-medium");
			add_location(span, file, 2104, 36, 39970);
			add_location(li, file, 2103, 12, 39928);
		},
		m: function mount(target, anchor) {
			insert_dev(target, li, anchor);
			append_dev(li, span);
			append_dev(span, t0);
			append_dev(span, t1);
			append_dev(span, fa_icon);
		},
		p: noop,
		d: function destroy(detaching) {
			if (detaching) detach_dev(li);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block.name,
		type: "if",
		source: "(2103:8) {#if isloading === 'true'}",
		ctx
	});

	return block;
}

// (2109:8) {#each list as {value, guid}}
function create_each_block(ctx) {
	let li;
	let t0_value = /*value*/ ctx[21] + "";
	let t0;
	let t1;
	let mounted;
	let dispose;

	function click_handler_2(...args) {
		return /*click_handler_2*/ ctx[14](/*value*/ ctx[21], /*guid*/ ctx[22], ...args);
	}

	const block = {
		c: function create() {
			li = element("li");
			t0 = text(t0_value);
			t1 = space();
			add_location(li, file, 2109, 12, 40259);
		},
		m: function mount(target, anchor) {
			insert_dev(target, li, anchor);
			append_dev(li, t0);
			append_dev(li, t1);

			if (!mounted) {
				dispose = listen_dev(li, "click", click_handler_2, false, false, false, false);
				mounted = true;
			}
		},
		p: function update(new_ctx, dirty) {
			ctx = new_ctx;
			if (dirty & /*list*/ 4 && t0_value !== (t0_value = /*value*/ ctx[21] + "")) set_data_dev(t0, t0_value);
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(li);
			mounted = false;
			dispose();
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_each_block.name,
		type: "each",
		source: "(2109:8) {#each list as {value, guid}}",
		ctx
	});

	return block;
}

function create_fragment(ctx) {
	let div;
	let ul;
	let li;
	let t1;
	let t2;
	let t3;
	let mounted;
	let dispose;
	let if_block0 = bc.parameterService.appParameters.userId && /*isactorlist*/ ctx[0] === 'true' && create_if_block_1(ctx);
	let if_block1 = /*isloading*/ ctx[1] === 'true' && create_if_block(ctx);
	let each_value = /*list*/ ctx[2];
	validate_each_argument(each_value);
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	const block = {
		c: function create() {
			div = element("div");
			ul = element("ul");
			li = element("li");
			li.textContent = `${/*componentText*/ ctx[4].noFilter}`;
			t1 = space();
			if (if_block0) if_block0.c();
			t2 = space();
			if (if_block1) if_block1.c();
			t3 = space();

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			this.c = noop;
			add_location(li, file, 2083, 8, 39237);
			attr_dev(ul, "class", "text-left");
			add_location(ul, file, 2082, 4, 39205);
			attr_dev(div, "class", "drop-down-list border border-solid border-gray-300 rounded mt-1");
			add_location(div, file, 2080, 0, 39120);
		},
		l: function claim(nodes) {
			throw new Error("options.hydrate only works if the component was compiled with the `hydratable: true` option");
		},
		m: function mount(target, anchor) {
			insert_dev(target, div, anchor);
			append_dev(div, ul);
			append_dev(ul, li);
			append_dev(ul, t1);
			if (if_block0) if_block0.m(ul, null);
			append_dev(ul, t2);
			if (if_block1) if_block1.m(ul, null);
			append_dev(ul, t3);

			for (let i = 0; i < each_blocks.length; i += 1) {
				if (each_blocks[i]) {
					each_blocks[i].m(ul, null);
				}
			}

			if (!mounted) {
				dispose = listen_dev(li, "click", /*click_handler*/ ctx[12], false, false, false, false);
				mounted = true;
			}
		},
		p: function update(ctx, [dirty]) {
			if (bc.parameterService.appParameters.userId && /*isactorlist*/ ctx[0] === 'true') {
				if (if_block0) {
					if_block0.p(ctx, dirty);
				} else {
					if_block0 = create_if_block_1(ctx);
					if_block0.c();
					if_block0.m(ul, t2);
				}
			} else if (if_block0) {
				if_block0.d(1);
				if_block0 = null;
			}

			if (/*isloading*/ ctx[1] === 'true') {
				if (if_block1) {
					if_block1.p(ctx, dirty);
				} else {
					if_block1 = create_if_block(ctx);
					if_block1.c();
					if_block1.m(ul, t3);
				}
			} else if (if_block1) {
				if_block1.d(1);
				if_block1 = null;
			}

			if (dirty & /*dispatchSelect, list*/ 36) {
				each_value = /*list*/ ctx[2];
				validate_each_argument(each_value);
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(ul, null);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value.length;
			}
		},
		i: noop,
		o: noop,
		d: function destroy(detaching) {
			if (detaching) detach_dev(div);
			if (if_block0) if_block0.d();
			if (if_block1) if_block1.d();
			destroy_each(each_blocks, detaching);
			mounted = false;
			dispose();
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_fragment.name,
		type: "component",
		source: "",
		ctx
	});

	return block;
}

function instance($$self, $$props, $$invalidate) {
	let $applicationReady;
	validate_store(applicationReady, 'applicationReady');
	component_subscribe($$self, applicationReady, $$value => $$invalidate(11, $applicationReady = $$value));
	let { $$slots: slots = {}, $$scope } = $$props;
	validate_slots('drop-down-list', slots, []);
	let { show = 'true' } = $$props;
	let { init = 'true' } = $$props;
	let { listvalues = '' } = $$props;
	let { isactorlist = 'false' } = $$props;
	let { isloading = 'false' } = $$props;

	// Base variables
	//================================================================
	let component = get_current_component();

	let firstInitialized = false;
	let subscribeGuid = uuidv4();
	let ready = false;
	const generalText = getComponentText('general');
	const componentText = getComponentText('filterDropDown');
	let mounted = false;
	let canReload = false;

	// Component Variables
	let list = [];

	// HTML Mount & Component initialization
	//================================================================
	onMount(() => {
		attachNormalize(component);

		tick().then(() => {
			dispatchMountedEvent(component);
			$$invalidate(10, mounted = true);
		});

		return () => {
			bc.messageService.unsubscribeComponent(subscribeGuid);
			window.removeEventListener('click', handleClickOutSide, false);
		};
	});

	const initComponent = () => {
		$$invalidate(9, firstInitialized = true);
		ready = true;
		window.addEventListener('click', handleClickOutSide, false);
	};

	// Component functions
	//================================================================
	const dispatchSelect = (value, guid) => {
		console.log('value', value, 'guid', guid);

		let event = createEvent('select', { value, guid }, {
			bubbles: false,
			cancelable: true,
			composed: false
		});

		component.dispatchEvent(event);
	};

	function handleClickOutSide(e) {
		if (!component.contains(e.target)) {
			let event = createEvent('clickoutside', null, {
				bubbles: false,
				cancelable: true,
				composed: false
			});

			component.dispatchEvent(event);
		}
	}

	const writable_props = ['show', 'init', 'listvalues', 'isactorlist', 'isloading'];

	

	const click_handler = e => {
		e.stopPropagation();
		dispatchSelect('', '');
	};

	const click_handler_1 = e => {
		e.stopPropagation();
		dispatchSelect(generalText.loggedInUser, 'current user');
	};

	const click_handler_2 = (value, guid, e) => {
		e.stopPropagation();
		dispatchSelect(value, guid);
	};

	$$self.$$set = $$props => {
		if ('show' in $$props) $$invalidate(6, show = $$props.show);
		if ('init' in $$props) $$invalidate(7, init = $$props.init);
		if ('listvalues' in $$props) $$invalidate(8, listvalues = $$props.listvalues);
		if ('isactorlist' in $$props) $$invalidate(0, isactorlist = $$props.isactorlist);
		if ('isloading' in $$props) $$invalidate(1, isloading = $$props.isloading);
	};

	$$self.$capture_state = () => ({
		bc,
		uuidv4,
		onMount,
		tick,
		attachNormalize,
		componentInitAndAttributeHandler,
		dispatchMountedEvent,
		handleComponentShowAttribute,
		get_current_component,
		applicationReady,
		createEvent,
		getComponentText,
		show,
		init,
		listvalues,
		isactorlist,
		isloading,
		component,
		firstInitialized,
		subscribeGuid,
		ready,
		generalText,
		componentText,
		mounted,
		canReload,
		list,
		initComponent,
		dispatchSelect,
		handleClickOutSide,
		$applicationReady
	});

	$$self.$inject_state = $$props => {
		if ('show' in $$props) $$invalidate(6, show = $$props.show);
		if ('init' in $$props) $$invalidate(7, init = $$props.init);
		if ('listvalues' in $$props) $$invalidate(8, listvalues = $$props.listvalues);
		if ('isactorlist' in $$props) $$invalidate(0, isactorlist = $$props.isactorlist);
		if ('isloading' in $$props) $$invalidate(1, isloading = $$props.isloading);
		if ('component' in $$props) $$invalidate(16, component = $$props.component);
		if ('firstInitialized' in $$props) $$invalidate(9, firstInitialized = $$props.firstInitialized);
		if ('subscribeGuid' in $$props) subscribeGuid = $$props.subscribeGuid;
		if ('ready' in $$props) ready = $$props.ready;
		if ('mounted' in $$props) $$invalidate(10, mounted = $$props.mounted);
		if ('canReload' in $$props) $$invalidate(18, canReload = $$props.canReload);
		if ('list' in $$props) $$invalidate(2, list = $$props.list);
	};

	if ($$props && "$$inject" in $$props) {
		$$self.$inject_state($$props.$$inject);
	}

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*show*/ 64) {
			$: if (show) {
				handleComponentShowAttribute(show, component);
			}
		}

		if ($$self.$$.dirty & /*$applicationReady, mounted, init, firstInitialized*/ 3712) {
			$: if ($applicationReady && mounted && init) {
				componentInitAndAttributeHandler({
					mounted,
					handler: initComponent,
					init,
					firstInitialized,
					canReload
				});
			}
		}

		if ($$self.$$.dirty & /*listvalues*/ 256) {
			$: if (listvalues) {
				$$invalidate(2, list = JSON.parse(listvalues));
			}
		}
	};

	return [
		isactorlist,
		isloading,
		list,
		generalText,
		componentText,
		dispatchSelect,
		show,
		init,
		listvalues,
		firstInitialized,
		mounted,
		$applicationReady,
		click_handler,
		click_handler_1,
		click_handler_2
	];
}

class DropDownList extends SvelteElement {
	constructor(options) {
		super();
		const style = document.createElement('style');
		style.textContent = `100%{left:0;right:0}100%{left:0;right:0}100%{opacity:1}100%{opacity:1}45%{-webkit-transform:scale(var(--fa-beat-scale, 1.25));transform:scale(var(--fa-beat-scale, 1.25))}45%{-webkit-transform:scale(var(--fa-beat-scale, 1.25));transform:scale(var(--fa-beat-scale, 1.25))}50%{opacity:1;-webkit-transform:scale(var(--fa-beat-fade-scale, 1.125));transform:scale(var(--fa-beat-fade-scale, 1.125))}50%{opacity:1;-webkit-transform:scale(var(--fa-beat-fade-scale, 1.125));transform:scale(var(--fa-beat-fade-scale, 1.125))}100%{-webkit-transform:rotate(360deg);transform:rotate(360deg)}100%{-webkit-transform:rotate(360deg);transform:rotate(360deg)}.drop-down-list{background:white;max-height:250px;overflow:auto;width:230px;box-shadow:4px 5px 7px 0px #0000001c}.drop-down-list ul{margin:0px;padding:0px}.drop-down-list ul li{list-style:none;padding:10px;margin:0;cursor:pointer}.drop-down-list ul li:hover{background-color:aliceblue}.border-gray-300{--tw-border-opacity:1;border-color:rgba(209, 213, 219, var(--tw-border-opacity))}.rounded{border-radius:0.25rem}.border-solid{border-style:solid}.border{border-width:1px}.font-medium{font-family:Roboto-Medium}.mt-1{margin-top:0.25rem}.text-left{text-align:left}`;
		this.shadowRoot.appendChild(style);

		init_1(
			this,
			{
				target: this.shadowRoot,
				props: attribute_to_object(this.attributes),
				customElement: true
			},
			instance,
			create_fragment,
			safe_not_equal,
			{
				show: 6,
				init: 7,
				listvalues: 8,
				isactorlist: 0,
				isloading: 1
			},
			null
		);

		if (options) {
			if (options.target) {
				insert_dev(options.target, this, options.anchor);
			}

			if (options.props) {
				this.$set(options.props);
				flush();
			}
		}
	}

	static get observedAttributes() {
		return ["show", "init", "listvalues", "isactorlist", "isloading"];
	}

	get show() {
		return this.$$.ctx[6];
	}

	set show(show) {
		this.$$set({ show });
		flush();
	}

	get init() {
		return this.$$.ctx[7];
	}

	set init(init) {
		this.$$set({ init });
		flush();
	}

	get listvalues() {
		return this.$$.ctx[8];
	}

	set listvalues(listvalues) {
		this.$$set({ listvalues });
		flush();
	}

	get isactorlist() {
		return this.$$.ctx[0];
	}

	set isactorlist(isactorlist) {
		this.$$set({ isactorlist });
		flush();
	}

	get isloading() {
		return this.$$.ctx[1];
	}

	set isloading(isloading) {
		this.$$set({ isloading });
		flush();
	}
}

customElements.define("drop-down-list", DropDownList);
export default DropDownList;
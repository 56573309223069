/* src\components\Popover\Popover-Calendar.svelte generated by Svelte v3.57.0 */
import {
	SvelteElement,
	add_location,
	append_dev,
	attr_dev,
	attribute_to_object,
	detach_dev,
	dispatch_dev,
	element,
	flush,
	init,
	insert_dev,
	noop,
	safe_not_equal,
	set_data_dev,
	space,
	text,
	validate_slots
} from "../../../web_modules/svelte/internal.js";

import '../../bcShim.js';
import { get_current_component } from "../../../web_modules/svelte/internal.js";
import { onMount } from "../../../web_modules/svelte.js";
import { attachNormalize } from "../../utilities/componentUtils.js";
import { getComponentText } from "../../utilities/generalUtils.js";
const file = "src\\components\\Popover\\Popover-Calendar.svelte";

function create_fragment(ctx) {
	let div;
	let span0;
	let t0;
	let t1;
	let span2;
	let span1;
	let t4;
	let t5;
	let t6;
	let span4;
	let span3;
	let t9;
	let t10;

	const block = {
		c: function create() {
			div = element("div");
			span0 = element("span");
			t0 = text(/*name*/ ctx[0]);
			t1 = space();
			span2 = element("span");
			span1 = element("span");
			span1.textContent = `${/*generalText*/ ctx[3].from}:`;
			t4 = space();
			t5 = text(/*start*/ ctx[1]);
			t6 = space();
			span4 = element("span");
			span3 = element("span");
			span3.textContent = `${/*generalText*/ ctx[3].to}:`;
			t9 = space();
			t10 = text(/*end*/ ctx[2]);
			this.c = noop;
			attr_dev(span0, "class", "text-lg");
			add_location(span0, file, 53, 4, 1476);
			attr_dev(span1, "class", "underline");
			add_location(span1, file, 54, 10, 1523);
			add_location(span2, file, 54, 4, 1517);
			attr_dev(span3, "class", "underline");
			add_location(span3, file, 55, 10, 1600);
			add_location(span4, file, 55, 4, 1594);
			attr_dev(div, "class", "text-white flex flex-col");
			add_location(div, file, 52, 0, 1431);
		},
		l: function claim(nodes) {
			throw new Error("options.hydrate only works if the component was compiled with the `hydratable: true` option");
		},
		m: function mount(target, anchor) {
			insert_dev(target, div, anchor);
			append_dev(div, span0);
			append_dev(span0, t0);
			append_dev(div, t1);
			append_dev(div, span2);
			append_dev(span2, span1);
			append_dev(span2, t4);
			append_dev(span2, t5);
			append_dev(div, t6);
			append_dev(div, span4);
			append_dev(span4, span3);
			append_dev(span4, t9);
			append_dev(span4, t10);
		},
		p: function update(ctx, [dirty]) {
			if (dirty & /*name*/ 1) set_data_dev(t0, /*name*/ ctx[0]);
			if (dirty & /*start*/ 2) set_data_dev(t5, /*start*/ ctx[1]);
			if (dirty & /*end*/ 4) set_data_dev(t10, /*end*/ ctx[2]);
		},
		i: noop,
		o: noop,
		d: function destroy(detaching) {
			if (detaching) detach_dev(div);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_fragment.name,
		type: "component",
		source: "",
		ctx
	});

	return block;
}

function instance($$self, $$props, $$invalidate) {
	let { $$slots: slots = {}, $$scope } = $$props;
	validate_slots('event-pop-over', slots, []);
	let { name = "" } = $$props;
	let { description = "" } = $$props;
	let { start = "" } = $$props;
	let { end = "" } = $$props;

	// Component variables
	//================================================================
	const generalText = getComponentText('general');

	const component = get_current_component();

	// HTML Mount & Component initialization
	//================================================================
	onMount(() => {
		attachNormalize(component);
	});

	const writable_props = ['name', 'description', 'start', 'end'];

	

	$$self.$$set = $$props => {
		if ('name' in $$props) $$invalidate(0, name = $$props.name);
		if ('description' in $$props) $$invalidate(4, description = $$props.description);
		if ('start' in $$props) $$invalidate(1, start = $$props.start);
		if ('end' in $$props) $$invalidate(2, end = $$props.end);
	};

	$$self.$capture_state = () => ({
		get_current_component,
		onMount,
		attachNormalize,
		getComponentText,
		name,
		description,
		start,
		end,
		generalText,
		component
	});

	$$self.$inject_state = $$props => {
		if ('name' in $$props) $$invalidate(0, name = $$props.name);
		if ('description' in $$props) $$invalidate(4, description = $$props.description);
		if ('start' in $$props) $$invalidate(1, start = $$props.start);
		if ('end' in $$props) $$invalidate(2, end = $$props.end);
	};

	if ($$props && "$$inject" in $$props) {
		$$self.$inject_state($$props.$$inject);
	}

	return [name, start, end, generalText, description];
}

class Popover_Calendar extends SvelteElement {
	constructor(options) {
		super();
		const style = document.createElement('style');
		style.textContent = `.flex{display:-webkit-box;display:-ms-flexbox;display:-webkit-flex;display:flex}.flex-col{-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;-webkit-flex-direction:column;flex-direction:column}.text-lg{font-size:1.125rem;line-height:1.75rem}.text-white{--tw-text-opacity:1;color:rgba(255, 255, 255, var(--tw-text-opacity))}.underline{-webkit-text-decoration-line:underline;text-decoration-line:underline}`;
		this.shadowRoot.appendChild(style);

		init(
			this,
			{
				target: this.shadowRoot,
				props: attribute_to_object(this.attributes),
				customElement: true
			},
			instance,
			create_fragment,
			safe_not_equal,
			{
				name: 0,
				description: 4,
				start: 1,
				end: 2
			},
			null
		);

		if (options) {
			if (options.target) {
				insert_dev(options.target, this, options.anchor);
			}

			if (options.props) {
				this.$set(options.props);
				flush();
			}
		}
	}

	static get observedAttributes() {
		return ["name", "description", "start", "end"];
	}

	get name() {
		return this.$$.ctx[0];
	}

	set name(name) {
		this.$$set({ name });
		flush();
	}

	get description() {
		return this.$$.ctx[4];
	}

	set description(description) {
		this.$$set({ description });
		flush();
	}

	get start() {
		return this.$$.ctx[1];
	}

	set start(start) {
		this.$$set({ start });
		flush();
	}

	get end() {
		return this.$$.ctx[2];
	}

	set end(end) {
		this.$$set({ end });
		flush();
	}
}

customElements.define("event-pop-over", Popover_Calendar);
export default Popover_Calendar;
export const getLocation = (userId) => {
  const locationTemp = window.location.href;
  let tab = locationTemp.split("?");
  let location = tab[0];
  tab = locationTemp.split("/");
  let lastElement = tab[tab.length - 1];
  if (lastElement.indexOf("?") === -1) {
    const regexGuid = /[[a-zA-Z0-9]{8}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{12}]*/gi;
    const matches = lastElement.match(regexGuid);
    if (matches && matches.length > 0) {
      location = location.substring(0, location.lastIndexOf("/"));
    }
  } else {
    if (location.endsWith("/")) {
      location = location.substring(0, location.length - 1);
    }
  }
  if (userId) {
    location = location + "_" + userId;
  }
  return location;
};

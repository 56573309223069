import bc from "../bcShim.js";
import {ongoingRequestHandler} from "./requestUtils.js";
import {requestHeaders} from "../services/ajaxService.js";
export const getCodeListValue = async (value) => {
  if (!value) {
    return null;
  }
  let filter = "";
  if (bc.parameterService.appParameters.serverVersion >= "4.0") {
    filter = `TY_ID eq ${value}`;
  } else {
    filter = `TY_ID eq guid'${value}'`;
  }
  let url = bc.parameterService.appParameters.OdataBaseUrl;
  if (bc.parameterService.appParameters.serverVersion >= "4.0") {
    url += "Metadata/UgdmTypes?$filter=" + filter;
  } else {
    url += "UgdmMeta/UgdmTypes?$filter=" + filter;
  }
  let result = await ongoingRequestHandler(url, requestHeaders.jsonGet, 2e3, "json");
  if (result && result["value"] && result["value"][0]) {
    return result["value"][0]["TY_LONGNAME"];
  }
  return null;
};
export const getEntityListValue = async (fieldName, metadata, value) => {
  if (!value) {
    return null;
  }
  let column = metadata.properties.columns.find((c) => c.field === fieldName);
  let filter = `(${column.filter}) and EY_ID eq guid'${value}'`;
  const url = bc.parameterService.appParameters.OdataBaseUrl + "UgdmCore/UgdmEntities?$filter=" + filter + "&$orderby=EY_LONGNAME";
  let result = await ongoingRequestHandler(url, requestHeaders.jsonGet, 2e3, "json");
  if (result && result["value"] && result["value"][0]) {
    return result["value"][0]["EY_LONGNAME"];
  }
  return null;
};
export const getViewListValue = async (fieldName, metadata, value) => {
  if (!value) {
    return null;
  }
  return null;
};
export const getEntityListEntryByName = async (fieldName, metadata, value) => {
  let column = metadata.properties.columns.find((c) => c.field === fieldName);
  let filter = `${column.filter} and EY_LONGNAME  eq '${value}'`;
  const url = bc.parameterService.appParameters.OdataBaseUrl + "UgdmCore/UgdmEntities?$filter=" + filter;
  let result = await ongoingRequestHandler(url, requestHeaders.jsonGet, 2e3, "json");
  if (result && result["value"] && result["value"][0]) {
    return result["value"][0];
  }
  return null;
};

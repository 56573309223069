import bc from "../bcShim.js";
import {constants} from "../constants.js";
export class DatviewAnalyser {
  constructor() {
    this.entityTypes = [];
    this.entityAttributes = [];
    this.dataviewAttributes = [];
    this.complexAttributeTypes = [];
    this.codeListTypes = [];
    this.geoTables = [];
    this.databaseSchemas = [];
    this.view = null;
    this.entityListTypeGuid = "";
    this.simpleAttributeTypeGuid = "";
    this.codeListAttributeGuid = "";
    this.errors = [];
    this.notNullable = [
      "ID",
      "EY_TYPE"
    ];
    this.dataViewToEntityType = [];
    this.coreAttributes = [];
    this.simpleGeometryComplexType = "";
    this.multiGeometryComplexType = "";
    this.geometryDataTypes = [];
    this.errorAttributes = [];
    this.dataTypesList = [];
    this.isSetup = false;
    this.coreAttributeNames = [];
    this.setup = async () => {
      if (!this.isSetup) {
        this.errors = [];
        this.entityTypes = await bc.metadataManager.getUgdmTypesByDomain("e612a1cf-bcfb-4271-8879-1a24934c455d");
        this.dataTypesList = await bc.metadataManager.getUgdmTypesByDomain(constants.domain.DATA_TYPE);
        this.objectMetadataAttributes = await bc.parameterService.getMetadata("f2c29445-bddf-48c5-a6d5-e0c0433ce398");
        this.complexAttributeTypes = await bc.metadataManager.getUgdmTypesByDomain("c700260b-7d84-44b7-982b-87423f2b3f28");
        this.geoTables = await bc.metadataManager.getUgdmTypesByDomain("b93b97a4-36a5-4145-9b5a-4897514664fa");
        this.codeListTypes = await bc.metadataManager.getUgdmTypesByDomain("caaf9a99-335d-467b-bf0c-037e9001c729");
        this.databaseSchemas = await bc.metadataManager.getUgdmTypesByDomain(constants.domain.DATABASE_SCHEMA);
        this.dataTypes = this.objectMetadataAttributes.properties.listMapping.find((lm) => lm.fieldName === "AT_DATA_TYPE");
        let coreAttributesRequest = await bc.metadataManager.fetchUgdmCoreUgdmEntityAttributes();
        if (coreAttributesRequest.value) {
          this.coreAttributes = coreAttributesRequest.value;
        }
        console.log("coreAttributes", this.coreAttributes);
        this.entityListTypeGuid = this.complexAttributeTypes.find((f) => f.TY_NAME === "EntityList").TY_ID;
        this.simpleAttributeTypeGuid = this.complexAttributeTypes.find((f) => f.TY_NAME === "SimpleAttribute").TY_ID;
        this.codeListAttributeGuid = this.complexAttributeTypes.find((f) => f.TY_NAME === "CodeList").TY_ID;
        this.simpleGeometryComplexType = this.complexAttributeTypes.find((f) => f.TY_NAME === "MonovaluedGeometry").TY_ID;
        this.multiGeometryComplexType = this.complexAttributeTypes.find((f) => f.TY_NAME === "MultivaluedGeometry").TY_ID;
        this.geometryDataTypes = this.dataTypes.values.map((d) => {
          if (d.value === "Point" || d.value === "LineString" || d.value === "Polygon" || d.value === "MultiPoint" || d.value === "MultiLineString" || d.value === "MultiPolygon") {
            return d.guid;
          }
        });
        this.isSetup = true;
      }
    };
    this.analyseDataview = async (dataviewguid) => {
      this.errors = [];
      this.view = await bc.metadataManager.getUgdmTypeById(dataviewguid);
      await this.analyseAttributes();
      return this.errors;
      console.log("errors", this.errors);
    };
    this.analyseAttributes = async () => {
      this.dataviewAttributes = await bc.metadataManager.getAttributesByEntityTypes(this.view.TY_ID);
      this.coreAttributeNames = this.coreAttributes.map((c) => {
        if (c.AT_PRIMARY_KEY) {
          return this.view.TY_ACRONYM + "_ID";
        } else {
          return this.view.TY_ACRONYM + "_" + c.TY_NAME;
        }
      });
      let entityType = await bc.metadataManager.fetchEntityTypeOfUserView(this.view.TY_ID);
      console.log("entityType", entityType);
      if (entityType && entityType[0].TR_TO) {
        this.entityAttributes = await bc.metadataManager.getAttributesByEntityTypes(entityType[0].TR_TO);
      }
      if (this.dataviewAttributes) {
        for (let j = 0; j < this.dataviewAttributes.length; j++) {
          await this.checkAttribute(this.dataviewAttributes[j], true);
        }
      }
    };
    this.getDataType = (guid) => {
      const dataTypeElement = this.dataTypes.values.find((v) => v.guid === guid);
      if (dataTypeElement) {
        return dataTypeElement.value;
      }
      return null;
    };
    this.checkAttribute = async (attribute, parentCheck) => {
      this.checkBaseAttributeMetadataVisibility(attribute);
      this.checkBaseAttributeMetadataNotNullable(attribute);
      if (parentCheck) {
        this.parentCheck(attribute);
      }
      this.hasName(attribute);
      this.hasLongName(attribute);
      this.checkAttributeTY_NAMELength(attribute);
      this.checkAttributeTY_LONGNAMELength(attribute);
      this.checkRequiredBooleanFieldsPresent(attribute);
      this.checkNullNotPossible(attribute);
      this.checkEditable(attribute);
      this.checkQueryable(attribute);
      this.checkTyRemarksDifferentFromTyName(attribute);
      this.EY_TYPEcheck(attribute);
      this.EY_IDcheck(attribute);
      this.checkPrimaryKeyIsGuid(attribute);
      this.checkNextIdDefaultValue(attribute);
      this.geometryFieldHasGeometryDataType(attribute);
      this.geometryFieldsAlwaysVisibleAndNotNullable(attribute);
      this.guidCheck(attribute);
      await this.checkNullNotPossibleForAlreadyExistingNullValues(attribute);
      await this.checkFilterGuids(attribute);
    };
    this.resetErrors = () => {
      this.errors = [];
    };
    this.setView = (v) => {
      this.view = v;
    };
    this.checkFilterGuids = async (attribute) => {
      if (attribute.AT_COMPLEX_TYPE === this.codeListAttributeGuid && attribute.AT_COMPLEX && attribute.TY_REMARKS) {
        let tempFilter = attribute.TY_REMARKS.split(";")[0];
        let matches = tempFilter.match(/[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}/gm);
        let codeList = await bc.metadataManager.getUgdmTypesByDomain(attribute.AT_COMPLEX);
        if (codeList) {
          if (matches) {
            let missingValue = false;
            let missingValues = [];
            let codeListGuids = codeList.map((c) => c.TY_ID);
            for (let i = 0; i < matches.length; i++) {
              let value = matches[i];
              if (!codeListGuids.includes(value)) {
                missingValue = true;
                missingValues.push(value);
              }
            }
            if (missingValue) {
              this.errors = [
                ...this.errors,
                {
                  viewName: this.view.TY_LONGNAME,
                  attribute,
                  message: `Codeliste hat Filter mit Guid-Werten, die nicht in der Liste sind. Diese Werte fehlen in der Liste: ${missingValues.join(", ")}`,
                  level: "error"
                }
              ];
            }
          }
        } else {
          if (matches.length > 0) {
            this.errors = [
              ...this.errors,
              {
                viewName: this.view.TY_LONGNAME,
                attribute,
                message: `Codelist ist leer aber enth\xE4lt Filter-GUIDs`,
                level: "error"
              }
            ];
          }
        }
      }
      if (attribute.AT_COMPLEX_TYPE === this.entityListTypeGuid && attribute.AT_COMPLEX && attribute.TY_REMARKS) {
        let matches = attribute.TY_REMARKS.match(/[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}/gm);
        let entityListRequest = await bc.metadataManager.getEntitiesFromEntityType(attribute.AT_COMPLEX);
        if (entityListRequest) {
          let entityList = entityListRequest.value;
          if (matches) {
            let missingValue = false;
            let missingValues = [];
            let entityListGuids = entityList.map((c) => c.EY_ID);
            for (let i = 0; i < matches.length; i++) {
              let value = matches[i];
              if (!entityListGuids.includes(value)) {
                missingValue = true;
                missingValues.push(value);
              }
            }
            if (missingValue) {
              this.errors = [
                ...this.errors,
                {
                  viewName: this.view.TY_LONGNAME,
                  attribute,
                  message: `EntityList hat Filter, enth\xE4lt aber NICHT folgende guids: ${missingValues.join(", ")}`,
                  level: "error"
                }
              ];
            }
          }
        } else {
          if (matches.length > 0) {
            this.errors = [
              ...this.errors,
              {
                viewName: this.view.TY_LONGNAME,
                attribute,
                message: `Entitlist ist leer aver enth\xE4lt Filter-GUIDs`,
                level: "error"
              }
            ];
          }
        }
      }
    };
    this.checkNullNotPossibleForAlreadyExistingNullValues = async (attribute) => {
      if (attribute.AT_NULLABLE === false) {
        try {
          const data = await bc.metadataManager.checkColumnForExistenNullValues(this.view.TY_NAME, attribute.TY_NAME);
          console.log("data", data);
          if (data) {
            this.errors = [
              ...this.errors,
              {
                viewName: this.view.TY_LONGNAME,
                attribute,
                message: `"Null m\xF6glich?" auf false aber es existieren Entit\xE4ten wo dieses Feld leer ist`,
                level: "error"
              }
            ];
          }
        } catch (error) {
          console.log(error);
        }
      }
    };
    this.checkAttributeTY_NAMELength = (attribute) => {
      if (attribute.TY_NAME.length > 50) {
        this.errors = [
          ...this.errors,
          {
            viewName: this.view.TY_LONGNAME,
            attribute,
            message: `TY_NAME/Name darf nicht l\xE4nger als 50 Character sein.`,
            level: "error"
          }
        ];
      }
    };
    this.checkAttributeTY_LONGNAMELength = (attribute) => {
      if (attribute.TY_LONGNAME.length > 250) {
        this.errors = [
          ...this.errors,
          {
            viewName: this.view.TY_LONGNAME,
            attribute,
            message: `TY_LONGNAME/Bezeichnung darf nicht l\xE4nger als 250 Character sein.`,
            level: "error"
          }
        ];
      }
    };
    this.checkStringAttributes = (attribute) => {
      if (attribute.AT_DATA_TYPE === "eb2d052b-d5ab-4eaf-bada-70d65634efc4") {
        if (attribute.AT_LENGTH === 0) {
          this.errors = [
            ...this.errors,
            {
              viewName: this.view.TY_LONGNAME,
              attribute,
              message: `String-Attribute m\xFCssen eine L\xE4nge enthalten (AT_LENGTH).`,
              level: "error"
            }
          ];
        }
        if (attribute.AT_LENGTH) {
          if (attribute.AT_VIEW_LENGTH !== void 0 || attribute.AT_VIEW_LENGTH !== null) {
            this.errors = [
              ...this.errors,
              {
                viewName: this.view.TY_LONGNAME,
                attribute,
                message: `Die Anzeigel\xE4nge von String-Attributen muss definiert sein.`,
                level: "error"
              }
            ];
          }
        }
      }
    };
    this.checkTyRemarksDifferentFromTyName = (attribute) => {
      if (attribute.TY_NAME === attribute.TY_REMARKS) {
        this.errors = [
          ...this.errors,
          {
            viewName: this.view.TY_LONGNAME,
            attribute,
            message: `Bemerkungen denselben Wert wie im Namen.`,
            level: "error"
          }
        ];
      }
    };
    this.hasEntityList = (attribute) => {
      if (attribute.AT_COMPLEX_TYPE === this.entityListTypeGuid) {
        if (attribute.AT_COMPLEX === void 0 || attribute.AT_COMPLEX === null || attribute.AT_COMPLEX === "") {
          this.errors = [
            ...this.errors,
            {
              viewName: this.view.TY_LONGNAME,
              attribute,
              message: `Entit\xE4tsliste hat keine Entit\xE4tsliste angegeben`,
              level: "error"
            }
          ];
        }
      }
    };
    this.checkBaseAttributeMetadataVisibility = (attribute) => {
      if (this.isBaseAttribute(attribute.TY_NAME)) {
        this.checkVisibileMetadata(attribute);
      }
    };
    this.checkVisibileMetadata = (attribute) => {
      if (attribute.AT_VISIBLE === void 0 || attribute.AT_VISIBLE === null || attribute.AT_VISIBLE === false) {
        this.errors = [
          ...this.errors,
          {
            viewName: this.view.TY_LONGNAME,
            attribute,
            message: `Standart-Attribut aber ist nicht sichtbar in den Metadaten`,
            level: "error"
          }
        ];
      }
    };
    this.checkBaseAttributeMetadataNotNullable = (attribute) => {
      if (this.isBaseAttribute(attribute.TY_NAME)) {
        this.checkNullableMetadata(attribute);
      }
    };
    this.checkNullableMetadata = (attribute) => {
      if (attribute.AT_NULLABLE === void 0 || attribute.AT_NULLABLE === null || attribute.AT_NULLABLE === true) {
        this.errors = [
          ...this.errors,
          {
            viewName: this.view.TY_LONGNAME,
            attribute,
            message: `Standart-Attribut auf "NULL m\xF6glich" gestellt.`,
            level: "warning"
          }
        ];
      }
    };
    this.geometryFieldHasGeometryDataType = (attribute) => {
      if (attribute.AT_COMPLEX_TYPE === this.simpleGeometryComplexType || attribute.AT_COMPLEX_TYPE === this.multiGeometryComplexType) {
        if (!this.geometryDataTypes.includes(attribute.AT_DATA_TYPE)) {
          this.errors = [
            ...this.errors,
            {
              viewName: this.view.TY_LONGNAME,
              attribute,
              message: `Keine Geometrie-Art (Point/Line etc..) angegeben`,
              level: "error"
            }
          ];
        }
      }
    };
    this.geometryFieldsAlwaysVisibleAndNotNullable = (attribute) => {
      if (attribute.AT_COMPLEX_TYPE === this.simpleGeometryComplexType || attribute.AT_COMPLEX_TYPE === this.multiGeometryComplexType || attribute.TY_NAME === "OBJECTID") {
        if (attribute.AT_VISIBLE === false) {
          this.errors = [
            ...this.errors,
            {
              viewName: this.view.TY_LONGNAME,
              attribute,
              message: `Geometrie-Felder m\xFCssen immer "sichtbar" sein in den Metadaten.`,
              level: "error"
            }
          ];
        }
        if (attribute.AT_NULLABLE === true) {
          this.errors = [
            ...this.errors,
            {
              viewName: this.view.TY_LONGNAME,
              attribute,
              message: `Geometrie-Felder d\xFCrfen keine Null Werte enthalten.`,
              level: "error"
            }
          ];
        }
      }
    };
    this.hasCodeList = (attribute) => {
      if (attribute.AT_COMPLEX_TYPE === this.codeListAttributeGuid) {
        if (attribute.AT_COMPLEX === void 0 || attribute.AT_COMPLEX === null || attribute.AT_COMPLEX === "") {
          this.errors = [
            ...this.errors,
            {
              viewName: this.view.TY_LONGNAME,
              attribute,
              message: `Keine Codeliste angegeben`,
              level: "error"
            }
          ];
        }
      }
    };
    this.existMoreThanOnce = (attribute) => {
      if (this.dataviewAttributes.find((otherAttribute) => otherAttribute.TY_NAME === attribute.TY_NAME && otherAttribute.TY_ID !== attribute.TY_ID)) {
        this.errors = [
          ...this.errors,
          {
            viewName: this.view.TY_LONGNAME,
            attribute,
            message: `Feld existiert mehr als einmal.`,
            level: "warning"
          }
        ];
      }
    };
    this.notSimpleAttributeCheck = (attribute) => {
      if (attribute.AT_COMPLEX_TYPE && attribute.AT_COMPLEX_TYPE === this.simpleAttributeTypeGuid && attribute.AT_DATA_TYPE === "90d61d2d-d160-4b3d-bf25-d86ec7c7568f") {
        if (attribute.AT_PRIMARY_KEY === void 0 || attribute.AT_PRIMARY_KEY === null || attribute.AT_PRIMARY_KEY === false) {
          if (attribute.TY_NAME !== "OBJECTID") {
            this.errors = [
              ...this.errors,
              {
                viewName: this.view.TY_LONGNAME,
                attribute,
                message: `Guid ist ein Simples Attribut`,
                level: "warning"
              }
            ];
          }
        }
      }
    };
    this.primaryKeyCheck = (attribute) => {
      if (attribute.AT_PRIMARY_KEY && attribute.AT_PRIMARY_KEY === true) {
        if (attribute.AT_COMPLEX_TYPE !== this.simpleAttributeTypeGuid) {
          this.errors = [
            ...this.errors,
            {
              viewName: this.view.TY_LONGNAME,
              attribute,
              message: `Guid (PRIMARY KEY) ist nicht ein Simples Attribut`,
              level: "error"
            }
          ];
        }
      }
    };
    this.simpleAttributeCheck = (attribute) => {
      if (!(attribute.AT_COMPLEX_TYPE === this.simpleAttributeTypeGuid)) {
        this.errors = [
          ...this.errors,
          {
            viewName: this.view.TY_LONGNAME,
            attribute,
            message: `Ist nicht ein Simples Attribut`,
            level: "error"
          }
        ];
      }
    };
    this.defaultValueToLong = (attribute) => {
      if (attribute.AT_DEFAULT_VALUE && attribute.AT_DEFAULT_VALUE !== "" && attribute.AT_DEFAULT_VALUE !== null) {
        if (attribute.AT_DEFAULT_VALUE.length > attribute.AT_LENGTH) {
          this.errors = [
            ...this.errors,
            {
              viewName: this.view.TY_LONGNAME,
              attribute,
              message: `Defaultwert l\xE4nger als das Feld`,
              level: "error"
            }
          ];
        }
      }
    };
    this.parentCheck = (attribute) => {
      if (!attribute.TY_NAME.endsWith("_GEOMETRY") && attribute.TY_NAME !== "OBJECTID") {
        if (this.isBaseAttribute(attribute.TY_NAME)) {
          if (attribute.TY_NAME.endsWith("_ID")) {
          } else {
            let coreAttribute = this.coreAttributes.find((c) => attribute.TY_NAME.endsWith(c.TY_NAME));
            if (coreAttribute) {
              if (attribute.TY_PARENT !== coreAttribute.TY_ID) {
                this.errors = [
                  ...this.errors,
                  {
                    viewName: this.view.TY_LONGNAME,
                    attribute,
                    message: `Falscher Wert in "abgeleited von", der Wert sollte ${coreAttribute.TY_ID} enthalten.`,
                    level: "error"
                  }
                ];
              }
            } else {
              this.errors = [
                ...this.errors,
                {
                  viewName: this.view.TY_LONGNAME,
                  attribute,
                  message: `Scheint einen falschen Namen zu haben.`,
                  level: "error"
                }
              ];
            }
          }
        } else {
          if (this.entityAttributes) {
            let entityAttribute = this.entityAttributes.find((e) => e.TY_ID === attribute.TY_PARENT);
            if (!entityAttribute) {
              this.errors = [
                ...this.errors,
                {
                  viewName: this.view.TY_LONGNAME,
                  attribute,
                  message: `Falscher Wert in "abgeleited von" (nicht in den Entit\xE4tsattributen enthalten).`,
                  level: "error"
                }
              ];
            }
          } else {
            this.errors = [
              ...this.errors,
              {
                viewName: this.view.TY_LONGNAME,
                attribute,
                message: `${this.view.TY_LONGNAME}  -- Keine Entit\xE4tsattribut vorhanden.`,
                level: "error"
              }
            ];
          }
        }
      } else {
      }
    };
    this.checkNullNotPossible = (attribute) => {
      if (attribute.AT_NULLABLE === false && attribute.AT_VISIBLE === false) {
        this.errors = [
          ...this.errors,
          {
            viewName: this.view.TY_LONGNAME,
            attribute,
            message: `"NULL m\xF6glich" auf ja obwohl "sichtbar" nicht auf "ja" gesetzt ist.`,
            level: "error"
          }
        ];
      }
    };
    this.checkEditable = (attribute) => {
      if (attribute.AT_EDITABLE === true && attribute.AT_VISIBLE === false) {
        this.errors = [
          ...this.errors,
          {
            viewName: this.view.TY_LONGNAME,
            attribute,
            message: `"editierbar" auf ja obwohl "sichtbar" nicht auf "ja" gestetzt ist.`,
            level: "error"
          }
        ];
      }
    };
    this.EY_TYPEcheck = (attribute) => {
      if (attribute.TY_NAME.endsWith("EY_TYPE")) {
        if (attribute.AT_EDITABLE === true) {
          this.errors = [
            ...this.errors,
            {
              viewName: this.view.TY_LONGNAME,
              attribute,
              message: `EY_TYPE nicht darf editierbar sein.`,
              level: "error"
            }
          ];
        }
      }
    };
    this.EY_IDcheck = (attribute) => {
      if (attribute.TY_NAME === this.view.TY_ACRONYM + "_ID") {
        if (attribute.AT_NULLABLE === true) {
          this.errors = [
            ...this.errors,
            {
              viewName: this.view.TY_LONGNAME,
              attribute,
              message: `Entit\xE4tsidentifikator darf nicht null sein. "Null m\xF6glich" muss auf false gestellt werden.`,
              level: "error"
            }
          ];
        }
      }
    };
    this.checkRequiredBooleanFieldsPresent = (attribute) => {
      if (attribute.AT_NULLABLE === void 0 || attribute.AT_NULLABLE === null) {
        this.errors = [
          ...this.errors,
          {
            viewName: this.view.TY_LONGNAME,
            attribute,
            message: `"NULL m\xF6glich?" ohne einen Wert. Dieses Feld muss einen Boolean (true/false) enthalten.`,
            level: "error"
          }
        ];
      }
      if (attribute.AT_VISIBLE === void 0 || attribute.AT_VISIBLE === null) {
        this.errors = [
          ...this.errors,
          {
            viewName: this.view.TY_LONGNAME,
            attribute,
            message: `"sichtbar?" ohne einen Wert. Dieses Feld muss einen Boolean (true/false) enthalten.`,
            level: "error"
          }
        ];
      }
      if (attribute.AT_VISIBLE_TABLE === void 0 || attribute.AT_VISIBLE_TABLE === null) {
        this.errors = [
          ...this.errors,
          {
            viewName: this.view.TY_LONGNAME,
            attribute,
            message: `"sichtbar in Tabelle?" ohne einen Wert. Dieses Feld muss einen Boolean (true/false) enthalten.`,
            level: "error"
          }
        ];
      }
      if (attribute.AT_VISIBLE_KANBAN === void 0 || attribute.AT_VISIBLE_KANBAN === null) {
        this.errors = [
          ...this.errors,
          {
            viewName: this.view.TY_LONGNAME,
            attribute,
            message: `"sichtbar im Kanban-Board?" ohne einen Wert. Dieses Feld muss einen Boolean (true/false) enthalten.`,
            level: "error"
          }
        ];
      }
      if (attribute.AT_VISIBLE_SHORT === void 0 || attribute.AT_VISIBLE_SHORT === null) {
        this.errors = [
          ...this.errors,
          {
            viewName: this.view.TY_LONGNAME,
            attribute,
            message: `"sichtbar in Liste?" ohne einen Wert. Dieses Feld muss einen Boolean (true/false) enthalten.`,
            level: "error"
          }
        ];
      }
      if (attribute.AT_QUERYABLE === void 0 || attribute.AT_QUERYABLE === null) {
        this.errors = [
          ...this.errors,
          {
            viewName: this.view.TY_LONGNAME,
            attribute,
            message: `"abfragbar?" ohne einen Wert. Dieses Feld muss einen Boolean (true/false) enthalten.`,
            level: "error"
          }
        ];
      }
      if (attribute.AT_UNIQUE === void 0 || attribute.AT_UNIQUE === null) {
        this.errors = [
          ...this.errors,
          {
            viewName: this.view.TY_LONGNAME,
            attribute,
            message: `"eindeutig?" ohne einen Wert. Dieses Feld muss einen Boolean (true/false) enthalten.`,
            level: "error"
          }
        ];
      }
      if (attribute.AT_INDEXED === void 0 || attribute.AT_INDEXED === null) {
        this.errors = [
          ...this.errors,
          {
            viewName: this.view.TY_LONGNAME,
            attribute,
            message: `"indexiert?" ohne einen Wert. Dieses Feld muss einen Boolean (true/false) enthalten.`,
            level: "error"
          }
        ];
      }
      if (attribute.AT_PRIMARY_KEY === void 0 || attribute.AT_PRIMARY_KEY === null) {
        this.errors = [
          ...this.errors,
          {
            viewName: this.view.TY_LONGNAME,
            attribute,
            message: `"Prim\xE4rschl\xFCssel?" ohne einen Wert. Dieses Feld muss einen Boolean (true/false) enthalten.`,
            level: "error"
          }
        ];
      }
    };
    this.checkQueryable = (attribute) => {
      if (attribute.AT_QUERYABLE === true && attribute.AT_VISIBLE === false) {
        this.errors = [
          ...this.errors,
          {
            viewName: this.view.TY_LONGNAME,
            attribute,
            message: `"abfragbar" auf ja obwohl "sichtbar" nicht auf "ja" gestetzt ist.`,
            level: "error"
          }
        ];
      }
    };
    this.checkVisibleInList = (attribute) => {
      if (attribute.AT_VISIBLE_SHORT === false && attribute.AT_VISIBLE === true) {
        this.errors = [
          ...this.errors,
          {
            viewName: this.view.TY_LONGNAME,
            attribute,
            message: `"sichtbar in Liste" auf nein obwohl "sichtbar" auf "ja" gestetzt ist.`,
            level: "warning"
          }
        ];
      }
    };
    this.isBaseAttribute = (name) => {
      return this.coreAttributeNames.includes(name);
    };
    this.baseAttributeVisible = (attribute) => {
      if (this.isBaseAttribute(attribute.TY_NAME)) {
        if (attribute.AT_VISIBLE === false) {
          this.errors = [
            ...this.errors,
            {
              viewName: this.view.TY_LONGNAME,
              attribute,
              message: `Basisattribut ist nicht sichtbar in den Metadaten`,
              level: "warning"
            }
          ];
        }
      }
    };
    this.hasName = (attribute) => {
      if (!attribute.TY_NAME || attribute.TY_NAME === "") {
        this.errors = [
          ...this.errors,
          {
            viewName: this.view.TY_LONGNAME,
            attribute,
            message: `Hat keinen Namen`,
            level: "error"
          }
        ];
      }
    };
    this.stringMinLength = (attribute) => {
      if (attribute.AT_LENGTH === void 0 || attribute.AT_LENGTH === null || attribute.AT_LENGTH === 0) {
        this.errors = [
          ...this.errors,
          {
            viewName: this.view.TY_LONGNAME,
            attribute,
            message: `Hat keine Mindestl\xE4nge. (L\xE4nge <= 0)`,
            level: "error"
          }
        ];
      }
    };
    this.hasLongName = (attribute) => {
      if (!attribute.TY_LONGNAME || attribute.TY_LONGNAME === void 0 || attribute.TY_LONGNAME === null || attribute.TY_LONGNAME === "") {
        this.errors = [
          ...this.errors,
          {
            viewName: this.view.TY_LONGNAME,
            attribute,
            message: `Hat keine Bezeichnung`,
            level: "error"
          }
        ];
      }
    };
    this.checkDefaultValueDate = (attribute) => {
      if (attribute.AT_DEFAULT_VALUE !== void 0 && attribute.AT_DEFAULT_VALUE !== null) {
        if (attribute.AT_DEFAULT_VALUE !== "Now") {
          this.errors = [
            ...this.errors,
            {
              viewName: this.view.TY_LONGNAME,
              attribute,
              message: `Hat einen falschen Defaultwert.`,
              level: "error"
            }
          ];
        }
      }
    };
    this.checkDefaultValueBoolean = (attribute) => {
      if (attribute.AT_DEFAULT_VALUE !== void 0 && attribute.AT_DEFAULT_VALUE !== null) {
        if (attribute.AT_DEFAULT_VALUE !== "0" && attribute.AT_DEFAULT_VALUE !== "1") {
          this.errors = [
            ...this.errors,
            {
              viewName: this.view.TY_LONGNAME,
              attribute,
              message: `Hat einen falschen Defaultwert.`,
              level: "error"
            }
          ];
        }
      }
    };
    this.checkPrimaryKeyIsGuid = (attribute) => {
      if (attribute.AT_PRIMARY_KEY === true && this.getDataType(attribute.AT_DATA_TYPE) !== "Guid") {
        this.errors = [
          ...this.errors,
          {
            viewName: this.view.TY_LONGNAME,
            attribute,
            message: `Primary-Key aber nicht vom Typ Guid.`,
            level: "error"
          }
        ];
      }
    };
    this.checkNextIdDefaultValue = (attribute) => {
      if (attribute.AT_DEFAULT_VALUE !== void 0 && attribute.AT_DEFAULT_VALUE !== null && attribute.AT_DEFAULT_VALUE !== "") {
        if (attribute.AT_DEFAULT_VALUE.toLowerCase().indexOf("nextid") !== -1) {
          let regex = /NextId,[a-zA-Z]*,\d{1}/g;
          let regexMatch = attribute.AT_DEFAULT_VALUE.match(regex);
          if (regexMatch && regexMatch.length === 0) {
            this.errors = [
              ...this.errors,
              {
                viewName: this.view.TY_LONGNAME,
                attribute,
                message: `Defaultwert vom Type NextId der falsch geschrieben ist.`,
                level: "error"
              }
            ];
          }
        }
      }
    };
    this.guidCheck = (attribute) => {
      this.notSimpleAttributeCheck(attribute);
      this.hasEntityList(attribute);
      this.hasCodeList(attribute);
      this.primaryKeyCheck(attribute);
    };
    this.stringCheck = (attribute) => {
      this.simpleAttributeCheck(attribute);
      this.defaultValueToLong(attribute);
      this.stringMinLength(attribute);
    };
  }
}

let colors = [
  "rgb(48,177, 255)",
  "rgb(164,231, 139)",
  "rgb(255, 241, 195)",
  "rgb(203, 90, 138)",
  "rgb(229, 210, 229)"
];
export const generateColors = (numberOfColors) => {
  let colorArray = [];
  let i = 0;
  let numberOfInterpolations = 4;
  if (numberOfColors > colors.length * (numberOfInterpolations - 1)) {
    while (numberOfColors > colors.length * (numberOfInterpolations - 1)) {
      numberOfInterpolations = numberOfInterpolations + 1;
    }
  }
  while (colorArray.length < numberOfColors) {
    let secondColor = i + 1;
    let reset = false;
    if (secondColor > colors.length - 1) {
      secondColor = 0;
      reset = true;
    }
    if (colorArray.length > 0) {
      colorArray.pop();
    }
    colorArray = [...colorArray, ...interpolateColors(colors[i], colors[secondColor], numberOfInterpolations)];
    i++;
    if (reset) {
      i = 0;
    }
  }
  colorArray.splice(numberOfColors);
  return colorArray.map((color) => "rgb(" + color[0] + "," + color[1] + "," + color[2] + ")");
};
function interpolateColor(color1, color2, factor) {
  if (arguments.length < 3) {
    factor = 0.5;
  }
  let result = color1.slice();
  for (let i = 0; i < 3; i++) {
    result[i] = Math.round(result[i] + factor * (color2[i] - color1[i]));
  }
  return result;
}
const interpolateColors = (color1, color2, steps) => {
  let stepFactor = 1 / (steps - 1), interpolatedColorArray = [];
  color1 = color1.match(/\d+/g).map(Number);
  color2 = color2.match(/\d+/g).map(Number);
  for (let i = 0; i < steps; i++) {
    interpolatedColorArray.push(interpolateColor(color1, color2, stepFactor * i));
  }
  return interpolatedColorArray;
};

/* src\components\Containers\ResizeContainer\ResizeContainer.svelte generated by Svelte v3.57.0 */
import {
	SvelteElement,
	add_location,
	append_dev,
	attr_dev,
	attribute_to_object,
	binding_callbacks,
	component_subscribe,
	detach_dev,
	dispatch_dev,
	element,
	empty,
	flush,
	init as init_1,
	insert_dev,
	listen_dev,
	noop,
	safe_not_equal,
	set_custom_element_data,
	space,
	toggle_class,
	validate_slots,
	validate_store
} from "../../../../web_modules/svelte/internal.js";

import bc from '../../../bcShim.js';
import { v4 as uuidv4 } from '../../../../web_modules/uuid.js';
import { onMount, tick } from '../../../../web_modules/svelte.js';
import { createResizableBox } from "../../../utilities/visibilityResizeUtils.js";

import {
	attachNormalize,
	componentInitAndAttributeHandler,
	dispatchMountedEvent,
	handleComponentShowAttribute
} from "../../../utilities/componentUtils.js";

import { get_current_component } from "../../../../web_modules/svelte/internal.js";
import { applicationReady, isMobileStore } from "../../../utilities/store.js";
import { getComponentText } from "../../../utilities/generalUtils.js";
const file = "src\\components\\Containers\\ResizeContainer\\ResizeContainer.svelte";

// (2192:4) {#if ready}
function create_if_block(ctx) {
	let if_block_anchor;

	function select_block_type(ctx, dirty) {
		if (/*resizeside*/ ctx[2] === 'right') return create_if_block_1;
		if (/*resizeside*/ ctx[2] === 'left') return create_if_block_6;
	}

	let current_block_type = select_block_type(ctx, [-1, -1]);
	let if_block = current_block_type && current_block_type(ctx);

	const block = {
		c: function create() {
			if (if_block) if_block.c();
			if_block_anchor = empty();
		},
		m: function mount(target, anchor) {
			if (if_block) if_block.m(target, anchor);
			insert_dev(target, if_block_anchor, anchor);
		},
		p: function update(ctx, dirty) {
			if (current_block_type === (current_block_type = select_block_type(ctx, dirty)) && if_block) {
				if_block.p(ctx, dirty);
			} else {
				if (if_block) if_block.d(1);
				if_block = current_block_type && current_block_type(ctx);

				if (if_block) {
					if_block.c();
					if_block.m(if_block_anchor.parentNode, if_block_anchor);
				}
			}
		},
		d: function destroy(detaching) {
			if (if_block) {
				if_block.d(detaching);
			}

			if (detaching) detach_dev(if_block_anchor);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block.name,
		type: "if",
		source: "(2192:4) {#if ready}",
		ctx
	});

	return block;
}

// (2236:40) 
function create_if_block_6(ctx) {
	let t;
	let div;
	let slot;
	let if_block = /*showresize*/ ctx[0] === 'true' && create_if_block_7(ctx);

	const block = {
		c: function create() {
			if (if_block) if_block.c();
			t = space();
			div = element("div");
			slot = element("slot");
			attr_dev(slot, "name", "right");
			add_location(slot, file, 2249, 16, 45826);
			attr_dev(div, "class", "h-full overflow-auto");
			toggle_class(div, "w-full", /*showresize*/ ctx[0] === 'false');
			toggle_class(div, "big-part", /*showresize*/ ctx[0] === 'true' && /*fullwidth*/ ctx[3] === 'false');
			toggle_class(div, "big-part-fullWidth", /*showresize*/ ctx[0] === 'true' && /*fullwidth*/ ctx[3] === 'true');
			add_location(div, file, 2247, 12, 45563);
		},
		m: function mount(target, anchor) {
			if (if_block) if_block.m(target, anchor);
			insert_dev(target, t, anchor);
			insert_dev(target, div, anchor);
			append_dev(div, slot);
			/*div_binding_4*/ ctx[23](div);
		},
		p: function update(ctx, dirty) {
			if (/*showresize*/ ctx[0] === 'true') {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block_7(ctx);
					if_block.c();
					if_block.m(t.parentNode, t);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}

			if (dirty[0] & /*showresize*/ 1) {
				toggle_class(div, "w-full", /*showresize*/ ctx[0] === 'false');
			}

			if (dirty[0] & /*showresize, fullwidth*/ 9) {
				toggle_class(div, "big-part", /*showresize*/ ctx[0] === 'true' && /*fullwidth*/ ctx[3] === 'false');
			}

			if (dirty[0] & /*showresize, fullwidth*/ 9) {
				toggle_class(div, "big-part-fullWidth", /*showresize*/ ctx[0] === 'true' && /*fullwidth*/ ctx[3] === 'true');
			}
		},
		d: function destroy(detaching) {
			if (if_block) if_block.d(detaching);
			if (detaching) detach_dev(t);
			if (detaching) detach_dev(div);
			/*div_binding_4*/ ctx[23](null);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block_6.name,
		type: "if",
		source: "(2236:40) ",
		ctx
	});

	return block;
}

// (2193:8) {#if resizeside === 'right'}
function create_if_block_1(ctx) {
	let t;
	let if_block1_anchor;

	function select_block_type_1(ctx, dirty) {
		if (/*$isMobileStore*/ ctx[10]) return create_if_block_5;
		return create_else_block_1;
	}

	let current_block_type = select_block_type_1(ctx, [-1, -1]);
	let if_block0 = current_block_type(ctx);
	let if_block1 = /*showresize*/ ctx[0] === 'true' && create_if_block_2(ctx);

	const block = {
		c: function create() {
			if_block0.c();
			t = space();
			if (if_block1) if_block1.c();
			if_block1_anchor = empty();
		},
		m: function mount(target, anchor) {
			if_block0.m(target, anchor);
			insert_dev(target, t, anchor);
			if (if_block1) if_block1.m(target, anchor);
			insert_dev(target, if_block1_anchor, anchor);
		},
		p: function update(ctx, dirty) {
			if (current_block_type === (current_block_type = select_block_type_1(ctx, dirty)) && if_block0) {
				if_block0.p(ctx, dirty);
			} else {
				if_block0.d(1);
				if_block0 = current_block_type(ctx);

				if (if_block0) {
					if_block0.c();
					if_block0.m(t.parentNode, t);
				}
			}

			if (/*showresize*/ ctx[0] === 'true') {
				if (if_block1) {
					if_block1.p(ctx, dirty);
				} else {
					if_block1 = create_if_block_2(ctx);
					if_block1.c();
					if_block1.m(if_block1_anchor.parentNode, if_block1_anchor);
				}
			} else if (if_block1) {
				if_block1.d(1);
				if_block1 = null;
			}
		},
		d: function destroy(detaching) {
			if_block0.d(detaching);
			if (detaching) detach_dev(t);
			if (if_block1) if_block1.d(detaching);
			if (detaching) detach_dev(if_block1_anchor);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block_1.name,
		type: "if",
		source: "(2193:8) {#if resizeside === 'right'}",
		ctx
	});

	return block;
}

// (2237:12) {#if showresize === 'true'}
function create_if_block_7(ctx) {
	let icon_button;
	let t;
	let div;
	let slot;
	let mounted;
	let dispose;

	const block = {
		c: function create() {
			icon_button = element("icon-button");
			t = space();
			div = element("div");
			slot = element("slot");
			set_custom_element_data(icon_button, "color", "white");
			set_custom_element_data(icon_button, "type", "secondary");
			set_custom_element_data(icon_button, "size", "xs");
			set_custom_element_data(icon_button, "icon", "far fa-times");
			set_custom_element_data(icon_button, "class", "absolute top-2 left-2 z-50");
			add_location(icon_button, file, 2237, 16, 45091);
			attr_dev(slot, "name", "left");
			add_location(slot, file, 2244, 20, 45487);
			toggle_class(div, "small-part", /*fullwidth*/ ctx[3] === 'false');
			toggle_class(div, "small-partfullWidth", /*fullwidth*/ ctx[3] === 'true');
			add_location(div, file, 2243, 16, 45356);
		},
		m: function mount(target, anchor) {
			insert_dev(target, icon_button, anchor);
			insert_dev(target, t, anchor);
			insert_dev(target, div, anchor);
			append_dev(div, slot);
			/*div_binding_3*/ ctx[22](div);

			if (!mounted) {
				dispose = listen_dev(icon_button, "click", /*hideResize*/ ctx[11], false, false, false, false);
				mounted = true;
			}
		},
		p: function update(ctx, dirty) {
			if (dirty[0] & /*fullwidth*/ 8) {
				toggle_class(div, "small-part", /*fullwidth*/ ctx[3] === 'false');
			}

			if (dirty[0] & /*fullwidth*/ 8) {
				toggle_class(div, "small-partfullWidth", /*fullwidth*/ ctx[3] === 'true');
			}
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(icon_button);
			if (detaching) detach_dev(t);
			if (detaching) detach_dev(div);
			/*div_binding_3*/ ctx[22](null);
			mounted = false;
			dispose();
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block_7.name,
		type: "if",
		source: "(2237:12) {#if showresize === 'true'}",
		ctx
	});

	return block;
}

// (2199:12) {:else}
function create_else_block_1(ctx) {
	let div;
	let slot;

	const block = {
		c: function create() {
			div = element("div");
			slot = element("slot");
			attr_dev(slot, "name", "left");
			add_location(slot, file, 2201, 20, 43629);
			attr_dev(div, "class", "h-full overflow-auto");
			toggle_class(div, "w-full", /*showresize*/ ctx[0] === 'false');
			toggle_class(div, "big-part", /*showresize*/ ctx[0] === 'true' && /*fullwidth*/ ctx[3] === 'false');
			toggle_class(div, "big-part-fullWidth", /*showresize*/ ctx[0] === 'true' && /*fullwidth*/ ctx[3] === 'true');
			add_location(div, file, 2199, 16, 43359);
		},
		m: function mount(target, anchor) {
			insert_dev(target, div, anchor);
			append_dev(div, slot);
			/*div_binding_1*/ ctx[19](div);
		},
		p: function update(ctx, dirty) {
			if (dirty[0] & /*showresize*/ 1) {
				toggle_class(div, "w-full", /*showresize*/ ctx[0] === 'false');
			}

			if (dirty[0] & /*showresize, fullwidth*/ 9) {
				toggle_class(div, "big-part", /*showresize*/ ctx[0] === 'true' && /*fullwidth*/ ctx[3] === 'false');
			}

			if (dirty[0] & /*showresize, fullwidth*/ 9) {
				toggle_class(div, "big-part-fullWidth", /*showresize*/ ctx[0] === 'true' && /*fullwidth*/ ctx[3] === 'true');
			}
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(div);
			/*div_binding_1*/ ctx[19](null);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_else_block_1.name,
		type: "else",
		source: "(2199:12) {:else}",
		ctx
	});

	return block;
}

// (2194:12) {#if $isMobileStore}
function create_if_block_5(ctx) {
	let div;
	let slot;

	const block = {
		c: function create() {
			div = element("div");
			slot = element("slot");
			attr_dev(slot, "name", "left");
			add_location(slot, file, 2196, 20, 43277);
			attr_dev(div, "class", "h-full overflow-auto w-full");
			toggle_class(div, "big-part", /*show*/ ctx[1] === 'true' && /*fullwidth*/ ctx[3] === 'false');
			toggle_class(div, "big-part-fullWidth", /*show*/ ctx[1] === 'true' && /*fullwidth*/ ctx[3] === 'true');
			add_location(div, file, 2194, 16, 43050);
		},
		m: function mount(target, anchor) {
			insert_dev(target, div, anchor);
			append_dev(div, slot);
			/*div_binding*/ ctx[18](div);
		},
		p: function update(ctx, dirty) {
			if (dirty[0] & /*show, fullwidth*/ 10) {
				toggle_class(div, "big-part", /*show*/ ctx[1] === 'true' && /*fullwidth*/ ctx[3] === 'false');
			}

			if (dirty[0] & /*show, fullwidth*/ 10) {
				toggle_class(div, "big-part-fullWidth", /*show*/ ctx[1] === 'true' && /*fullwidth*/ ctx[3] === 'true');
			}
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(div);
			/*div_binding*/ ctx[18](null);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block_5.name,
		type: "if",
		source: "(2194:12) {#if $isMobileStore}",
		ctx
	});

	return block;
}

// (2205:12) {#if showresize === 'true'}
function create_if_block_2(ctx) {
	let if_block_anchor;

	function select_block_type_2(ctx, dirty) {
		if (/*$isMobileStore*/ ctx[10]) return create_if_block_3;
		return create_else_block;
	}

	let current_block_type = select_block_type_2(ctx, [-1, -1]);
	let if_block = current_block_type(ctx);

	const block = {
		c: function create() {
			if_block.c();
			if_block_anchor = empty();
		},
		m: function mount(target, anchor) {
			if_block.m(target, anchor);
			insert_dev(target, if_block_anchor, anchor);
		},
		p: function update(ctx, dirty) {
			if (current_block_type === (current_block_type = select_block_type_2(ctx, dirty)) && if_block) {
				if_block.p(ctx, dirty);
			} else {
				if_block.d(1);
				if_block = current_block_type(ctx);

				if (if_block) {
					if_block.c();
					if_block.m(if_block_anchor.parentNode, if_block_anchor);
				}
			}
		},
		d: function destroy(detaching) {
			if_block.d(detaching);
			if (detaching) detach_dev(if_block_anchor);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block_2.name,
		type: "if",
		source: "(2205:12) {#if showresize === 'true'}",
		ctx
	});

	return block;
}

// (2217:16) {:else}
function create_else_block(ctx) {
	let div;
	let t;
	let slot;
	let if_block = /*showclosebutton*/ ctx[5] === 'true' && create_if_block_4(ctx);

	const block = {
		c: function create() {
			div = element("div");
			if (if_block) if_block.c();
			t = space();
			slot = element("slot");
			attr_dev(slot, "name", "right");
			add_location(slot, file, 2229, 24, 44896);
			toggle_class(div, "small-part", /*fullwidth*/ ctx[3] === 'false');
			toggle_class(div, "small-partfullWidth", /*fullwidth*/ ctx[3] === 'true');
			toggle_class(div, "flex", /*title*/ ctx[4]);
			toggle_class(div, "flex-col", /*title*/ ctx[4]);
			add_location(div, file, 2217, 20, 44217);
		},
		m: function mount(target, anchor) {
			insert_dev(target, div, anchor);
			if (if_block) if_block.m(div, null);
			append_dev(div, t);
			append_dev(div, slot);
			/*div_binding_2*/ ctx[21](div);
		},
		p: function update(ctx, dirty) {
			if (/*showclosebutton*/ ctx[5] === 'true') {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block_4(ctx);
					if_block.c();
					if_block.m(div, t);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}

			if (dirty[0] & /*fullwidth*/ 8) {
				toggle_class(div, "small-part", /*fullwidth*/ ctx[3] === 'false');
			}

			if (dirty[0] & /*fullwidth*/ 8) {
				toggle_class(div, "small-partfullWidth", /*fullwidth*/ ctx[3] === 'true');
			}

			if (dirty[0] & /*title*/ 16) {
				toggle_class(div, "flex", /*title*/ ctx[4]);
			}

			if (dirty[0] & /*title*/ 16) {
				toggle_class(div, "flex-col", /*title*/ ctx[4]);
			}
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(div);
			if (if_block) if_block.d();
			/*div_binding_2*/ ctx[21](null);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_else_block.name,
		type: "else",
		source: "(2217:16) {:else}",
		ctx
	});

	return block;
}

// (2206:16) {#if $isMobileStore}
function create_if_block_3(ctx) {
	let full_overlay;
	let div;
	let slot;
	let mounted;
	let dispose;

	const block = {
		c: function create() {
			full_overlay = element("full-overlay");
			div = element("div");
			slot = element("slot");
			attr_dev(slot, "name", "right");
			add_location(slot, file, 2213, 28, 44081);
			attr_dev(div, "slot", "content");
			add_location(div, file, 2212, 24, 44031);
			add_location(full_overlay, file, 2206, 20, 43792);
		},
		m: function mount(target, anchor) {
			insert_dev(target, full_overlay, anchor);
			append_dev(full_overlay, div);
			append_dev(div, slot);

			if (!mounted) {
				dispose = listen_dev(full_overlay, "close", /*close_handler*/ ctx[20], false, false, false, false);
				mounted = true;
			}
		},
		p: noop,
		d: function destroy(detaching) {
			if (detaching) detach_dev(full_overlay);
			mounted = false;
			dispose();
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block_3.name,
		type: "if",
		source: "(2206:16) {#if $isMobileStore}",
		ctx
	});

	return block;
}

// (2222:24) {#if showclosebutton === 'true'}
function create_if_block_4(ctx) {
	let icon_button;
	let mounted;
	let dispose;

	const block = {
		c: function create() {
			icon_button = element("icon-button");
			set_custom_element_data(icon_button, "color", "white");
			set_custom_element_data(icon_button, "type", "secondary");
			set_custom_element_data(icon_button, "size", "xs");
			set_custom_element_data(icon_button, "icon", "far fa-times");
			set_custom_element_data(icon_button, "class", "absolute top-2 right-2 z-50");
			add_location(icon_button, file, 2222, 28, 44531);
		},
		m: function mount(target, anchor) {
			insert_dev(target, icon_button, anchor);

			if (!mounted) {
				dispose = listen_dev(icon_button, "click", /*hideResize*/ ctx[11], false, false, false, false);
				mounted = true;
			}
		},
		p: noop,
		d: function destroy(detaching) {
			if (detaching) detach_dev(icon_button);
			mounted = false;
			dispose();
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block_4.name,
		type: "if",
		source: "(2222:24) {#if showclosebutton === 'true'}",
		ctx
	});

	return block;
}

function create_fragment(ctx) {
	let div;
	let div_style_value;
	let if_block = /*ready*/ ctx[9] && create_if_block(ctx);

	const block = {
		c: function create() {
			div = element("div");
			if (if_block) if_block.c();
			this.c = noop;
			attr_dev(div, "class", "flex flex-grow h-full w-full relative");

			attr_dev(div, "style", div_style_value = /*minheight*/ ctx[6] !== '' && /*showresize*/ ctx[0] === 'true'
			? 'min-height: ' + /*minheight*/ ctx[6] + ';'
			: '');

			add_location(div, file, 2189, 0, 42801);
		},
		l: function claim(nodes) {
			throw new Error("options.hydrate only works if the component was compiled with the `hydratable: true` option");
		},
		m: function mount(target, anchor) {
			insert_dev(target, div, anchor);
			if (if_block) if_block.m(div, null);
		},
		p: function update(ctx, dirty) {
			if (/*ready*/ ctx[9]) {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block(ctx);
					if_block.c();
					if_block.m(div, null);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}

			if (dirty[0] & /*minheight, showresize*/ 65 && div_style_value !== (div_style_value = /*minheight*/ ctx[6] !== '' && /*showresize*/ ctx[0] === 'true'
			? 'min-height: ' + /*minheight*/ ctx[6] + ';'
			: '')) {
				attr_dev(div, "style", div_style_value);
			}
		},
		i: noop,
		o: noop,
		d: function destroy(detaching) {
			if (detaching) detach_dev(div);
			if (if_block) if_block.d();
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_fragment.name,
		type: "component",
		source: "",
		ctx
	});

	return block;
}

function instance($$self, $$props, $$invalidate) {
	let $applicationReady;
	let $isMobileStore;
	validate_store(applicationReady, 'applicationReady');
	component_subscribe($$self, applicationReady, $$value => $$invalidate(17, $applicationReady = $$value));
	validate_store(isMobileStore, 'isMobileStore');
	component_subscribe($$self, isMobileStore, $$value => $$invalidate(10, $isMobileStore = $$value));
	let { $$slots: slots = {}, $$scope } = $$props;
	validate_slots('bc-resize-container', slots, []);
	let { show = 'true' } = $$props;
	let { init = 'true' } = $$props;
	let { showresize = 'false' } = $$props;
	let { resizeside = 'right' } = $$props;
	let { fullwidth = 'false' } = $$props;
	let { closeonevent = '' } = $$props;
	let { hideresizeevent = '' } = $$props;
	let { title = '' } = $$props;
	let { showclosebutton = 'true' } = $$props;
	let { minheight = '' } = $$props;

	// Base Variables
	//===========================================================
	let component = get_current_component();

	let firstInitialized = false;
	let subscribeGuid = uuidv4();
	let ready = false;
	const generalText = getComponentText('general');
	let mounted = false;
	let canReload = false;

	// Component variables
	//===========================================================
	let left;

	let right;
	let containerWidth = 0;

	// HTML Mount & Component initialization
	//================================================================
	onMount(() => {
		attachNormalize(component);

		tick().then(() => {
			dispatchMountedEvent(component);
			$$invalidate(16, mounted = true);
		});

		return () => {
			bc.messageService.unsubscribeComponent(subscribeGuid);
		};
	});

	const initComponent = () => {
		$$invalidate(15, firstInitialized = true);

		if (closeonevent) {
			bc.messageService.subscribe(closeonevent, subscribeGuid, hideResize);
		}

		$$invalidate(9, ready = true);
	};

	// Component functions
	//================================================================
	const handleShowResize = resize => {
		if (showresize === 'false' && firstInitialized) {
			hideResize(new MouseEvent('click'));
		}
	};

	const previewRightContainerResize = right => {
		if (right && resizeside === 'right') {
			createResizableBox({
				element: right,
				side: 'left',
				containsMap: true,
				borderVisible: true,
				otherElement: left,
				grapHandle: true
			});

			containerWidth = left.parentElement.clientWidth;
			window.addEventListener('resize', handleWindowResize);
		}
	};

	const previewLeftContainerResize = left => {
		if (right && resizeside === 'left') {
			createResizableBox({
				element: left,
				side: 'right',
				containsMap: true,
				borderVisible: true,
				otherElement: right,
				grapHandle: true
			});

			containerWidth = right.parentElement.clientWidth;
			window.addEventListener('resize', handleWindowResize);
		}
	};

	const handleWindowResize = () => {
		if (resizeside === 'left') {
			if (right && right.style.minWidth) {
				let widthElement = +right.style.minWidth.substring(0, right.style.minWidth.indexOf('px'));
				let difference = right.parentElement.clientWidth - containerWidth;
				let newWidth = widthElement + difference;
				$$invalidate(8, right.style.width = `${newWidth}px`, right);
				$$invalidate(8, right.style.maxWidth = `${newWidth}px`, right);
				$$invalidate(8, right.style.minWidth = `${newWidth}px`, right);
				right.style.setProperty('width', `${newWidth}px`, 'important');
				right.style.setProperty('min-width', `${newWidth}px`, 'important');
				right.style.setProperty('max-width', `${newWidth}px`, 'important');
				containerWidth = right.parentElement.clientWidth;
			}
		} else if (resizeside === 'right') {
			if (left && left.style.minWidth) {
				let widthElement = +left.style.minWidth.substring(0, left.style.minWidth.indexOf('px'));
				let difference = left.parentElement.clientWidth - containerWidth;
				let newWidth = widthElement + difference;
				$$invalidate(7, left.style.width = `${newWidth}px`, left);
				$$invalidate(7, left.style.maxWidth = `${newWidth}px`, left);
				$$invalidate(7, left.style.minWidth = `${newWidth}px`, left);
				left.style.setProperty('width', `${newWidth}px`, 'important');
				left.style.setProperty('min-width', `${newWidth}px`, 'important');
				left.style.setProperty('max-width', `${newWidth}px`, 'important');
				containerWidth = left.parentElement.clientWidth;
			}
		}
	};

	const hideResize = e => {
		$$invalidate(0, showresize = 'false');

		if (resizeside === 'left') {
			right.style.removeProperty('width');
			right.style.removeProperty('min-width');
			right.style.removeProperty('max-width');
		} else if (resizeside === 'right') {
			left.style.removeProperty('width');
			left.style.removeProperty('min-width');
			left.style.removeProperty('max-width');
		}

		if (hideresizeevent !== '') {
			bc.messageService.publish(hideresizeevent);
		}
	};

	const writable_props = [
		'show',
		'init',
		'showresize',
		'resizeside',
		'fullwidth',
		'closeonevent',
		'hideresizeevent',
		'title',
		'showclosebutton',
		'minheight'
	];

	

	function div_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			left = $$value;
			$$invalidate(7, left);
		});
	}

	function div_binding_1($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			left = $$value;
			$$invalidate(7, left);
		});
	}

	const close_handler = e => {
		e.stopPropagation();
		hideResize();
	};

	function div_binding_2($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			right = $$value;
			$$invalidate(8, right);
		});
	}

	function div_binding_3($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			left = $$value;
			$$invalidate(7, left);
		});
	}

	function div_binding_4($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			right = $$value;
			$$invalidate(8, right);
		});
	}

	$$self.$$set = $$props => {
		if ('show' in $$props) $$invalidate(1, show = $$props.show);
		if ('init' in $$props) $$invalidate(12, init = $$props.init);
		if ('showresize' in $$props) $$invalidate(0, showresize = $$props.showresize);
		if ('resizeside' in $$props) $$invalidate(2, resizeside = $$props.resizeside);
		if ('fullwidth' in $$props) $$invalidate(3, fullwidth = $$props.fullwidth);
		if ('closeonevent' in $$props) $$invalidate(13, closeonevent = $$props.closeonevent);
		if ('hideresizeevent' in $$props) $$invalidate(14, hideresizeevent = $$props.hideresizeevent);
		if ('title' in $$props) $$invalidate(4, title = $$props.title);
		if ('showclosebutton' in $$props) $$invalidate(5, showclosebutton = $$props.showclosebutton);
		if ('minheight' in $$props) $$invalidate(6, minheight = $$props.minheight);
	};

	$$self.$capture_state = () => ({
		bc,
		uuidv4,
		onMount,
		tick,
		createResizableBox,
		attachNormalize,
		componentInitAndAttributeHandler,
		dispatchMountedEvent,
		handleComponentShowAttribute,
		get_current_component,
		applicationReady,
		isMobileStore,
		getComponentText,
		show,
		init,
		showresize,
		resizeside,
		fullwidth,
		closeonevent,
		hideresizeevent,
		title,
		showclosebutton,
		minheight,
		component,
		firstInitialized,
		subscribeGuid,
		ready,
		generalText,
		mounted,
		canReload,
		left,
		right,
		containerWidth,
		initComponent,
		handleShowResize,
		previewRightContainerResize,
		previewLeftContainerResize,
		handleWindowResize,
		hideResize,
		$applicationReady,
		$isMobileStore
	});

	$$self.$inject_state = $$props => {
		if ('show' in $$props) $$invalidate(1, show = $$props.show);
		if ('init' in $$props) $$invalidate(12, init = $$props.init);
		if ('showresize' in $$props) $$invalidate(0, showresize = $$props.showresize);
		if ('resizeside' in $$props) $$invalidate(2, resizeside = $$props.resizeside);
		if ('fullwidth' in $$props) $$invalidate(3, fullwidth = $$props.fullwidth);
		if ('closeonevent' in $$props) $$invalidate(13, closeonevent = $$props.closeonevent);
		if ('hideresizeevent' in $$props) $$invalidate(14, hideresizeevent = $$props.hideresizeevent);
		if ('title' in $$props) $$invalidate(4, title = $$props.title);
		if ('showclosebutton' in $$props) $$invalidate(5, showclosebutton = $$props.showclosebutton);
		if ('minheight' in $$props) $$invalidate(6, minheight = $$props.minheight);
		if ('component' in $$props) $$invalidate(25, component = $$props.component);
		if ('firstInitialized' in $$props) $$invalidate(15, firstInitialized = $$props.firstInitialized);
		if ('subscribeGuid' in $$props) subscribeGuid = $$props.subscribeGuid;
		if ('ready' in $$props) $$invalidate(9, ready = $$props.ready);
		if ('mounted' in $$props) $$invalidate(16, mounted = $$props.mounted);
		if ('canReload' in $$props) $$invalidate(28, canReload = $$props.canReload);
		if ('left' in $$props) $$invalidate(7, left = $$props.left);
		if ('right' in $$props) $$invalidate(8, right = $$props.right);
		if ('containerWidth' in $$props) containerWidth = $$props.containerWidth;
	};

	if ($$props && "$$inject" in $$props) {
		$$self.$inject_state($$props.$$inject);
	}

	$$self.$$.update = () => {
		if ($$self.$$.dirty[0] & /*show*/ 2) {
			$: if (show) {
				handleComponentShowAttribute(show, component);
			}
		}

		if ($$self.$$.dirty[0] & /*$applicationReady, mounted, init, firstInitialized*/ 233472) {
			$: if ($applicationReady && mounted && init) {
				componentInitAndAttributeHandler({
					mounted,
					handler: initComponent,
					init,
					firstInitialized,
					canReload
				});
			}
		}

		if ($$self.$$.dirty[0] & /*showresize*/ 1) {
			$: handleShowResize(showresize);
		}

		if ($$self.$$.dirty[0] & /*right*/ 256) {
			$: previewRightContainerResize(right);
		}

		if ($$self.$$.dirty[0] & /*left*/ 128) {
			$: previewLeftContainerResize(left);
		}
	};

	return [
		showresize,
		show,
		resizeside,
		fullwidth,
		title,
		showclosebutton,
		minheight,
		left,
		right,
		ready,
		$isMobileStore,
		hideResize,
		init,
		closeonevent,
		hideresizeevent,
		firstInitialized,
		mounted,
		$applicationReady,
		div_binding,
		div_binding_1,
		close_handler,
		div_binding_2,
		div_binding_3,
		div_binding_4
	];
}

class ResizeContainer extends SvelteElement {
	constructor(options) {
		super();
		const style = document.createElement('style');
		style.textContent = `:host{display:block}:host([hidden]){display:none}100%{left:0;right:0}100%{left:0;right:0}100%{opacity:1}100%{opacity:1}45%{-webkit-transform:scale(var(--fa-beat-scale, 1.25));transform:scale(var(--fa-beat-scale, 1.25))}45%{-webkit-transform:scale(var(--fa-beat-scale, 1.25));transform:scale(var(--fa-beat-scale, 1.25))}50%{opacity:1;-webkit-transform:scale(var(--fa-beat-fade-scale, 1.125));transform:scale(var(--fa-beat-fade-scale, 1.125))}50%{opacity:1;-webkit-transform:scale(var(--fa-beat-fade-scale, 1.125));transform:scale(var(--fa-beat-fade-scale, 1.125))}100%{-webkit-transform:rotate(360deg);transform:rotate(360deg)}100%{-webkit-transform:rotate(360deg);transform:rotate(360deg)}.small-part{width:39%;position:absolute;right:0px;background-color:white;z-index:4;box-shadow:rgba(0, 0, 0, 0.06) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px, 0px -1px 1px 0 rgba(0, 0, 0, 0.14);border-radius:5px;height:calc(100% - 1px) !important}.big-part{width:60%}.big-part-fullWidth{width:0%}.small-partfullWidth{width:98%}.flex{display:-webkit-box;display:-ms-flexbox;display:-webkit-flex;display:flex}.flex-col{-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;-webkit-flex-direction:column;flex-direction:column}.flex-grow{-webkit-box-flex:1;-ms-flex-positive:1;-webkit-flex-grow:1;flex-grow:1}.h-full{height:100%}.overflow-auto{overflow:auto}.absolute{position:absolute}.relative{position:relative}.top-2{top:0.5rem}.right-2{right:0.5rem}.left-2{left:0.5rem}.w-full{width:100%}.z-50{z-index:50}`;
		this.shadowRoot.appendChild(style);

		init_1(
			this,
			{
				target: this.shadowRoot,
				props: attribute_to_object(this.attributes),
				customElement: true
			},
			instance,
			create_fragment,
			safe_not_equal,
			{
				show: 1,
				init: 12,
				showresize: 0,
				resizeside: 2,
				fullwidth: 3,
				closeonevent: 13,
				hideresizeevent: 14,
				title: 4,
				showclosebutton: 5,
				minheight: 6
			},
			null,
			[-1, -1]
		);

		if (options) {
			if (options.target) {
				insert_dev(options.target, this, options.anchor);
			}

			if (options.props) {
				this.$set(options.props);
				flush();
			}
		}
	}

	static get observedAttributes() {
		return [
			"show",
			"init",
			"showresize",
			"resizeside",
			"fullwidth",
			"closeonevent",
			"hideresizeevent",
			"title",
			"showclosebutton",
			"minheight"
		];
	}

	get show() {
		return this.$$.ctx[1];
	}

	set show(show) {
		this.$$set({ show });
		flush();
	}

	get init() {
		return this.$$.ctx[12];
	}

	set init(init) {
		this.$$set({ init });
		flush();
	}

	get showresize() {
		return this.$$.ctx[0];
	}

	set showresize(showresize) {
		this.$$set({ showresize });
		flush();
	}

	get resizeside() {
		return this.$$.ctx[2];
	}

	set resizeside(resizeside) {
		this.$$set({ resizeside });
		flush();
	}

	get fullwidth() {
		return this.$$.ctx[3];
	}

	set fullwidth(fullwidth) {
		this.$$set({ fullwidth });
		flush();
	}

	get closeonevent() {
		return this.$$.ctx[13];
	}

	set closeonevent(closeonevent) {
		this.$$set({ closeonevent });
		flush();
	}

	get hideresizeevent() {
		return this.$$.ctx[14];
	}

	set hideresizeevent(hideresizeevent) {
		this.$$set({ hideresizeevent });
		flush();
	}

	get title() {
		return this.$$.ctx[4];
	}

	set title(title) {
		this.$$set({ title });
		flush();
	}

	get showclosebutton() {
		return this.$$.ctx[5];
	}

	set showclosebutton(showclosebutton) {
		this.$$set({ showclosebutton });
		flush();
	}

	get minheight() {
		return this.$$.ctx[6];
	}

	set minheight(minheight) {
		this.$$set({ minheight });
		flush();
	}
}

customElements.define("bc-resize-container", ResizeContainer);
export default ResizeContainer;
/* src\components\Icon\FaIcon.svelte generated by Svelte v3.57.0 */
import {
	SvelteElement,
	add_location,
	attr_dev,
	attribute_to_object,
	binding_callbacks,
	detach_dev,
	dispatch_dev,
	element,
	flush,
	init,
	insert_dev,
	noop,
	safe_not_equal,
	validate_slots
} from "../../../web_modules/svelte/internal.js";

import { v4 as uuidv4 } from "../../../web_modules/uuid.js";
import { get_current_component } from "../../../web_modules/svelte/internal.js";
import { getSharedStyleSheet } from "../../utilities/iconUtils.js";
const file = "src\\components\\Icon\\FaIcon.svelte";

function create_fragment(ctx) {
	let i;
	let i_class_value;
	let i_style_value;

	const block = {
		c: function create() {
			i = element("i");
			this.c = noop;
			attr_dev(i, "class", i_class_value = /*icon*/ ctx[0] + ' ');

			attr_dev(i, "style", i_style_value = /*lineheight*/ ctx[4] !== ''
			? `display: inline-block; font-size: ${/*size*/ ctx[1]}${/*unit*/ ctx[2]}; color: ${/*color*/ ctx[3]}; line-height:${/*lineheight*/ ctx[4]}${/*unit*/ ctx[2]}`
			: `display: inline-block; font-size: ${/*size*/ ctx[1]}${/*unit*/ ctx[2]}; color: ${/*color*/ ctx[3]};`);

			add_location(i, file, 33, 0, 1193);
		},
		l: function claim(nodes) {
			throw new Error("options.hydrate only works if the component was compiled with the `hydratable: true` option");
		},
		m: function mount(target, anchor) {
			insert_dev(target, i, anchor);
			/*i_binding*/ ctx[7](i);
		},
		p: function update(ctx, [dirty]) {
			if (dirty & /*icon*/ 1 && i_class_value !== (i_class_value = /*icon*/ ctx[0] + ' ')) {
				attr_dev(i, "class", i_class_value);
			}

			if (dirty & /*lineheight, size, unit, color*/ 30 && i_style_value !== (i_style_value = /*lineheight*/ ctx[4] !== ''
			? `display: inline-block; font-size: ${/*size*/ ctx[1]}${/*unit*/ ctx[2]}; color: ${/*color*/ ctx[3]}; line-height:${/*lineheight*/ ctx[4]}${/*unit*/ ctx[2]}`
			: `display: inline-block; font-size: ${/*size*/ ctx[1]}${/*unit*/ ctx[2]}; color: ${/*color*/ ctx[3]};`)) {
				attr_dev(i, "style", i_style_value);
			}
		},
		i: noop,
		o: noop,
		d: function destroy(detaching) {
			if (detaching) detach_dev(i);
			/*i_binding*/ ctx[7](null);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_fragment.name,
		type: "component",
		source: "",
		ctx
	});

	return block;
}

function instance($$self, $$props, $$invalidate) {
	let { $$slots: slots = {}, $$scope } = $$props;
	validate_slots('fa-icon', slots, []);
	let { icon = '' } = $$props;
	let { size = 1 } = $$props;
	let { unit = 'rem' } = $$props;
	let { color = 'black' } = $$props;
	let { hovercolor = '' } = $$props;
	let { lineheight = '' } = $$props;
	let subscribeGuid = uuidv4();
	let iconContainer;
	let component = get_current_component();

	if (component && component.shadowRoot) {
		getSharedStyleSheet().then(value => component.shadowRoot.adoptedStyleSheets = [value]);
	}

	const mousOverHandler = e => {
		$$invalidate(5, iconContainer.style.color = hovercolor, iconContainer);
		$$invalidate(5, iconContainer.style.cursor = 'pointer', iconContainer);
	};

	const mousLeaveHandler = e => {
		$$invalidate(5, iconContainer.style.color = color, iconContainer);
		$$invalidate(5, iconContainer.style.cursor = 'default', iconContainer);
	};

	if (hovercolor !== '' && iconContainer) {
		iconContainer.removeEventListener('mouseover', mousOverHandler);
		iconContainer.addEventListener('mouseover', mousOverHandler);
		iconContainer.removeEventListener('mouseleave', mousLeaveHandler);
		iconContainer.addEventListener('mouseleave', mousLeaveHandler);
	}

	const writable_props = ['icon', 'size', 'unit', 'color', 'hovercolor', 'lineheight'];

	

	function i_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			iconContainer = $$value;
			$$invalidate(5, iconContainer);
		});
	}

	$$self.$$set = $$props => {
		if ('icon' in $$props) $$invalidate(0, icon = $$props.icon);
		if ('size' in $$props) $$invalidate(1, size = $$props.size);
		if ('unit' in $$props) $$invalidate(2, unit = $$props.unit);
		if ('color' in $$props) $$invalidate(3, color = $$props.color);
		if ('hovercolor' in $$props) $$invalidate(6, hovercolor = $$props.hovercolor);
		if ('lineheight' in $$props) $$invalidate(4, lineheight = $$props.lineheight);
	};

	$$self.$capture_state = () => ({
		uuidv4,
		get_current_component,
		getSharedStyleSheet,
		icon,
		size,
		unit,
		color,
		hovercolor,
		lineheight,
		subscribeGuid,
		iconContainer,
		component,
		mousOverHandler,
		mousLeaveHandler
	});

	$$self.$inject_state = $$props => {
		if ('icon' in $$props) $$invalidate(0, icon = $$props.icon);
		if ('size' in $$props) $$invalidate(1, size = $$props.size);
		if ('unit' in $$props) $$invalidate(2, unit = $$props.unit);
		if ('color' in $$props) $$invalidate(3, color = $$props.color);
		if ('hovercolor' in $$props) $$invalidate(6, hovercolor = $$props.hovercolor);
		if ('lineheight' in $$props) $$invalidate(4, lineheight = $$props.lineheight);
		if ('subscribeGuid' in $$props) subscribeGuid = $$props.subscribeGuid;
		if ('iconContainer' in $$props) $$invalidate(5, iconContainer = $$props.iconContainer);
		if ('component' in $$props) component = $$props.component;
	};

	if ($$props && "$$inject" in $$props) {
		$$self.$inject_state($$props.$$inject);
	}

	return [icon, size, unit, color, lineheight, iconContainer, hovercolor, i_binding];
}

class FaIcon extends SvelteElement {
	constructor(options) {
		super();

		init(
			this,
			{
				target: this.shadowRoot,
				props: attribute_to_object(this.attributes),
				customElement: true
			},
			instance,
			create_fragment,
			safe_not_equal,
			{
				icon: 0,
				size: 1,
				unit: 2,
				color: 3,
				hovercolor: 6,
				lineheight: 4
			},
			null
		);

		if (options) {
			if (options.target) {
				insert_dev(options.target, this, options.anchor);
			}

			if (options.props) {
				this.$set(options.props);
				flush();
			}
		}
	}

	static get observedAttributes() {
		return ["icon", "size", "unit", "color", "hovercolor", "lineheight"];
	}

	get icon() {
		return this.$$.ctx[0];
	}

	set icon(icon) {
		this.$$set({ icon });
		flush();
	}

	get size() {
		return this.$$.ctx[1];
	}

	set size(size) {
		this.$$set({ size });
		flush();
	}

	get unit() {
		return this.$$.ctx[2];
	}

	set unit(unit) {
		this.$$set({ unit });
		flush();
	}

	get color() {
		return this.$$.ctx[3];
	}

	set color(color) {
		this.$$set({ color });
		flush();
	}

	get hovercolor() {
		return this.$$.ctx[6];
	}

	set hovercolor(hovercolor) {
		this.$$set({ hovercolor });
		flush();
	}

	get lineheight() {
		return this.$$.ctx[4];
	}

	set lineheight(lineheight) {
		this.$$set({ lineheight });
		flush();
	}
}

customElements.define("fa-icon", FaIcon);
export default FaIcon;
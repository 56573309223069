import {PARAMS, parameterService} from "./parameterService.js";
import {messageService} from "./messageService.js";
export const requestHeaders = {
  jsonHead: {
    method: "HEAD",
    headers: {
      Accept: "application/json; charset=utf-8"
    }
  },
  jsonGet: {
    method: "GET",
    headers: {
      Accept: "application/json; charset=utf-8"
    }
  },
  xmlGet: {
    method: "GET",
    headers: {
      Accept: "text/xml; application/xml;"
    }
  },
  htmlGet: {
    method: "GET",
    dataType: "html",
    headers: {
      Accept: "text/html; charset=utf-8, text/plain; charset=utf-8, text/html, text/plain"
    }
  },
  jsonPost: {
    method: "POST",
    headers: {
      Accept: "application/json; charset=utf-8",
      "Content-Type": "application/json; charset=utf-8"
    }
  },
  jsonDelete: {
    method: "DELETE",
    headers: {
      Accept: "application/json; charset=utf-8"
    }
  }
};
const createAjaxService = function(parameterService2, messageService2, reqHeaders) {
  const setTokenHeader = (request) => {
    let token = parameterService2.get(PARAMS.user.bearerToken);
    if (token) {
      if (!request.headers) {
        request.headers = {};
      }
      request.headers.Authorization = "Bearer " + token;
    }
    request.headers.origin = "";
  };
  const controler = new AbortController();
  const signal = controler.signal;
  return {
    controler,
    requestHeaders: reqHeaders,
    setTokenHeader,
    fetchWithCallback: (header, url, callback, errorCallback) => {
      setTokenHeader(header);
      fetch(url, header).then((response) => {
        callback(response["data"]);
      }).catch((error) => {
        errorCallback(error);
      });
    },
    fetch: async (header, url) => {
      try {
        await fetch(url, header);
        let result = await fetch(url, header);
        if (!result.ok) {
          await throwErrow(result);
        }
        return result["data"];
      } catch (error) {
        throw error;
      }
    },
    fetchHead: async (header, url) => {
      try {
        await fetch(url, header);
        let result = await fetch(url, header);
        return result;
      } catch (error) {
        throw error;
      }
    },
    fetchPost: async (header, url, data) => {
      setTokenHeader(header);
      header.body = data;
      try {
        let result = await fetch(url, header);
        if (!result.ok) {
          await throwErrow(result);
        }
        return result;
      } catch (error) {
        throw error;
      }
    },
    fetchPostAny: async (header, url, data) => {
      setTokenHeader(header);
      header.body = data;
      try {
        let result = await fetch(url, header);
        if (!result.ok) {
          await throwErrow(result);
        }
        return result;
      } catch (error) {
        throw error;
      }
    },
    fetchDelete: async (header, url) => {
      setTokenHeader(header);
      try {
        let result = await fetch(url, header);
        if (!result.ok) {
          await throwErrow(result);
        }
        return result;
      } catch (error) {
        throw error;
      }
    },
    fetchRawWithCallback: async (header, url, callback, errorCallback) => {
      setTokenHeader(header);
      fetch(url, header).then((response) => {
        callback(response);
      }).catch((error) => {
        errorCallback(error);
      });
    },
    fetchRaw: async (header, url) => {
      console.log("fetchRaw: " + url);
      setTokenHeader(header);
      header.signal = controler.signal;
      try {
        const result = await fetch(url, header);
        if (!result.ok && result.status !== 404) {
          await throwErrow(result);
        }
        if (result.status !== 200 && result.status !== 404) {
          await throwErrow(result);
        }
        return result;
      } catch (error) {
        throw error;
      }
    },
    fetchViewData: async (viewName, filter, relationshipFilter, spatialFilter, orderBy, top = null, skip = null, countOnly = false) => {
      let url = parameterService2.appParameters.ApplicationDataServiceBaseUrl + viewName;
      if (countOnly === true) {
        top = 1;
        skip = null;
        url += "?$count=true";
      } else {
        if (parameterService2.appParameters.serverVersion >= "4.0") {
        } else {
          url += "?$inlinecount=allpages";
        }
      }
      if (filter && filter.length > 0) {
        url += "&$filter=" + filter;
      }
      if (relationshipFilter && relationshipFilter.length > 0) {
        url += "&$relationshipfilter=" + relationshipFilter;
      }
      if (spatialFilter && spatialFilter.length > 0) {
        url += "&$spatialfilter=" + spatialFilter;
      }
      if (orderBy && orderBy.length > 0) {
        url += "&$orderby=" + orderBy;
      }
      if (top !== void 0 && top !== null) {
        if (skip !== void 0 && skip !== null) {
          url += "&$top=" + top + "&$skip=" + skip;
        } else {
          url += "&$top=" + top;
        }
      }
      let response = await ajaxService.fetchRaw(ajaxService.requestHeaders.jsonGet, url);
      if (response && response.ok) {
        console.log(response);
        let jsonAnswer = await response.json();
        if (countOnly === true) {
          return jsonAnswer.Count;
        }
        if (jsonAnswer.Items) {
          if (jsonAnswer.Items.length > 0) {
            return jsonAnswer.Items;
          }
        }
      } else {
        if (countOnly === true) {
          return 0;
        }
        console.log("Problems with data query " + url, response);
      }
      return [];
    },
    fetchNextId: async (keyName, keyGroup, length) => {
      let urlCall = parameterService2.get(PARAMS.urls.OdataBaseUrl) + "NextId?KeyName=" + keyName + "&KeyGroup=" + keyGroup + "&length=" + length;
      try {
        const result = await fetch(urlCall, requestHeaders.jsonGet);
        if (!result.ok) {
          await throwErrow(result);
        }
        let nextId = await result.json();
        return nextId;
      } catch (error) {
        throw error;
      }
    },
    fetchParameter: async (parameter, header, url) => {
      setTokenHeader(header);
      const result = await fetch(url, header);
      if (!result.ok) {
        parameterService2.set(parameter, null);
        await throwErrow(result);
      }
      let jsonResponse = await result.json();
      console.log(parameter, jsonResponse);
      parameterService2.set(parameter, jsonResponse);
    }
  };
};
const throwErrow = async (result) => {
  try {
    const contentType = result.headers.get("content-type");
    if (contentType && contentType.indexOf("application/json") !== -1) {
      let body = await result.json();
      if (!body) {
        throw {
          status: result.status,
          message: result.statusText,
          url: result.url
        };
      } else {
        body.custom = result.status + " \n" + result.statusText + " \n" + result.url;
        throw body;
      }
    } else {
      let body = await result.text();
      if (!body) {
        throw {
          status: result.status,
          message: result.statusText,
          url: result.url
        };
      } else {
        throw result.status + " \n" + result.statusText + " \n" + result.url + "\n" + body;
      }
    }
  } catch (error) {
    throw error;
  }
};
export const ajaxService = createAjaxService(parameterService, messageService, requestHeaders);

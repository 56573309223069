import tippy from "../../web_modules/tippyjs.js";
import bc from "../bcShim.js";
import {PARAMS} from "../services/parameterService.js";
export const createEvent = (eventName, data, options = {composed: false, bubbles: false, cancelable: true}) => {
  let event = new Event(eventName, options);
  if (data) {
    event.detail = data;
  }
  return event;
};
export const exists = (value) => {
  if (value !== void 0 && value !== null) {
    return true;
  }
  return false;
};
export function debounce(callback, delay) {
  let timeout;
  return (...args) => {
    const context = this;
    clearTimeout(timeout);
    timeout = setTimeout(() => callback.apply(context, args), delay);
  };
}
export function tooltip(node, params = {}) {
  const content = node.title;
  if (content && content !== "") {
    node.setAttribute("aria-label", content);
    node.title = "";
    const tip = tippy(node, {content, touch: false});
    return {
      destroy: () => tip.destroy()
    };
  }
}
function fallbackCopyTextToClipboard(text) {
  let textArea = document.createElement("textarea");
  textArea.value = text;
  textArea.style.top = "-99999px";
  textArea.style.left = "-99999px";
  textArea.style.position = "fixed";
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();
  try {
    let successful = document.execCommand("copy");
    if (successful) {
      document.body.removeChild(textArea);
      textArea = void 0;
      return true;
    } else {
      document.body.removeChild(textArea);
      textArea = void 0;
      return false;
    }
  } catch (err) {
    document.body.removeChild(textArea);
    textArea = void 0;
    console.error("Fallback: Oops, unable to copy", err);
    return false;
  }
}
export const copyToClipboard = async (text) => {
  if (!navigator.clipboard) {
    return fallbackCopyTextToClipboard(text);
  } else {
    try {
      await navigator.clipboard.writeText(text);
      return true;
    } catch (error) {
      return false;
    }
  }
};
export const getThemeTreeViewGuids = () => {
  let themeTreeViewGuids = bc.parameterService.get("THEME_TREE_VIEW_GUIDS");
  console.log("getThemeTreeViewGuids themeTreeViewGuids", themeTreeViewGuids);
  if (themeTreeViewGuids) {
    return themeTreeViewGuids;
  }
  themeTreeViewGuids = [];
  let domains = bc.parameterService.get("DOMAINS");
  let rights = bc.parameterService.get("RIGHTS");
  let typeDomains = bc.parameterService.get("TYPE_DOMAINS");
  let views = bc.parameterService.get("DATAVIEWS");
  console.log("getThemeTreeViewGuids", domains, rights, typeDomains, views);
  const getRecusiveTree = (parentGuid) => {
    let subDomains = domains.filter((d) => d.DM_PARENT && d.DM_PARENT.toLowerCase() === parentGuid.toLowerCase());
    subDomains.sort((a, b) => a.DM_NAME.localeCompare(b.DM_NAME));
    for (let i = 0; i < subDomains.length; i++) {
      if (subDomains[i].DM_PARENT.toLowerCase() === parentGuid.toLowerCase()) {
        let items = typeDomains.filter((t) => t.TD_DOMAIN && t.TD_DOMAIN.toLowerCase() === subDomains[i].DM_ID.toLowerCase());
        let itemViews = [];
        for (let j = 0; j < items.length; j++) {
          if (views) {
            let view = views.find((v) => v.TY_ID.toLowerCase() === items[j].TD_TYPE.toLowerCase());
            if (view && rights[view.TY_ID].canRead) {
              itemViews = [...itemViews, view];
            }
          }
        }
        itemViews.sort((a, b) => ("" + a.TY_LONGNAME).localeCompare(b.TY_LONGNAME));
        themeTreeViewGuids = [...themeTreeViewGuids, ...itemViews.map((v) => v.TY_ID)];
        getRecusiveTree(subDomains[i].DM_ID);
      }
    }
  };
  let themeTree = bc.parameterService.get("ThemeTree");
  getRecusiveTree(themeTree ? themeTree : PARAMS.application.classificationTreeRootGUID);
  bc.parameterService.set("THEME_TREE_VIEW_GUIDS", themeTreeViewGuids);
  console.log("THEME_TREE_VIEW_GUIDS set", themeTreeViewGuids);
  return themeTreeViewGuids;
};
export const getComponentText = (name) => {
  let allTexts = window["texts"][name];
  let finalText = null;
  if (allTexts) {
    finalText = {...allTexts["en"]};
    finalText = {...finalText, ...allTexts[navigator.language.substring(0, 2)]};
    finalText = {...finalText, ...allTexts[navigator.language]};
  }
  return finalText;
};

import {handleErrorInAlert} from "./alertNotificationUtils.js";
import {parameterService} from "../services/parameterService.js";
import bc from "../bcShim.js";
export const getCountAndValueProperties = (resourceName) => {
  let format = null;
  let count = null;
  if (resourceName && resourceName.length > 0) {
    if (resourceName.substring(0, 21) === "UgdmTypeRelationships") {
      resourceName = "UgdmTypeRelationships";
    }
    if (parameterService.appParameters.serverVersion >= "4.0") {
      switch (resourceName) {
        case "UgdmEntityMeasurementTypes": {
          resourceName = "MeasurementTypesForEntity";
          break;
        }
        case "MeasurementTypesForEntity": {
          resourceName = "MeasurementTypesForEntity";
          break;
        }
        case "UgdmEntityViews":
        case "UgdmEntityTypes": {
          resourceName = "UgdmTypes";
          break;
        }
        case "UgdmEntityViewAttributes":
        case "UgdmEntityTypeAttributes": {
          resourceName = "UgdmTypes";
          break;
        }
        default: {
          format = "value";
          count = "@odata.count";
        }
      }
    } else {
      switch (resourceName) {
        case "UgdmTypes": {
          format = "value";
          count = "odata.count";
          break;
        }
        case "UgdmDomains":
        case "Codes":
        case "UgdmTypeDomains":
        case "UgdmTypeRelationships":
        case "UgdmRelationshipAssociations":
        case "UgdmDomainTreeNodes":
        case "UgdmTypeTreeNodes":
        case "UgdmTechnicalTypeTreeNodes":
        case "UgdmThematicTypeTreeNodes": {
          format = "value";
          count = "odata.count";
          break;
        }
        case "UgdmEntities":
        case "UgdmEntityWithLocations":
        case "UgdmEntityLocs":
        case "UgdmActions":
        case "UgdmArtifacts":
        case "UgdmAttachments":
        case "UgdmRelationships":
        case "UgdmEntityLocations":
        case "UgdmRouteEvents":
        case "UgdmMeasurements":
        case "UgdmLogs":
        case "UgdmReferences":
        case "UgdmEntityDomains": {
          format = "value";
          count = "odata.count";
          break;
        }
        case "UgdmEntityMeasurementTypes": {
          resourceName = "MeasurementTypesForEntity";
          format = "value";
          count = "odata.count";
          break;
        }
        case "MeasurementTypesForEntity": {
          resourceName = "MeasurementTypesForEntity";
          format = "value";
          count = "odata.count";
          break;
        }
        case "UgdmUsers":
        case "UgdmRoles":
        case "UgdmUsfrRoles":
        case "UgdmTypeRoles":
        case "UgdmPageRoles":
        case "UgdmViewRoles":
        case "UgdmDomainRoles":
        case "UgdmEntityUsers":
        case "UgdmUserClaims":
        case "UgdmUserLogins": {
          format = "value";
          count = "odata.count";
          break;
        }
        case "UgdmAssociations":
        case "UgdmAttributes":
        case "UgdmLocationTypes":
        case "UgdmMeasurementTypes":
        case "UgdmSrs":
        case "UgdmSystems":
        case "UgdmUnits": {
          format = "value";
          count = "odata.count";
          break;
        }
        case "UgdmEntityViews":
        case "UgdmEntityTypes": {
          resourceName = "UgdmTypes";
          format = "value";
          count = "odata.count";
          break;
        }
        case "UgdmEntityViewAttributes":
        case "UgdmEntityTypeAttributes": {
          resourceName = "UgdmTypes";
          format = "value";
          count = "odata.count";
          break;
        }
        default: {
          format = "Items";
          count = "Count";
        }
      }
    }
  }
  return {
    resourceName,
    format,
    count
  };
};
let ongoingRequestArray = [];
let requestDeleteTimeouts = [];
export const ongoingRequestHandler = async (url, headers, duration, valueType) => {
  try {
    let existingRequest = ongoingRequestArray.find((r) => r.url === url && r.header === headers);
    if (existingRequest) {
      existingRequest.demands = existingRequest.demands + 1;
      if (existingRequest.status === "pending") {
        let data = await existingRequest.request;
        existingRequest.demands = existingRequest.demands - 1;
        if (existingRequest.demands === 0 && duration !== 0) {
          if (requestDeleteTimeouts[url]) {
            clearTimeout(requestDeleteTimeouts[url]);
          }
          requestDeleteTimeouts[url] = setTimeout(() => {
            ongoingRequestArray = [...ongoingRequestArray.filter((r) => r.url !== url)];
            requestDeleteTimeouts[url] = void 0;
          }, duration);
        }
        return data;
      } else {
        existingRequest.demands = existingRequest.demands - 1;
        if (existingRequest.demands === 0 && duration !== 0) {
          if (requestDeleteTimeouts[url]) {
            clearTimeout(requestDeleteTimeouts[url]);
          }
          requestDeleteTimeouts[url] = setTimeout(() => {
            ongoingRequestArray = [...ongoingRequestArray.filter((r) => r.url !== url)];
            requestDeleteTimeouts[url] = void 0;
          }, duration);
        }
        return existingRequest.data;
      }
    } else {
      let ongoingRequest = {
        url,
        header: headers,
        data: null,
        status: "pending",
        demands: 0,
        request: null
      };
      ongoingRequest.request = fetch(url, headers).then(async (response) => {
        if (response.status === 200) {
          if (valueType === "json") {
            ongoingRequest.data = await response.json();
          } else if (valueType === "text") {
            ongoingRequest.data = await response.text();
          }
        } else if (response.status === 404) {
          ongoingRequest.data = null;
        } else {
          throw {
            message: response.status + "," + response.statusText + "," + response.url,
            name: "RequestError"
          };
        }
        ongoingRequest.status = "finished";
        return ongoingRequest.data;
      }).catch((error) => {
        ongoingRequestArray = [...ongoingRequestArray.filter((r) => r.url !== url)];
        requestDeleteTimeouts[url] = void 0;
        let urlErrors = bc.parameterService.get("URL_ERRORS");
        if (!urlErrors) {
          urlErrors = [url];
          bc.parameterService.set("URL_ERRORS", urlErrors);
          throw {url, error};
        }
        if (!urlErrors.includes(url)) {
          urlErrors = [...urlErrors, url];
          bc.parameterService.set("URL_ERRORS", urlErrors);
          throw {url, error};
        } else {
          console.log("URL error already handled!", url);
        }
      });
      ongoingRequestArray.push(ongoingRequest);
      return await ongoingRequest.request;
    }
  } catch (error) {
    console.error("url:", url, "error:", error);
    handleErrorInAlert(error);
    throw error;
  }
};
let duplicateRequestArray = [];
let failedRequestsArray = [];
export const noDuplicateRequest = async (url, headers, valueType) => {
  try {
    let existingRequest = duplicateRequestArray.find((r) => r.url === url && r.header === headers);
    if (existingRequest) {
      if (existingRequest.status === "pending") {
        return await existingRequest.request;
      } else {
        return existingRequest.data;
      }
    } else {
      let ongoingRequest = {
        url,
        header: headers,
        data: null,
        status: "pending",
        request: null,
        try: 1
      };
      duplicateRequestArray.push(ongoingRequest);
      ongoingRequest.request = fetch(url, headers).then(async (response) => {
        if (response.status === 200) {
          if (valueType === "json") {
            ongoingRequest.data = await response.json();
          } else if (valueType === "text") {
            ongoingRequest.data = await response.text();
          }
        } else if (response.status === 404) {
          ongoingRequest.data = null;
        } else if (response.status === 400) {
          ongoingRequest.data = null;
        } else if (response.status === 500) {
          let failedRequest = failedRequestsArray.find((f) => f.url === url);
          if (failedRequest) {
            failedRequest.tries = failedRequest.tries + 1;
            if (failedRequest.tries === 4) {
              throw new Error(response.status + "," + response.statusText + "," + response.url);
            } else {
              duplicateRequestArray = [...duplicateRequestArray.filter((r) => r.url !== url)];
              return await noDuplicateRequest(url, headers, valueType);
            }
          } else {
            failedRequestsArray.push({url, tries: 2});
            duplicateRequestArray = [...duplicateRequestArray.filter((r) => r.url !== url)];
            return await noDuplicateRequest(url, headers, valueType);
          }
        } else {
          throw new Error(response.status + "," + response.statusText + "," + response.url);
        }
        ongoingRequest.status = "finished";
        duplicateRequestArray = [...duplicateRequestArray.filter((r) => r.url !== url)];
        failedRequestsArray = [...failedRequestsArray.filter((r) => r.url !== url)];
        console.log("DATA", ongoingRequest.data);
        return ongoingRequest.data;
      });
      return await ongoingRequest.request;
    }
  } catch (error) {
    handleErrorInAlert(error);
    throw error;
  }
};
export const createQueuePromiseFunction = (callback, ...args) => {
  let isRunning = false;
  let call;
  return async () => {
    if (!isRunning) {
      isRunning = true;
      call = callback(...args);
      return call.then((result) => {
        isRunning = false;
        return result;
      });
    } else if (isRunning) {
      return call.then((result) => {
        isRunning = false;
        return result;
      });
    }
  };
};

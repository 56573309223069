/* src\components\Export\Export.svelte generated by Svelte v3.57.0 */
import {
	SvelteElement,
	add_location,
	append_dev,
	attr_dev,
	attribute_to_object,
	component_subscribe,
	destroy_each,
	detach_dev,
	dispatch_dev,
	element,
	empty,
	flush,
	globals,
	init as init_1,
	insert_dev,
	listen_dev,
	noop,
	run_all,
	safe_not_equal,
	set_custom_element_data,
	set_data_dev,
	space,
	text,
	validate_each_argument,
	validate_slots,
	validate_store
} from "../../../web_modules/svelte/internal.js";

const { console: console_1 } = globals;
import bc from '../../bcShim.js';
import { v4 as uuidv4 } from '../../../web_modules/uuid.js';
import { onMount, tick } from '../../../web_modules/svelte.js';

import {
	addValuesToAdvancedFilter,
	createFilter
} from "../../utilities/filterUtils.js";

import {
	attachNormalize,
	componentInitAndAttributeHandler,
	dispatchMountedEvent,
	handleComponentShowAttribute,
	singletonComponentExists
} from "../../utilities/componentUtils.js";

import { get_current_component } from "../../../web_modules/svelte/internal.js";
import { applicationReady } from "../../utilities/store.js";
import { constants } from "../../constants.js";
import { getComponentText } from "../../utilities/generalUtils.js";
const file = "src\\components\\Export\\Export.svelte";

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[53] = list[i];
	return child_ctx;
}

function get_each_context_1(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[53] = list[i];
	return child_ctx;
}

function get_each_context_2(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[53] = list[i];
	return child_ctx;
}

function get_each_context_3(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[53] = list[i];
	return child_ctx;
}

// (2453:4) {#if firstInitialized}
function create_if_block(ctx) {
	let if_block_anchor;
	let if_block = /*ready*/ ctx[1] && create_if_block_1(ctx);

	const block = {
		c: function create() {
			if (if_block) if_block.c();
			if_block_anchor = empty();
		},
		m: function mount(target, anchor) {
			if (if_block) if_block.m(target, anchor);
			insert_dev(target, if_block_anchor, anchor);
		},
		p: function update(ctx, dirty) {
			if (/*ready*/ ctx[1]) {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block_1(ctx);
					if_block.c();
					if_block.m(if_block_anchor.parentNode, if_block_anchor);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}
		},
		d: function destroy(detaching) {
			if (if_block) if_block.d(detaching);
			if (detaching) detach_dev(if_block_anchor);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block.name,
		type: "if",
		source: "(2453:4) {#if firstInitialized}",
		ctx
	});

	return block;
}

// (2454:8) {#if ready}
function create_if_block_1(ctx) {
	let if_block_anchor;
	let if_block = /*showExportOptions*/ ctx[3] && create_if_block_2(ctx);

	const block = {
		c: function create() {
			if (if_block) if_block.c();
			if_block_anchor = empty();
		},
		m: function mount(target, anchor) {
			if (if_block) if_block.m(target, anchor);
			insert_dev(target, if_block_anchor, anchor);
		},
		p: function update(ctx, dirty) {
			if (/*showExportOptions*/ ctx[3]) {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block_2(ctx);
					if_block.c();
					if_block.m(if_block_anchor.parentNode, if_block_anchor);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}
		},
		d: function destroy(detaching) {
			if (if_block) if_block.d(detaching);
			if (detaching) detach_dev(if_block_anchor);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block_1.name,
		type: "if",
		source: "(2454:8) {#if ready}",
		ctx
	});

	return block;
}

// (2455:12) {#if showExportOptions}
function create_if_block_2(ctx) {
	let full_overlay;
	let div;
	let h3;
	let t1;
	let ul;
	let t2;
	let t3;
	let mounted;
	let dispose;

	function select_block_type(ctx, dirty) {
		if (/*type*/ ctx[2] === 'Entity') return create_if_block_9;
		if (/*type*/ ctx[2] === 'Action') return create_if_block_13;
		if (/*type*/ ctx[2] === 'Document') return create_if_block_16;
		if (/*type*/ ctx[2] === 'Dataview') return create_if_block_19;
	}

	let current_block_type = select_block_type(ctx, [-1, -1]);
	let if_block0 = current_block_type && current_block_type(ctx);
	let if_block1 = /*relatedWordTemplates*/ ctx[4].length > 0 && create_if_block_6(ctx);
	let if_block2 = /*qrBillTemplates*/ ctx[5].length > 0 && create_if_block_3(ctx);

	const block = {
		c: function create() {
			full_overlay = element("full-overlay");
			div = element("div");
			h3 = element("h3");
			h3.textContent = `${/*componentText*/ ctx[9].createDocument}`;
			t1 = space();
			ul = element("ul");
			if (if_block0) if_block0.c();
			t2 = space();
			if (if_block1) if_block1.c();
			t3 = space();
			if (if_block2) if_block2.c();
			attr_dev(h3, "class", "text-2xl");
			add_location(h3, file, 2464, 24, 55086);
			add_location(ul, file, 2465, 24, 55168);
			attr_dev(div, "class", "bg-white rounded p-2 flex flex-col gap-2");
			attr_dev(div, "slot", "content");
			add_location(div, file, 2463, 20, 54991);
			set_custom_element_data(full_overlay, "fullscreen", "false");
			set_custom_element_data(full_overlay, "title", "Dokumentenexport");
			add_location(full_overlay, file, 2455, 16, 54687);
		},
		m: function mount(target, anchor) {
			insert_dev(target, full_overlay, anchor);
			append_dev(full_overlay, div);
			append_dev(div, h3);
			append_dev(div, t1);
			append_dev(div, ul);
			if (if_block0) if_block0.m(ul, null);
			append_dev(div, t2);
			if (if_block1) if_block1.m(div, null);
			append_dev(div, t3);
			if (if_block2) if_block2.m(div, null);

			if (!mounted) {
				dispose = listen_dev(full_overlay, "close", /*close_handler*/ ctx[37], false, false, false, false);
				mounted = true;
			}
		},
		p: function update(ctx, dirty) {
			if (current_block_type === (current_block_type = select_block_type(ctx, dirty)) && if_block0) {
				if_block0.p(ctx, dirty);
			} else {
				if (if_block0) if_block0.d(1);
				if_block0 = current_block_type && current_block_type(ctx);

				if (if_block0) {
					if_block0.c();
					if_block0.m(ul, null);
				}
			}

			if (/*relatedWordTemplates*/ ctx[4].length > 0) {
				if (if_block1) {
					if_block1.p(ctx, dirty);
				} else {
					if_block1 = create_if_block_6(ctx);
					if_block1.c();
					if_block1.m(div, t3);
				}
			} else if (if_block1) {
				if_block1.d(1);
				if_block1 = null;
			}

			if (/*qrBillTemplates*/ ctx[5].length > 0) {
				if (if_block2) {
					if_block2.p(ctx, dirty);
				} else {
					if_block2 = create_if_block_3(ctx);
					if_block2.c();
					if_block2.m(div, null);
				}
			} else if (if_block2) {
				if_block2.d(1);
				if_block2 = null;
			}
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(full_overlay);

			if (if_block0) {
				if_block0.d();
			}

			if (if_block1) if_block1.d();
			if (if_block2) if_block2.d();
			mounted = false;
			dispose();
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block_2.name,
		type: "if",
		source: "(2455:12) {#if showExportOptions}",
		ctx
	});

	return block;
}

// (2540:58) 
function create_if_block_19(ctx) {
	let t0;
	let t1;
	let show_if = !bc.parameterService.get('IS_BACKEND') || bc.parameterService.get('IS_BACKEND') === false;
	let if_block2_anchor;
	let if_block0 = /*showExcelExport*/ ctx[6] && create_if_block_23(ctx);
	let if_block1 = /*showGeopackageExport*/ ctx[8] && create_if_block_22(ctx);
	let if_block2 = show_if && create_if_block_20(ctx);

	const block = {
		c: function create() {
			if (if_block0) if_block0.c();
			t0 = space();
			if (if_block1) if_block1.c();
			t1 = space();
			if (if_block2) if_block2.c();
			if_block2_anchor = empty();
		},
		m: function mount(target, anchor) {
			if (if_block0) if_block0.m(target, anchor);
			insert_dev(target, t0, anchor);
			if (if_block1) if_block1.m(target, anchor);
			insert_dev(target, t1, anchor);
			if (if_block2) if_block2.m(target, anchor);
			insert_dev(target, if_block2_anchor, anchor);
		},
		p: function update(ctx, dirty) {
			if (/*showExcelExport*/ ctx[6]) {
				if (if_block0) {
					if_block0.p(ctx, dirty);
				} else {
					if_block0 = create_if_block_23(ctx);
					if_block0.c();
					if_block0.m(t0.parentNode, t0);
				}
			} else if (if_block0) {
				if_block0.d(1);
				if_block0 = null;
			}

			if (/*showGeopackageExport*/ ctx[8]) {
				if (if_block1) {
					if_block1.p(ctx, dirty);
				} else {
					if_block1 = create_if_block_22(ctx);
					if_block1.c();
					if_block1.m(t1.parentNode, t1);
				}
			} else if (if_block1) {
				if_block1.d(1);
				if_block1 = null;
			}

			if (show_if) if_block2.p(ctx, dirty);
		},
		d: function destroy(detaching) {
			if (if_block0) if_block0.d(detaching);
			if (detaching) detach_dev(t0);
			if (if_block1) if_block1.d(detaching);
			if (detaching) detach_dev(t1);
			if (if_block2) if_block2.d(detaching);
			if (detaching) detach_dev(if_block2_anchor);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block_19.name,
		type: "if",
		source: "(2540:58) ",
		ctx
	});

	return block;
}

// (2520:58) 
function create_if_block_16(ctx) {
	let t;
	let if_block1_anchor;
	let if_block0 = /*showExcelExport*/ ctx[6] && create_if_block_18(ctx);
	let if_block1 = /*showWordExport*/ ctx[7] && create_if_block_17(ctx);

	const block = {
		c: function create() {
			if (if_block0) if_block0.c();
			t = space();
			if (if_block1) if_block1.c();
			if_block1_anchor = empty();
		},
		m: function mount(target, anchor) {
			if (if_block0) if_block0.m(target, anchor);
			insert_dev(target, t, anchor);
			if (if_block1) if_block1.m(target, anchor);
			insert_dev(target, if_block1_anchor, anchor);
		},
		p: function update(ctx, dirty) {
			if (/*showExcelExport*/ ctx[6]) {
				if (if_block0) {
					if_block0.p(ctx, dirty);
				} else {
					if_block0 = create_if_block_18(ctx);
					if_block0.c();
					if_block0.m(t.parentNode, t);
				}
			} else if (if_block0) {
				if_block0.d(1);
				if_block0 = null;
			}

			if (/*showWordExport*/ ctx[7]) {
				if (if_block1) {
					if_block1.p(ctx, dirty);
				} else {
					if_block1 = create_if_block_17(ctx);
					if_block1.c();
					if_block1.m(if_block1_anchor.parentNode, if_block1_anchor);
				}
			} else if (if_block1) {
				if_block1.d(1);
				if_block1 = null;
			}
		},
		d: function destroy(detaching) {
			if (if_block0) if_block0.d(detaching);
			if (detaching) detach_dev(t);
			if (if_block1) if_block1.d(detaching);
			if (detaching) detach_dev(if_block1_anchor);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block_16.name,
		type: "if",
		source: "(2520:58) ",
		ctx
	});

	return block;
}

// (2500:56) 
function create_if_block_13(ctx) {
	let t;
	let if_block1_anchor;
	let if_block0 = /*showExcelExport*/ ctx[6] && create_if_block_15(ctx);
	let if_block1 = /*showWordExport*/ ctx[7] && create_if_block_14(ctx);

	const block = {
		c: function create() {
			if (if_block0) if_block0.c();
			t = space();
			if (if_block1) if_block1.c();
			if_block1_anchor = empty();
		},
		m: function mount(target, anchor) {
			if (if_block0) if_block0.m(target, anchor);
			insert_dev(target, t, anchor);
			if (if_block1) if_block1.m(target, anchor);
			insert_dev(target, if_block1_anchor, anchor);
		},
		p: function update(ctx, dirty) {
			if (/*showExcelExport*/ ctx[6]) {
				if (if_block0) {
					if_block0.p(ctx, dirty);
				} else {
					if_block0 = create_if_block_15(ctx);
					if_block0.c();
					if_block0.m(t.parentNode, t);
				}
			} else if (if_block0) {
				if_block0.d(1);
				if_block0 = null;
			}

			if (/*showWordExport*/ ctx[7]) {
				if (if_block1) {
					if_block1.p(ctx, dirty);
				} else {
					if_block1 = create_if_block_14(ctx);
					if_block1.c();
					if_block1.m(if_block1_anchor.parentNode, if_block1_anchor);
				}
			} else if (if_block1) {
				if_block1.d(1);
				if_block1 = null;
			}
		},
		d: function destroy(detaching) {
			if (if_block0) if_block0.d(detaching);
			if (detaching) detach_dev(t);
			if (if_block1) if_block1.d(detaching);
			if (detaching) detach_dev(if_block1_anchor);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block_13.name,
		type: "if",
		source: "(2500:56) ",
		ctx
	});

	return block;
}

// (2467:28) {#if type === 'Entity'}
function create_if_block_9(ctx) {
	let t0;
	let t1;
	let t2;
	let li;
	let fa_icon;
	let t3;
	let t4_value = /*componentText*/ ctx[9].pdf + "";
	let t4;
	let mounted;
	let dispose;
	let if_block0 = /*showExcelExport*/ ctx[6] && create_if_block_12(ctx);
	let if_block1 = /*showWordExport*/ ctx[7] && create_if_block_11(ctx);
	let if_block2 = /*showPdfExport*/ ctx[10] && create_if_block_10(ctx);

	const block = {
		c: function create() {
			if (if_block0) if_block0.c();
			t0 = space();
			if (if_block1) if_block1.c();
			t1 = space();
			if (if_block2) if_block2.c();
			t2 = space();
			li = element("li");
			fa_icon = element("fa-icon");
			t3 = space();
			t4 = text(t4_value);
			set_custom_element_data(fa_icon, "icon", "far fa-file-pdf");
			add_location(fa_icon, file, 2495, 36, 57499);
			attr_dev(li, "class", "p-4 border-b last:border-b-0 hover:bg-gray-100 border-solid border-gray-300 flex items-center gap-2 cursor-pointer");
			add_location(li, file, 2493, 32, 57270);
		},
		m: function mount(target, anchor) {
			if (if_block0) if_block0.m(target, anchor);
			insert_dev(target, t0, anchor);
			if (if_block1) if_block1.m(target, anchor);
			insert_dev(target, t1, anchor);
			if (if_block2) if_block2.m(target, anchor);
			insert_dev(target, t2, anchor);
			insert_dev(target, li, anchor);
			append_dev(li, fa_icon);
			append_dev(li, t3);
			append_dev(li, t4);

			if (!mounted) {
				dispose = listen_dev(li, "click", /*launchPdfExport*/ ctx[19], false, false, false, false);
				mounted = true;
			}
		},
		p: function update(ctx, dirty) {
			if (/*showExcelExport*/ ctx[6]) {
				if (if_block0) {
					if_block0.p(ctx, dirty);
				} else {
					if_block0 = create_if_block_12(ctx);
					if_block0.c();
					if_block0.m(t0.parentNode, t0);
				}
			} else if (if_block0) {
				if_block0.d(1);
				if_block0 = null;
			}

			if (/*showWordExport*/ ctx[7]) {
				if (if_block1) {
					if_block1.p(ctx, dirty);
				} else {
					if_block1 = create_if_block_11(ctx);
					if_block1.c();
					if_block1.m(t1.parentNode, t1);
				}
			} else if (if_block1) {
				if_block1.d(1);
				if_block1 = null;
			}

			if (/*showPdfExport*/ ctx[10]) if_block2.p(ctx, dirty);
		},
		d: function destroy(detaching) {
			if (if_block0) if_block0.d(detaching);
			if (detaching) detach_dev(t0);
			if (if_block1) if_block1.d(detaching);
			if (detaching) detach_dev(t1);
			if (if_block2) if_block2.d(detaching);
			if (detaching) detach_dev(t2);
			if (detaching) detach_dev(li);
			mounted = false;
			dispose();
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block_9.name,
		type: "if",
		source: "(2467:28) {#if type === 'Entity'}",
		ctx
	});

	return block;
}

// (2541:32) {#if showExcelExport}
function create_if_block_23(ctx) {
	let li0;
	let fa_icon0;
	let t0;
	let t1_value = /*componentText*/ ctx[9].excelRaw + "";
	let t1;
	let t2;
	let li1;
	let fa_icon1;
	let t3;
	let t4_value = /*componentText*/ ctx[9].excelFormatted + "";
	let t4;
	let mounted;
	let dispose;

	const block = {
		c: function create() {
			li0 = element("li");
			fa_icon0 = element("fa-icon");
			t0 = space();
			t1 = text(t1_value);
			t2 = space();
			li1 = element("li");
			fa_icon1 = element("fa-icon");
			t3 = space();
			t4 = text(t4_value);
			set_custom_element_data(fa_icon0, "icon", "far fa-file-excel");
			add_location(fa_icon0, file, 2544, 40, 61205);
			attr_dev(li0, "class", "p-4 border-b last:border-b-0 hover:bg-gray-100 border-solid border-gray-300 flex items-center gap-2 cursor-pointer");
			add_location(li0, file, 2541, 36, 60906);
			set_custom_element_data(fa_icon1, "icon", "far fa-file-excel");
			add_location(fa_icon1, file, 2550, 40, 61694);
			attr_dev(li1, "class", "p-4 border-b last:border-b-0 hover:bg-gray-100 border-solid border-gray-300 flex items-center gap-2 cursor-pointer");
			add_location(li1, file, 2547, 36, 61396);
		},
		m: function mount(target, anchor) {
			insert_dev(target, li0, anchor);
			append_dev(li0, fa_icon0);
			append_dev(li0, t0);
			append_dev(li0, t1);
			insert_dev(target, t2, anchor);
			insert_dev(target, li1, anchor);
			append_dev(li1, fa_icon1);
			append_dev(li1, t3);
			append_dev(li1, t4);

			if (!mounted) {
				dispose = [
					listen_dev(li0, "click", /*click_handler_6*/ ctx[30], false, false, false, false),
					listen_dev(li1, "click", /*click_handler_7*/ ctx[31], false, false, false, false)
				];

				mounted = true;
			}
		},
		p: noop,
		d: function destroy(detaching) {
			if (detaching) detach_dev(li0);
			if (detaching) detach_dev(t2);
			if (detaching) detach_dev(li1);
			mounted = false;
			run_all(dispose);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block_23.name,
		type: "if",
		source: "(2541:32) {#if showExcelExport}",
		ctx
	});

	return block;
}

// (2555:32) {#if showGeopackageExport}
function create_if_block_22(ctx) {
	let li;
	let fa_icon;
	let t0;
	let t1_value = /*componentText*/ ctx[9].geoPackage + "";
	let t1;
	let mounted;
	let dispose;

	const block = {
		c: function create() {
			li = element("li");
			fa_icon = element("fa-icon");
			t0 = space();
			t1 = text(t1_value);
			set_custom_element_data(fa_icon, "icon", "far fa-database");
			add_location(fa_icon, file, 2558, 40, 62294);
			attr_dev(li, "class", "p-4 border-b last:border-b-0 hover:bg-gray-100 border-solid border-gray-300 flex items-center gap-2 cursor-pointer");
			add_location(li, file, 2555, 36, 61990);
		},
		m: function mount(target, anchor) {
			insert_dev(target, li, anchor);
			append_dev(li, fa_icon);
			append_dev(li, t0);
			append_dev(li, t1);

			if (!mounted) {
				dispose = listen_dev(li, "click", /*click_handler_8*/ ctx[32], false, false, false, false);
				mounted = true;
			}
		},
		p: noop,
		d: function destroy(detaching) {
			if (detaching) detach_dev(li);
			mounted = false;
			dispose();
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block_22.name,
		type: "if",
		source: "(2555:32) {#if showGeopackageExport}",
		ctx
	});

	return block;
}

// (2563:32) {#if !bc.parameterService.get('IS_BACKEND') || bc.parameterService.get('IS_BACKEND') === false}
function create_if_block_20(ctx) {
	let if_block_anchor;
	let if_block = /*showWordExport*/ ctx[7] && create_if_block_21(ctx);

	const block = {
		c: function create() {
			if (if_block) if_block.c();
			if_block_anchor = empty();
		},
		m: function mount(target, anchor) {
			if (if_block) if_block.m(target, anchor);
			insert_dev(target, if_block_anchor, anchor);
		},
		p: function update(ctx, dirty) {
			if (/*showWordExport*/ ctx[7]) {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block_21(ctx);
					if_block.c();
					if_block.m(if_block_anchor.parentNode, if_block_anchor);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}
		},
		d: function destroy(detaching) {
			if (if_block) if_block.d(detaching);
			if (detaching) detach_dev(if_block_anchor);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block_20.name,
		type: "if",
		source: "(2563:32) {#if !bc.parameterService.get('IS_BACKEND') || bc.parameterService.get('IS_BACKEND') === false}",
		ctx
	});

	return block;
}

// (2564:36) {#if showWordExport}
function create_if_block_21(ctx) {
	let li;
	let fa_icon;
	let t0;
	let t1_value = /*componentText*/ ctx[9].word + "";
	let t1;
	let mounted;
	let dispose;

	const block = {
		c: function create() {
			li = element("li");
			fa_icon = element("fa-icon");
			t0 = space();
			t1 = text(t1_value);
			set_custom_element_data(fa_icon, "icon", "far fa-file-word");
			add_location(fa_icon, file, 2567, 44, 62997);
			attr_dev(li, "class", "p-4 border-b last:border-b-0 hover:bg-gray-100 border-solid border-gray-300 flex items-center gap-2 cursor-pointer");
			add_location(li, file, 2564, 40, 62715);
		},
		m: function mount(target, anchor) {
			insert_dev(target, li, anchor);
			append_dev(li, fa_icon);
			append_dev(li, t0);
			append_dev(li, t1);

			if (!mounted) {
				dispose = listen_dev(li, "click", /*wordExport*/ ctx[15], false, false, false, false);
				mounted = true;
			}
		},
		p: noop,
		d: function destroy(detaching) {
			if (detaching) detach_dev(li);
			mounted = false;
			dispose();
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block_21.name,
		type: "if",
		source: "(2564:36) {#if showWordExport}",
		ctx
	});

	return block;
}

// (2521:32) {#if showExcelExport}
function create_if_block_18(ctx) {
	let li0;
	let fa_icon0;
	let t0;
	let t1_value = /*componentText*/ ctx[9].excelRaw + "";
	let t1;
	let t2;
	let li1;
	let fa_icon1;
	let t3;
	let t4_value = /*componentText*/ ctx[9].excelFormatted + "";
	let t4;
	let mounted;
	let dispose;

	const block = {
		c: function create() {
			li0 = element("li");
			fa_icon0 = element("fa-icon");
			t0 = space();
			t1 = text(t1_value);
			t2 = space();
			li1 = element("li");
			fa_icon1 = element("fa-icon");
			t3 = space();
			t4 = text(t4_value);
			set_custom_element_data(fa_icon0, "icon", "far fa-file-excel");
			add_location(fa_icon0, file, 2523, 40, 59601);
			attr_dev(li0, "class", "p-4 border-b last:border-b-0 hover:bg-gray-100 border-solid border-gray-300 flex items-center gap-2 cursor-pointer");
			add_location(li0, file, 2521, 36, 59348);
			set_custom_element_data(fa_icon1, "icon", "far fa-file-excel");
			add_location(fa_icon1, file, 2528, 40, 60044);
			attr_dev(li1, "class", "p-4 border-b last:border-b-0 hover:bg-gray-100 border-solid border-gray-300 flex items-center gap-2 cursor-pointer");
			add_location(li1, file, 2526, 36, 59792);
		},
		m: function mount(target, anchor) {
			insert_dev(target, li0, anchor);
			append_dev(li0, fa_icon0);
			append_dev(li0, t0);
			append_dev(li0, t1);
			insert_dev(target, t2, anchor);
			insert_dev(target, li1, anchor);
			append_dev(li1, fa_icon1);
			append_dev(li1, t3);
			append_dev(li1, t4);

			if (!mounted) {
				dispose = [
					listen_dev(li0, "click", /*click_handler_4*/ ctx[28], false, false, false, false),
					listen_dev(li1, "click", /*click_handler_5*/ ctx[29], false, false, false, false)
				];

				mounted = true;
			}
		},
		p: noop,
		d: function destroy(detaching) {
			if (detaching) detach_dev(li0);
			if (detaching) detach_dev(t2);
			if (detaching) detach_dev(li1);
			mounted = false;
			run_all(dispose);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block_18.name,
		type: "if",
		source: "(2521:32) {#if showExcelExport}",
		ctx
	});

	return block;
}

// (2533:32) {#if showWordExport}
function create_if_block_17(ctx) {
	let li;
	let fa_icon;
	let t0;
	let t1_value = /*componentText*/ ctx[9].word + "";
	let t1;
	let mounted;
	let dispose;

	const block = {
		c: function create() {
			li = element("li");
			fa_icon = element("fa-icon");
			t0 = space();
			t1 = text(t1_value);
			set_custom_element_data(fa_icon, "icon", "far fa-file-word");
			add_location(fa_icon, file, 2535, 40, 60566);
			attr_dev(li, "class", "p-4 border-b last:border-b-0 hover:bg-gray-100 border-solid border-gray-300 flex items-center gap-2 cursor-pointer");
			add_location(li, file, 2533, 36, 60334);
		},
		m: function mount(target, anchor) {
			insert_dev(target, li, anchor);
			append_dev(li, fa_icon);
			append_dev(li, t0);
			append_dev(li, t1);

			if (!mounted) {
				dispose = listen_dev(li, "click", /*wordExport*/ ctx[15], false, false, false, false);
				mounted = true;
			}
		},
		p: noop,
		d: function destroy(detaching) {
			if (detaching) detach_dev(li);
			mounted = false;
			dispose();
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block_17.name,
		type: "if",
		source: "(2533:32) {#if showWordExport}",
		ctx
	});

	return block;
}

// (2501:32) {#if showExcelExport}
function create_if_block_15(ctx) {
	let li0;
	let fa_icon0;
	let t0;
	let t1_value = /*componentText*/ ctx[9].excelRaw + "";
	let t1;
	let t2;
	let li1;
	let fa_icon1;
	let t3;
	let t4_value = /*componentText*/ ctx[9].excelFormatted + "";
	let t4;
	let mounted;
	let dispose;

	const block = {
		c: function create() {
			li0 = element("li");
			fa_icon0 = element("fa-icon");
			t0 = space();
			t1 = text(t1_value);
			t2 = space();
			li1 = element("li");
			fa_icon1 = element("fa-icon");
			t3 = space();
			t4 = text(t4_value);
			set_custom_element_data(fa_icon0, "icon", "far fa-file-excel");
			add_location(fa_icon0, file, 2503, 40, 58043);
			attr_dev(li0, "class", "p-4 border-b last:border-b-0 hover:bg-gray-100 border-solid border-gray-300 flex items-center gap-2 cursor-pointer");
			add_location(li0, file, 2501, 36, 57790);
			set_custom_element_data(fa_icon1, "icon", "far fa-file-excel");
			add_location(fa_icon1, file, 2508, 40, 58486);
			attr_dev(li1, "class", "p-4 border-b last:border-b-0 hover:bg-gray-100 border-solid border-gray-300 flex items-center gap-2 cursor-pointer");
			add_location(li1, file, 2506, 36, 58234);
		},
		m: function mount(target, anchor) {
			insert_dev(target, li0, anchor);
			append_dev(li0, fa_icon0);
			append_dev(li0, t0);
			append_dev(li0, t1);
			insert_dev(target, t2, anchor);
			insert_dev(target, li1, anchor);
			append_dev(li1, fa_icon1);
			append_dev(li1, t3);
			append_dev(li1, t4);

			if (!mounted) {
				dispose = [
					listen_dev(li0, "click", /*click_handler_2*/ ctx[26], false, false, false, false),
					listen_dev(li1, "click", /*click_handler_3*/ ctx[27], false, false, false, false)
				];

				mounted = true;
			}
		},
		p: noop,
		d: function destroy(detaching) {
			if (detaching) detach_dev(li0);
			if (detaching) detach_dev(t2);
			if (detaching) detach_dev(li1);
			mounted = false;
			run_all(dispose);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block_15.name,
		type: "if",
		source: "(2501:32) {#if showExcelExport}",
		ctx
	});

	return block;
}

// (2513:32) {#if showWordExport}
function create_if_block_14(ctx) {
	let li;
	let fa_icon;
	let t0;
	let t1_value = /*componentText*/ ctx[9].word + "";
	let t1;
	let mounted;
	let dispose;

	const block = {
		c: function create() {
			li = element("li");
			fa_icon = element("fa-icon");
			t0 = space();
			t1 = text(t1_value);
			set_custom_element_data(fa_icon, "icon", "far fa-file-word");
			add_location(fa_icon, file, 2515, 40, 59008);
			attr_dev(li, "class", "p-4 border-b last:border-b-0 hover:bg-gray-100 border-solid border-gray-300 flex items-center gap-2 cursor-pointer");
			add_location(li, file, 2513, 36, 58776);
		},
		m: function mount(target, anchor) {
			insert_dev(target, li, anchor);
			append_dev(li, fa_icon);
			append_dev(li, t0);
			append_dev(li, t1);

			if (!mounted) {
				dispose = listen_dev(li, "click", /*wordExport*/ ctx[15], false, false, false, false);
				mounted = true;
			}
		},
		p: noop,
		d: function destroy(detaching) {
			if (detaching) detach_dev(li);
			mounted = false;
			dispose();
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block_14.name,
		type: "if",
		source: "(2513:32) {#if showWordExport}",
		ctx
	});

	return block;
}

// (2468:32) {#if showExcelExport}
function create_if_block_12(ctx) {
	let li0;
	let fa_icon0;
	let t0;
	let t1_value = /*componentText*/ ctx[9].excelRaw + "";
	let t1;
	let t2;
	let li1;
	let fa_icon1;
	let t3;
	let t4_value = /*componentText*/ ctx[9].excelFormatted + "";
	let t4;
	let mounted;
	let dispose;

	const block = {
		c: function create() {
			li0 = element("li");
			fa_icon0 = element("fa-icon");
			t0 = space();
			t1 = text(t1_value);
			t2 = space();
			li1 = element("li");
			fa_icon1 = element("fa-icon");
			t3 = space();
			t4 = text(t4_value);
			set_custom_element_data(fa_icon0, "icon", "far fa-file-excel");
			add_location(fa_icon0, file, 2470, 40, 55571);
			attr_dev(li0, "class", "p-4 border-b last:border-b-0 hover:bg-gray-100 border-solid border-gray-300 flex items-center gap-2 cursor-pointer");
			add_location(li0, file, 2468, 36, 55318);
			set_custom_element_data(fa_icon1, "icon", "far fa-file-excel");
			add_location(fa_icon1, file, 2475, 40, 56014);
			attr_dev(li1, "class", "p-4 border-b last:border-b-0 hover:bg-gray-100 border-solid border-gray-300 flex items-center gap-2 cursor-pointer");
			add_location(li1, file, 2473, 36, 55762);
		},
		m: function mount(target, anchor) {
			insert_dev(target, li0, anchor);
			append_dev(li0, fa_icon0);
			append_dev(li0, t0);
			append_dev(li0, t1);
			insert_dev(target, t2, anchor);
			insert_dev(target, li1, anchor);
			append_dev(li1, fa_icon1);
			append_dev(li1, t3);
			append_dev(li1, t4);

			if (!mounted) {
				dispose = [
					listen_dev(li0, "click", /*click_handler*/ ctx[24], false, false, false, false),
					listen_dev(li1, "click", /*click_handler_1*/ ctx[25], false, false, false, false)
				];

				mounted = true;
			}
		},
		p: noop,
		d: function destroy(detaching) {
			if (detaching) detach_dev(li0);
			if (detaching) detach_dev(t2);
			if (detaching) detach_dev(li1);
			mounted = false;
			run_all(dispose);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block_12.name,
		type: "if",
		source: "(2468:32) {#if showExcelExport}",
		ctx
	});

	return block;
}

// (2480:32) {#if showWordExport}
function create_if_block_11(ctx) {
	let li;
	let fa_icon;
	let t0;
	let t1_value = /*componentText*/ ctx[9].word + "";
	let t1;
	let mounted;
	let dispose;

	const block = {
		c: function create() {
			li = element("li");
			fa_icon = element("fa-icon");
			t0 = space();
			t1 = text(t1_value);
			set_custom_element_data(fa_icon, "icon", "far fa-file-word");
			add_location(fa_icon, file, 2482, 40, 56536);
			attr_dev(li, "class", "p-4 border-b last:border-b-0 hover:bg-gray-100 border-solid border-gray-300 flex items-center gap-2 cursor-pointer");
			add_location(li, file, 2480, 36, 56304);
		},
		m: function mount(target, anchor) {
			insert_dev(target, li, anchor);
			append_dev(li, fa_icon);
			append_dev(li, t0);
			append_dev(li, t1);

			if (!mounted) {
				dispose = listen_dev(li, "click", /*wordExport*/ ctx[15], false, false, false, false);
				mounted = true;
			}
		},
		p: noop,
		d: function destroy(detaching) {
			if (detaching) detach_dev(li);
			mounted = false;
			dispose();
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block_11.name,
		type: "if",
		source: "(2480:32) {#if showWordExport}",
		ctx
	});

	return block;
}

// (2487:32) {#if showPdfExport}
function create_if_block_10(ctx) {
	let li;
	let fa_icon;
	let t0;
	let t1_value = /*componentText*/ ctx[9].pdfServer + "";
	let t1;
	let mounted;
	let dispose;

	const block = {
		c: function create() {
			li = element("li");
			fa_icon = element("fa-icon");
			t0 = space();
			t1 = text(t1_value);
			set_custom_element_data(fa_icon, "icon", "far fa-file-pdf");
			add_location(fa_icon, file, 2489, 40, 57045);
			attr_dev(li, "class", "p-4 border-b last:border-b-0 hover:bg-gray-100 border-solid border-gray-300 flex items-center gap-2 cursor-pointer");
			add_location(li, file, 2487, 36, 56814);
		},
		m: function mount(target, anchor) {
			insert_dev(target, li, anchor);
			append_dev(li, fa_icon);
			append_dev(li, t0);
			append_dev(li, t1);

			if (!mounted) {
				dispose = listen_dev(li, "click", /*pdfExport*/ ctx[16], false, false, false, false);
				mounted = true;
			}
		},
		p: noop,
		d: function destroy(detaching) {
			if (detaching) detach_dev(li);
			mounted = false;
			dispose();
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block_10.name,
		type: "if",
		source: "(2487:32) {#if showPdfExport}",
		ctx
	});

	return block;
}

// (2575:24) {#if relatedWordTemplates.length > 0}
function create_if_block_6(ctx) {
	let h3;
	let t1;
	let ul;
	let t2;
	let if_block0 = /*type*/ ctx[2] === 'Entity' && create_if_block_8(ctx);
	let if_block1 = /*type*/ ctx[2] === 'Dataview' && create_if_block_7(ctx);

	const block = {
		c: function create() {
			h3 = element("h3");
			h3.textContent = `${/*componentText*/ ctx[9].generateWord}`;
			t1 = space();
			ul = element("ul");
			if (if_block0) if_block0.c();
			t2 = space();
			if (if_block1) if_block1.c();
			attr_dev(h3, "class", "text-2xl mt-2");
			add_location(h3, file, 2575, 28, 63394);
			add_location(ul, file, 2576, 28, 63483);
		},
		m: function mount(target, anchor) {
			insert_dev(target, h3, anchor);
			insert_dev(target, t1, anchor);
			insert_dev(target, ul, anchor);
			if (if_block0) if_block0.m(ul, null);
			append_dev(ul, t2);
			if (if_block1) if_block1.m(ul, null);
		},
		p: function update(ctx, dirty) {
			if (/*type*/ ctx[2] === 'Entity') {
				if (if_block0) {
					if_block0.p(ctx, dirty);
				} else {
					if_block0 = create_if_block_8(ctx);
					if_block0.c();
					if_block0.m(ul, t2);
				}
			} else if (if_block0) {
				if_block0.d(1);
				if_block0 = null;
			}

			if (/*type*/ ctx[2] === 'Dataview') {
				if (if_block1) {
					if_block1.p(ctx, dirty);
				} else {
					if_block1 = create_if_block_7(ctx);
					if_block1.c();
					if_block1.m(ul, null);
				}
			} else if (if_block1) {
				if_block1.d(1);
				if_block1 = null;
			}
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(h3);
			if (detaching) detach_dev(t1);
			if (detaching) detach_dev(ul);
			if (if_block0) if_block0.d();
			if (if_block1) if_block1.d();
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block_6.name,
		type: "if",
		source: "(2575:24) {#if relatedWordTemplates.length > 0}",
		ctx
	});

	return block;
}

// (2578:32) {#if type === 'Entity'}
function create_if_block_8(ctx) {
	let each_1_anchor;
	let each_value_3 = /*relatedWordTemplates*/ ctx[4];
	validate_each_argument(each_value_3);
	let each_blocks = [];

	for (let i = 0; i < each_value_3.length; i += 1) {
		each_blocks[i] = create_each_block_3(get_each_context_3(ctx, each_value_3, i));
	}

	const block = {
		c: function create() {
			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			each_1_anchor = empty();
		},
		m: function mount(target, anchor) {
			for (let i = 0; i < each_blocks.length; i += 1) {
				if (each_blocks[i]) {
					each_blocks[i].m(target, anchor);
				}
			}

			insert_dev(target, each_1_anchor, anchor);
		},
		p: function update(ctx, dirty) {
			if (dirty[0] & /*wordTemplateExport, relatedWordTemplates*/ 131088) {
				each_value_3 = /*relatedWordTemplates*/ ctx[4];
				validate_each_argument(each_value_3);
				let i;

				for (i = 0; i < each_value_3.length; i += 1) {
					const child_ctx = get_each_context_3(ctx, each_value_3, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block_3(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(each_1_anchor.parentNode, each_1_anchor);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value_3.length;
			}
		},
		d: function destroy(detaching) {
			destroy_each(each_blocks, detaching);
			if (detaching) detach_dev(each_1_anchor);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block_8.name,
		type: "if",
		source: "(2578:32) {#if type === 'Entity'}",
		ctx
	});

	return block;
}

// (2579:36) {#each relatedWordTemplates as template}
function create_each_block_3(ctx) {
	let li;
	let fa_icon;
	let t0;
	let t1_value = /*template*/ ctx[53].TY_LONGNAME + "";
	let t1;
	let t2;
	let mounted;
	let dispose;

	function click_handler_9(...args) {
		return /*click_handler_9*/ ctx[33](/*template*/ ctx[53], ...args);
	}

	const block = {
		c: function create() {
			li = element("li");
			fa_icon = element("fa-icon");
			t0 = space();
			t1 = text(t1_value);
			t2 = space();
			set_custom_element_data(fa_icon, "icon", "far fa-file-word");
			add_location(fa_icon, file, 2582, 44, 63987);
			attr_dev(li, "class", "p-4 border-b last:border-b-0 hover:bg-gray-100 border-solid border-gray-300 flex items-center gap-2 cursor-pointer");
			add_location(li, file, 2579, 40, 63664);
		},
		m: function mount(target, anchor) {
			insert_dev(target, li, anchor);
			append_dev(li, fa_icon);
			append_dev(li, t0);
			append_dev(li, t1);
			append_dev(li, t2);

			if (!mounted) {
				dispose = listen_dev(li, "click", click_handler_9, false, false, false, false);
				mounted = true;
			}
		},
		p: function update(new_ctx, dirty) {
			ctx = new_ctx;
			if (dirty[0] & /*relatedWordTemplates*/ 16 && t1_value !== (t1_value = /*template*/ ctx[53].TY_LONGNAME + "")) set_data_dev(t1, t1_value);
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(li);
			mounted = false;
			dispose();
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_each_block_3.name,
		type: "each",
		source: "(2579:36) {#each relatedWordTemplates as template}",
		ctx
	});

	return block;
}

// (2589:32) {#if type === 'Dataview'}
function create_if_block_7(ctx) {
	let each_1_anchor;
	let each_value_2 = /*relatedWordTemplates*/ ctx[4];
	validate_each_argument(each_value_2);
	let each_blocks = [];

	for (let i = 0; i < each_value_2.length; i += 1) {
		each_blocks[i] = create_each_block_2(get_each_context_2(ctx, each_value_2, i));
	}

	const block = {
		c: function create() {
			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			each_1_anchor = empty();
		},
		m: function mount(target, anchor) {
			for (let i = 0; i < each_blocks.length; i += 1) {
				if (each_blocks[i]) {
					each_blocks[i].m(target, anchor);
				}
			}

			insert_dev(target, each_1_anchor, anchor);
		},
		p: function update(ctx, dirty) {
			if (dirty[0] & /*wordTemplateExport, relatedWordTemplates*/ 131088) {
				each_value_2 = /*relatedWordTemplates*/ ctx[4];
				validate_each_argument(each_value_2);
				let i;

				for (i = 0; i < each_value_2.length; i += 1) {
					const child_ctx = get_each_context_2(ctx, each_value_2, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block_2(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(each_1_anchor.parentNode, each_1_anchor);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value_2.length;
			}
		},
		d: function destroy(detaching) {
			destroy_each(each_blocks, detaching);
			if (detaching) detach_dev(each_1_anchor);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block_7.name,
		type: "if",
		source: "(2589:32) {#if type === 'Dataview'}",
		ctx
	});

	return block;
}

// (2590:36) {#each relatedWordTemplates as template}
function create_each_block_2(ctx) {
	let li;
	let fa_icon;
	let t0;
	let t1_value = /*template*/ ctx[53].TY_LONGNAME + "";
	let t1;
	let t2;
	let mounted;
	let dispose;

	function click_handler_10(...args) {
		return /*click_handler_10*/ ctx[34](/*template*/ ctx[53], ...args);
	}

	const block = {
		c: function create() {
			li = element("li");
			fa_icon = element("fa-icon");
			t0 = space();
			t1 = text(t1_value);
			t2 = space();
			set_custom_element_data(fa_icon, "icon", "far fa-file-word");
			add_location(fa_icon, file, 2593, 44, 64735);
			attr_dev(li, "class", "p-4 border-b last:border-b-0 hover:bg-gray-100 border-solid border-gray-300 flex items-center gap-2 cursor-pointer");
			add_location(li, file, 2590, 40, 64410);
		},
		m: function mount(target, anchor) {
			insert_dev(target, li, anchor);
			append_dev(li, fa_icon);
			append_dev(li, t0);
			append_dev(li, t1);
			append_dev(li, t2);

			if (!mounted) {
				dispose = listen_dev(li, "click", click_handler_10, false, false, false, false);
				mounted = true;
			}
		},
		p: function update(new_ctx, dirty) {
			ctx = new_ctx;
			if (dirty[0] & /*relatedWordTemplates*/ 16 && t1_value !== (t1_value = /*template*/ ctx[53].TY_LONGNAME + "")) set_data_dev(t1, t1_value);
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(li);
			mounted = false;
			dispose();
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_each_block_2.name,
		type: "each",
		source: "(2590:36) {#each relatedWordTemplates as template}",
		ctx
	});

	return block;
}

// (2601:24) {#if qrBillTemplates.length > 0}
function create_if_block_3(ctx) {
	let h3;
	let t1;
	let ul;
	let t2;
	let if_block0 = /*type*/ ctx[2] === 'Entity' && create_if_block_5(ctx);
	let if_block1 = /*type*/ ctx[2] === 'Dataview' && create_if_block_4(ctx);

	const block = {
		c: function create() {
			h3 = element("h3");
			h3.textContent = `${/*componentText*/ ctx[9].generateBill}`;
			t1 = space();
			ul = element("ul");
			if (if_block0) if_block0.c();
			t2 = space();
			if (if_block1) if_block1.c();
			attr_dev(h3, "class", "text-2xl mt-2");
			add_location(h3, file, 2601, 28, 65131);
			add_location(ul, file, 2602, 28, 65220);
		},
		m: function mount(target, anchor) {
			insert_dev(target, h3, anchor);
			insert_dev(target, t1, anchor);
			insert_dev(target, ul, anchor);
			if (if_block0) if_block0.m(ul, null);
			append_dev(ul, t2);
			if (if_block1) if_block1.m(ul, null);
		},
		p: function update(ctx, dirty) {
			if (/*type*/ ctx[2] === 'Entity') {
				if (if_block0) {
					if_block0.p(ctx, dirty);
				} else {
					if_block0 = create_if_block_5(ctx);
					if_block0.c();
					if_block0.m(ul, t2);
				}
			} else if (if_block0) {
				if_block0.d(1);
				if_block0 = null;
			}

			if (/*type*/ ctx[2] === 'Dataview') {
				if (if_block1) {
					if_block1.p(ctx, dirty);
				} else {
					if_block1 = create_if_block_4(ctx);
					if_block1.c();
					if_block1.m(ul, null);
				}
			} else if (if_block1) {
				if_block1.d(1);
				if_block1 = null;
			}
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(h3);
			if (detaching) detach_dev(t1);
			if (detaching) detach_dev(ul);
			if (if_block0) if_block0.d();
			if (if_block1) if_block1.d();
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block_3.name,
		type: "if",
		source: "(2601:24) {#if qrBillTemplates.length > 0}",
		ctx
	});

	return block;
}

// (2604:32) {#if type === 'Entity'}
function create_if_block_5(ctx) {
	let each_1_anchor;
	let each_value_1 = /*qrBillTemplates*/ ctx[5];
	validate_each_argument(each_value_1);
	let each_blocks = [];

	for (let i = 0; i < each_value_1.length; i += 1) {
		each_blocks[i] = create_each_block_1(get_each_context_1(ctx, each_value_1, i));
	}

	const block = {
		c: function create() {
			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			each_1_anchor = empty();
		},
		m: function mount(target, anchor) {
			for (let i = 0; i < each_blocks.length; i += 1) {
				if (each_blocks[i]) {
					each_blocks[i].m(target, anchor);
				}
			}

			insert_dev(target, each_1_anchor, anchor);
		},
		p: function update(ctx, dirty) {
			if (dirty[0] & /*qrBillExport, qrBillTemplates*/ 262176) {
				each_value_1 = /*qrBillTemplates*/ ctx[5];
				validate_each_argument(each_value_1);
				let i;

				for (i = 0; i < each_value_1.length; i += 1) {
					const child_ctx = get_each_context_1(ctx, each_value_1, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block_1(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(each_1_anchor.parentNode, each_1_anchor);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value_1.length;
			}
		},
		d: function destroy(detaching) {
			destroy_each(each_blocks, detaching);
			if (detaching) detach_dev(each_1_anchor);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block_5.name,
		type: "if",
		source: "(2604:32) {#if type === 'Entity'}",
		ctx
	});

	return block;
}

// (2605:36) {#each qrBillTemplates as template}
function create_each_block_1(ctx) {
	let li;
	let fa_icon;
	let t0;
	let t1_value = /*template*/ ctx[53].TY_LONGNAME + "";
	let t1;
	let t2;
	let mounted;
	let dispose;

	function click_handler_11(...args) {
		return /*click_handler_11*/ ctx[35](/*template*/ ctx[53], ...args);
	}

	const block = {
		c: function create() {
			li = element("li");
			fa_icon = element("fa-icon");
			t0 = space();
			t1 = text(t1_value);
			t2 = space();
			set_custom_element_data(fa_icon, "icon", "far fa-file-word");
			add_location(fa_icon, file, 2608, 44, 65713);
			attr_dev(li, "class", "p-4 border-b last:border-b-0 hover:bg-gray-100 border-solid border-gray-300 flex items-center gap-2 cursor-pointer");
			add_location(li, file, 2605, 40, 65396);
		},
		m: function mount(target, anchor) {
			insert_dev(target, li, anchor);
			append_dev(li, fa_icon);
			append_dev(li, t0);
			append_dev(li, t1);
			append_dev(li, t2);

			if (!mounted) {
				dispose = listen_dev(li, "click", click_handler_11, false, false, false, false);
				mounted = true;
			}
		},
		p: function update(new_ctx, dirty) {
			ctx = new_ctx;
			if (dirty[0] & /*qrBillTemplates*/ 32 && t1_value !== (t1_value = /*template*/ ctx[53].TY_LONGNAME + "")) set_data_dev(t1, t1_value);
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(li);
			mounted = false;
			dispose();
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_each_block_1.name,
		type: "each",
		source: "(2605:36) {#each qrBillTemplates as template}",
		ctx
	});

	return block;
}

// (2614:32) {#if type === 'Dataview'}
function create_if_block_4(ctx) {
	let each_1_anchor;
	let each_value = /*qrBillTemplates*/ ctx[5];
	validate_each_argument(each_value);
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	const block = {
		c: function create() {
			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			each_1_anchor = empty();
		},
		m: function mount(target, anchor) {
			for (let i = 0; i < each_blocks.length; i += 1) {
				if (each_blocks[i]) {
					each_blocks[i].m(target, anchor);
				}
			}

			insert_dev(target, each_1_anchor, anchor);
		},
		p: function update(ctx, dirty) {
			if (dirty[0] & /*qrBillExport, qrBillTemplates*/ 262176) {
				each_value = /*qrBillTemplates*/ ctx[5];
				validate_each_argument(each_value);
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(each_1_anchor.parentNode, each_1_anchor);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value.length;
			}
		},
		d: function destroy(detaching) {
			destroy_each(each_blocks, detaching);
			if (detaching) detach_dev(each_1_anchor);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block_4.name,
		type: "if",
		source: "(2614:32) {#if type === 'Dataview'}",
		ctx
	});

	return block;
}

// (2615:36) {#each qrBillTemplates as template}
function create_each_block(ctx) {
	let li;
	let fa_icon;
	let t0;
	let t1_value = /*template*/ ctx[53].TY_LONGNAME + "";
	let t1;
	let t2;
	let mounted;
	let dispose;

	function click_handler_12(...args) {
		return /*click_handler_12*/ ctx[36](/*template*/ ctx[53], ...args);
	}

	const block = {
		c: function create() {
			li = element("li");
			fa_icon = element("fa-icon");
			t0 = space();
			t1 = text(t1_value);
			t2 = space();
			set_custom_element_data(fa_icon, "icon", "far fa-file-word");
			add_location(fa_icon, file, 2618, 44, 66448);
			attr_dev(li, "class", "p-4 border-b last:border-b-0 hover:bg-gray-100 border-solid border-gray-300 flex items-center gap-2 cursor-pointer");
			add_location(li, file, 2615, 40, 66129);
		},
		m: function mount(target, anchor) {
			insert_dev(target, li, anchor);
			append_dev(li, fa_icon);
			append_dev(li, t0);
			append_dev(li, t1);
			append_dev(li, t2);

			if (!mounted) {
				dispose = listen_dev(li, "click", click_handler_12, false, false, false, false);
				mounted = true;
			}
		},
		p: function update(new_ctx, dirty) {
			ctx = new_ctx;
			if (dirty[0] & /*qrBillTemplates*/ 32 && t1_value !== (t1_value = /*template*/ ctx[53].TY_LONGNAME + "")) set_data_dev(t1, t1_value);
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(li);
			mounted = false;
			dispose();
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_each_block.name,
		type: "each",
		source: "(2615:36) {#each qrBillTemplates as template}",
		ctx
	});

	return block;
}

function create_fragment(ctx) {
	let div;
	let if_block = /*firstInitialized*/ ctx[0] && create_if_block(ctx);

	const block = {
		c: function create() {
			div = element("div");
			if (if_block) if_block.c();
			this.c = noop;
			add_location(div, file, 2451, 0, 54578);
		},
		l: function claim(nodes) {
			throw new Error("options.hydrate only works if the component was compiled with the `hydratable: true` option");
		},
		m: function mount(target, anchor) {
			insert_dev(target, div, anchor);
			if (if_block) if_block.m(div, null);
		},
		p: function update(ctx, dirty) {
			if (/*firstInitialized*/ ctx[0]) {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block(ctx);
					if_block.c();
					if_block.m(div, null);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}
		},
		i: noop,
		o: noop,
		d: function destroy(detaching) {
			if (detaching) detach_dev(div);
			if (if_block) if_block.d();
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_fragment.name,
		type: "component",
		source: "",
		ctx
	});

	return block;
}

function instance($$self, $$props, $$invalidate) {
	let $applicationReady;
	validate_store(applicationReady, 'applicationReady');
	component_subscribe($$self, applicationReady, $$value => $$invalidate(23, $applicationReady = $$value));
	let { $$slots: slots = {}, $$scope } = $$props;
	validate_slots('bc-export', slots, []);
	let { show = 'true' } = $$props;
	let { init = 'true' } = $$props;

	// Base Variables
	let firstInitialized = false;

	let subscribeGuid = uuidv4();
	let ready = false;
	const generalText = getComponentText('general');
	const componentText = getComponentText('dataExport');
	let mounted = false;
	let component = get_current_component();
	let canReload = false;

	// Component Variables
	let type = '';

	let showExportOptions = false;
	let dataview = '';
	let objectId = '';
	let search = null;
	let metadata;
	let relationshipType = '';
	let relationshipFromItemGuid = '';
	let relationshipDirection = '';
	let relatedWordTemplates = [];
	let qrBillTemplates = [];
	let showExcelExport = true;
	let showWordExport = true;
	let showPdfExport = true;
	let showGeopackageExport = true;

	// HTML Mount & Component initialization
	//================================================================
	onMount(() => {
		attachNormalize(component);

		tick().then(() => {
			dispatchMountedEvent(component);
			$$invalidate(22, mounted = true);
		});

		return () => {
			bc.messageService.unsubscribeComponent(subscribeGuid);
		};
	});

	const initComponent = () => {
		$$invalidate(0, firstInitialized = true);

		if (!singletonComponentExists('BC-EXPORT', true)) {
			bc.messageService.subscribe('EXPORT', subscribeGuid, handleExportChoice);

			if (bc.parameterService.get('ShowExcelExport') === 'NO') {
				$$invalidate(6, showExcelExport = false);
			}

			if (bc.parameterService.get('ShowWordExport') === 'NO') {
				$$invalidate(7, showWordExport = false);
			}

			if (bc.parameterService.get('ShowGeopackageExport') === 'NO') {
				$$invalidate(8, showGeopackageExport = false);
			}

			$$invalidate(1, ready = true);
		}
	};

	// Component functions
	//================================================================
	const hideExportOptions = () => {
		$$invalidate(3, showExportOptions = false);
		bc.messageService.publish('REFRESH_ALL');
	};

	const handleExportChoice = async data => {
		const { exportType, dataviewGuid, dataviewSearch, elementId, rlType, rlFromItemguid, rlDirection, excelExport, wordExport, geopackageExport } = data;
		console.log('data', data);
		$$invalidate(2, type = exportType);
		dataview = dataviewGuid;
		objectId = elementId;
		search = dataviewSearch;
		metadata = await bc.parameterService.getMetadata(dataview);
		$$invalidate(4, relatedWordTemplates = []);
		$$invalidate(5, qrBillTemplates = []);

		// Reset to default value from ParameterManager
		$$invalidate(6, showExcelExport = true);

		$$invalidate(7, showWordExport = true);
		$$invalidate(8, showGeopackageExport = true);

		if (bc.parameterService.get('ShowExcelExport') === 'NO') {
			$$invalidate(6, showExcelExport = false);
		}

		if (bc.parameterService.get('ShowWordExport') === 'NO') {
			$$invalidate(7, showWordExport = false);
		}

		if (bc.parameterService.get('ShowGeopackageExport') === 'NO') {
			$$invalidate(8, showGeopackageExport = false);
		}

		// Update to value from message
		if (excelExport !== undefined && excelExport !== null) {
			$$invalidate(6, showExcelExport = excelExport);
		}

		if (wordExport !== undefined && wordExport !== null) {
			$$invalidate(7, showWordExport = wordExport);
		}

		if (geopackageExport !== undefined && geopackageExport !== null) {
			$$invalidate(8, showGeopackageExport = geopackageExport);
		}

		console.log('showExcelExport', showExcelExport);

		if (type === 'Dataview' || type === 'Entity') {
			let relationShipsWord = await bc.metadataManager.fetchToTypeRelationships(dataview, constants.typeRelationshipType.WORD_TEMPLATE_4_VIEW);
			let relationShipsQrBill = await bc.metadataManager.fetchToTypeRelationships(dataview, constants.typeRelationshipType.QR_BILL_TEMPLATE_4_VIEW);

			if (relationShipsWord && relationShipsWord.value) {
				for (let i = 0; i < relationShipsWord.value.length; i++) {
					let type = await bc.metadataManager.getUgdmTypeById(relationShipsWord.value[i].TR_FROM);

					if (type) {
						relatedWordTemplates.push(type);
					}
				}
			}

			console.log('relationShipsQrBill', relationShipsQrBill);

			if (relationShipsQrBill && relationShipsQrBill.value) {
				for (let i = 0; i < relationShipsQrBill.value.length; i++) {
					let type = await bc.metadataManager.getUgdmTypeById(relationShipsQrBill.value[i].TR_FROM);

					if (type) {
						qrBillTemplates.push(type);
					}
				}
			}
		}

		$$invalidate(4, relatedWordTemplates);
		$$invalidate(5, qrBillTemplates);
		relationshipType = rlType ? rlType : '';
		relationshipFromItemGuid = rlFromItemguid ? rlFromItemguid : '';
		relationshipDirection = rlDirection ? rlDirection : '';
		$$invalidate(3, showExportOptions = true);
	};

	const downloadAsExel = (e, userFriendly) => {
		e.stopPropagation();

		try {
			let excelUrl = '';

			switch (metadata.resourceName) {
				case 'UgdmActions':
					{
						const idColumn = metadata.getField('AC_ID');

						const filter = createFilter({
							filterExpressionNumber: 0,
							filterExpressionJoinType: 'and',
							columnName: idColumn.name,
							joinType: 'and',
							metadata,
							comparator: '=',
							intable: false
						});

						addValuesToAdvancedFilter(filter, [{ name: idColumn.longName, value: objectId }]);
						excelUrl = bc.metadataManager.getExelUrl('action', [filter], metadata.resourceName, [], '', '', '', userFriendly);
						break;
					}
				case 'UgdmTypes':
					{
						break;
					}
				case 'UgdmArtifacts':
					{
						break;
					}
				default:
					{
						const idColumn = metadata.getBaseField('EY_ID');

						const filter = createFilter({
							filterExpressionNumber: 0,
							filterExpressionJoinType: 'and',
							columnName: idColumn.name,
							joinType: 'and',
							metadata,
							comparator: '=',
							intable: false
						});

						addValuesToAdvancedFilter(filter, [{ name: idColumn.longName, value: objectId }]);
						excelUrl = bc.metadataManager.getExelUrl('entity', [filter], metadata.resourceName, [], '', '', '', userFriendly);
					}
			}

			console.log(excelUrl);
			window.open(excelUrl);
			bc.messageService.publish('TOOLTIP_CLOSE');
		} catch(error) {
			
		} // notify(componentText.excelCreated)
		// handleErrorInAlert(error, componentText.errorCreatingExcel)
	};

	const downloadDataviewAsExel = (e, userFriendly) => {
		e.stopPropagation();

		try {
			let excelUrl = '';
			console.log('metadata.resourceName', metadata.resourceName, search);

			switch (metadata.resourceName) {
				case 'UgdmActions':
					{
						excelUrl = bc.metadataManager.getExelUrl('action', search.filters, metadata.resourceName, search.orderBys, '', '', '', userFriendly);
						break;
					}
				case 'UgdmUnits':
				case 'UgdmMeasurementTypes':
				case 'UgdmSrs':
				case 'UgdmLocationTypes':
				case 'UgdmSystems':
				case 'UgdmAttributes':
				case 'UgdmRelationshipAssociations':
				case 'UgdmTypes':
					{
						excelUrl = bc.metadataManager.getExelUrl('types', search.filters, metadata.resourceName, search.orderBys, relationshipType, relationshipFromItemGuid, relationshipDirection, userFriendly);
						break;
					}
				case 'UgdmArtifacts':
					{
						excelUrl = bc.metadataManager.getExelUrl('attachment', search.filters, metadata.resourceName, search.orderBys, relationshipType, relationshipFromItemGuid, relationshipDirection, userFriendly);
						console.log('oki');
						break;
					}
				case 'UgdmDomains':
					{
						excelUrl = bc.metadataManager.getExelUrl('domains', search.filters, metadata.resourceName, search.orderBys, relationshipType, relationshipFromItemGuid, relationshipDirection, userFriendly);
						break;
					}
				case 'UgdmUsers':
					{
						excelUrl = bc.metadataManager.getExelUrl('users', search.filters, metadata.resourceName, search.orderBys, relationshipType, relationshipFromItemGuid, relationshipDirection, userFriendly);
						break;
					}
				case 'UgdmRoles':
					{
						excelUrl = bc.metadataManager.getExelUrl('roles', search.filters, metadata.resourceName, search.orderBys, relationshipType, relationshipFromItemGuid, relationshipDirection, userFriendly);
						break;
					}
				default:
					{
						let tableType = relationshipType === '' ? 'entity' : 'relation';
						excelUrl = bc.metadataManager.getExelUrl(tableType, search.filters, metadata.resourceName, search.orderBys, relationshipType, relationshipFromItemGuid, relationshipDirection, userFriendly);
					}
			}

			console.log(excelUrl);
			window.open(excelUrl);
			bc.messageService.publish('TOOLTIP_CLOSE');
		} catch(error) {
			
		} // notify(componentText.excelCreated)
		// handleErrorInAlert(error, componentText.errorCreatingExcel)
	};

	const downloadDataviewAsGeopackage = (e, userFriendly) => {
		e.stopPropagation();

		try {
			let excelUrl = '';
			console.log('metadata.resourceName', metadata.resourceName, search);

			switch (metadata.resourceName) {
				case 'UgdmActions':
					{
						excelUrl = bc.metadataManager.getGeoPackageURL('action', search.filters, metadata.resourceName, search.orderBys, '', '', '', userFriendly);
						break;
					}
				case 'UgdmUnits':
				case 'UgdmMeasurementTypes':
				case 'UgdmSrs':
				case 'UgdmLocationTypes':
				case 'UgdmSystems':
				case 'UgdmAttributes':
				case 'UgdmRelationshipAssociations':
				case 'UgdmTypes':
					{
						excelUrl = bc.metadataManager.getGeoPackageURL('types', search.filters, metadata.resourceName, search.orderBys, relationshipType, relationshipFromItemGuid, relationshipDirection, userFriendly);
						break;
					}
				case 'UgdmArtifacts':
					{
						excelUrl = bc.metadataManager.getGeoPackageURL('attachment', search.filters, metadata.resourceName, search.orderBys, relationshipType, relationshipFromItemGuid, relationshipDirection, userFriendly);
						console.log('oki');
						break;
					}
				case 'UgdmDomains':
					{
						excelUrl = bc.metadataManager.getGeoPackageURL('domains', search.filters, metadata.resourceName, search.orderBys, relationshipType, relationshipFromItemGuid, relationshipDirection, userFriendly);
						break;
					}
				case 'UgdmUsers':
					{
						excelUrl = bc.metadataManager.getGeoPackageURL('users', search.filters, metadata.resourceName, search.orderBys, relationshipType, relationshipFromItemGuid, relationshipDirection, userFriendly);
						break;
					}
				case 'UgdmRoles':
					{
						excelUrl = bc.metadataManager.getGeoPackageURL('roles', search.filters, metadata.resourceName, search.orderBys, relationshipType, relationshipFromItemGuid, relationshipDirection, userFriendly);
						break;
					}
				default:
					{
						let tableType = relationshipType === '' ? 'entity' : 'relation';
						excelUrl = bc.metadataManager.getGeoPackageURL(tableType, search.filters, metadata.resourceName, search.orderBys, relationshipType, relationshipFromItemGuid, relationshipDirection, userFriendly);
					}
			}

			console.log(excelUrl);
			window.open(excelUrl);
			bc.messageService.publish('TOOLTIP_CLOSE');
		} catch(error) {
			
		} // notify(componentText.excelCreated)
		// handleErrorInAlert(error, componentText.errorCreatingExcel)
	};

	const textDocExport = pdf => {
		let wordUrl = '';

		switch (metadata.resourceName) {
			case 'UgdmActions':
				{
					break;
				}
			case 'UgdmTypes':
				{
					break;
				}
			case 'UgdmArtifacts':
				{
					break;
				}
			default:
				{
					const idColumn = metadata.getBaseField('EY_ID');

					const filter = createFilter({
						filterExpressionNumber: 0,
						filterExpressionJoinType: 'and',
						columnName: idColumn.name,
						joinType: 'and',
						metadata,
						comparator: '=',
						intable: false
					});

					addValuesToAdvancedFilter(filter, [{ name: idColumn.longName, value: objectId }]);
					wordUrl = bc.metadataManager.getWordUrl('entity', [filter], metadata.resourceName, [], '', '', '', true, null, pdf);
				}
		}

		console.log('Text Doc URL', wordUrl);
		window.open(wordUrl);
		bc.messageService.publish('TOOLTIP_CLOSE');
	};

	const wordExport = e => {
		bc.messageService.publish('CLOSE_POPOVER', '');
		e.stopPropagation();
		textDocExport(false);
	};

	const pdfExport = e => {
		bc.messageService.publish('CLOSE_POPOVER', '');
		e.stopPropagation();
		textDocExport(true);
	};

	const wordDataviewExport = e => {
		bc.messageService.publish('CLOSE_POPOVER', '');
		e.stopPropagation();
		let wordUrl = '';

		switch (metadata.resourceName) {
			case 'UgdmActions':
				{
					break;
				}
			case 'UgdmTypes':
				{
					break;
				}
			case 'UgdmArtifacts':
				{
					break;
				}
			default:
				{
					wordUrl = bc.metadataManager.getWordUrl('entity', search.filters, metadata.resourceName, search.orderBys, '', '', '', true);
				}
		}

		window.open(wordUrl);
		bc.messageService.publish('TOOLTIP_CLOSE');
	};

	const wordTemplateExport = (templateId, type) => {
		bc.messageService.publish('CLOSE_POPOVER', '');
		let url = '';
		let finalFilter = [];
		let finalOrderBys = [];

		if (type === 'entity') {
			const idColumn = metadata.getBaseField('EY_ID');

			const filter = createFilter({
				filterExpressionNumber: 0,
				filterExpressionJoinType: 'and',
				columnName: idColumn.name,
				joinType: 'and',
				metadata,
				comparator: '=',
				intable: false
			});

			addValuesToAdvancedFilter(filter, [{ name: idColumn.longName, value: objectId }]);
			finalFilter = [filter];
		} else {
			finalFilter = search.filters;
			finalOrderBys = search.orderBys;
		}

		url = bc.metadataManager.getWordUrl('entity', finalFilter, metadata.resourceName, finalOrderBys, '', '', '', true, templateId);
		window.open(url);
		bc.messageService.publish('TOOLTIP_CLOSE');
	};

	const qrBillExport = (templateId, type) => {
		bc.messageService.publish('CLOSE_POPOVER', '');
		let url = '';
		let finalFilter = [];
		let finalOrderBys = [];

		if (type === 'entity') {
			const idColumn = metadata.getBaseField('EY_ID');

			const filter = createFilter({
				filterExpressionNumber: 0,
				filterExpressionJoinType: 'and',
				columnName: idColumn.name,
				joinType: 'and',
				metadata,
				comparator: '=',
				intable: false
			});

			addValuesToAdvancedFilter(filter, [{ name: idColumn.longName, value: objectId }]);
			finalFilter = [filter];
		} else {
			finalFilter = search.filters;
			finalOrderBys = search.orderBys;
		}

		url = bc.metadataManager.getQRBillUrl('entity', finalFilter, metadata.resourceName, finalOrderBys, '', '', '', true, templateId);
		window.open(url);
		bc.messageService.publish('TOOLTIP_CLOSE');
	};

	const launchPdfExport = e => {
		hideExportOptions();

		bc.messageService.publish('EXPORT_PDF', {
			dataviewGuid: dataview,
			entityGuid: objectId
		});
	};

	const writable_props = ['show', 'init'];

	

	const click_handler = e => {
		downloadAsExel(e, false);
	};

	const click_handler_1 = e => {
		downloadAsExel(e, true);
	};

	const click_handler_2 = e => {
		downloadAsExel(e, false);
	};

	const click_handler_3 = e => {
		downloadAsExel(e, true);
	};

	const click_handler_4 = e => {
		downloadAsExel(e, false);
	};

	const click_handler_5 = e => {
		downloadAsExel(e, true);
	};

	const click_handler_6 = e => {
		downloadDataviewAsExel(e, false);
	};

	const click_handler_7 = e => {
		downloadDataviewAsExel(e, true);
	};

	const click_handler_8 = e => {
		downloadDataviewAsGeopackage(e, true);
	};

	const click_handler_9 = (template, e) => {
		wordTemplateExport(template.TY_ID, 'entity');
	};

	const click_handler_10 = (template, e) => {
		wordTemplateExport(template.TY_ID, 'dataview');
	};

	const click_handler_11 = (template, e) => {
		qrBillExport(template.TY_ID, 'entity');
	};

	const click_handler_12 = (template, e) => {
		qrBillExport(template.TY_ID, 'dataview');
	};

	const close_handler = e => {
		e.stopPropagation();
		hideExportOptions();
	};

	$$self.$$set = $$props => {
		if ('show' in $$props) $$invalidate(20, show = $$props.show);
		if ('init' in $$props) $$invalidate(21, init = $$props.init);
	};

	$$self.$capture_state = () => ({
		bc,
		uuidv4,
		onMount,
		tick,
		addValuesToAdvancedFilter,
		createFilter,
		attachNormalize,
		componentInitAndAttributeHandler,
		dispatchMountedEvent,
		handleComponentShowAttribute,
		singletonComponentExists,
		get_current_component,
		applicationReady,
		constants,
		getComponentText,
		show,
		init,
		firstInitialized,
		subscribeGuid,
		ready,
		generalText,
		componentText,
		mounted,
		component,
		canReload,
		type,
		showExportOptions,
		dataview,
		objectId,
		search,
		metadata,
		relationshipType,
		relationshipFromItemGuid,
		relationshipDirection,
		relatedWordTemplates,
		qrBillTemplates,
		showExcelExport,
		showWordExport,
		showPdfExport,
		showGeopackageExport,
		initComponent,
		hideExportOptions,
		handleExportChoice,
		downloadAsExel,
		downloadDataviewAsExel,
		downloadDataviewAsGeopackage,
		textDocExport,
		wordExport,
		pdfExport,
		wordDataviewExport,
		wordTemplateExport,
		qrBillExport,
		launchPdfExport,
		$applicationReady
	});

	$$self.$inject_state = $$props => {
		if ('show' in $$props) $$invalidate(20, show = $$props.show);
		if ('init' in $$props) $$invalidate(21, init = $$props.init);
		if ('firstInitialized' in $$props) $$invalidate(0, firstInitialized = $$props.firstInitialized);
		if ('subscribeGuid' in $$props) subscribeGuid = $$props.subscribeGuid;
		if ('ready' in $$props) $$invalidate(1, ready = $$props.ready);
		if ('mounted' in $$props) $$invalidate(22, mounted = $$props.mounted);
		if ('component' in $$props) $$invalidate(47, component = $$props.component);
		if ('canReload' in $$props) $$invalidate(48, canReload = $$props.canReload);
		if ('type' in $$props) $$invalidate(2, type = $$props.type);
		if ('showExportOptions' in $$props) $$invalidate(3, showExportOptions = $$props.showExportOptions);
		if ('dataview' in $$props) dataview = $$props.dataview;
		if ('objectId' in $$props) objectId = $$props.objectId;
		if ('search' in $$props) search = $$props.search;
		if ('metadata' in $$props) metadata = $$props.metadata;
		if ('relationshipType' in $$props) relationshipType = $$props.relationshipType;
		if ('relationshipFromItemGuid' in $$props) relationshipFromItemGuid = $$props.relationshipFromItemGuid;
		if ('relationshipDirection' in $$props) relationshipDirection = $$props.relationshipDirection;
		if ('relatedWordTemplates' in $$props) $$invalidate(4, relatedWordTemplates = $$props.relatedWordTemplates);
		if ('qrBillTemplates' in $$props) $$invalidate(5, qrBillTemplates = $$props.qrBillTemplates);
		if ('showExcelExport' in $$props) $$invalidate(6, showExcelExport = $$props.showExcelExport);
		if ('showWordExport' in $$props) $$invalidate(7, showWordExport = $$props.showWordExport);
		if ('showPdfExport' in $$props) $$invalidate(10, showPdfExport = $$props.showPdfExport);
		if ('showGeopackageExport' in $$props) $$invalidate(8, showGeopackageExport = $$props.showGeopackageExport);
	};

	if ($$props && "$$inject" in $$props) {
		$$self.$inject_state($$props.$$inject);
	}

	$$self.$$.update = () => {
		if ($$self.$$.dirty[0] & /*show*/ 1048576) {
			$: if (show) {
				handleComponentShowAttribute(show, component);
			}
		}

		if ($$self.$$.dirty[0] & /*$applicationReady, mounted, init, firstInitialized*/ 14680065) {
			$: if ($applicationReady && mounted && init) {
				componentInitAndAttributeHandler({
					mounted,
					handler: initComponent,
					init,
					firstInitialized,
					canReload
				});
			}
		}
	};

	return [
		firstInitialized,
		ready,
		type,
		showExportOptions,
		relatedWordTemplates,
		qrBillTemplates,
		showExcelExport,
		showWordExport,
		showGeopackageExport,
		componentText,
		showPdfExport,
		hideExportOptions,
		downloadAsExel,
		downloadDataviewAsExel,
		downloadDataviewAsGeopackage,
		wordExport,
		pdfExport,
		wordTemplateExport,
		qrBillExport,
		launchPdfExport,
		show,
		init,
		mounted,
		$applicationReady,
		click_handler,
		click_handler_1,
		click_handler_2,
		click_handler_3,
		click_handler_4,
		click_handler_5,
		click_handler_6,
		click_handler_7,
		click_handler_8,
		click_handler_9,
		click_handler_10,
		click_handler_11,
		click_handler_12,
		close_handler
	];
}

class Export extends SvelteElement {
	constructor(options) {
		super();
		const style = document.createElement('style');
		style.textContent = `100%{left:0;right:0}100%{left:0;right:0}100%{opacity:1}100%{opacity:1}45%{-webkit-transform:scale(var(--fa-beat-scale, 1.25));transform:scale(var(--fa-beat-scale, 1.25))}45%{-webkit-transform:scale(var(--fa-beat-scale, 1.25));transform:scale(var(--fa-beat-scale, 1.25))}50%{opacity:1;-webkit-transform:scale(var(--fa-beat-fade-scale, 1.125));transform:scale(var(--fa-beat-fade-scale, 1.125))}50%{opacity:1;-webkit-transform:scale(var(--fa-beat-fade-scale, 1.125));transform:scale(var(--fa-beat-fade-scale, 1.125))}100%{-webkit-transform:rotate(360deg);transform:rotate(360deg)}100%{-webkit-transform:rotate(360deg);transform:rotate(360deg)}.bg-white{--tw-bg-opacity:1;background-color:rgba(255, 255, 255, var(--tw-bg-opacity))}.hover\\:bg-gray-100:hover{--tw-bg-opacity:1;background-color:rgba(243, 244, 246, var(--tw-bg-opacity))}.border-gray-300{--tw-border-opacity:1;border-color:rgba(209, 213, 219, var(--tw-border-opacity))}.rounded{border-radius:0.25rem}.border-solid{border-style:solid}.border-b{border-bottom-width:1px}.last\\:border-b-0:last-child{border-bottom-width:0px}.cursor-pointer{cursor:pointer}.flex{display:-webkit-box;display:-ms-flexbox;display:-webkit-flex;display:flex}.flex-col{-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;-webkit-flex-direction:column;flex-direction:column}.items-center{-webkit-box-align:center;-ms-flex-align:center;-webkit-align-items:center;align-items:center}.text-2xl{font-size:1.5rem;line-height:2rem}.mt-2{margin-top:0.5rem}.p-2{padding:0.5rem}.p-4{padding:1rem}.gap-2{grid-gap:0.5rem;gap:0.5rem}`;
		this.shadowRoot.appendChild(style);

		init_1(
			this,
			{
				target: this.shadowRoot,
				props: attribute_to_object(this.attributes),
				customElement: true
			},
			instance,
			create_fragment,
			safe_not_equal,
			{ show: 20, init: 21 },
			null,
			[-1, -1]
		);

		if (options) {
			if (options.target) {
				insert_dev(options.target, this, options.anchor);
			}

			if (options.props) {
				this.$set(options.props);
				flush();
			}
		}
	}

	static get observedAttributes() {
		return ["show", "init"];
	}

	get show() {
		return this.$$.ctx[20];
	}

	set show(show) {
		this.$$set({ show });
		flush();
	}

	get init() {
		return this.$$.ctx[21];
	}

	set init(init) {
		this.$$set({ init });
		flush();
	}
}

customElements.define("bc-export", Export);
export default Export;
/* src\components\EntityManager\EntityMeasurements\CreateMeasurementManual\CreateMeasurementManual.svelte generated by Svelte v3.57.0 */
import {
	SvelteElement,
	add_location,
	append_dev,
	attr_dev,
	attribute_to_object,
	component_subscribe,
	destroy_each,
	detach_dev,
	dispatch_dev,
	element,
	flush,
	globals,
	init as init_1,
	insert_dev,
	listen_dev,
	noop,
	run_all,
	safe_not_equal,
	set_custom_element_data,
	set_data_dev,
	space,
	text,
	toggle_class,
	validate_each_argument,
	validate_slots,
	validate_store
} from "../../../../../web_modules/svelte/internal.js";

const { console: console_1 } = globals;
import bc from '../../../../bcShim.js';
import { v4 as uuidv4 } from '../../../../../web_modules/uuid.js';
import { onMount, tick } from '../../../../../web_modules/svelte.js';

import {
	attachNormalize,
	dispatchMountedEvent,
	handleComponentInitAttribute,
	handleComponentProps,
	handleComponentShowAttribute
} from "../../../../utilities/componentUtils.js";

import { get_current_component } from "../../../../../web_modules/svelte/internal.js";
import { applicationReady } from "../../../../utilities/store.js";
import { createEvent, getComponentText } from "../../../../utilities/generalUtils.js";
import "../../../../interfaces.js";
const file = "src\\components\\EntityManager\\EntityMeasurements\\CreateMeasurementManual\\CreateMeasurementManual.svelte";

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[32] = list[i];
	child_ctx[34] = i;
	return child_ctx;
}

// (2273:4) {:else}
function create_else_block(ctx) {
	let loading_spinner;

	const block = {
		c: function create() {
			loading_spinner = element("loading-spinner");
			set_custom_element_data(loading_spinner, "text", "Loading...");
			add_location(loading_spinner, file, 2273, 8, 45492);
		},
		m: function mount(target, anchor) {
			insert_dev(target, loading_spinner, anchor);
		},
		p: noop,
		d: function destroy(detaching) {
			if (detaching) detach_dev(loading_spinner);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_else_block.name,
		type: "else",
		source: "(2273:4) {:else}",
		ctx
	});

	return block;
}

// (2183:4) {#if ready}
function create_if_block(ctx) {
	let div5;
	let div4;
	let div1;
	let div0;
	let date_picker_form0;
	let date_picker_form0_label_value;
	let t0;
	let div3;
	let div2;
	let date_picker_form1;
	let date_picker_form1_label_value;
	let t1;
	let mounted;
	let dispose;
	let each_value = /*measurementTypes*/ ctx[1];
	validate_each_argument(each_value);
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	const block = {
		c: function create() {
			div5 = element("div");
			div4 = element("div");
			div1 = element("div");
			div0 = element("div");
			date_picker_form0 = element("date-picker-form");
			t0 = space();
			div3 = element("div");
			div2 = element("div");
			date_picker_form1 = element("date-picker-form");
			t1 = space();

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			set_custom_element_data(date_picker_form0, "label", date_picker_form0_label_value = /*componentText*/ ctx[8].startDate);
			set_custom_element_data(date_picker_form0, "value", /*startDate*/ ctx[3]);
			set_custom_element_data(date_picker_form0, "placeholder", "");
			set_custom_element_data(date_picker_form0, "display", "stack");
			set_custom_element_data(date_picker_form0, "column", "startDate");
			set_custom_element_data(date_picker_form0, "viewlength", "0");
			set_custom_element_data(date_picker_form0, "viewprecision", "0");
			set_custom_element_data(date_picker_form0, "isnullable", "false");
			set_custom_element_data(date_picker_form0, "iseditable", "true");
			set_custom_element_data(date_picker_form0, "padding", "false");
			set_custom_element_data(date_picker_form0, "isvalid", /*isValidStartDate*/ ctx[5]);
			add_location(date_picker_form0, file, 2192, 20, 42132);
			attr_dev(div0, "class", "flex flex-col relative");
			add_location(div0, file, 2191, 16, 42074);
			add_location(div1, file, 2190, 12, 42051);
			set_custom_element_data(date_picker_form1, "label", date_picker_form1_label_value = /*componentText*/ ctx[8].endDate);
			set_custom_element_data(date_picker_form1, "value", /*endDate*/ ctx[4]);
			set_custom_element_data(date_picker_form1, "placeholder", "");
			set_custom_element_data(date_picker_form1, "display", "stack");
			set_custom_element_data(date_picker_form1, "column", "endDate");
			set_custom_element_data(date_picker_form1, "viewlength", "0");
			set_custom_element_data(date_picker_form1, "viewprecision", "0");
			set_custom_element_data(date_picker_form1, "iseditable", "true");
			set_custom_element_data(date_picker_form1, "padding", "false");
			set_custom_element_data(date_picker_form1, "isnullable", "true");
			set_custom_element_data(date_picker_form1, "isvalid", /*isValidEndDate*/ ctx[9]);
			add_location(date_picker_form1, file, 2210, 20, 42869);
			attr_dev(div2, "class", "flex flex-col relative");
			add_location(div2, file, 2209, 16, 42811);
			add_location(div3, file, 2208, 12, 42788);
			attr_dev(div4, "class", "grid grid-cols-1 md:grid-cols-2 gap-4");
			add_location(div4, file, 2189, 8, 41986);
			attr_dev(div5, "class", "p-2 border border-solid border-gray-300 rounded");
			toggle_class(div5, "border-red-400", /*isValidEntry*/ ctx[6] === false);
			toggle_class(div5, "border-2", /*isValidEntry*/ ctx[6] === false);
			add_location(div5, file, 2183, 8, 41779);
		},
		m: function mount(target, anchor) {
			insert_dev(target, div5, anchor);
			append_dev(div5, div4);
			append_dev(div4, div1);
			append_dev(div1, div0);
			append_dev(div0, date_picker_form0);
			append_dev(div4, t0);
			append_dev(div4, div3);
			append_dev(div3, div2);
			append_dev(div2, date_picker_form1);
			append_dev(div5, t1);

			for (let i = 0; i < each_blocks.length; i += 1) {
				if (each_blocks[i]) {
					each_blocks[i].m(div5, null);
				}
			}

			if (!mounted) {
				dispose = [
					listen_dev(date_picker_form0, "change", /*change_handler*/ ctx[19], false, false, false, false),
					listen_dev(date_picker_form1, "change", /*change_handler_1*/ ctx[20], false, false, false, false)
				];

				mounted = true;
			}
		},
		p: function update(ctx, dirty) {
			if (dirty[0] & /*startDate*/ 8) {
				set_custom_element_data(date_picker_form0, "value", /*startDate*/ ctx[3]);
			}

			if (dirty[0] & /*isValidStartDate*/ 32) {
				set_custom_element_data(date_picker_form0, "isvalid", /*isValidStartDate*/ ctx[5]);
			}

			if (dirty[0] & /*endDate*/ 16) {
				set_custom_element_data(date_picker_form1, "value", /*endDate*/ ctx[4]);
			}

			if (dirty[0] & /*measurementTypes, handleOnChange, values, subscribeGuid*/ 1158) {
				each_value = /*measurementTypes*/ ctx[1];
				validate_each_argument(each_value);
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(div5, null);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value.length;
			}

			if (dirty[0] & /*isValidEntry*/ 64) {
				toggle_class(div5, "border-red-400", /*isValidEntry*/ ctx[6] === false);
			}

			if (dirty[0] & /*isValidEntry*/ 64) {
				toggle_class(div5, "border-2", /*isValidEntry*/ ctx[6] === false);
			}
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(div5);
			destroy_each(each_blocks, detaching);
			mounted = false;
			run_all(dispose);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block.name,
		type: "if",
		source: "(2183:4) {#if ready}",
		ctx
	});

	return block;
}

// (2250:24) {#if type.MT_CODE_LIST}
function create_if_block_1(ctx) {
	let label;
	let t1;
	let drop_down;
	let drop_down_label_value;
	let drop_down_codelistguid_value;
	let drop_down_column_value;
	let mounted;
	let dispose;

	const block = {
		c: function create() {
			label = element("label");
			label.textContent = " ";
			t1 = space();
			drop_down = element("drop-down");
			attr_dev(label, "class", "font-medium roboto-medium");
			add_location(label, file, 2250, 24, 44635);
			set_custom_element_data(drop_down, "isvalid", "true");
			set_custom_element_data(drop_down, "haschanged", "false");
			set_custom_element_data(drop_down, "label", drop_down_label_value = /*type*/ ctx[32].MT_CODE_LIST);
			set_custom_element_data(drop_down, "codelistguid", drop_down_codelistguid_value = /*type*/ ctx[32].MT_CODE_LIST);
			set_custom_element_data(drop_down, "placeholder", "");
			set_custom_element_data(drop_down, "display", "stack");
			set_custom_element_data(drop_down, "column", drop_down_column_value = `code_${/*type*/ ctx[32].TY_ID}`);
			set_custom_element_data(drop_down, "iseditable", "true");
			set_custom_element_data(drop_down, "isnullable", "true");
			set_custom_element_data(drop_down, "showlabel", "false");
			add_location(drop_down, file, 2251, 24, 44716);
		},
		m: function mount(target, anchor) {
			insert_dev(target, label, anchor);
			insert_dev(target, t1, anchor);
			insert_dev(target, drop_down, anchor);

			if (!mounted) {
				dispose = listen_dev(drop_down, "change", /*change_handler_3*/ ctx[22], false, false, false, false);
				mounted = true;
			}
		},
		p: function update(ctx, dirty) {
			if (dirty[0] & /*measurementTypes*/ 2 && drop_down_label_value !== (drop_down_label_value = /*type*/ ctx[32].MT_CODE_LIST)) {
				set_custom_element_data(drop_down, "label", drop_down_label_value);
			}

			if (dirty[0] & /*measurementTypes*/ 2 && drop_down_codelistguid_value !== (drop_down_codelistguid_value = /*type*/ ctx[32].MT_CODE_LIST)) {
				set_custom_element_data(drop_down, "codelistguid", drop_down_codelistguid_value);
			}

			if (dirty[0] & /*measurementTypes*/ 2 && drop_down_column_value !== (drop_down_column_value = `code_${/*type*/ ctx[32].TY_ID}`)) {
				set_custom_element_data(drop_down, "column", drop_down_column_value);
			}
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(label);
			if (detaching) detach_dev(t1);
			if (detaching) detach_dev(drop_down);
			mounted = false;
			dispose();
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block_1.name,
		type: "if",
		source: "(2250:24) {#if type.MT_CODE_LIST}",
		ctx
	});

	return block;
}

// (2229:8) {#each measurementTypes as type, index}
function create_each_block(ctx) {
	let div4;
	let div1;
	let div0;
	let label;
	let t0_value = /*type*/ ctx[32].TY_LONGNAME + "";
	let t0;
	let label_for_value;
	let t1;
	let text_field;
	let text_field_value_value;
	let text_field_column_value;
	let t2;
	let div3;
	let div2;
	let t3;
	let mounted;
	let dispose;
	let if_block = /*type*/ ctx[32].MT_CODE_LIST && create_if_block_1(ctx);

	const block = {
		c: function create() {
			div4 = element("div");
			div1 = element("div");
			div0 = element("div");
			label = element("label");
			t0 = text(t0_value);
			t1 = space();
			text_field = element("text-field");
			t2 = space();
			div3 = element("div");
			div2 = element("div");
			if (if_block) if_block.c();
			t3 = space();
			attr_dev(label, "class", "font-medium roboto-medium");
			attr_dev(label, "for", label_for_value = `measurement-type-${/*subscribeGuid*/ ctx[7]}`);
			add_location(label, file, 2232, 24, 43741);
			set_custom_element_data(text_field, "isvalid", "true");
			set_custom_element_data(text_field, "haschanged", "false");
			set_custom_element_data(text_field, "value", text_field_value_value = /*values*/ ctx[2][/*index*/ ctx[34]]);
			set_custom_element_data(text_field, "fieldtype", "Number");
			set_custom_element_data(text_field, "column", text_field_column_value = `value_${/*type*/ ctx[32].TY_ID}`);
			set_custom_element_data(text_field, "display", "stack");
			set_custom_element_data(text_field, "iseditable", "true");
			set_custom_element_data(text_field, "isnullable", "true");
			set_custom_element_data(text_field, "padding", "false");
			add_location(text_field, file, 2233, 24, 43876);
			attr_dev(div0, "class", "flex flex-col relative");
			add_location(div0, file, 2231, 20, 43679);
			add_location(div1, file, 2230, 16, 43652);
			attr_dev(div2, "class", "flex flex-col relative");
			add_location(div2, file, 2248, 20, 44524);
			add_location(div3, file, 2247, 16, 44497);
			attr_dev(div4, "class", "grid grid-cols-1 md:grid-cols-2 gap-4");
			add_location(div4, file, 2229, 12, 43583);
		},
		m: function mount(target, anchor) {
			insert_dev(target, div4, anchor);
			append_dev(div4, div1);
			append_dev(div1, div0);
			append_dev(div0, label);
			append_dev(label, t0);
			append_dev(div0, t1);
			append_dev(div0, text_field);
			append_dev(div4, t2);
			append_dev(div4, div3);
			append_dev(div3, div2);
			if (if_block) if_block.m(div2, null);
			append_dev(div4, t3);

			if (!mounted) {
				dispose = listen_dev(text_field, "change", /*change_handler_2*/ ctx[21], false, false, false, false);
				mounted = true;
			}
		},
		p: function update(ctx, dirty) {
			if (dirty[0] & /*measurementTypes*/ 2 && t0_value !== (t0_value = /*type*/ ctx[32].TY_LONGNAME + "")) set_data_dev(t0, t0_value);

			if (dirty[0] & /*values*/ 4 && text_field_value_value !== (text_field_value_value = /*values*/ ctx[2][/*index*/ ctx[34]])) {
				set_custom_element_data(text_field, "value", text_field_value_value);
			}

			if (dirty[0] & /*measurementTypes*/ 2 && text_field_column_value !== (text_field_column_value = `value_${/*type*/ ctx[32].TY_ID}`)) {
				set_custom_element_data(text_field, "column", text_field_column_value);
			}

			if (/*type*/ ctx[32].MT_CODE_LIST) {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block_1(ctx);
					if_block.c();
					if_block.m(div2, null);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(div4);
			if (if_block) if_block.d();
			mounted = false;
			dispose();
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_each_block.name,
		type: "each",
		source: "(2229:8) {#each measurementTypes as type, index}",
		ctx
	});

	return block;
}

function create_fragment(ctx) {
	let div;

	function select_block_type(ctx, dirty) {
		if (/*ready*/ ctx[0]) return create_if_block;
		return create_else_block;
	}

	let current_block_type = select_block_type(ctx, [-1, -1]);
	let if_block = current_block_type(ctx);

	const block = {
		c: function create() {
			div = element("div");
			if_block.c();
			this.c = noop;
			add_location(div, file, 2181, 0, 41747);
		},
		l: function claim(nodes) {
			throw new Error("options.hydrate only works if the component was compiled with the `hydratable: true` option");
		},
		m: function mount(target, anchor) {
			insert_dev(target, div, anchor);
			if_block.m(div, null);
		},
		p: function update(ctx, dirty) {
			if (current_block_type === (current_block_type = select_block_type(ctx, dirty)) && if_block) {
				if_block.p(ctx, dirty);
			} else {
				if_block.d(1);
				if_block = current_block_type(ctx);

				if (if_block) {
					if_block.c();
					if_block.m(div, null);
				}
			}
		},
		i: noop,
		o: noop,
		d: function destroy(detaching) {
			if (detaching) detach_dev(div);
			if_block.d();
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_fragment.name,
		type: "component",
		source: "",
		ctx
	});

	return block;
}

function instance($$self, $$props, $$invalidate) {
	let $applicationReady;
	validate_store(applicationReady, 'applicationReady');
	component_subscribe($$self, applicationReady, $$value => $$invalidate(18, $applicationReady = $$value));
	let { $$slots: slots = {}, $$scope } = $$props;
	validate_slots('manual-measurement', slots, []);
	let { show = 'true' } = $$props;
	let { init = 'true' } = $$props;
	let { measurementtypes = '' } = $$props;
	let { guid = '' } = $$props;

	// Base variables
	//================================================================
	let component = get_current_component();

	let firstInitialized = false;
	let subscribeGuid = uuidv4();
	let ready = false;
	const generalText = getComponentText('general');
	const componentText = getComponentText('createMeasurementManual');
	let mounted = false;
	let canReload = true;

	// Component Variables
	let measurementTypes = [];

	let values = [];
	let codes = [];
	let startDate = '';
	let endDate = '';
	let types = [];
	let isValidValue = [];
	let isValidStartDate = 'true';
	let isValidEndDate = 'true';
	let isValidEntry = true;

	// HTML Mount & Component initialization
	//================================================================
	onMount(() => {
		attachNormalize(component);

		tick().then(() => {
			dispatchMountedEvent(component);
			$$invalidate(17, mounted = true);
		});

		return () => {
			bc.messageService.unsubscribeComponent(subscribeGuid);
		};
	});

	const initComponent = () => {
		$$invalidate(16, firstInitialized = true);

		if (measurementtypes && guid) {
			$$invalidate(1, measurementTypes = JSON.parse(measurementtypes));
			types = measurementTypes.map(m => m.TY_ID);
			$$invalidate(2, values = measurementTypes.map(m => ''));
			codes = measurementTypes.map(m => '');
			$$invalidate(0, ready = true);
		}
	};

	/////////////////////////////////////////////////////
	// Init when canReload is true
	////////////////////////////////////////////////////
	const applyProps = afterInit => {
		let shouldInit = afterInit && firstInitialized || !afterInit && !firstInitialized;

		if (shouldInit) {
			handleComponentProps(
				{
					ready: $applicationReady,
					componentMounted: mounted,
					init,
					firstInitialized,
					canReload,
					handler: initComponent
				},
				measurementtypes,
				guid
			);
		}
	};

	// Component functions
	//================================================================
	const handleOnChange = data => {
		console.log('data', data);
		const { column, value: newValue } = data;

		if (column.startsWith('value')) {
			let id = column.split('_')[1];

			for (let i = 0; i < measurementTypes.length; i++) {
				if (measurementTypes[i].TY_ID === id) {
					$$invalidate(2, values[i] = newValue, values);
					break;
				}
			}
		}

		if (column.startsWith('code')) {
			let id = column.split('_')[1];

			for (let i = 0; i < measurementTypes.length; i++) {
				if (measurementTypes[i].TY_ID === id) {
					codes[i] = newValue;
					break;
				}
			}
		}

		if (column === 'endDate') {
			$$invalidate(4, endDate = newValue);
		}

		if (column === 'startDate') {
			$$invalidate(3, startDate = newValue);
		}
	};

	const dispatchCloseEvent = () => {
		let event = createEvent("close", guid, {
			bubbles: false,
			composed: false,
			cancelable: true
		});

		component.dispatchEvent(event);
	};

	const validateAndGetData = () => {
		let isValid = true;
		let finalValues = [];

		for (let i = 0; i < values.length; i++) {
			console.log("Set value " + values[i] + " code " + codes[i]);

			if (values[i] || codes[i]) {
				finalValues.push({
					type: types[i],
					value: values[i],
					code: codes[i]
				});
			}
		}

		if (finalValues.length === 0) {
			isValid = false;
			$$invalidate(6, isValidEntry = false);
		} else {
			$$invalidate(6, isValidEntry = true);
		}

		if (!startDate) {
			$$invalidate(5, isValidStartDate = 'false');
			isValid = false;
			$$invalidate(6, isValidEntry = false);
		} else {
			$$invalidate(5, isValidStartDate = 'true');
		}

		let data = { values: finalValues, startDate, endDate };
		return { valid: isValid, data };
	};

	const writable_props = ['show', 'init', 'measurementtypes', 'guid'];

	

	const change_handler = e => {
		handleOnChange(e.detail);
	};

	const change_handler_1 = e => {
		handleOnChange(e.detail);
	};

	const change_handler_2 = e => {
		handleOnChange(e.detail);
	};

	const change_handler_3 = e => {
		handleOnChange(e.detail);
	};

	$$self.$$set = $$props => {
		if ('show' in $$props) $$invalidate(11, show = $$props.show);
		if ('init' in $$props) $$invalidate(12, init = $$props.init);
		if ('measurementtypes' in $$props) $$invalidate(13, measurementtypes = $$props.measurementtypes);
		if ('guid' in $$props) $$invalidate(14, guid = $$props.guid);
	};

	$$self.$capture_state = () => ({
		bc,
		uuidv4,
		onMount,
		tick,
		attachNormalize,
		dispatchMountedEvent,
		handleComponentInitAttribute,
		handleComponentProps,
		handleComponentShowAttribute,
		get_current_component,
		applicationReady,
		createEvent,
		getComponentText,
		show,
		init,
		measurementtypes,
		guid,
		component,
		firstInitialized,
		subscribeGuid,
		ready,
		generalText,
		componentText,
		mounted,
		canReload,
		measurementTypes,
		values,
		codes,
		startDate,
		endDate,
		types,
		isValidValue,
		isValidStartDate,
		isValidEndDate,
		isValidEntry,
		initComponent,
		applyProps,
		handleOnChange,
		dispatchCloseEvent,
		validateAndGetData,
		$applicationReady
	});

	$$self.$inject_state = $$props => {
		if ('show' in $$props) $$invalidate(11, show = $$props.show);
		if ('init' in $$props) $$invalidate(12, init = $$props.init);
		if ('measurementtypes' in $$props) $$invalidate(13, measurementtypes = $$props.measurementtypes);
		if ('guid' in $$props) $$invalidate(14, guid = $$props.guid);
		if ('component' in $$props) $$invalidate(25, component = $$props.component);
		if ('firstInitialized' in $$props) $$invalidate(16, firstInitialized = $$props.firstInitialized);
		if ('subscribeGuid' in $$props) $$invalidate(7, subscribeGuid = $$props.subscribeGuid);
		if ('ready' in $$props) $$invalidate(0, ready = $$props.ready);
		if ('mounted' in $$props) $$invalidate(17, mounted = $$props.mounted);
		if ('canReload' in $$props) canReload = $$props.canReload;
		if ('measurementTypes' in $$props) $$invalidate(1, measurementTypes = $$props.measurementTypes);
		if ('values' in $$props) $$invalidate(2, values = $$props.values);
		if ('codes' in $$props) codes = $$props.codes;
		if ('startDate' in $$props) $$invalidate(3, startDate = $$props.startDate);
		if ('endDate' in $$props) $$invalidate(4, endDate = $$props.endDate);
		if ('types' in $$props) types = $$props.types;
		if ('isValidValue' in $$props) isValidValue = $$props.isValidValue;
		if ('isValidStartDate' in $$props) $$invalidate(5, isValidStartDate = $$props.isValidStartDate);
		if ('isValidEndDate' in $$props) $$invalidate(9, isValidEndDate = $$props.isValidEndDate);
		if ('isValidEntry' in $$props) $$invalidate(6, isValidEntry = $$props.isValidEntry);
	};

	if ($$props && "$$inject" in $$props) {
		$$self.$inject_state($$props.$$inject);
	}

	$$self.$$.update = () => {
		if ($$self.$$.dirty[0] & /*show*/ 2048) {
			$: if (show) {
				handleComponentShowAttribute(show, component);
			}
		}

		if ($$self.$$.dirty[0] & /*init, firstInitialized, mounted, $applicationReady*/ 462848) {
			$: handleComponentInitAttribute(init, firstInitialized, mounted, $applicationReady, applyProps);
		}

		if ($$self.$$.dirty[0] & /*measurementtypes, guid*/ 24576) {
			$: (measurementtypes, guid, applyProps(true));
		}
	};

	return [
		ready,
		measurementTypes,
		values,
		startDate,
		endDate,
		isValidStartDate,
		isValidEntry,
		subscribeGuid,
		componentText,
		isValidEndDate,
		handleOnChange,
		show,
		init,
		measurementtypes,
		guid,
		validateAndGetData,
		firstInitialized,
		mounted,
		$applicationReady,
		change_handler,
		change_handler_1,
		change_handler_2,
		change_handler_3
	];
}

class CreateMeasurementManual extends SvelteElement {
	constructor(options) {
		super();
		const style = document.createElement('style');
		style.textContent = `100%{left:0;right:0}100%{left:0;right:0}100%{opacity:1}100%{opacity:1}45%{-webkit-transform:scale(var(--fa-beat-scale, 1.25));transform:scale(var(--fa-beat-scale, 1.25))}45%{-webkit-transform:scale(var(--fa-beat-scale, 1.25));transform:scale(var(--fa-beat-scale, 1.25))}50%{opacity:1;-webkit-transform:scale(var(--fa-beat-fade-scale, 1.125));transform:scale(var(--fa-beat-fade-scale, 1.125))}50%{opacity:1;-webkit-transform:scale(var(--fa-beat-fade-scale, 1.125));transform:scale(var(--fa-beat-fade-scale, 1.125))}100%{-webkit-transform:rotate(360deg);transform:rotate(360deg)}100%{-webkit-transform:rotate(360deg);transform:rotate(360deg)}:host{display:block}:host([hidden]){display:none}.border-gray-300{--tw-border-opacity:1;border-color:rgba(209, 213, 219, var(--tw-border-opacity))}.border-red-400{--tw-border-opacity:1;border-color:rgba(248, 113, 113, var(--tw-border-opacity))}.rounded{border-radius:0.25rem}.border-solid{border-style:solid}.border{border-width:1px}.border-2{border-width:2px}.flex{display:-webkit-box;display:-ms-flexbox;display:-webkit-flex;display:flex}.grid{display:-ms-grid;display:grid}.flex-col{-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;-webkit-flex-direction:column;flex-direction:column}.font-medium{font-family:Roboto-Medium}.p-2{padding:0.5rem}.relative{position:relative}.gap-4{grid-gap:1rem;gap:1rem}.grid-cols-1{grid-template-columns:repeat(1, minmax(0, 1fr))}@media(min-width: 768px){.md\\:grid-cols-2{grid-template-columns:repeat(2, minmax(0, 1fr))}}`;
		this.shadowRoot.appendChild(style);

		init_1(
			this,
			{
				target: this.shadowRoot,
				props: attribute_to_object(this.attributes),
				customElement: true
			},
			instance,
			create_fragment,
			safe_not_equal,
			{
				show: 11,
				init: 12,
				measurementtypes: 13,
				guid: 14,
				validateAndGetData: 15
			},
			null,
			[-1, -1]
		);

		if (options) {
			if (options.target) {
				insert_dev(options.target, this, options.anchor);
			}

			if (options.props) {
				this.$set(options.props);
				flush();
			}
		}
	}

	static get observedAttributes() {
		return ["show", "init", "measurementtypes", "guid", "validateAndGetData"];
	}

	get show() {
		return this.$$.ctx[11];
	}

	set show(show) {
		this.$$set({ show });
		flush();
	}

	get init() {
		return this.$$.ctx[12];
	}

	set init(init) {
		this.$$set({ init });
		flush();
	}

	get measurementtypes() {
		return this.$$.ctx[13];
	}

	set measurementtypes(measurementtypes) {
		this.$$set({ measurementtypes });
		flush();
	}

	get guid() {
		return this.$$.ctx[14];
	}

	set guid(guid) {
		this.$$set({ guid });
		flush();
	}

	get validateAndGetData() {
		return this.$$.ctx[15];
	}

	set validateAndGetData(value) {
		throw new Error("<manual-measurement>: Cannot set read-only property 'validateAndGetData'");
	}
}

customElements.define("manual-measurement", CreateMeasurementManual);
export default CreateMeasurementManual;
import linkify from "../../web_modules/linkify-it.js";
import {roundOff} from "../components/Map/map-utils.js";
import {getGroups} from "./getterUtils.js";
import {isUserDefinedView} from "./dataviewUtils.js";
export const linkyInstance = linkify();
export function escapeRegExp(text) {
  return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
}
export const getSimpleDisplayValue = async (value, fieldInfo, metadata) => {
  try {
    if (value !== void 0 && value !== null || fieldInfo.dataType === "Boolean") {
      switch (fieldInfo.dataType) {
        case "List": {
          const mappingList = metadata.properties.listMapping.find((l) => l.fieldName === fieldInfo.name);
          if (mappingList) {
            const listValue = mappingList.values.find((v) => v.guid === value);
            if (listValue) {
              value = listValue.value;
            }
          }
          return value;
        }
        case "EntityList": {
          let viewList = metadata.properties.viewLists.find((v) => v.columnName === fieldInfo.name);
          if (viewList) {
            let viewListMetadata = await bc.parameterService.getMetadata(viewList.dataviewGuid);
            let entry = await viewList.getEntry(value);
            if (entry) {
              return entry[viewListMetadata.titleProperty];
            }
          }
        }
        case "Guid": {
          return value;
        }
        case "String": {
          return value;
        }
        case "DateTime": {
          let dateString = "";
          if (value) {
            const tSplit = value.split("T");
            const fullDateArray = tSplit[0].split("-");
            dateString = `${fullDateArray[2]}.${fullDateArray[1]}.${fullDateArray[0]}`;
          }
          return dateString;
        }
        case "Int16":
        case "Int32":
        case "Int64": {
          return value;
        }
        case "Decimal":
        case "Double": {
          let tempValue = parseFloat(value);
          if (fieldInfo.viewPrecision) {
            tempValue = tempValue.toFixed(fieldInfo.viewPrecision);
          }
          if (fieldInfo.viewLength) {
            if (tempValue.toString().length > fieldInfo.viewLength) {
              tempValue = tempValue.toString().substring(0, fieldInfo.viewLength);
            }
          }
          return parseFloat(tempValue).toString();
        }
        case "Boolean": {
          if (value === true) {
            return "Ja";
          } else if (value === false) {
            return "Nein";
          } else if (value === null) {
            return "";
          }
        }
        case "Geometry": {
        }
        default: {
          return value;
        }
      }
    }
  } catch (error) {
    console.error("getDisplayValue", value, fieldInfo, metadata, error);
    return null;
  }
};
export const processComposedFields = async (metadata, postObject) => {
  let composedObjectArray = [];
  let composedProperties = [];
  for (let i = 0; i < metadata.properties.fields.length; i++) {
    let field = metadata.properties.fields[i];
    let defaultValue = void 0;
    if (field.defaultValue) {
      defaultValue = field.defaultValue;
      if (defaultValue.indexOf("Composed") !== -1 && defaultValue.trim().startsWith("{")) {
        composedObjectArray[field.name] = JSON.parse(defaultValue);
        composedProperties.push(field.name);
      }
    }
  }
  for (let i = 0; i < composedProperties.length; i++) {
    let composedProperty = composedProperties[i];
    let composedObject = composedObjectArray[composedProperty];
    if (composedObject.Composed.method === "concat") {
      let fields = composedObject.Composed.fields;
      let prefix = composedObject.Composed.prefix;
      let suffix = composedObject.Composed.suffix;
      let empty = composedObject.Composed.empty;
      let finalValue = "";
      for (let j = 0; j < fields.length; j++) {
        let field = fields[j];
        if (postObject[field] !== void 0 && postObject[field] !== null && postObject[field] !== "") {
          const fieldMetadata = metadata.getField(field);
          let value = await getSimpleDisplayValue(postObject[field], fieldMetadata, metadata);
          finalValue = finalValue + prefix[j] + value + suffix[j];
        } else {
          if (empty[j] !== "") {
            finalValue = finalValue + prefix[j] + empty[j] + suffix[j];
          }
        }
      }
      postObject[composedProperty] = finalValue;
    } else if (composedObject.Composed.method === "math") {
      let fields = composedObject.Composed.fields;
      let operators = composedObject.Composed.operator;
      let round = +composedObject.Composed.round;
      let empty = composedObject.Composed.empty;
      let finalValue;
      for (let j = 0; j < fields.length; j++) {
        let field = fields[j];
        if (!Number.isNaN(+field) || postObject[field] !== void 0 && postObject[field] !== null) {
          if (!finalValue) {
            let value = +field;
            if (Number.isNaN(value)) {
              finalValue = postObject[field];
            } else {
              finalValue = value;
            }
          } else {
            let value = +field;
            if (Number.isNaN(value)) {
              value = postObject[field];
            }
            if (operators[j] === "+") {
              finalValue = +finalValue + +value;
            }
            if (operators[j] === "-") {
              finalValue = finalValue - value;
            }
            if (operators[j] === "*") {
              finalValue = finalValue * value;
            }
            if (operators[j] === "/") {
              finalValue = finalValue / value;
            }
          }
        } else {
          if (empty[j] !== "") {
            if (finalValue) {
              let value = +empty[j];
              if (operators[j] === "+") {
                finalValue = +finalValue + +value;
              }
              if (operators[j] === "-") {
                finalValue = finalValue - value;
              }
              if (operators[j] === "*") {
                finalValue = finalValue * value;
              }
              if (operators[j] === "/") {
                finalValue = finalValue / value;
              }
            } else {
              finalValue = +empty[j];
            }
          }
        }
      }
      finalValue = roundOff(finalValue, round);
      postObject[composedProperty] = finalValue;
    }
  }
  return postObject;
};
export const setCurrentFocus = (key) => {
  bc.parameterService.set("FOCUS", key);
};
export const parseFieldGroups = async (objectMetadata) => {
  let isEntityForm = isUserDefinedView(objectMetadata.resourceName);
  let allGroupTypes = await getGroups();
  let groups = [];
  let groupIds = [];
  let hiddenGroups = [];
  objectMetadata.properties.fields.forEach((field) => {
    if (!groups.includes(field.atGroup)) {
      groups = [...groups, field.atGroup];
      groupIds = [...groupIds, field.atGroupId];
      if (allGroupTypes) {
        let group = allGroupTypes.find((g) => g.TY_ID === field.atGroupId);
        if (group && group.TY_NUMERIC === 0) {
          hiddenGroups = [...hiddenGroups, group.TY_LONGNAME];
        }
      }
    }
  });
  return {
    groups,
    hiddenGroups,
    groupIds
  };
};
export const getNullablesMap = (keys, metadata) => {
  const mappedNullable = new Map();
  for (const key of keys) {
    const field = metadata.getField(key);
    mappedNullable.set(key, field.nullable);
  }
  return mappedNullable;
};
export const getUniqueMap = (keys, metadata) => {
  const uniqueMap = new Map();
  for (const key of keys) {
    const field = metadata.getField(key);
    uniqueMap.set(key, field.unique);
  }
  return uniqueMap;
};
export const checkGroupHasFieldsFactoryFunction = (objectMetadata, mappedEditable) => {
  return (group) => {
    let field = objectMetadata.properties.fields.find((f) => f.atGroup === group && (mappedEditable.get(f.name) === true || objectMetadata.isVisibleList(f.name) === true) && !f.name.endsWith("WellKnownText") && !f.name.endsWith("CoordinateSystemId"));
    return !!field;
  };
};

import bc from "../bcShim.js";
import {constants} from "../constants.js";
export const getDataTypes = async () => {
  let dataTypes = bc.parameterService.get("DATA_TYPES");
  if (dataTypes === null) {
    dataTypes = await bc.metadataManager.getCachedUgdmTypesByDomain(constants.domain.DATA_TYPE);
    bc.parameterService.set("DATA_TYPES", dataTypes);
  }
  return dataTypes;
};
export const getEntityCoreAttributes = async () => {
  let coreAttributes = bc.parameterService.get("CORE_ENTITY_ATTRIBUTES");
  if (coreAttributes === null) {
    let coreAttributesRequest = await bc.metadataManager.fetchUgdmCoreUgdmEntityAttributes();
    if (coreAttributesRequest.value) {
      coreAttributes = coreAttributesRequest.value;
      bc.parameterService.set("CORE_ENTITY_ATTRIBUTES", coreAttributes);
    }
  }
  return coreAttributes;
};
export const getCoreDataviewGuids = async () => {
  let guids = bc.parameterService.get("CORE_DATAVIEW_GUIDS");
  if (guids === null) {
    let dataTypes = await bc.metadataManager.getCachedUgdmTypesByDomain(constants.domain.CC_UGDM_COREMODEL_DATA_VIEW);
    guids = dataTypes.map((m) => m.TY_ID);
    bc.parameterService.set("CORE_DATAVIEW_GUIDS", guids);
  }
  return guids;
};
export const getMetamodelDataviewGuids = async () => {
  let guids = bc.parameterService.get("METAMODEL_DATAVIEW_GUIDS");
  if (guids === null) {
    let dataTypes = await bc.metadataManager.getCachedUgdmTypesByDomain(constants.domain.CC_UGDM_METAMODEL_DATA_VIEW);
    guids = dataTypes.map((m) => m.TY_ID);
    bc.parameterService.set("METAMODEL_DATAVIEW_GUIDS", guids);
  }
  return guids;
};
export const getGroups = async () => {
  let groups = bc.parameterService.get("GROUPS");
  if (groups === null) {
    groups = await bc.metadataManager.getCachedUgdmTypesByDomain(constants.domain.ATTRIBUTE_GROUP);
    bc.parameterService.set("GROUPS", groups);
  }
  return groups;
};
export const getComplexAttributeTypes = async () => {
  let complexAttributeTypes = bc.parameterService.get("COMPLEX_ATTRIBUTE_TYPES");
  if (complexAttributeTypes === null) {
    complexAttributeTypes = await bc.metadataManager.getCachedUgdmTypesByDomain(constants.domain.COMPLEX_ATTRIBUTE_TYPE);
    bc.parameterService.set("COMPLEX_ATTRIBUTE_TYPES", complexAttributeTypes);
  }
  return complexAttributeTypes;
};
export const getArtifactMimeTypes = async () => {
  let mimeTypes = bc.parameterService.get("MIME_TYPES");
  if (mimeTypes === null) {
    const response = await bc.metadataManager.fetchAllArtifactMimeTypes();
    mimeTypes = response.value;
    bc.parameterService.set("MIME_TYPES", mimeTypes);
  }
  return mimeTypes;
};
export const getDomains = async () => {
  let domains = bc.parameterService.get("DOMAINS");
  if (domains === null) {
    let domains2 = await bc.metadataManager.getDomains();
    bc.parameterService.set("DOMAINS", domains2);
  }
  return domains;
};
export const getTypeDomains = async () => {
  let typeDomains = bc.parameterService.get("TYPE_DOMAINS");
  if (typeDomains === null) {
    let typeDomains2 = await bc.metadataManager.getTypeDomains();
    bc.parameterService.set("TYPE_DOMAINS", typeDomains2);
  }
  return typeDomains;
};

import bc from "../../bcShim.js";
import {GeometryType} from "../../interfaces.js";
import VectorSource from "../../../web_modules/ol/source/Vector.js";
import {Fill, Stroke, Style} from "../../../web_modules/ol/style.js";
import CircleStyle from "../../../web_modules/ol/style/Circle.js";
import VectorLayer from "../../../web_modules/ol/layer/Vector.js";
import {PARAMS} from "../../services/parameterService.js";
import MultiPoint from "../../../web_modules/ol/geom/MultiPoint.js";
import {wktHelper} from "../../utilities/WKTHelper.js";
import {getThemeTreeViewGuids} from "../../utilities/generalUtils.js";
export const getMaxZIndex = (map) => {
  let zIndex = 0;
  if (map) {
    map.getLayers().forEach((l) => {
      if (l.getZIndex() > zIndex) {
        zIndex = l.getZIndex();
      }
    });
  } else {
    zIndex = 1e3;
  }
  return zIndex;
};
export const mapToolsWithSidebarOpen = ["FeatureSearch-Point", "DBSearch-Square", "DBSearch-Polygon", "DBSearch-Circle", "WMSFeatureInfo", "Preview", "GeoSearch"];
export const createVectorLayer = (layerParameterName, addStyles) => {
  if (bc.parameterService.get(layerParameterName)) {
    let layer = bc.parameterService.get(layerParameterName);
    layer.getSource().clear();
    return layer;
  } else {
    const width = 2;
    const styles = [];
    styles[GeometryType.POLYGON] = [
      new Style({
        fill: new Fill({
          color: "#1976d217"
        }),
        stroke: new Stroke({
          color: "#0039dc",
          width: 2
        })
      })
    ];
    styles[GeometryType.CIRCLE] = [
      new Style({
        fill: new Fill({
          color: "#1976d217"
        }),
        stroke: new Stroke({
          color: "#0039dc",
          width: 2
        })
      })
    ];
    styles[GeometryType.MULTI_POLYGON] = styles[GeometryType.POLYGON];
    styles[GeometryType.LINE_STRING] = [
      new Style({
        stroke: new Stroke({
          color: "#0039dc",
          lineDash: [10, 10],
          width
        })
      })
    ];
    styles[GeometryType.MULTI_LINE_STRING] = styles[GeometryType.LINE_STRING];
    styles[GeometryType.POINT] = [
      new Style({
        image: new CircleStyle({
          radius: 10,
          fill: new Fill({
            color: "#ffaa117d"
          }),
          stroke: new Stroke({
            color: "#ffaa11",
            width: 2
          })
        })
      })
    ];
    styles[GeometryType.MULTI_POINT] = styles[GeometryType.POINT];
    styles[GeometryType.GEOMETRY_COLLECTION] = styles[GeometryType.POLYGON].concat(styles[GeometryType.LINE_STRING], styles[GeometryType.POINT]);
    const styleFunction = function(feature, resolution) {
      const featureStyleFunction = feature.getStyleFunction();
      if (featureStyleFunction) {
        return featureStyleFunction.call(feature, resolution);
      } else {
        return styles[feature.getGeometry().getType()];
      }
    };
    let vectorSource = new VectorSource();
    let layer;
    if (!addStyles || addStyles === true) {
      layer = new VectorLayer({
        source: vectorSource,
        style: styleFunction
      });
    } else if (addStyles === void 0 || addStyles === false) {
      layer = new VectorLayer({
        source: vectorSource
      });
    }
    layer.set("name", layerParameterName);
    bc.parameterService.set(layerParameterName, layer);
    return layer;
  }
};
export const createEditVectorLayer = (layerParameterName) => {
  if (bc.parameterService.get(layerParameterName)) {
    let layer = bc.parameterService.get(layerParameterName);
    layer.getSource().clear();
    return layer;
  } else {
    const width = 2;
    const styles = [];
    styles[GeometryType.POLYGON] = [
      new Style({
        fill: new Fill({
          color: "#A872D138"
        }),
        stroke: new Stroke({
          color: "white",
          width: 3
        })
      }),
      new Style({
        stroke: new Stroke({
          color: "#8400dc",
          width: 1
        })
      }),
      new Style({
        image: new CircleStyle({
          radius: 3,
          fill: new Fill({
            color: "blue"
          })
        }),
        geometry: function(feature) {
          const coordinates = feature.getGeometry().getCoordinates()[0];
          return new MultiPoint(coordinates);
        }
      })
    ];
    styles[GeometryType.CIRCLE] = [
      new Style({
        fill: new Fill({
          color: "#A872D138"
        }),
        stroke: new Stroke({
          color: "#8400dc",
          width: 2
        })
      })
    ];
    styles[GeometryType.MULTI_POLYGON] = [
      new Style({
        fill: new Fill({
          color: "#A872D138"
        }),
        stroke: new Stroke({
          color: "white",
          width: 3
        })
      }),
      new Style({
        stroke: new Stroke({
          color: "#8400dc",
          width: 1
        })
      }),
      new Style({
        image: new CircleStyle({
          radius: 3,
          fill: new Fill({
            color: "blue"
          })
        }),
        geometry: function(feature) {
          const coordinates = feature.getGeometry().getCoordinates();
          let allPoints = [];
          if (coordinates) {
            for (let i = 0; i < coordinates.length; i++) {
              if (coordinates[i] && coordinates[i][0] && coordinates[i][0].length > 1) {
                allPoints = [...allPoints, ...coordinates[i][0]];
              }
            }
          }
          return new MultiPoint(allPoints);
        }
      })
    ];
    styles[GeometryType.LINE_STRING] = [
      new Style({
        fill: new Fill({
          color: "#A872D138"
        }),
        stroke: new Stroke({
          color: "#8400dc",
          lineDash: [10, 10],
          width
        })
      }),
      new Style({
        image: new CircleStyle({
          radius: 3,
          fill: new Fill({
            color: "blue"
          })
        }),
        geometry: function(feature) {
          const coordinates = feature.getGeometry().getCoordinates();
          return new MultiPoint(coordinates);
        }
      })
    ];
    styles[GeometryType.MULTI_LINE_STRING] = styles[GeometryType.LINE_STRING];
    styles[GeometryType.POINT] = [
      new Style({
        image: new CircleStyle({
          radius: 10,
          fill: new Fill({
            color: "#A872D138"
          }),
          stroke: new Stroke({
            color: "#8400dc",
            width: 2
          })
        })
      })
    ];
    styles[GeometryType.MULTI_POINT] = styles[GeometryType.POINT];
    styles[GeometryType.GEOMETRY_COLLECTION] = styles[GeometryType.POLYGON].concat(styles[GeometryType.LINE_STRING], styles[GeometryType.POINT]);
    const styleFunction = function(feature, resolution) {
      const featureStyleFunction = feature.getStyleFunction();
      if (featureStyleFunction) {
        return featureStyleFunction.call(feature, resolution);
      } else {
        return styles[feature.getGeometry().getType()];
      }
    };
    let vectorSource = new VectorSource();
    let layer = new VectorLayer({
      source: vectorSource,
      style: styleFunction
    });
    bc.parameterService.set(layerParameterName, layer);
    return layer;
  }
};
export const getCanvas = async (map) => {
  let image = new Image();
  let dimensions = [];
  image.id = "pic";
  image.crossOrigin = "anonymous";
  let mapCanvas = document.createElement("canvas");
  let mapHTMLElement = map.getTargetElement();
  let size = map.getSize();
  mapCanvas.width = mapHTMLElement.clientWidth;
  mapCanvas.height = size[1];
  let mapContext = mapCanvas.getContext("2d");
  Array.prototype.forEach.call(mapHTMLElement.querySelectorAll(".ol-layer canvas"), function(canvas) {
    if (canvas.width > 0) {
      let opacity = canvas.parentNode.style.opacity;
      mapContext.globalAlpha = opacity === "" ? 1 : Number(opacity);
      let transform = canvas.style.transform;
      let matrix = transform.match(/^matrix\(([^\(]*)\)$/)[1].split(",").map(Number);
      CanvasRenderingContext2D.prototype.setTransform.apply(mapContext, matrix);
      mapContext.drawImage(canvas, 0, 0);
    }
  });
  const lengthOrAreaInfo = bc.parameterService.get("LENGTH_OR_AREA");
  if (lengthOrAreaInfo !== void 0 && lengthOrAreaInfo !== null) {
    let info = "";
    if (lengthOrAreaInfo.type === "Line") {
      info = "L\xE4nge: " + lengthOrAreaInfo.value;
    } else if (lengthOrAreaInfo.type === "Area") {
      info = "Fl\xE4che: " + lengthOrAreaInfo.value;
      info = info.substring(0, info.length - 12);
      info = info + "\xB2";
    }
    mapContext.beginPath();
    mapContext.rect(20, mapCanvas.height - 70, mapCanvas.width - 40, 50);
    mapContext.fillStyle = "white";
    mapContext.fill();
    mapContext.font = "26pt Verdana";
    mapContext.fillStyle = "black";
    mapContext.fillText(info, 25, mapCanvas.height - 35);
  }
  mapContext.globalAlpha = 1;
  mapContext.setTransform(1, 0, 0, 1, 0, 0);
  image.src = mapCanvas.toDataURL();
  dimensions = [mapHTMLElement.clientWidth, map.getSize()[1], mapHTMLElement.clientWidth / map.getSize()[1]];
  return {img: image, dimensions};
};
export const searchDatabaseFeaturesByWKT = async (map, wkt, projection, limitResults, dataviewGuids) => {
  try {
    let views = [];
    let layersWithResults = [];
    let wktFeatures = [];
    let featureItems = [];
    console.log("dataviewGuids", dataviewGuids);
    const layersTemp = map.getLayers();
    let layers = [];
    layersTemp.forEach((l) => {
      if (l.getVisible() && l.get("type") && l.get("type") === "normal") {
        if (dataviewGuids) {
          if (dataviewGuids.includes(l.get("viewId"))) {
            layers.push(l);
          }
        } else {
          layers.push(l);
        }
      }
    });
    console.log("layers", layers);
    let featurePromises = [];
    let names = [];
    let idProperties = [];
    let geometryColumnNames = [];
    const viewIds = [];
    for (let i = 0; i < layers.length; i++) {
      let layer = layers[i];
      const name = layer.get("name");
      const viewId = layer.get("viewId");
      console.log("searchDatabaseFeaturesByWKT " + name + " " + viewId);
      if (viewId) {
        try {
          let themeTreeGuids = getThemeTreeViewGuids();
          let showOtherViews = bc.parameterService.get("SHOW_VIEWS_NOT_IN_THEME_TREE");
          let goOn = false;
          console.log("searchDatabaseFeaturesByWKT " + showOtherViews, themeTreeGuids);
          if (showOtherViews) {
            goOn = true;
          } else {
            if (themeTreeGuids) {
              if (themeTreeGuids.includes(viewId)) {
                goOn = true;
              }
            } else {
              goOn = true;
            }
          }
          if (goOn) {
            viewIds.push(viewId);
            const entityMetadata = await bc.parameterService.getMetadata(viewId);
            views.push({name, guid: viewId});
            let idPropertyField = entityMetadata.getBaseField("EY_ID");
            if (idPropertyField) {
              idProperties = [...idProperties, idPropertyField.name];
            }
            geometryColumnNames.push(entityMetadata.geometryField);
            let url = "";
            if (limitResults) {
              url = bc.parameterService.get(PARAMS.urls.ApplicationDataServiceBaseUrl) + name + "?$inlinecount=allpages&$top=500&$spatialfilter=geo.intersects('" + entityMetadata.geometryField + "',geometry'SRID=" + projection.replace("EPSG:", "") + ";GEOMETRYCOLLECTION(" + wkt + ")')";
            } else {
              url = bc.parameterService.get(PARAMS.urls.ApplicationDataServiceBaseUrl) + name + "?$inlinecount=allpages&$spatialfilter=geo.intersects('" + entityMetadata.geometryField + "',geometry'SRID=" + projection.replace("EPSG:", "") + ";GEOMETRYCOLLECTION(" + wkt + ")')";
            }
            names.push(name);
            featurePromises.push(bc.ajaxService.fetchRaw(bc.ajaxService.requestHeaders.jsonGet, url));
          }
        } catch (error) {
          console.error("error while getting features", error);
        }
      }
    }
    if (featurePromises.length > 0) {
      const allData = await Promise.all(featurePromises);
      const featureJsonResponse = [];
      for (let i = 0; i < allData.length; i++) {
        let response = allData[i];
        if (response.status === 200) {
          featureJsonResponse[i] = await response.json();
        } else {
          featureJsonResponse[i] = void 0;
        }
      }
      featureJsonResponse.forEach((items, index) => {
        if (items) {
          let view = views[index];
          let viewId = viewIds[index];
          layersWithResults = [...layersWithResults, {name: names[index], guid: view.guid}];
          let idProperty = idProperties[index];
          const geomColumnName = geometryColumnNames[index];
          items.Items.forEach((wktItem, wktItemIndex) => {
            const WKTString = wktItem[geomColumnName].Geometry.WellKnownText;
            const wktFeature = wktHelper.readFeature(WKTString);
            const fill = new Fill({color: "#ff71003d"});
            const stroke = new Stroke({color: "orange", width: 2});
            wktFeature.set("VIEW_ID", viewId);
            wktFeature.set("LAYERNAME", names[index]);
            wktFeature.set("ID_KEY", idProperty);
            wktFeature.set("GUID", wktItem[idProperty]);
            wktFeature.setStyle(new Style({
              fill: new Fill({
                color: "#ffbe504a"
              }),
              stroke: new Stroke({
                color: "yellow",
                width: 2
              }),
              image: new CircleStyle({
                radius: 5,
                fill: new Fill({
                  color: "#ffaa117d"
                }),
                stroke: new Stroke({
                  color: "#ffaa11",
                  width: 2
                })
              })
            }));
            bc.parameterService.set("OBJECT_ITEM_" + view.guid + "_" + wktItem[idProperty], wktItem);
            if (wktFeatures[names[index]] === void 0) {
              wktFeatures[names[index]] = [];
            }
            wktFeatures[names[index]][wktItemIndex] = wktFeature;
          });
          featureItems[names[index]] = [items.Items];
        }
      });
    }
    return {
      layersWithResults,
      wktFeatures,
      featureItems
    };
  } catch (error) {
    throw error;
  }
};
export const searchDatabaseFeaturesForSnap = async (map, wkt, projection, limitResults, dataviewGuids) => {
  try {
    let views = [];
    let layersWithResults = [];
    let wktFeatures = [];
    let featureItems = [];
    let featurePromises = [];
    let names = [];
    let idProperties = [];
    let geometryColumnNames = [];
    const viewIds = [];
    for (let i = 0; i < dataviewGuids.length; i++) {
      try {
        viewIds.push(dataviewGuids[i]);
        const entityMetadata = await bc.parameterService.getMetadata(dataviewGuids[i]);
        let name = entityMetadata.resourceName;
        views.push({name: entityMetadata.resourceLongname, guid: dataviewGuids[i]});
        let idPropertyField = entityMetadata.getBaseField("EY_ID");
        if (idPropertyField) {
          idProperties = [...idProperties, idPropertyField.name];
        }
        geometryColumnNames.push(entityMetadata.geometryField);
        let url = "";
        if (limitResults) {
          url = bc.parameterService.get(PARAMS.urls.ApplicationDataServiceBaseUrl) + name + "?$inlinecount=allpages&$top=500&$spatialfilter=geo.intersects('" + entityMetadata.geometryField + "',geometry'SRID=" + projection.replace("EPSG:", "") + ";GEOMETRYCOLLECTION(" + wkt + ")')";
        } else {
          url = bc.parameterService.get(PARAMS.urls.ApplicationDataServiceBaseUrl) + name + "?$inlinecount=allpages&$spatialfilter=geo.intersects('" + entityMetadata.geometryField + "',geometry'SRID=" + projection.replace("EPSG:", "") + ";GEOMETRYCOLLECTION(" + wkt + ")')";
        }
        names.push(name);
        featurePromises.push(bc.ajaxService.fetchRaw(bc.ajaxService.requestHeaders.jsonGet, url));
      } catch (error) {
        console.log("error while getting features", error);
      }
    }
    if (featurePromises.length > 0) {
      const allData = await Promise.all(featurePromises);
      const featureJsonResponse = [];
      for (let i = 0; i < allData.length; i++) {
        let response = allData[i];
        if (response.status === 200) {
          featureJsonResponse[i] = await response.json();
        } else {
          featureJsonResponse[i] = void 0;
        }
      }
      featureJsonResponse.forEach((items, index) => {
        if (items) {
          let view = views[index];
          let viewId = viewIds[index];
          layersWithResults = [...layersWithResults, {name: names[index], guid: view.guid}];
          let idProperty = idProperties[index];
          const geomColumnName = geometryColumnNames[index];
          items.Items.forEach((wktItem, wktItemIndex) => {
            const WKTString = wktItem[geomColumnName].Geometry.WellKnownText;
            const wktFeature = wktHelper.readFeature(WKTString);
            wktFeature.set("VIEW_ID", viewId);
            wktFeature.set("LAYERNAME", names[index]);
            wktFeature.set("ID_KEY", idProperty);
            wktFeature.set("GUID", wktItem[idProperty]);
            wktFeature.set("SNAP", true);
            wktFeature.setStyle([
              new Style({
                fill: new Fill({
                  color: "rgba(0,0,0,0.05)"
                }),
                stroke: new Stroke({
                  color: "#d3d3d3",
                  width: 2
                }),
                image: new CircleStyle({
                  radius: 5,
                  fill: new Fill({
                    color: "rgba(0,0,0,0.67)"
                  }),
                  stroke: new Stroke({
                    color: "#d3d3d3",
                    width: 3
                  })
                })
              }),
              new Style({
                image: new CircleStyle({
                  radius: 3,
                  fill: new Fill({
                    color: "#d3d3d3"
                  })
                }),
                geometry: function(feature) {
                  const coordinates = feature.getGeometry().getCoordinates()[0];
                  return new MultiPoint(coordinates);
                }
              })
            ]);
            bc.parameterService.set("OBJECT_ITEM_" + view.guid + "_" + wktItem[idProperty], wktItem);
            if (wktFeatures[names[index]] === void 0) {
              wktFeatures[names[index]] = [];
            }
            wktFeatures[names[index]][wktItemIndex] = wktFeature;
          });
          featureItems[names[index]] = [items.Items];
        }
      });
    }
    return {
      layersWithResults,
      wktFeatures,
      featureItems
    };
  } catch (error) {
    throw error;
  }
};
export const createWKTFeaturesForMapResult = (items, views, names, viewIds, wktWriter, idProperties, geometryColumnNames) => {
  let wktFeatures = [];
  let featureItems = [];
  let layersWithResults = [];
  items.forEach((items2, index) => {
    if (items2) {
      let view = views[index];
      let viewId = viewIds[index];
      layersWithResults = [...layersWithResults, {name: names[index], guid: view.guid}];
      let idProperty = idProperties[index];
      const geomColumnName = geometryColumnNames[index];
      items2.Items.forEach((wktItem, wktItemIndex) => {
        const WKTString = wktItem[geomColumnName].Geometry.WellKnownText;
        const wktFeature = wktWriter.readFeature(WKTString);
        const fill = new Fill({color: "#ff71003d"});
        const stroke = new Stroke({color: "orange", width: 2});
        wktFeature.set("VIEW_ID", viewId);
        wktFeature.set("LAYERNAME", names[index]);
        wktFeature.set("ID_KEY", idProperty);
        wktFeature.set("GUID", wktItem[idProperty]);
        wktFeature.setStyle(new Style({
          fill: new Fill({
            color: "#ffbe504a"
          }),
          stroke: new Stroke({
            color: "yellow",
            width: 2
          }),
          image: new CircleStyle({
            radius: 5,
            fill: new Fill({
              color: "#ffaa117d"
            }),
            stroke: new Stroke({
              color: "#ffaa11",
              width: 2
            })
          })
        }));
        bc.parameterService.set("OBJECT_ITEM_" + view.guid + "_" + wktItem[idProperty], wktItem);
        if (wktFeatures[names[index]] === void 0) {
          wktFeatures[names[index]] = [];
        }
        wktFeatures[names[index]][wktItemIndex] = wktFeature;
      });
      featureItems[names[index]] = [items2.Items];
    }
  });
  return {
    layersWithResults,
    wktFeatures,
    featureItems
  };
};
export const searchFeatureInfosByWKT = async (map, coordinates) => {
  try {
    let featureInfoLayerNames = [];
    let featureInfoHTML = [];
    const layers = map.getLayers();
    let singlePointPromises = [];
    let allQueriedLayers = [];
    layers.forEach((layer) => {
      if (layer.getVisible() && layer.get("type") && layer.get("type") === "normal") {
        const name = layer.get("display-name");
        const viewId = layer.get("viewId");
        const layerType = layer.get("type");
        const layerService = layer.get("mapService");
        if (!viewId && layerService === "WMS") {
          const tileLayer = layer;
          const source = tileLayer.getSource();
          let url = source.getFeatureInfoUrl(coordinates, map.getView().getResolution(), bc.parameterService.get("PROJECTION"), {
            INFO_FORMAT: layer.get("format")
          });
          if (url) {
            url = url + "&FI_POINT_TOLERANCE=5";
            allQueriedLayers.push(name);
            try {
              console.log(url);
              let fetchPromise = fetch(url);
              singlePointPromises.push(fetchPromise);
            } catch (error) {
              console.log(error);
            }
          }
        }
      }
    });
    const allSinglePointResponses = await Promise.all(singlePointPromises);
    const allResponsesText = allSinglePointResponses.map((response) => {
      return response.text();
    });
    const html = await Promise.all(allResponsesText);
    allQueriedLayers.forEach((layerName, index) => {
      if (html[index].length > 0 && html[index].indexOf("WMS server error") === -1) {
        featureInfoLayerNames = [...featureInfoLayerNames, layerName.replace(/\./g, "_")];
        featureInfoHTML[layerName.replace(/\./g, "_")] = html[index];
      }
    });
    return {
      featureInfoLayerNames,
      featureInfoHTML
    };
  } catch (error) {
    throw error;
  }
};
export const createIsSearchingDiv = () => {
  let isSearchingDiv = document.createElement("div");
  isSearchingDiv.style.display = "flex";
  isSearchingDiv.style.position = "absolute";
  isSearchingDiv.style.right = "20px";
  isSearchingDiv.style.top = "20px";
  isSearchingDiv.style.backgroundColor = "#374151";
  isSearchingDiv.style.color = "white";
  isSearchingDiv.style.zIndex = "50";
  isSearchingDiv.style.padding = "0.5rem";
  isSearchingDiv.style.borderRadius = "5px";
  isSearchingDiv.style.border = "solid thin white";
  isSearchingDiv.innerHTML = '<p class="text-white mr-2 p-0 mb-0">Suche</p><div><i class="fas fa-circle-notch fa-spin text-white" /></div>';
  return isSearchingDiv;
};
export const createRadiusDiv = () => {
  let radiusDiv = document.createElement("div");
  radiusDiv.style.display = "flex";
  radiusDiv.style.position = "absolute";
  radiusDiv.style.left = "50%";
  radiusDiv.style.transform = "translateX(-50%)";
  radiusDiv.style.top = "20px";
  radiusDiv.style.backgroundColor = "#565f6c";
  radiusDiv.style.color = "white";
  radiusDiv.style.zIndex = "50";
  radiusDiv.style.padding = "0.5rem";
  radiusDiv.style.borderRadius = "5px";
  radiusDiv.style.border = "solid thin white";
  return radiusDiv;
};
export const createSearchResultScrollDiv = () => {
  let resultScrollDiv = document.createElement("div");
  resultScrollDiv.style.display = "flex";
  resultScrollDiv.style.alignItems = "center";
  resultScrollDiv.style.justifyContent = "center";
  resultScrollDiv.style.position = "absolute";
  resultScrollDiv.style.right = "20px";
  resultScrollDiv.style.top = "20px";
  resultScrollDiv.style.backgroundColor = "#565f6c";
  resultScrollDiv.style.color = "white";
  resultScrollDiv.style.zIndex = "50";
  resultScrollDiv.style.borderRadius = "5px";
  resultScrollDiv.style.height = "2rem";
  resultScrollDiv.style.width = "2rem";
  resultScrollDiv.style.border = "solid thin white";
  return resultScrollDiv;
};
export const roundOff = (num, places) => {
  const x = Math.pow(10, places);
  return Math.round(num * x) / x;
};
export const getLayerSourceFunctionsMap = new Map();

/* src\components\Map\MapTools\MapAddImageButton\MapAddImageButton.svelte generated by Svelte v3.57.0 */
import {
	SvelteElement,
	add_location,
	append_dev,
	attr_dev,
	attribute_to_object,
	detach_dev,
	dispatch_dev,
	element,
	flush,
	init,
	insert_dev,
	listen_dev,
	noop,
	safe_not_equal,
	toggle_class,
	validate_slots
} from "../../../../../web_modules/svelte/internal.js";

import bc from '../../../../bcShim.js';
import { createVectorLayer, getMaxZIndex } from '../../map-utils.js';
import { v4 as uuidv4 } from "../../../../../web_modules/uuid.js";
import { wktHelper } from "../../../../utilities/WKTHelper.js";
import { get_current_component } from "../../../../../web_modules/svelte/internal.js";
import { onMount, tick } from "../../../../../web_modules/svelte.js";

import {
	attachNormalize,
	dispatchMountedEvent
} from "../../../../utilities/componentUtils.js";

import { getComponentText } from "../../../../utilities/generalUtils.js";
const file = "src\\components\\Map\\MapTools\\MapAddImageButton\\MapAddImageButton.svelte";

// (1823:4) {#if ready}
function create_if_block(ctx) {
	let div;
	let i;
	let mounted;
	let dispose;

	const block = {
		c: function create() {
			div = element("div");
			i = element("i");
			attr_dev(i, "class", "far fa-panorama fa-lg !h-10 !w-10 flex items-center justify-center text-white rounded");
			toggle_class(i, "hover:text-orange-500", /*maptool*/ ctx[2] !== 'Add-Image-Layer');
			toggle_class(i, "bg-blue-500", /*maptool*/ ctx[2] === 'Add-Image-Layer');
			add_location(i, file, 1829, 12, 34311);
			attr_dev(div, "class", "cursor-pointer !h-10 !w-10 relative");
			toggle_class(div, "underline-element", /*maptool*/ ctx[2] === 'GeoSearch');
			add_location(div, file, 1823, 8, 34016);
		},
		m: function mount(target, anchor) {
			insert_dev(target, div, anchor);
			append_dev(div, i);

			if (!mounted) {
				dispose = listen_dev(div, "click", /*click_handler*/ ctx[4], false, false, false, false);
				mounted = true;
			}
		},
		p: noop,
		d: function destroy(detaching) {
			if (detaching) detach_dev(div);
			mounted = false;
			dispose();
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block.name,
		type: "if",
		source: "(1823:4) {#if ready}",
		ctx
	});

	return block;
}

function create_fragment(ctx) {
	let div;
	let if_block = /*ready*/ ctx[0] && create_if_block(ctx);

	const block = {
		c: function create() {
			div = element("div");
			if (if_block) if_block.c();
			this.c = noop;
			add_location(div, file, 1821, 0, 33984);
		},
		l: function claim(nodes) {
			throw new Error("options.hydrate only works if the component was compiled with the `hydratable: true` option");
		},
		m: function mount(target, anchor) {
			insert_dev(target, div, anchor);
			if (if_block) if_block.m(div, null);
		},
		p: function update(ctx, [dirty]) {
			if (/*ready*/ ctx[0]) {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block(ctx);
					if_block.c();
					if_block.m(div, null);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}
		},
		i: noop,
		o: noop,
		d: function destroy(detaching) {
			if (detaching) detach_dev(div);
			if (if_block) if_block.d();
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_fragment.name,
		type: "component",
		source: "",
		ctx
	});

	return block;
}

function instance($$self, $$props, $$invalidate) {
	let { $$slots: slots = {}, $$scope } = $$props;
	validate_slots('map-add-image-button', slots, []);
	const generalText = getComponentText('general');
	let ready = false;
	const component = get_current_component();
	let mounted = false;

	// Component variables
	//================================================================
	let maptool;

	let vectorLayer;
	let vectorSource;
	let map;
	let setMapTool;
	let setMapSidebar;
	let hasBeenSet = false;
	let componentId = uuidv4();

	// HTML Mount & Component initialization
	//================================================================
	onMount(() => {
		attachNormalize(component);

		tick().then(() => {
			dispatchMountedEvent(component);
			mounted = true;
		});

		return () => {
			
		};
	});

	const initComponent = (newMap, mapToolFunc, setSidebarFunc) => {
		map = newMap;
		$$invalidate(1, setMapTool = mapToolFunc);
		setMapSidebar = setSidebarFunc;
		bc.messageService.subscribe('SHOW_GEO_SEARCH_PREVIEW', 'MAP_GEO_SEARCH', showPreview);
		$$invalidate(0, ready = true);
	};

	// Component functions
	//================================================================
	const showPreview = wkt => {
		let feature = wktHelper.readFeature(wkt);
		vectorSource.addFeature(feature);

		setTimeout(
			() => {
				map.getView().fit(feature.getGeometry().getExtent(), {
					callback: zoomOut2Levels,
					size: map.getSize()
				});
			},
			100
		);
	};

	const handleMapTool = tool => {
		if (maptool !== 'Add-Image-Layer') {
			if (hasBeenSet) {
				cleanup();
				bc.messageService.publish('CLEAN_GEOSEARCH');

				if (maptool !== 'DBSearch-Square' && maptool !== 'DBSearch-Polygon' && maptool !== 'DBSearch-Circle' && maptool !== 'FeatureSearch-Point' && maptool !== 'GeoSearch' && maptool !== 'Add-Image-Layer') {
					setMapSidebar(false);
				}
			}
		}

		if (maptool === 'Add-Image-Layer') {
			hasBeenSet = true;
			setMapSidebar(true);

			if (!vectorLayer) {
				vectorLayer = createVectorLayer('MAP_GEO_SEARCH_PREVIEW' + componentId);
				let zIndex = getMaxZIndex(map);
				vectorLayer.setZIndex(zIndex + 100);
				vectorSource = vectorLayer.getSource();
				map.addLayer(vectorLayer);
			}
		}
	};

	const zoomOut2Levels = () => {
		map.getView().animate({
			zoom: map.getView().getZoom() - 2,
			duration: 250
		});
	};

	const cleanup = () => {
		if (vectorLayer) {
			map.removeLayer(vectorLayer);
		}

		if (vectorSource) {
			vectorSource.clear();
		}

		vectorLayer = undefined;
		vectorSource = undefined;
	};

	const writable_props = [];

	

	const click_handler = e => {
		maptool !== 'Add-Image-Layer'
		? setMapTool('Add-Image-Layer')
		: setMapTool('');
	};

	$$self.$capture_state = () => ({
		bc,
		createVectorLayer,
		getMaxZIndex,
		uuidv4,
		wktHelper,
		get_current_component,
		onMount,
		tick,
		attachNormalize,
		dispatchMountedEvent,
		getComponentText,
		generalText,
		ready,
		component,
		mounted,
		maptool,
		vectorLayer,
		vectorSource,
		map,
		setMapTool,
		setMapSidebar,
		hasBeenSet,
		componentId,
		initComponent,
		showPreview,
		handleMapTool,
		zoomOut2Levels,
		cleanup
	});

	$$self.$inject_state = $$props => {
		if ('ready' in $$props) $$invalidate(0, ready = $$props.ready);
		if ('mounted' in $$props) mounted = $$props.mounted;
		if ('maptool' in $$props) $$invalidate(2, maptool = $$props.maptool);
		if ('vectorLayer' in $$props) vectorLayer = $$props.vectorLayer;
		if ('vectorSource' in $$props) vectorSource = $$props.vectorSource;
		if ('map' in $$props) map = $$props.map;
		if ('setMapTool' in $$props) $$invalidate(1, setMapTool = $$props.setMapTool);
		if ('setMapSidebar' in $$props) setMapSidebar = $$props.setMapSidebar;
		if ('hasBeenSet' in $$props) hasBeenSet = $$props.hasBeenSet;
		if ('componentId' in $$props) componentId = $$props.componentId;
	};

	if ($$props && "$$inject" in $$props) {
		$$self.$inject_state($$props.$$inject);
	}

	$: handleMapTool(maptool);
	return [ready, setMapTool, maptool, initComponent, click_handler];
}

class MapAddImageButton extends SvelteElement {
	constructor(options) {
		super();
		const style = document.createElement('style');
		style.textContent = `.fa-lg{font-size:1.25em;line-height:0.05em;vertical-align:-0.075em}45%{-webkit-transform:scale(var(--fa-beat-scale, 1.25));transform:scale(var(--fa-beat-scale, 1.25))}45%{-webkit-transform:scale(var(--fa-beat-scale, 1.25));transform:scale(var(--fa-beat-scale, 1.25))}50%{opacity:1;-webkit-transform:scale(var(--fa-beat-fade-scale, 1.125));transform:scale(var(--fa-beat-fade-scale, 1.125))}50%{opacity:1;-webkit-transform:scale(var(--fa-beat-fade-scale, 1.125));transform:scale(var(--fa-beat-fade-scale, 1.125))}100%{-webkit-transform:rotate(360deg);transform:rotate(360deg)}100%{-webkit-transform:rotate(360deg);transform:rotate(360deg)}.fa-panorama::before{content:""}.far{font-weight:400 !important}.far{font-family:"Font Awesome 6 Pro" !important;font-style:normal !important}.underline-element::before{content:"";position:absolute;z-index:-1;left:51%;right:51%;bottom:0;background:#2563eb;height:4px;animation-name:hint;animation-timing-function:ease-out;animation-duration:0.15s;animation-iteration-count:1;animation-fill-mode:forwards}100%{left:0;right:0}.bg-blue-500{--tw-bg-opacity:1;background-color:rgba(59, 130, 246, var(--tw-bg-opacity))}.rounded{border-radius:0.25rem}.cursor-pointer{cursor:pointer}.flex{display:-webkit-box;display:-ms-flexbox;display:-webkit-flex;display:flex}.items-center{-webkit-box-align:center;-ms-flex-align:center;-webkit-align-items:center;align-items:center}.justify-center{-webkit-box-pack:center;-ms-flex-pack:center;-webkit-justify-content:center;justify-content:center}.\\!h-10{height:2.5rem !important}.relative{position:relative}.text-white{--tw-text-opacity:1;color:rgba(255, 255, 255, var(--tw-text-opacity))}.hover\\:text-orange-500:hover{--tw-text-opacity:1;color:rgba(249, 115, 22, var(--tw-text-opacity))}.\\!w-10{width:2.5rem !important}`;
		this.shadowRoot.appendChild(style);

		init(
			this,
			{
				target: this.shadowRoot,
				props: attribute_to_object(this.attributes),
				customElement: true
			},
			instance,
			create_fragment,
			safe_not_equal,
			{ initComponent: 3 },
			null
		);

		if (options) {
			if (options.target) {
				insert_dev(options.target, this, options.anchor);
			}

			if (options.props) {
				this.$set(options.props);
				flush();
			}
		}
	}

	static get observedAttributes() {
		return ["initComponent"];
	}

	get initComponent() {
		return this.$$.ctx[3];
	}

	set initComponent(value) {
		throw new Error("<map-add-image-button>: Cannot set read-only property 'initComponent'");
	}
}

customElements.define("map-add-image-button", MapAddImageButton);
export default MapAddImageButton;
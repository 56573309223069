/* src\components\Containers\Container\Container.svelte generated by Svelte v3.57.0 */
import {
	SvelteElement,
	add_location,
	append_dev,
	attr_dev,
	attribute_to_object,
	component_subscribe,
	detach_dev,
	dispatch_dev,
	element,
	flush,
	init as init_1,
	insert_dev,
	noop,
	safe_not_equal,
	validate_slots,
	validate_store
} from "../../../../web_modules/svelte/internal.js";

import bc from '../../../bcShim.js';
import { v4 as uuidv4 } from '../../../../web_modules/uuid.js';
import { onMount, tick } from '../../../../web_modules/svelte.js';

import {
	attachNormalize,
	componentInitAndAttributeHandler,
	dispatchMountedEvent,
	handleComponentShowAttribute
} from "../../../utilities/componentUtils.js";

import { get_current_component } from "../../../../web_modules/svelte/internal.js";
import { applicationReady } from "../../../utilities/store.js";
import { getComponentText } from "../../../utilities/generalUtils.js";
const file = "src\\components\\Containers\\Container\\Container.svelte";

function create_fragment(ctx) {
	let div;
	let slot;

	const block = {
		c: function create() {
			div = element("div");
			slot = element("slot");
			this.c = noop;
			add_location(slot, file, 2034, 4, 37735);
			attr_dev(div, "class", "flex flex-grow h-full w-full");
			add_location(div, file, 2033, 0, 37687);
		},
		l: function claim(nodes) {
			throw new Error("options.hydrate only works if the component was compiled with the `hydratable: true` option");
		},
		m: function mount(target, anchor) {
			insert_dev(target, div, anchor);
			append_dev(div, slot);
		},
		p: noop,
		i: noop,
		o: noop,
		d: function destroy(detaching) {
			if (detaching) detach_dev(div);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_fragment.name,
		type: "component",
		source: "",
		ctx
	});

	return block;
}

function instance($$self, $$props, $$invalidate) {
	let $applicationReady;
	validate_store(applicationReady, 'applicationReady');
	component_subscribe($$self, applicationReady, $$value => $$invalidate(4, $applicationReady = $$value));
	let { $$slots: slots = {}, $$scope } = $$props;
	validate_slots('bc-container', slots, []);
	let { show = 'true' } = $$props;
	let { init = 'true' } = $$props;

	// Base Variables
	//===========================================================
	let firstInitialized = false;

	let subscribeGuid = uuidv4();
	let ready = false;
	let mounted = false;
	const generalText = getComponentText('general');
	let component = get_current_component();
	let canReload = false;

	// HTML Mount & Component initialization
	//================================================================
	onMount(() => {
		attachNormalize(component);

		tick().then(() => {
			dispatchMountedEvent(component);
			$$invalidate(3, mounted = true);
		});

		return () => {
			bc.messageService.unsubscribeComponent(subscribeGuid);
		};
	});

	const initComponent = () => {
		$$invalidate(2, firstInitialized = true);
		ready = true;
	};

	const writable_props = ['show', 'init'];

	

	$$self.$$set = $$props => {
		if ('show' in $$props) $$invalidate(0, show = $$props.show);
		if ('init' in $$props) $$invalidate(1, init = $$props.init);
	};

	$$self.$capture_state = () => ({
		bc,
		uuidv4,
		onMount,
		tick,
		attachNormalize,
		componentInitAndAttributeHandler,
		dispatchMountedEvent,
		handleComponentShowAttribute,
		get_current_component,
		applicationReady,
		getComponentText,
		show,
		init,
		firstInitialized,
		subscribeGuid,
		ready,
		mounted,
		generalText,
		component,
		canReload,
		initComponent,
		$applicationReady
	});

	$$self.$inject_state = $$props => {
		if ('show' in $$props) $$invalidate(0, show = $$props.show);
		if ('init' in $$props) $$invalidate(1, init = $$props.init);
		if ('firstInitialized' in $$props) $$invalidate(2, firstInitialized = $$props.firstInitialized);
		if ('subscribeGuid' in $$props) subscribeGuid = $$props.subscribeGuid;
		if ('ready' in $$props) ready = $$props.ready;
		if ('mounted' in $$props) $$invalidate(3, mounted = $$props.mounted);
		if ('component' in $$props) $$invalidate(8, component = $$props.component);
		if ('canReload' in $$props) $$invalidate(9, canReload = $$props.canReload);
	};

	if ($$props && "$$inject" in $$props) {
		$$self.$inject_state($$props.$$inject);
	}

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*show*/ 1) {
			$: if (show) {
				handleComponentShowAttribute(show, component);
			}
		}

		if ($$self.$$.dirty & /*$applicationReady, mounted, init, firstInitialized*/ 30) {
			$: if ($applicationReady && mounted && init) {
				componentInitAndAttributeHandler({
					mounted,
					handler: initComponent,
					init,
					firstInitialized,
					canReload
				});
			}
		}
	};

	return [show, init, firstInitialized, mounted, $applicationReady];
}

class Container extends SvelteElement {
	constructor(options) {
		super();
		const style = document.createElement('style');
		style.textContent = `100%{left:0;right:0}100%{left:0;right:0}100%{opacity:1}100%{opacity:1}45%{-webkit-transform:scale(var(--fa-beat-scale, 1.25));transform:scale(var(--fa-beat-scale, 1.25))}45%{-webkit-transform:scale(var(--fa-beat-scale, 1.25));transform:scale(var(--fa-beat-scale, 1.25))}50%{opacity:1;-webkit-transform:scale(var(--fa-beat-fade-scale, 1.125));transform:scale(var(--fa-beat-fade-scale, 1.125))}50%{opacity:1;-webkit-transform:scale(var(--fa-beat-fade-scale, 1.125));transform:scale(var(--fa-beat-fade-scale, 1.125))}100%{-webkit-transform:rotate(360deg);transform:rotate(360deg)}100%{-webkit-transform:rotate(360deg);transform:rotate(360deg)}.flex{display:-webkit-box;display:-ms-flexbox;display:-webkit-flex;display:flex}.flex-grow{-webkit-box-flex:1;-ms-flex-positive:1;-webkit-flex-grow:1;flex-grow:1}.h-full{height:100%}.w-full{width:100%}`;
		this.shadowRoot.appendChild(style);

		init_1(
			this,
			{
				target: this.shadowRoot,
				props: attribute_to_object(this.attributes),
				customElement: true
			},
			instance,
			create_fragment,
			safe_not_equal,
			{ show: 0, init: 1 },
			null
		);

		if (options) {
			if (options.target) {
				insert_dev(options.target, this, options.anchor);
			}

			if (options.props) {
				this.$set(options.props);
				flush();
			}
		}
	}

	static get observedAttributes() {
		return ["show", "init"];
	}

	get show() {
		return this.$$.ctx[0];
	}

	set show(show) {
		this.$$set({ show });
		flush();
	}

	get init() {
		return this.$$.ctx[1];
	}

	set init(init) {
		this.$$set({ init });
		flush();
	}
}

customElements.define("bc-container", Container);
export default Container;
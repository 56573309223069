/* src\components\Popover\CustomPopover.svelte generated by Svelte v3.57.0 */
import {
	SvelteElement,
	add_location,
	append_dev,
	attr_dev,
	attribute_to_object,
	binding_callbacks,
	component_subscribe,
	detach_dev,
	dispatch_dev,
	element,
	empty,
	flush,
	init as init_1,
	insert_dev,
	listen_dev,
	noop,
	run_all,
	safe_not_equal,
	set_custom_element_data,
	set_data_dev,
	space,
	text,
	toggle_class,
	validate_slots,
	validate_store
} from "../../../web_modules/svelte/internal.js";

import bc from '../../bcShim.js';
import { onMount, tick } from '../../../web_modules/svelte.js';
import { v4 as uuidv4 } from '../../../web_modules/uuid.js';
import { isInsideElement } from "../../utilities/visibilityResizeUtils.js";

import {
	attachNormalize,
	dispatchMountedEvent,
	handleComponentInitAttribute,
	handleComponentProps,
	handleComponentShowAttribute
} from "../../utilities/componentUtils.js";

import { get_current_component } from "../../../web_modules/svelte/internal.js";
import { applicationReady, isMobileStore } from "../../utilities/store.js";
import { getComponentText } from "../../utilities/generalUtils.js";
const file = "src\\components\\Popover\\CustomPopover.svelte";

// (2405:4) {#if $isMobileStore}
function create_if_block_3(ctx) {
	let if_block_anchor;
	let if_block = /*isOpen*/ ctx[10] && create_if_block_4(ctx);

	const block = {
		c: function create() {
			if (if_block) if_block.c();
			if_block_anchor = empty();
		},
		m: function mount(target, anchor) {
			if (if_block) if_block.m(target, anchor);
			insert_dev(target, if_block_anchor, anchor);
		},
		p: function update(ctx, dirty) {
			if (/*isOpen*/ ctx[10]) {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block_4(ctx);
					if_block.c();
					if_block.m(if_block_anchor.parentNode, if_block_anchor);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}
		},
		d: function destroy(detaching) {
			if (if_block) if_block.d(detaching);
			if (detaching) detach_dev(if_block_anchor);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block_3.name,
		type: "if",
		source: "(2405:4) {#if $isMobileStore}",
		ctx
	});

	return block;
}

// (2406:8) {#if isOpen}
function create_if_block_4(ctx) {
	let full_overlay;
	let div;
	let slot;
	let full_overlay_closeevent_value;
	let full_overlay_closeeventvalue_value;
	let full_overlay_title_value;

	const block = {
		c: function create() {
			full_overlay = element("full-overlay");
			div = element("div");
			slot = element("slot");
			attr_dev(slot, "name", "content");
			add_location(slot, file, 2414, 20, 49284);
			attr_dev(div, "class", "overflow-auto h-full p-2 border border-gray-300 border-solid rounded");
			attr_dev(div, "slot", "content");
			add_location(div, file, 2411, 16, 49115);
			set_custom_element_data(full_overlay, "closeevent", full_overlay_closeevent_value = `CLOSE_POPOVER`);
			set_custom_element_data(full_overlay, "closeeventvalue", full_overlay_closeeventvalue_value = 'not_' + /*subscribeGuid*/ ctx[12]);
			set_custom_element_data(full_overlay, "title", full_overlay_title_value = /*title*/ ctx[1] !== '' ? /*title*/ ctx[1] : '');
			add_location(full_overlay, file, 2406, 12, 48904);
		},
		m: function mount(target, anchor) {
			insert_dev(target, full_overlay, anchor);
			append_dev(full_overlay, div);
			append_dev(div, slot);
			/*slot_binding*/ ctx[25](slot);
		},
		p: function update(ctx, dirty) {
			if (dirty[0] & /*title*/ 2 && full_overlay_title_value !== (full_overlay_title_value = /*title*/ ctx[1] !== '' ? /*title*/ ctx[1] : '')) {
				set_custom_element_data(full_overlay, "title", full_overlay_title_value);
			}
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(full_overlay);
			/*slot_binding*/ ctx[25](null);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block_4.name,
		type: "if",
		source: "(2406:8) {#if isOpen}",
		ctx
	});

	return block;
}

// (2425:8) {#if showclosebutton === 'true' && showtitle === 'true'}
function create_if_block(ctx) {
	let div;
	let t;
	let if_block0 = /*showtitle*/ ctx[3] === 'true' && create_if_block_2(ctx);
	let if_block1 = /*showclosebutton*/ ctx[2] === 'true' && create_if_block_1(ctx);

	const block = {
		c: function create() {
			div = element("div");
			if (if_block0) if_block0.c();
			t = space();
			if (if_block1) if_block1.c();
			attr_dev(div, "class", "flex items-center justify-between gap-4");
			toggle_class(div, "pr-4", /*padding*/ ctx[0] === 'false');
			toggle_class(div, "pt-4", /*padding*/ ctx[0] === 'false');
			toggle_class(div, "pl-4", /*padding*/ ctx[0] === 'false');
			add_location(div, file, 2425, 12, 49797);
		},
		m: function mount(target, anchor) {
			insert_dev(target, div, anchor);
			if (if_block0) if_block0.m(div, null);
			append_dev(div, t);
			if (if_block1) if_block1.m(div, null);
		},
		p: function update(ctx, dirty) {
			if (/*showtitle*/ ctx[3] === 'true') {
				if (if_block0) {
					if_block0.p(ctx, dirty);
				} else {
					if_block0 = create_if_block_2(ctx);
					if_block0.c();
					if_block0.m(div, t);
				}
			} else if (if_block0) {
				if_block0.d(1);
				if_block0 = null;
			}

			if (/*showclosebutton*/ ctx[2] === 'true') {
				if (if_block1) {
					if_block1.p(ctx, dirty);
				} else {
					if_block1 = create_if_block_1(ctx);
					if_block1.c();
					if_block1.m(div, null);
				}
			} else if (if_block1) {
				if_block1.d(1);
				if_block1 = null;
			}

			if (dirty[0] & /*padding*/ 1) {
				toggle_class(div, "pr-4", /*padding*/ ctx[0] === 'false');
			}

			if (dirty[0] & /*padding*/ 1) {
				toggle_class(div, "pt-4", /*padding*/ ctx[0] === 'false');
			}

			if (dirty[0] & /*padding*/ 1) {
				toggle_class(div, "pl-4", /*padding*/ ctx[0] === 'false');
			}
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(div);
			if (if_block0) if_block0.d();
			if (if_block1) if_block1.d();
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block.name,
		type: "if",
		source: "(2425:8) {#if showclosebutton === 'true' && showtitle === 'true'}",
		ctx
	});

	return block;
}

// (2431:16) {#if showtitle === 'true'}
function create_if_block_2(ctx) {
	let span;
	let t;

	const block = {
		c: function create() {
			span = element("span");
			t = text(/*title*/ ctx[1]);
			attr_dev(span, "class", "font-medium roboto-medium text-lg");
			add_location(span, file, 2431, 20, 50081);
		},
		m: function mount(target, anchor) {
			insert_dev(target, span, anchor);
			append_dev(span, t);
		},
		p: function update(ctx, dirty) {
			if (dirty[0] & /*title*/ 2) set_data_dev(t, /*title*/ ctx[1]);
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(span);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block_2.name,
		type: "if",
		source: "(2431:16) {#if showtitle === 'true'}",
		ctx
	});

	return block;
}

// (2434:16) {#if showclosebutton === 'true'}
function create_if_block_1(ctx) {
	let icon_button;
	let mounted;
	let dispose;

	const block = {
		c: function create() {
			icon_button = element("icon-button");
			set_custom_element_data(icon_button, "icon", "far fa-times");
			set_custom_element_data(icon_button, "size", "xs");
			set_custom_element_data(icon_button, "color", "white");
			set_custom_element_data(icon_button, "type", "secondary");
			add_location(icon_button, file, 2434, 20, 50238);
		},
		m: function mount(target, anchor) {
			insert_dev(target, icon_button, anchor);

			if (!mounted) {
				dispose = listen_dev(icon_button, "click", /*click_handler_1*/ ctx[26], false, false, false, false);
				mounted = true;
			}
		},
		p: noop,
		d: function destroy(detaching) {
			if (detaching) detach_dev(icon_button);
			mounted = false;
			dispose();
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block_1.name,
		type: "if",
		source: "(2434:16) {#if showclosebutton === 'true'}",
		ctx
	});

	return block;
}

function create_fragment(ctx) {
	let div2;
	let div0;
	let slot0;
	let t0;
	let t1;
	let div1;
	let t2;
	let slot1;
	let mounted;
	let dispose;
	let if_block0 = /*$isMobileStore*/ ctx[11] && create_if_block_3(ctx);
	let if_block1 = /*showclosebutton*/ ctx[2] === 'true' && /*showtitle*/ ctx[3] === 'true' && create_if_block(ctx);

	const block = {
		c: function create() {
			div2 = element("div");
			div0 = element("div");
			slot0 = element("slot");
			t0 = space();
			if (if_block0) if_block0.c();
			t1 = space();
			div1 = element("div");
			if (if_block1) if_block1.c();
			t2 = space();
			slot1 = element("slot");
			this.c = noop;
			attr_dev(slot0, "name", "open-close");
			add_location(slot0, file, 2402, 8, 48805);
			toggle_class(div0, "w-fit", /*widthfit*/ ctx[4] === 'true');
			add_location(div0, file, 2394, 4, 48542);
			attr_dev(slot1, "name", "content");
			add_location(slot1, file, 2444, 8, 50593);
			attr_dev(div1, "class", "bg-white text-black popover-shadow invisible fixed -left-9999px -top-9999px rounded border border-solid border-gray-300 overflow-auto");
			toggle_class(div1, "p-4", /*padding*/ ctx[0] === 'true');
			toggle_class(div1, "!hidden", /*$isMobileStore*/ ctx[11]);
			add_location(div1, file, 2419, 4, 49415);
			attr_dev(div2, "class", "float-component-container");
			add_location(div2, file, 2393, 0, 48475);
		},
		l: function claim(nodes) {
			throw new Error("options.hydrate only works if the component was compiled with the `hydratable: true` option");
		},
		m: function mount(target, anchor) {
			insert_dev(target, div2, anchor);
			append_dev(div2, div0);
			append_dev(div0, slot0);
			/*div0_binding*/ ctx[23](div0);
			append_dev(div2, t0);
			if (if_block0) if_block0.m(div2, null);
			append_dev(div2, t1);
			append_dev(div2, div1);
			if (if_block1) if_block1.m(div1, null);
			append_dev(div1, t2);
			append_dev(div1, slot1);
			/*slot1_binding*/ ctx[27](slot1);
			/*div1_binding*/ ctx[28](div1);
			/*div2_binding*/ ctx[29](div2);

			if (!mounted) {
				dispose = [
					listen_dev(div0, "click", /*click_handler*/ ctx[24], false, false, false, false),
					listen_dev(div1, "click", /*checkForHide*/ ctx[14], false, false, false, false)
				];

				mounted = true;
			}
		},
		p: function update(ctx, dirty) {
			if (dirty[0] & /*widthfit*/ 16) {
				toggle_class(div0, "w-fit", /*widthfit*/ ctx[4] === 'true');
			}

			if (/*$isMobileStore*/ ctx[11]) {
				if (if_block0) {
					if_block0.p(ctx, dirty);
				} else {
					if_block0 = create_if_block_3(ctx);
					if_block0.c();
					if_block0.m(div2, t1);
				}
			} else if (if_block0) {
				if_block0.d(1);
				if_block0 = null;
			}

			if (/*showclosebutton*/ ctx[2] === 'true' && /*showtitle*/ ctx[3] === 'true') {
				if (if_block1) {
					if_block1.p(ctx, dirty);
				} else {
					if_block1 = create_if_block(ctx);
					if_block1.c();
					if_block1.m(div1, t2);
				}
			} else if (if_block1) {
				if_block1.d(1);
				if_block1 = null;
			}

			if (dirty[0] & /*padding*/ 1) {
				toggle_class(div1, "p-4", /*padding*/ ctx[0] === 'true');
			}

			if (dirty[0] & /*$isMobileStore*/ 2048) {
				toggle_class(div1, "!hidden", /*$isMobileStore*/ ctx[11]);
			}
		},
		i: noop,
		o: noop,
		d: function destroy(detaching) {
			if (detaching) detach_dev(div2);
			/*div0_binding*/ ctx[23](null);
			if (if_block0) if_block0.d();
			if (if_block1) if_block1.d();
			/*slot1_binding*/ ctx[27](null);
			/*div1_binding*/ ctx[28](null);
			/*div2_binding*/ ctx[29](null);
			mounted = false;
			run_all(dispose);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_fragment.name,
		type: "component",
		source: "",
		ctx
	});

	return block;
}

function instance($$self, $$props, $$invalidate) {
	let $applicationReady;
	let $isMobileStore;
	validate_store(applicationReady, 'applicationReady');
	component_subscribe($$self, applicationReady, $$value => $$invalidate(22, $applicationReady = $$value));
	validate_store(isMobileStore, 'isMobileStore');
	component_subscribe($$self, isMobileStore, $$value => $$invalidate(11, $isMobileStore = $$value));
	let { $$slots: slots = {}, $$scope } = $$props;
	validate_slots('custom-pop-over', slots, []);
	let { closeonevent = '' } = $$props;
	let { oncloseevent = '' } = $$props;
	let { axis = 'vertical' } = $$props;
	let { padding = 'true' } = $$props;
	let { title = '' } = $$props;
	let { showclosebutton = 'true' } = $$props;
	let { showtitle = 'true' } = $$props;
	let { widthfit = 'false' } = $$props;
	let { show = 'true' } = $$props;
	let { init = 'true' } = $$props;

	// Base variables
	//================================================================
	let firstInitialized = false;

	let container;
	let subscribeGuid = uuidv4();
	const generalText = getComponentText('general');
	let component = get_current_component();
	let mounted = false;
	let canReload = true;

	// Component variables
	//================================================================
	let showContent = false;

	let openCloseContainer;
	let contentContainer;
	let slotElement;
	let popper;
	let componentId = uuidv4();
	let isOpen = false;
	let contentWidth = 0;
	let contentHeight = 0;
	let buttonRect;
	let isLeft = false;
	let isRight = false;
	let isTop = false;
	let isBottom = false;
	let top = 0;
	let left = 0;
	let intervalCallback = null;
	let isSetting = false;
	let maxHeight = 0;
	let maxWidth = 0;

	// HTML Mount & Component initialization
	//================================================================
	onMount(() => {
		attachNormalize(component);

		tick().then(() => {
			dispatchMountedEvent(component);
			$$invalidate(21, mounted = true);
		});

		return () => {
			bc.messageService.unsubscribeComponent(subscribeGuid);
			bc.messageService.unsubscribeComponent(componentId);

			if (container) {
				container.removeEventListener('click', stopPropagation);
			}

			if (intervalCallback) {
				clearInterval(intervalCallback);
			}

			window.removeEventListener('click', checkClickOutsidePopover);
		};
	});

	const initComponent = (openCloseContainer, contentContainer) => {
		$$invalidate(20, firstInitialized = true);

		if (openCloseContainer && contentContainer) {
			bc.messageService.subscribe('CLOSE_POPOVER', subscribeGuid, checkClosePopover);

			if (closeonevent !== '') {
				bc.messageService.subscribe(closeonevent, subscribeGuid, hidePopover);
			}

			container.addEventListener('click', stopPropagation);
		}
	};

	const applyProps = afterInit => {
		let shouldInit = afterInit && firstInitialized || !afterInit && !firstInitialized;

		if (shouldInit) {
			handleComponentProps(
				{
					ready: $applicationReady,
					componentMounted: mounted,
					init,
					firstInitialized,
					canReload,
					handler: initComponent
				},
				openCloseContainer,
				contentContainer
			);
		}
	};

	// Component functions
	//================================================================
	const getBestSide = () => {
		isLeft = false;
		isRight = false;
		isTop = false;
		isBottom = false;
		const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
		const screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

		// Check if it is left
		if (buttonRect.left < screenWidth / 2) {
			// If it's clearly left
			if (buttonRect.left + buttonRect.width <= screenWidth / 2) {
				isLeft = true;
			} else {
				let difference = screenWidth / 2 - buttonRect.left;

				if (difference <= buttonRect.width) {
					isLeft = true;
				} else {
					isRight = true;
				}
			}
		}

		// Check isRight
		if (buttonRect.left >= screenWidth / 2) {
			isRight = true;
		}

		// Check isTop
		if (buttonRect.top < screenHeight / 2) {
			// if it's clearly top
			if (buttonRect.top + buttonRect.height < screenWidth / 2) {
				isTop = true;
			} else {
				let difference = screenHeight / 2 - buttonRect.top;

				if (difference <= buttonRect.height) {
					isTop = true;
				} else {
					isBottom = true;
				}
			}
		}

		// Check if it's Bottom
		if (buttonRect.top > screenHeight / 2) {
			isBottom = true;
		}
	}; // console.log('isLeft', isLeft)
	// console.log('isRight', isRight)
	// console.log('isTop', isTop)
	// console.log('isBottom', isBottom)

	const getX = () => {
		const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
		let x = 0;

		////////////////////////////////////////
		// Vertical Axis
		////////////////////////////////////////
		if (axis === 'vertical') {
			if (isLeft) {
				x = buttonRect.left + buttonRect.width / 2 - contentWidth / 2;

				if (x < 0) {
					// console.log('outOfBounds, adjusting')
					x = buttonRect.left + 1;
				}
			} else {
				x = buttonRect.left + buttonRect.width / 2 - contentWidth / 2;

				if (x + contentWidth > screenWidth) {
					x = buttonRect.left + buttonRect.width - (contentWidth + 1);

					if (x < 0) {
						x = 0;
					}
				}
			}
		} else // Horizontal Axis
		////////////////////////////////////////
		{
			if (isLeft) {
				x = buttonRect.left + buttonRect.width; ////////////////////////////////////////
			} else {
				x = buttonRect.left - (buttonRect.width + 1);
			}
		}

		return x;
	};

	const getY = () => {
		const screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
		let y = 0;

		if (axis === 'vertical') {
			if (isTop) {
				y = buttonRect.top + buttonRect.height + 1;
			} else {
				y = buttonRect.top - (contentHeight + 1);
			}
		} else {
			if (isTop) {
				y = buttonRect.top + buttonRect.height / 2 - contentHeight / 2;

				if (y < 0) {
					// console.log('outOfBounds, adjusting')
					y = buttonRect.top + 1;
				}
			} else {
				y = buttonRect.top + buttonRect.height / 2 - contentHeight / 2;

				if (y + contentHeight > screenHeight) {
					// console.log('outOfBounds, adjusting')
					y = buttonRect.top + buttonRect.height - (contentHeight + 1);
				}
			}
		}

		if (y <= 10) {
			y = 10;
		}

		return y;
	};

	const setPosition = () => {
		let rect = contentContainer.getBoundingClientRect();
		contentWidth = rect.width;
		contentHeight = rect.height;
		buttonRect = openCloseContainer.getBoundingClientRect();

		// console.log('buttonRect', buttonRect, openCloseContainer)
		getBestSide();

		let x = getX();
		let y = getY();

		// console.log('x', x)
		// console.log('y', y)
		// console.log('contentHeight', contentHeight)
		const screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

		const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
		maxWidth = screenWidth - (x + 20);
		maxHeight = screenHeight - (y + 20);
		$$invalidate(7, contentContainer.style.maxHeight = maxHeight + 'px', contentContainer);
		$$invalidate(7, contentContainer.style.maxWidth = maxWidth + 'px', contentContainer);
		$$invalidate(7, contentContainer.style.display = 'block', contentContainer);
		$$invalidate(7, contentContainer.style.zIndex = '999', contentContainer);
		$$invalidate(7, contentContainer.style.left = x + 'px', contentContainer);
		$$invalidate(7, contentContainer.style.top = y + 'px', contentContainer);
		$$invalidate(7, contentContainer.style.top = y + 'px', contentContainer);
		top = buttonRect.top;
		left = buttonRect.left;
	};

	const updatePosition = () => {
		const rect = openCloseContainer.getBoundingClientRect();

		if (rect.top !== top || left !== rect.left) {
			setPosition();
		}
	};

	const checkClickOutsidePopover = e => {
		if (!isInsideElement(e, contentContainer)) {
			hidePopover();
		} else {
			
		} // console.log('clicked!!!!')
	};

	const toggleOpen = () => {
		if (!isSetting) {
			if (!isOpen) {
				isSetting = true;
				setPosition();
				intervalCallback = setInterval(updatePosition, 100);
				window.addEventListener('click', checkClickOutsidePopover);
				isSetting = false;
				$$invalidate(10, isOpen = true);
			} else {
				hidePopover();
				$$invalidate(10, isOpen = false);
			}
		}
	};

	const checkClosePopover = guid => {
		if (guid !== subscribeGuid) {
			hidePopover();
		}
	};

	const stopPropagation = e => {
		e.stopPropagation();
	};

	const checkForSlotUpdates = slotElement => {
		if (slotElement) {
			slotElement.addEventListener('slotchange', () => {
				setContentDimensions();
			});
		}
	};

	const hidePopover = () => {
		if (isOpen) {
			window.removeEventListener('click', checkClickOutsidePopover);

			// contentContainer.style.display = 'none'
			$$invalidate(7, contentContainer.style.left = '-9999px', contentContainer);

			$$invalidate(7, contentContainer.style.top = '-9999px', contentContainer);

			if (intervalCallback) {
				clearInterval(intervalCallback);
			}

			intervalCallback = null;
			$$invalidate(10, isOpen = false);

			if (oncloseevent !== '') {
				bc.messageService.publish(oncloseevent);
			}
		}
	};

	const checkForHide = e => {
		
	}; // console.log('closeOnClick', closeonclick)
	// if(closeonclick === 'true'){
	//     hidePopover()
	// }

	const setContentDimensions = () => {
		// let rect = contentContainer.getBoundingClientRect()
		// contentWidth = rect.width
		// contentHeight = rect.height
		// buttonRect = openCloseContainer.getBoundingClientRect()
		$$invalidate(7, contentContainer.style.visibility = 'visible', contentContainer);
	};

	const writable_props = [
		'closeonevent',
		'oncloseevent',
		'axis',
		'padding',
		'title',
		'showclosebutton',
		'showtitle',
		'widthfit',
		'show',
		'init'
	];

	

	function div0_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			openCloseContainer = $$value;
			$$invalidate(6, openCloseContainer);
		});
	}

	const click_handler = e => {
		e.stopPropagation();
		bc.messageService.publish('CLOSE_POPOVER', subscribeGuid);
		toggleOpen();
	};

	function slot_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			slotElement = $$value;
			$$invalidate(8, slotElement);
		});
	}

	const click_handler_1 = e => {
		hidePopover();
	};

	function slot1_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			slotElement = $$value;
			$$invalidate(8, slotElement);
		});
	}

	function div1_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			contentContainer = $$value;
			$$invalidate(7, contentContainer);
		});
	}

	function div2_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			container = $$value;
			$$invalidate(9, container);
		});
	}

	$$self.$$set = $$props => {
		if ('closeonevent' in $$props) $$invalidate(15, closeonevent = $$props.closeonevent);
		if ('oncloseevent' in $$props) $$invalidate(16, oncloseevent = $$props.oncloseevent);
		if ('axis' in $$props) $$invalidate(17, axis = $$props.axis);
		if ('padding' in $$props) $$invalidate(0, padding = $$props.padding);
		if ('title' in $$props) $$invalidate(1, title = $$props.title);
		if ('showclosebutton' in $$props) $$invalidate(2, showclosebutton = $$props.showclosebutton);
		if ('showtitle' in $$props) $$invalidate(3, showtitle = $$props.showtitle);
		if ('widthfit' in $$props) $$invalidate(4, widthfit = $$props.widthfit);
		if ('show' in $$props) $$invalidate(18, show = $$props.show);
		if ('init' in $$props) $$invalidate(19, init = $$props.init);
	};

	$$self.$capture_state = () => ({
		bc,
		onMount,
		tick,
		uuidv4,
		isInsideElement,
		attachNormalize,
		dispatchMountedEvent,
		handleComponentInitAttribute,
		handleComponentProps,
		handleComponentShowAttribute,
		get_current_component,
		applicationReady,
		isMobileStore,
		getComponentText,
		closeonevent,
		oncloseevent,
		axis,
		padding,
		title,
		showclosebutton,
		showtitle,
		widthfit,
		show,
		init,
		firstInitialized,
		container,
		subscribeGuid,
		generalText,
		component,
		mounted,
		canReload,
		showContent,
		openCloseContainer,
		contentContainer,
		slotElement,
		popper,
		componentId,
		isOpen,
		contentWidth,
		contentHeight,
		buttonRect,
		isLeft,
		isRight,
		isTop,
		isBottom,
		top,
		left,
		intervalCallback,
		isSetting,
		maxHeight,
		maxWidth,
		initComponent,
		applyProps,
		getBestSide,
		getX,
		getY,
		setPosition,
		updatePosition,
		checkClickOutsidePopover,
		toggleOpen,
		checkClosePopover,
		stopPropagation,
		checkForSlotUpdates,
		hidePopover,
		checkForHide,
		setContentDimensions,
		$applicationReady,
		$isMobileStore
	});

	$$self.$inject_state = $$props => {
		if ('closeonevent' in $$props) $$invalidate(15, closeonevent = $$props.closeonevent);
		if ('oncloseevent' in $$props) $$invalidate(16, oncloseevent = $$props.oncloseevent);
		if ('axis' in $$props) $$invalidate(17, axis = $$props.axis);
		if ('padding' in $$props) $$invalidate(0, padding = $$props.padding);
		if ('title' in $$props) $$invalidate(1, title = $$props.title);
		if ('showclosebutton' in $$props) $$invalidate(2, showclosebutton = $$props.showclosebutton);
		if ('showtitle' in $$props) $$invalidate(3, showtitle = $$props.showtitle);
		if ('widthfit' in $$props) $$invalidate(4, widthfit = $$props.widthfit);
		if ('show' in $$props) $$invalidate(18, show = $$props.show);
		if ('init' in $$props) $$invalidate(19, init = $$props.init);
		if ('firstInitialized' in $$props) $$invalidate(20, firstInitialized = $$props.firstInitialized);
		if ('container' in $$props) $$invalidate(9, container = $$props.container);
		if ('subscribeGuid' in $$props) $$invalidate(12, subscribeGuid = $$props.subscribeGuid);
		if ('component' in $$props) $$invalidate(44, component = $$props.component);
		if ('mounted' in $$props) $$invalidate(21, mounted = $$props.mounted);
		if ('canReload' in $$props) canReload = $$props.canReload;
		if ('showContent' in $$props) showContent = $$props.showContent;
		if ('openCloseContainer' in $$props) $$invalidate(6, openCloseContainer = $$props.openCloseContainer);
		if ('contentContainer' in $$props) $$invalidate(7, contentContainer = $$props.contentContainer);
		if ('slotElement' in $$props) $$invalidate(8, slotElement = $$props.slotElement);
		if ('popper' in $$props) popper = $$props.popper;
		if ('componentId' in $$props) componentId = $$props.componentId;
		if ('isOpen' in $$props) $$invalidate(10, isOpen = $$props.isOpen);
		if ('contentWidth' in $$props) contentWidth = $$props.contentWidth;
		if ('contentHeight' in $$props) contentHeight = $$props.contentHeight;
		if ('buttonRect' in $$props) buttonRect = $$props.buttonRect;
		if ('isLeft' in $$props) isLeft = $$props.isLeft;
		if ('isRight' in $$props) isRight = $$props.isRight;
		if ('isTop' in $$props) isTop = $$props.isTop;
		if ('isBottom' in $$props) isBottom = $$props.isBottom;
		if ('top' in $$props) top = $$props.top;
		if ('left' in $$props) left = $$props.left;
		if ('intervalCallback' in $$props) intervalCallback = $$props.intervalCallback;
		if ('isSetting' in $$props) isSetting = $$props.isSetting;
		if ('maxHeight' in $$props) maxHeight = $$props.maxHeight;
		if ('maxWidth' in $$props) maxWidth = $$props.maxWidth;
	};

	if ($$props && "$$inject" in $$props) {
		$$self.$inject_state($$props.$$inject);
	}

	$$self.$$.update = () => {
		if ($$self.$$.dirty[0] & /*show*/ 262144) {
			$: if (show) {
				handleComponentShowAttribute(show, component);
			}
		}

		if ($$self.$$.dirty[0] & /*init, firstInitialized, mounted, $applicationReady*/ 7864320) {
			/**
 * Handle the component init attribute, for the case that the component has not been initialized yet.
 */
			$: handleComponentInitAttribute(init, firstInitialized, mounted, $applicationReady, applyProps);
		}

		if ($$self.$$.dirty[0] & /*openCloseContainer, contentContainer*/ 192) {
			/**
 * Handles any component attribute changes after the component is initialized.
 */
			$: (openCloseContainer, contentContainer, applyProps(true));
		}

		if ($$self.$$.dirty[0] & /*slotElement*/ 256) {
			$: checkForSlotUpdates(slotElement);
		}
	};

	return [
		padding,
		title,
		showclosebutton,
		showtitle,
		widthfit,
		hidePopover,
		openCloseContainer,
		contentContainer,
		slotElement,
		container,
		isOpen,
		$isMobileStore,
		subscribeGuid,
		toggleOpen,
		checkForHide,
		closeonevent,
		oncloseevent,
		axis,
		show,
		init,
		firstInitialized,
		mounted,
		$applicationReady,
		div0_binding,
		click_handler,
		slot_binding,
		click_handler_1,
		slot1_binding,
		div1_binding,
		div2_binding
	];
}

class CustomPopover extends SvelteElement {
	constructor(options) {
		super();
		const style = document.createElement('style');
		style.textContent = `100%{left:0;right:0}100%{left:0;right:0}100%{opacity:1}100%{opacity:1}45%{-webkit-transform:scale(var(--fa-beat-scale, 1.25));transform:scale(var(--fa-beat-scale, 1.25))}45%{-webkit-transform:scale(var(--fa-beat-scale, 1.25));transform:scale(var(--fa-beat-scale, 1.25))}50%{opacity:1;-webkit-transform:scale(var(--fa-beat-fade-scale, 1.125));transform:scale(var(--fa-beat-fade-scale, 1.125))}50%{opacity:1;-webkit-transform:scale(var(--fa-beat-fade-scale, 1.125));transform:scale(var(--fa-beat-fade-scale, 1.125))}100%{-webkit-transform:rotate(360deg);transform:rotate(360deg)}100%{-webkit-transform:rotate(360deg);transform:rotate(360deg)}.popover-shadow{box-shadow:0 0.25rem 1rem rgba(0, 0, 0, 0.24), 0 0 0.125rem rgba(0, 0, 0, 0.24)}.w-fit{width:fit-content}.bg-white{--tw-bg-opacity:1;background-color:rgba(255, 255, 255, var(--tw-bg-opacity))}.border-gray-300{--tw-border-opacity:1;border-color:rgba(209, 213, 219, var(--tw-border-opacity))}.rounded{border-radius:0.25rem}.border-solid{border-style:solid}.border{border-width:1px}.flex{display:-webkit-box;display:-ms-flexbox;display:-webkit-flex;display:flex}.\\!hidden{display:none !important}.items-center{-webkit-box-align:center;-ms-flex-align:center;-webkit-align-items:center;align-items:center}.justify-between{-webkit-box-pack:justify;-ms-flex-pack:justify;-webkit-justify-content:space-between;justify-content:space-between}.font-medium{font-family:Roboto-Medium}.h-full{height:100%}.text-lg{font-size:1.125rem;line-height:1.75rem}.overflow-auto{overflow:auto}.p-2{padding:0.5rem}.p-4{padding:1rem}.pr-4{padding-right:1rem}.pt-4{padding-top:1rem}.pl-4{padding-left:1rem}.fixed{position:fixed}.-left-9999px{left:-9999px}.-top-9999px{top:-9999px}.text-black{--tw-text-opacity:1;color:rgba(0, 0, 0, var(--tw-text-opacity))}.invisible{visibility:hidden}.gap-4{grid-gap:1rem;gap:1rem}`;
		this.shadowRoot.appendChild(style);

		init_1(
			this,
			{
				target: this.shadowRoot,
				props: attribute_to_object(this.attributes),
				customElement: true
			},
			instance,
			create_fragment,
			safe_not_equal,
			{
				closeonevent: 15,
				oncloseevent: 16,
				axis: 17,
				padding: 0,
				title: 1,
				showclosebutton: 2,
				showtitle: 3,
				widthfit: 4,
				show: 18,
				init: 19,
				hidePopover: 5
			},
			null,
			[-1, -1]
		);

		if (options) {
			if (options.target) {
				insert_dev(options.target, this, options.anchor);
			}

			if (options.props) {
				this.$set(options.props);
				flush();
			}
		}
	}

	static get observedAttributes() {
		return [
			"closeonevent",
			"oncloseevent",
			"axis",
			"padding",
			"title",
			"showclosebutton",
			"showtitle",
			"widthfit",
			"show",
			"init",
			"hidePopover"
		];
	}

	get closeonevent() {
		return this.$$.ctx[15];
	}

	set closeonevent(closeonevent) {
		this.$$set({ closeonevent });
		flush();
	}

	get oncloseevent() {
		return this.$$.ctx[16];
	}

	set oncloseevent(oncloseevent) {
		this.$$set({ oncloseevent });
		flush();
	}

	get axis() {
		return this.$$.ctx[17];
	}

	set axis(axis) {
		this.$$set({ axis });
		flush();
	}

	get padding() {
		return this.$$.ctx[0];
	}

	set padding(padding) {
		this.$$set({ padding });
		flush();
	}

	get title() {
		return this.$$.ctx[1];
	}

	set title(title) {
		this.$$set({ title });
		flush();
	}

	get showclosebutton() {
		return this.$$.ctx[2];
	}

	set showclosebutton(showclosebutton) {
		this.$$set({ showclosebutton });
		flush();
	}

	get showtitle() {
		return this.$$.ctx[3];
	}

	set showtitle(showtitle) {
		this.$$set({ showtitle });
		flush();
	}

	get widthfit() {
		return this.$$.ctx[4];
	}

	set widthfit(widthfit) {
		this.$$set({ widthfit });
		flush();
	}

	get show() {
		return this.$$.ctx[18];
	}

	set show(show) {
		this.$$set({ show });
		flush();
	}

	get init() {
		return this.$$.ctx[19];
	}

	set init(init) {
		this.$$set({ init });
		flush();
	}

	get hidePopover() {
		return this.$$.ctx[5];
	}

	set hidePopover(value) {
		throw new Error("<custom-pop-over>: Cannot set read-only property 'hidePopover'");
	}
}

customElements.define("custom-pop-over", CustomPopover);
export default CustomPopover;
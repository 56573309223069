import proj4 from "../../../web_modules/proj4.js";
import {register} from "../../../web_modules/ol/proj/proj4.js";
import {transform} from "../../../web_modules/ol/proj.js";
proj4.defs("EPSG:9802", "+proj=lcc +lat_1=46.0 +lat_2=47.3333333 +lat_0=46.95240555555556 +lon_0=7.439583333333333 +x_0=2600000 +y_0=1200000 +a=6378137 +b=6356752.314140347 +units=m +no_defs");
register(proj4);
let parameters = {};
let changeCoords = false;
const transformPoint = function(point) {
  if (changeCoords) {
    point = transform(point, "EPSG:2056", "EPSG:9802");
    const x = parameters.A * point[0] + parameters.B * point[1] + parameters.C;
    const y = parameters.D * point[0] + parameters.E * point[1] + parameters.F;
    let newPoint = transform([x, y], "EPSG:9802", "EPSG:2056");
    console.log("newPoint", newPoint);
    return newPoint;
  } else {
    const x = parameters.A * point[0] + parameters.B * point[1] + parameters.C;
    const y = parameters.D * point[0] + parameters.E * point[1] + parameters.F;
    return [x, y];
  }
};
const calculate = function(sourcePoints, targetPoints) {
  const numberPoints = sourcePoints.length;
  if (changeCoords) {
    let tempSourcePoints = [];
    for (let i2 = 0; i2 < sourcePoints.length; i2++) {
      tempSourcePoints[i2] = transform(sourcePoints[i2], "EPSG:2056", "EPSG:9802");
    }
    console.log("tempSourcePoints", tempSourcePoints, sourcePoints);
    sourcePoints = [...tempSourcePoints];
  }
  if (numberPoints < 3)
    throw "Number of points must be at least 3!";
  if (sourcePoints.length !== targetPoints.length)
    throw "Number of points do not mach!";
  let i;
  let sp = [];
  for (i = 0; i < numberPoints; i++) {
    sp[i] = sourcePoints[i].slice(0);
  }
  let tp = [];
  for (i = 0; i < numberPoints; i++) {
    tp[i] = targetPoints[i].slice(0);
  }
  i = 0;
  let xcg, ycg, xcl, ycl;
  let a1, b1, a2, b2, c1, c2;
  xcg = ycg = xcl = ycl = a1 = b1 = a2 = b2 = c1 = c2 = 0;
  for (i = 0; i < numberPoints; i++) {
    xcg = xcg + tp[i][0];
    ycg = ycg + tp[i][1];
    xcl = xcl + sp[i][0];
    ycl = ycl + sp[i][1];
  }
  xcg /= numberPoints;
  ycg /= numberPoints;
  xcl /= numberPoints;
  ycl /= numberPoints;
  for (i = 0; i < numberPoints; i++) {
    tp[i][0] -= xcg;
    tp[i][1] -= ycg;
    sp[i][0] -= xcl;
    sp[i][1] -= ycl;
  }
  let x1 = 0;
  let y1 = 0;
  let x2 = 0;
  let y2 = 0;
  let x3 = 0;
  let y3 = 0;
  let x4 = 0;
  let n1 = 0;
  for (i = 0; i < numberPoints; i++) {
    x1 += sp[i][0] * tp[i][0];
    y1 += sp[i][1] * tp[i][0];
    x2 += sp[i][0] * sp[i][0];
    y2 += sp[i][1] * tp[i][1];
    x3 += sp[i][0] * tp[i][1];
    y3 += sp[i][1] * sp[i][1];
    x4 += sp[i][0] * sp[i][1];
  }
  a1 = x1 * y3 - y1 * x4;
  b1 = y1 * x2 - x1 * x4;
  a2 = y2 * x2 - x3 * x4;
  b2 = x3 * y3 - y2 * x4;
  n1 = x2 * y3 - x4 * x4;
  a1 /= n1;
  b1 /= n1;
  a2 /= n1;
  b2 /= n1;
  c1 = xcg - a1 * xcl - b1 * ycl;
  c2 = ycg - a2 * ycl - b2 * xcl;
  parameters.A = a1;
  parameters.B = b1;
  parameters.C = c1;
  parameters.D = b2;
  parameters.E = a2;
  parameters.F = c2;
  console.log("parameters", parameters);
  return parameters;
};
export {calculate, transformPoint};

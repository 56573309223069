let fontAwesomeStyleSheet = null;
let normalizeStyleSheet = null;
let isGettingStyleSheet = false;
let isGettingNormalize = false;
export const getSharedStyleSheet = async () => {
  if (!fontAwesomeStyleSheet) {
    if (!isGettingStyleSheet) {
      isGettingStyleSheet = true;
      await getFontAwesomeStyleSheet();
      isGettingStyleSheet = false;
      return fontAwesomeStyleSheet;
    } else {
      while (!isGettingStyleSheet) {
      }
      return fontAwesomeStyleSheet;
    }
  } else {
    return fontAwesomeStyleSheet;
  }
};
export const getSharedNormalize = async () => {
  if (!normalizeStyleSheet) {
    if (!isGettingNormalize) {
      isGettingNormalize = true;
      await getNormalizeStyleSheet();
      isGettingNormalize = false;
      return normalizeStyleSheet;
    } else {
      while (!isGettingNormalize) {
      }
      return normalizeStyleSheet;
    }
  } else {
    return normalizeStyleSheet;
  }
};
const getNormalizeStyleSheet = async () => {
  normalizeStyleSheet = new CSSStyleSheet();
  let sheetFound = false;
  for (let sheet of document.styleSheets) {
    if (sheet.href && sheet.href.endsWith("normalize.css")) {
      sheetFound = true;
      const rules = [...sheet.cssRules];
      let text = "";
      for (let i = 0; i < rules.length; i++) {
        text = text + rules[i].cssText;
      }
      normalizeStyleSheet.replaceSync(text);
      break;
    }
  }
  if (!sheetFound) {
    console.error("normalize.css missing!");
  }
  return true;
};
const getFontAwesomeStyleSheet = async () => {
  fontAwesomeStyleSheet = new CSSStyleSheet();
  for (let sheet of document.styleSheets) {
    if (sheet.href && (sheet.href.endsWith("fontawesome6/pro/css/all.min.css") || sheet.href.endsWith("fontawesome6/css/all.min.css"))) {
      const rules = [...sheet.cssRules];
      let text = "";
      for (let i = 0; i < rules.length; i++) {
        text = text + rules[i].cssText;
      }
      fontAwesomeStyleSheet.replaceSync(text);
      break;
    }
  }
  return true;
};

import bc from "../bcShim.js";
import {requestHeaders} from "../services/ajaxService.js";
import {addValuesToAdvancedFilter, createFilter} from "./filterUtils.js";
import {appendErrorInformation, handleErrorInAlert} from "./alertNotificationUtils.js";
import {constants} from "../constants.js";
import {find} from "../../web_modules/linkifyjs.js";
import {escapeRegExp, linkyInstance} from "./formUtilities.js";
import {isUserDefinedView} from "./dataviewUtils.js";
import {getCountAndValueProperties} from "./requestUtils.js";
import {numberWithSpaces, numberWithSpacesFloat} from "./stringUtils.js";
import {getCodeListValue} from "./codelisteEntityListUtils.js";
export const getDisplayValue = async (data, fieldName, metadata) => {
  try {
    let fieldInfo = metadata.getField(fieldName);
    let value = getRawValue(fieldName, data);
    if (value !== void 0 && value !== null || fieldInfo.dataType === "Boolean") {
      switch (fieldInfo.dataType) {
        case "List": {
          try {
            const mappingList = metadata.properties.listMapping.find((l) => l.fieldName === fieldInfo.name);
            if (mappingList) {
              const listValue = mappingList.values.find((v) => v.guid === value);
              if (listValue) {
                value = listValue.value;
              } else {
                let searchedValue = await getCodeListValue(value);
                if (searchedValue) {
                  return searchedValue;
                }
              }
            }
            return value;
          } catch (error) {
            throw "Error getting Codelist value for field " + fieldName + " for the VIEW " + metadata.resourceLongname;
          }
        }
        case "EntityList": {
          try {
            let viewList = metadata.properties.viewLists.find((v) => v.columnName === fieldInfo.name);
            if (viewList) {
              let viewListMetadata = await bc.parameterService.getMetadata(viewList.dataviewGuid);
              let entry = await viewList.getEntry(value);
              if (entry) {
                return entry[viewListMetadata.titleProperty];
              }
            }
            return value;
          } catch (error) {
            throw "Error getting Entitylist value for field " + fieldName + " for the VIEW " + metadata.resourceLongname;
          }
        }
        case "Binary": {
          try {
            if (value) {
              return value;
            }
          } catch (error) {
            throw "Error getting display value for binary value";
          }
        }
        case "Guid": {
          return value;
        }
        case "String": {
          let newValue = value;
          if (newValue.length > 3) {
            let result = find(newValue);
            let treadedMatches = [];
            for (let i = 0; i < result.length; i++) {
              let element = result[i];
              if (element.type === "email") {
                let mail = element.value;
                if (!treadedMatches.includes(mail)) {
                  let regex = new RegExp(mail, "gm");
                  newValue = newValue.replace(regex, `<mail-tag mail="${mail}"></mail-tag>`);
                  treadedMatches.push(mail);
                }
              }
            }
            if (linkyInstance.test(newValue)) {
              let resultMail = linkyInstance.match(newValue);
              if (resultMail) {
                for (let i = 0; i < resultMail.length; i++) {
                  let url = resultMail[i].url;
                  if (!treadedMatches.includes(url)) {
                    let regex = new RegExp(escapeRegExp(url), "gm");
                    newValue = newValue.replace(regex, `<url-tag url="${url}"></url-tag>`);
                    treadedMatches.push(url);
                  }
                }
              }
            }
          }
          return newValue;
        }
        case "DateTime": {
          let locale = metadata.resource.TY_CULTURE;
          let separator;
          if (locale === "46ba9cdc-f416-421b-a38f-75715f6a1554") {
            separator = ".";
          } else {
            separator = "/";
          }
          if (value) {
            if (fieldInfo.viewLength === 0 && fieldInfo.viewPrecision === 0 || fieldInfo.viewLength === void 0 && fieldInfo.viewPrecision === void 0) {
              let dateString = "";
              let workWeek;
              const tSplit = value.split("T");
              const fullDateArray = tSplit[0].split("-");
              const fullTimeArray = tSplit[1].split(":");
              let hours = fullTimeArray[0];
              let minutes = fullTimeArray[1];
              let date = moment(`${fullDateArray[0]}-${fullDateArray[1]}-${fullDateArray[2]}`);
              workWeek = moment(date).isoWeek();
              if (+workWeek < 10) {
                workWeek = "0" + workWeek;
              }
              workWeek = "KW" + workWeek;
              dateString = `${fullDateArray[2]}${separator}${fullDateArray[1]}${separator}${fullDateArray[0]} ${hours}:${minutes}`;
              return `<span><span style="border:solid thin #ddd; padding: 0.125rem; margin-right: 0.25rem; border-radius: 2px; background-color:#f5f5f5;" >${workWeek}</span>${dateString}</span>`;
            } else if (fieldInfo.viewLength === 0 && fieldInfo.viewPrecision === 8) {
              const tSplit = value.split("T");
              const fullTimeArray = tSplit[1].split(":");
              let hours = fullTimeArray[0];
              let minutes = fullTimeArray[1];
              return `${hours}:${minutes}`;
            } else if (fieldInfo.viewLength === 10 && fieldInfo.viewPrecision === 0) {
              let dateString = "";
              let workWeek;
              const tSplit = value.split("T");
              const fullDateArray = tSplit[0].split("-");
              let date = moment(`${fullDateArray[0]}-${fullDateArray[1]}-${fullDateArray[2]}`);
              workWeek = moment(date).isoWeek();
              if (+workWeek < 10) {
                workWeek = "0" + workWeek;
              }
              workWeek = "KW" + workWeek;
              dateString = `${fullDateArray[2]}${separator}${fullDateArray[1]}${separator}${fullDateArray[0]}`;
              return `<span style="border:solid thin #ddd; padding: 0.125rem; margin-right: 0.25rem; border-radius: 2px; background-color:#f5f5f5;" >${workWeek}</span>${dateString}`;
            }
          } else {
            return "";
          }
        }
        case "Int16":
        case "Int32":
        case "Int64": {
          return numberWithSpaces(value.toString());
        }
        case "Decimal":
        case "Double": {
          let tempValue = parseFloat(value);
          let newValue = numberWithSpacesFloat(parseFloat(tempValue).toString());
          let precision = fieldInfo.viewPrecision;
          if (precision === void 0 || precision === null) {
            precision = fieldInfo.typePrecision;
          }
          if (!precision) {
            return newValue;
          }
          if (newValue.toString().indexOf(".") !== -1) {
            let lengthAfterPoint = newValue.toString().split(".")[1].length;
            if (lengthAfterPoint < precision) {
              while (lengthAfterPoint < precision) {
                newValue = newValue.toString() + "0";
                lengthAfterPoint = newValue.toString().split(".")[1].length;
              }
            }
            if (lengthAfterPoint > precision) {
              while (lengthAfterPoint > precision) {
                newValue = newValue.toString().substring(0, newValue.length - 1);
                lengthAfterPoint = newValue.toString().split(".")[1].length;
              }
            }
          } else {
            if (precision) {
              let stringToAdd = ".";
              for (let i = 0; i < precision; i++) {
                stringToAdd = stringToAdd + "0";
              }
              newValue += stringToAdd;
            }
          }
          return newValue;
        }
        case "Boolean": {
          if (value === true) {
            return "Ja";
          } else if (value === false) {
            return "Nein";
          } else if (value === null) {
            return "";
          }
        }
        case "Geometry": {
        }
        default: {
          return value;
        }
      }
    } else {
      return "";
    }
  } catch (error) {
    console.error("(METHOD: getDisplayValue)", error, fieldName);
    return null;
  }
};
export const getObjectItem = async (metadata, objectId, typeDomainOrDmParent) => {
  try {
    let searchedObject = bc.parameterService.get("OBJECT_ITEM_" + metadata.resourceId + "_" + objectId);
    if (searchedObject) {
      return searchedObject;
    }
    if (isUserDefinedView(metadata.resourceName)) {
      let url = bc.parameterService.appParameters.ApplicationDataServiceBaseUrl + metadata.resource.TY_NAME + `(guid'${objectId}')`;
      let response = await bc.ajaxService.fetchRaw(requestHeaders.jsonGet, url);
      if (response && response.status == 200) {
        let jsonAnswer = await response.json();
        bc.parameterService.set("OBJECT_ITEM_" + metadata.resourceId + "_" + objectId, jsonAnswer.Items[0]);
        return jsonAnswer.Items[0];
      }
      return null;
    }
    const filter = createFilter({
      filterExpressionNumber: 0,
      filterExpressionJoinType: "and",
      columnName: metadata.idProperty,
      joinType: "and",
      metadata,
      comparator: "=",
      intable: false
    });
    addValuesToAdvancedFilter(filter, [{name: metadata.getColumnLongName(metadata.idProperty), value: objectId}]);
    const data = await bc.metadataManager.fetchJsonData({
      filters: [filter],
      resourceName: metadata.resourceName,
      inlinecount: "allpages",
      top: 1,
      offset: 0,
      orderBy: [],
      typeDomainOrDmParent: typeDomainOrDmParent ? typeDomainOrDmParent : null
    });
    const countAndValueProperties = getCountAndValueProperties(metadata.resourceName);
    if (data !== null) {
      if (data[countAndValueProperties.format]) {
        let item = data[countAndValueProperties.format][0];
        bc.parameterService.set("OBJECT_ITEM_" + metadata.resourceId + "_" + objectId, item);
        return item;
      }
    }
    return null;
  } catch (error) {
    console.error("ENTITY does not exist anymore?", error, metadata);
    return null;
  }
};
export const getNextId = async (defaultValue) => {
  try {
    const parameters = defaultValue.split(",");
    console.log("getNextId", parameters);
    let value = "";
    let length = parseInt(parameters[parameters.length - 1]);
    if (isNaN(length)) {
      length = 4;
    }
    if (parameters.length > 3) {
      value = await bc.ajaxService.fetchNextId(parameters[1], parameters[2], length);
    } else if (parameters.length > 2) {
      const d = new Date();
      const n = d.getFullYear();
      value = await bc.ajaxService.fetchNextId(parameters[1], n, length);
    } else {
      value = await bc.ajaxService.fetchNextId("", "", length);
    }
    return value;
  } catch (error) {
    handleErrorInAlert(error);
    throw error;
  }
};
export const getRawValue = (key, objectToRender) => {
  try {
    let value;
    if (key.endsWith(".Geometry.WellKnownText")) {
      key = key.split(".")[0];
      value = objectToRender[key]["Geometry"]["WellKnownText"];
    } else if (key.endsWith(".Geometry.CoordinateSystemId")) {
      key = key.split(".")[0];
      value = objectToRender[key]["Geometry"]["CoordinateSystemId"];
    } else {
      value = objectToRender[key];
    }
    return value;
  } catch (error) {
    console.error("getRawValue", key, objectToRender, error);
    return void 0;
  }
};
export const getSingleObject = async (objectMetadata, idProperty, objectId, domain) => {
  try {
    let idFilter = createFilter({
      filterExpressionNumber: 1,
      filterExpressionJoinType: "and",
      columnName: idProperty,
      joinType: "and",
      metadata: objectMetadata,
      comparator: "=",
      intable: false
    });
    addValuesToAdvancedFilter(idFilter, [{name: objectMetadata.getColumnLongName(idProperty), value: objectId}]);
    let typeDomainorDmParent = "";
    switch (objectMetadata.resourceName) {
      case "UgdmAttributes": {
        typeDomainorDmParent = constants.domain.ATTRIBUTE;
        break;
      }
      case "UgdmRelationshipAssociations": {
        typeDomainorDmParent = constants.domain.RELATIONSHIP_ASSOCIATION;
        break;
      }
      case "UgdmSystems": {
        typeDomainorDmParent = constants.domain.SYSTEM;
        if (domain) {
          switch (domain) {
            case constants.domain.SYSTEM: {
              typeDomainorDmParent = constants.domain.SYSTEM;
              break;
            }
            case constants.domain.DATA_SOURCE_SYSTEM: {
              typeDomainorDmParent = constants.domain.DATA_SOURCE_SYSTEM;
              break;
            }
            case constants.domain.DATABASE_SOURCE_SYSTEM: {
              typeDomainorDmParent = constants.domain.DATABASE_SOURCE_SYSTEM;
              break;
            }
            case constants.domain.FILE_SOURCE_SYSTEM: {
              typeDomainorDmParent = constants.domain.FILE_SOURCE_SYSTEM;
              break;
            }
            case constants.domain.DATA_SERVICE_SOURCE_SYSTEM: {
              typeDomainorDmParent = constants.domain.DATA_SERVICE_SOURCE_SYSTEM;
              break;
            }
            case constants.domain.DATA_SERVICE: {
              typeDomainorDmParent = constants.domain.DATA_SERVICE;
              break;
            }
            case constants.domain.MAP_SERVICE: {
              typeDomainorDmParent = constants.domain.MAP_SERVICE;
              break;
            }
          }
        }
        break;
      }
      case "UgdmLocationTypes": {
        typeDomainorDmParent = constants.domain.LOCATION_TYPE;
        break;
      }
      case "UgdmSrs": {
        typeDomainorDmParent = constants.domain.SRS;
        break;
      }
      case "UgdmMeasurementTypes": {
        typeDomainorDmParent = constants.domain.MEASUREMENT_TYPE;
        break;
      }
      case "UgdmUnits": {
        typeDomainorDmParent = constants.domain.UNIT;
        break;
      }
    }
    let data = await bc.metadataManager.fetchJsonData({
      filters: [idFilter],
      resourceName: objectMetadata.resourceName,
      inlinecount: "allpages",
      top: 1,
      offset: 0,
      orderBy: [],
      typeDomainOrDmParent: typeDomainorDmParent,
      entityGuid: "",
      rlType: "",
      rlFromItemguid: "",
      rlDirection: ""
    });
    if (data && data["Items"] && data["Items"][0]) {
      return data["Items"][0];
    } else if (data && data["value"] && data["value"][0]) {
      return data["value"][0];
    } else {
      return null;
    }
  } catch (error) {
    handleErrorInAlert(error, "Error Fetching Single Object");
    console.error("Error Fetching Single Object", error);
  }
};
export const updateEntity = async (dataviewguid, entity) => {
  try {
    let objectMetadata = await bc.parameterService.getMetadata(dataviewguid);
    const urlAndResourceParams = bc.metadataManager.getResourceUrlAndParameters(objectMetadata.resourceName, "");
    const answerEnttityUpdate = await bc.ajaxService.fetchPost(requestHeaders.jsonPost, urlAndResourceParams.url, JSON.stringify(entity));
    const newObject = await answerEnttityUpdate.json();
    bc.parameterService.set("OBJECT_ITEM_" + dataviewguid + "_" + entity[objectMetadata.idProperty], newObject);
    return newObject;
  } catch (error) {
    appendErrorInformation(error, "Fehler beim updaten einer Entit\xE4t (refreshen durch erneute Speicherung).");
    throw error;
  }
};
export const getValidationEntry = (validation, key) => {
  console.log("getValidationEntry", validation, key);
  if (!validation || !key) {
    return null;
  }
  let found = validation.find((v) => v.column === key);
  return found;
};

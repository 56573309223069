import Container from "./components/Containers/Container/Container.js";
import ToggleElement from "./components/Containers/ToggleElement/ToggleElement.js";
import KuviContainer from "./components/Containers/KuviContainer/KuviContainer.js";
import SidebarContainer from "./components/Containers/SidebarContainer/SidebarContainer.js";
import ResizeContainer from "./components/Containers/ResizeContainer/ResizeContainer.js";
import FormField from "./components/Entity/FormField.js";
import Kanban from "./components/Kanban/Kanban.js";
import CompositionConstructor from "./components/CompositionConstructor/CompositionConstructor.js";
import DatePickerForm from "./components/FormElements/DatePicker/DatePickerForm.js";
import NativeDateTimePicker from "./components/FormElements/DatePicker/NativeDateTimePicker.js";
import DropDown from "./components/DropDown/DropDown.js";
import ObjectForm from "./components/ObjectForm/ObjectForm.js";
import FormContainer from "./components/ObjectForm/FormContainer.js";
import UploadContainer from "./components/ObjectForm/UploadContainer.js";
import BatchEditForm from "./components/ObjectForm/BatchEditForm.js";
import CopyForm from "./components/ObjectForm/CopyForm.js";
import KanbanItem from "./components/Kanban/KanbanItem/KanbanItem.js";
import RenderObject from "./components/ObjectRenderer/ObjectRenderer.js";
import TextField from "./components/FormElements/TextField/TextField.js";
import TextArea from "./components/FormElements/TextArea/TextArea.js";
import Checkbox from "./components/FormElements/CheckBox/Checkbox.js";
import ArtifactTextArea from "./components/FormElements/ArtifactTextarea/ArtifactTextArea.js";
import ArtifactRichText from "./components/FormElements/ArtifactRichText/ArtifactRichText.js";
import ObjectTable from "./components/ObjectTable/ObjectTable.js";
import TimeLine from "./components/TimeLine/TimeLine.js";
import FilterDropDown from "./components/ObjectTable/FilterDropDown/FilterDropDown.js";
import DatePickerTable from "./components/FormElements/DatePicker/DatePickerTable.js";
import CustomPopover from "./components/Popover/CustomPopover.js";
import PopOverOption from "./components/Popover/PopOverOption.js";
import PopoverCalendar from "./components/Popover/Popover-Calendar.js";
import Dashboard from "./components/Dashboard/Dashboard.js";
import FaIcon from "./components/Icon/FaIcon.js";
import DashboardTabCreator from "./components/Dashboard/DashboardTabCreator/DashboardFavoriteCreator.js";
import PinnedEntities from "./components/Dashboard/PinnedEntities/PinnedEntities.js";
import MainMap from "./components/Map/MainMap/MainMap.js";
import NewMap from "./components/Map/MainMap/NewMap.js";
import PreviewMap from "./components/Map/EntityPreviewMap/EntityPreviewMap.js";
import SearchMap from "./components/Map/SearchMap/SearchMap.js";
import SimpleMap from "./components/Map/SimpleMap/SimpleMap.js";
import MapToolMaxExtent from "./components/Map/MapTools/MaxExtent/MaxExtent.js";
import MapMeasureTools from "./components/Map/MapTools/MapMeasureTools/MapMeasureTools.js";
import MapDragAndDrop from "./components/Map/MapTools/MapDragAndDrop/MapDragAndDrop.js";
import MapBackgroundSwitcher from "./components/Map/MapTools/MapBackgroundSwitcher/MapBackgroundSwitcher.js";
import MapBoxZoom from "./components/Map/MapTools/MapBoxZoom/MapBoxZoom.js";
import FeatureSearchPoint from "./components/Map/MapTools/FeatureSearchPoint/FeatureSearchPoint.js";
import ScaleLine from "./components/Map/MapTools/ScaleLine/ScaleLine.js";
import FeatureSearchResults from "./components/Map/MapTools/FeatureSearchResults/FeatureSearchResults.js";
import FeatureSearchDatabase from "./components/Map/MapTools/FeatureSearchDatabase/FeatureSearchDatabase.js";
import ExternalMapTool from "./components/Map/MapTools/ExternalMapTools/ExternalMapTools.js";
import DevicePosition from "./components/Map/MapTools/DevicePosition/DevicePosition.js";
import PinLocation from "./components/Map/MapTools/PinLocation/PinLocation.js";
import PrintMap from "./components/Map/MapTools/PrintMap/PrintMap.js";
import DrawTool from "./components/Map/MapTools/DrawTool/DrawTool.js";
import ZoomIn from "./components/Map/MapTools/ZoomIn/ZoomIn.js";
import ZoomOut from "./components/Map/MapTools/ZoomOut/ZoomOut.js";
import MapImageDownload from "./components/Map/MapTools/MapImageDownload/MapImageDownload.js";
import MapShareMapLocation from "./components/Map/MapTools/MapShareMapLocation/MapShareMapLocation.js";
import MapGeoSearch from "./components/Map/MapTools/MapGeoSearch/MapGeoSearch.js";
import MapObjectPreview from "./components/Map/MapTools/MapObjectPreview/MapObjectPreview.js";
import MapQuickSearch from "./components/Map/MapTools/QuickSearch/MapQuickSearch/MapQuickSearch.js";
import MapQuickSearchOverlay from "./components/Map/MapTools/QuickSearch/MapQuickSearchOverlay/MapQuickSearchOverlay.js";
import MapEditGeometry from "./components/Map/MapTools/MapEditGeometry/MapEditGeometry.js";
import MapFilePreview from "./components/Map/MapTools/MapFilePreview/MapFilePreview.js";
import MapWindowSearch from "./components/Map/MapTools/MapWindowSearch/MapWindowSearch.js";
import MapPluginRequestHandler from "./components/Map/MapTools/MapPluginRequestHandler/MapPluginRequestHandler.js";
import MapAdvancedSearchDraw from "./components/Map/MapTools/MapAdvancedSearchDraw/MapAdvancedSearchDraw.js";
import LayerSwitcher from "./components/Map/MapTools/LayerSwitcher/LayerSwitcher.js";
import MapAddImageButton from "./components/Map/MapTools/MapAddImageButton/MapAddImageButton.js";
import AddImageLayer from "./components/Map/MapTools/AddImageLayer/AddImageLayer.js";
import LayerTree from "./components/MenuTree/MenuTree.js";
import ThemeTree from "./components/MenuTree/ThemeTree.js";
import ContentWrapper from "./components/ContentWrapper/ContentWrapper.js";
import LoadingSpinner from "./components/LoadingSpinner/LoadingSpinner.js";
import DataviewManager from "./components/DataviewManager/DataviewManager.js";
import EntityManager from "./components/EntityManager/EntityManager.js";
import PDFViewer from "./components/PDFViewer/PDFViewer.js";
import FullOverlay from "./components/FullOverlay/FullOverlay.js";
import SideOverlay from "./components/SideOverlay/SideOverlay.js";
import ViewChoiceOverlay from "./components/ViewChoiceOverlay/ViewChoiceOverlay.js";
import SingleRelationship from "./components/RelationshipManager/SingleRelationship.js";
import BreadCrumb from "./components/BreadCrumb/BreadCrumb.js";
import BreadCrumbMobile from "./components/BreadCrumb/BreadCrumbMobile.js";
import SinglePropertyInlineEditor from "./components/FormElements/SinglePropertyInlineEditor/SinglePropertyInlineEditor.js";
import RadioButton from "./components/FormElements/RadioButton/RadioButton.js";
import Toast from "./components/Toast/Toast.js";
import UserMenu from "./components/UserMenu/UserMenu.js";
import DragAndDropUpload from "./components/DragAndDropUpload/DragAndDropUpload.js";
import ResumeContainer from "./components/ResumeContainer/ResumeContainer.js";
import EntityRelations from "./components/EntityManager/EntityRelations/EntityRelations.js";
import EntityMap from "./components/EntityManager/EntityMap/EntityMap.js";
import EntityDocuments from "./components/EntityManager/EntityDocuments/EntityDocuments.js";
import EntityMeasurements from "./components/EntityManager/EntityMeasurements/EntityMeasurements.js";
import EntityActions from "./components/EntityManager/EntityActions/EntityActions.js";
import GeoSearch from "./components/Search/GeoSearch/GeoSearch.js";
import DataviewSearch from "./components/Search/DataviewSearch/DataviewSearch.js";
import DocumentSearch from "./components/Search/DocumentSearch/DocumentSearch.js";
import Tabs from "./components/Tabs/TabContainer/Tabs.js";
import TabLink from "./components/Tabs/TabLink/TabLink.js";
import TabPanel from "./components/Tabs/TabPanel/TabPanel.js";
import MapPlugin from "./components/Map/MapPlugin/MapPlugin.js";
import SimpleObjectRenderer from "./components/ObjectRenderer/SimpleObjectRenderer.js";
import Accordeon from "./components/Accordeon/Accordeon.js";
import ObjectList from "./components/ObjectList/ObjectList.js";
import AddUserAction from "./components/AddUserForm/AddUserForm.js";
import UserForms from "./components/UserForms/UserFormsDisplay.js";
import DataCharts from "./components/Charts/Charts.js";
import SearchBuilder from "./components/Search/SearchBuilder/SearchBuilder.js";
import Mail from "./components/TypeInteractions/Mail.js";
import Website from "./components/TypeInteractions/Website.js";
import WMTSLayerImporter from "./components/LayerImporter/WMTSLayerImporter.js";
import WMSLayerImporter from "./components/LayerImporter/WMSLayerImporter.js";
import Measurement from "./components/Measurement/Measurement.js";
import MeasurementFilterBuilder from "./components/Measurement/MeasurementFilterBuilder/MeasurementFilterBuilder.js";
import MeasurementProgramDownload from "./components/Measurement/MeasurementProgramDownload/MeasurementProgramDownload.js";
import DataviewCreator from "./components/Backend/DataviewCreator/DataviewCreator.js";
import AttributeHandler from "./components/Backend/DataviewCreator/AttributeHandler/AttributeHandler.js";
import ExcelToData from "./components/Backend/ExcelToData/ExcelToData.js";
import ViewAttributeAnalyser from "./components/Backend/ViewAttributAnalyser/ViewAttributeAnalyser.js";
import Backend from "./components/Backend/Backend/Backend.js";
import DataviewManagerBackend from "./components/Backend/DataviewManagerBackend/DataviewManagerBackend.js";
import DataviewRightsManager from "./components/Backend/DataviewRightsManager/DataviewRightsManager.js";
import EventExporter from "./components/EventExporter/EventExporter.js";
import Calendar from "./components/Calendar/Calendar.js";
import ElementSwitcher from "./components/Backend/ElementSwitcher/ElementSwitcher.js";
import Button from "./components/Buttons/Button/Button.js";
import IconButton from "./components/Buttons/IconButton/IconButton.js";
import BackDrop from "./components/BackDrop/BackDrop.js";
import MenuLink from "./components/Buttons/MenuLink/MenuLink.js";
import CodelistCreator from "./components/Backend/CodeListCreator/CodeListCreator.js";
import CopyDataview from "./components/Backend/CopyDataview/CopyDataview.js";
import CodelistCreatorManager from "./components/Backend/CodeListCreatorManager/CodelistCreatorManager.js";
import RelationshipCreator from "./components/Backend/RelationshipCreator/RelationshipCreator.js";
import RelationshipEditor from "./components/Backend/RelationshipEditor/RelationshipEditor.js";
import TypeCreator from "./components/Backend/TypeCreator/TypeCreator.js";
import RelationshipOrder from "./components/Backend/RelationshipOrder/RelationshipOrder.js";
import HTMLPageRights from "./components/Backend/HTMLPageRights/HTMLPageRights.js";
import GeoTableCreator from "./components/Backend/GeoTableCreator/GeoTableCreator.js";
import DataviewTriggerDisplay from "./components/Backend/DataviewTriggerDisplay/DataviewTriggerDisplay.js";
import TypeChooserInThemeTree from "./components/Backend/TypeChooserInThemeTree/TypeChooserInThemeTree.js";
import TypeMenuTree from "./components/Backend/TypeMenuTree/TypeMenuTree.js";
import CoreViews from "./components/Backend/CoreViews/CoreViews.js";
import EntityTableGenerator from "./components/Backend/EntityTableGenerator/EntityTableGenerator.js";
import TypeDomainManager from "./components/Backend/TypeDomainManager/TypeDomainManager.js";
import MetadataLinks from "./components/Backend/MetadataLinks/MetadataLinks.js";
import BcExport from "./components/Export/Export.js";
import HeaderBar from "./components/HeaderBar/HeaderBar.js";
import EntityMetadataLinks from "./components/Backend/EntityMetadataLinks/EntityMetadataLinks.js";
import DataImportLinks from "./components/Backend/DataImportLinks/DataImportLinks.js";
import DomainTreeCreator from "./components/Backend/DomainTreeCreator/DomainTreeCreator.js";
import ResetPassword from "./components/Backend/ResetPassword/ResetPassword.js";
import MetadataDownload from "./components/Backend/MetadataDownload/MetadataDownload.js";
import DataviewViewRights from "./components/Backend/DataviewViewRights/DataviewViewRights.js";
import ViewGenerator from "./components/Backend/ViewGenerator/ViewGenerator.js";
import AlterTableGenerator from "./components/Backend/AlterTableGenerator/AlterTableGenerator.js";
import TypeRelationshipElementManager from "./components/Backend/TypeRelationshipElementManager/TypeRelationshipElementManager.js";
import DataDownloader from "./components/Backend/DataDownloader/DataDownloader.js";
import EventDispatcher from "./components/EventDispatcher/EventDispatcher.js";
import Favorites from "./components/Dashboard/Favorites/Favorites.js";
import LastEntities from "./components/Dashboard/LastEntities/LastEntities.js";
import LastActions from "./components/Dashboard/LastActions/LastActions.js";
import Logout from "./components/Logout/Logout.js";
import SingleRecordForm from "./components/SingleRecordForm/SingleRecordForm.js";
import ParameterManager from "./components/ParameterManager/ParameterManager.js";
import ApplicationStarter from "./components/ApplicationStarter/ApplicationStarter.js";
import SettingsMigrator from "./components/SettingsMigrator/SettingsMigrator.js";
import UserSearch from "./components/Search/UserSearch/UserSearch.js";
import UrlParameterDispatcher from "./components/URLParameterDispatcher/UrlParameterDispatcher.js";
import UserRights from "./components/UserRights/UserRights.js";
import Switch from "./components/Switch/Switch.js";
import PdfExporter from "./components/PdfExporter/PdfExporter.js";
import DataviewSelector from "./components/DocumentationHelper/DataviewSelector/DataviewSelector.js";
import ThemeTreeSelector from "./components/ThemeTreeSelector/ThemeTreeSelector.js";
import EditStatusList from "./components/EditStatusList/EditStatusList.js";
import AnyObjectRenderer from "./components/AnyObjectRenderer/AnyObjectRenderer.js";
import AdvancedBackendTable from "./components/Backend/AdvancedBackendTable/AdvancedBackendTable.js";
import DatatypeIcon from "./components/Backend/DataviewCreator/DataTypeIcon/DatatypeIcon.js";
import AddDatabasePrecedure from "./components/Backend/AddDatabaseProcedure/AddDatabaseProcedure.js";
import EditDatabasePrecedure from "./components/Backend/EditDatabaseProcedure/EditDatabaseProcedure.js";
import ReloadViewDescription from "./components/Backend/ReloadViewDescription/ReloadViewDescription.js";
import Pagination from "./components/Pagination/Pagination.js";
import UploadDataMetadata from "./components/Backend/UploadFile/UploadFile.js";
import Spinner from "./components/Spinner/Spinner.js";
import {successLog} from "./utilities/consoleUtils.js";
import {subscribeToWindowResize} from "./utilities/windowResizeObserver.js";
import Router from "./components/Router/Router.js";
import EntityChooser from "./components/DocumentationHelper/EntityChooser/EntityChooser.js";
import Title from "./components/Title/Title.js";
import Portal from "./components/Portal/Portal.js";
import PortalContent from "./components/Portal/PortalContent.js";
import DropDownList from "./components/ObjectTable/FilterDropDown/DropDownList/DropDownList.js";
import Alert from "./components/Alert/Alert.js";
import Stepper from "./components/Stepper/Stepper.js";
import MeasurementProgramChooser from "./components/Measurement/MeasurementProgramChooser/MeasurementProgramChooser.js";
import MeasurementManual from "./components/EntityManager/EntityMeasurements/CreateMeasurementManual/CreateMeasurementManual.js";
import Test from "./components/Test/Test.js";
const container = Container;
const toggleElement = ToggleElement;
const sidebarContainer = SidebarContainer;
const resizeContainer = ResizeContainer;
const kuviContainer = KuviContainer;
const formField = FormField;
const objectForm = ObjectForm;
const kanban = Kanban;
const datePickerForm = DatePickerForm;
const dropDown = DropDown;
const kanbanItem = KanbanItem;
const renderObject = RenderObject;
const textField = TextField;
const textArea = TextArea;
const objectTable = ObjectTable;
const filterDropDown = FilterDropDown;
const datePickerTable = DatePickerTable;
const layerTree = LayerTree;
const contentWrapper = ContentWrapper;
const dashBoard = Dashboard;
const loadingSpinner = LoadingSpinner;
const dataviewManager = DataviewManager;
const entityManager = EntityManager;
const fullOverlay = FullOverlay;
const breadCrumb = BreadCrumb;
const breadCrumbMobile = BreadCrumbMobile;
const singlePropertyInlineEditor = SinglePropertyInlineEditor;
const radioButton = RadioButton;
const toast = Toast;
const dragAndDropUpload = DragAndDropUpload;
const resumeContainer = ResumeContainer;
const mapPlugin = MapPlugin;
const simpleObjectRenderer = SimpleObjectRenderer;
const accordeon = Accordeon;
const objectList = ObjectList;
const geosearch = GeoSearch;
const dataChart = DataCharts;
const wmtslayerImporter = WMTSLayerImporter;
const wmslayerImporter = WMSLayerImporter;
const measurement = Measurement;
const measurementFilterBuilder = MeasurementFilterBuilder;
const measurementProgramDownload = MeasurementProgramDownload;
const dataviewCreator = DataviewCreator;
const mainMap = MainMap;
const newMap = NewMap;
const layerSwitcher = LayerSwitcher;
const mapToolMaxExtent = MapToolMaxExtent;
const mapMeasureTools = MapMeasureTools;
const mapDragAndDrop = MapDragAndDrop;
const mapBoxZoom = MapBoxZoom;
const featureSearchPoint = FeatureSearchPoint;
const featureSearchPointResults = FeatureSearchResults;
const externalMapTool = ExternalMapTool;
const devicePosition = DevicePosition;
const pinLocation = PinLocation;
const printMap = PrintMap;
const zoomIn = ZoomIn;
const zoomOut = ZoomOut;
const mapImageDownload = MapImageDownload;
const mapGeoSearch = MapGeoSearch;
const featureSearchDatabase = FeatureSearchDatabase;
const mapEditGeometry = MapEditGeometry;
const mapFilePreview = MapFilePreview;
const mapPluginRequestHandler = MapPluginRequestHandler;
const mapAdvancedSearchDraw = MapAdvancedSearchDraw;
const dataviewSearch = DataviewSearch;
const documentSearch = DocumentSearch;
const mapObjectPreview = MapObjectPreview;
const excelToData = ExcelToData;
const timeline = TimeLine;
const viewAttributeAnalyser = ViewAttributeAnalyser;
const backend = Backend;
const viewChoiceOverlay = ViewChoiceOverlay;
const dataviewManagerBackend = DataviewManagerBackend;
const mail = Mail;
const website = Website;
const eventExporter = EventExporter;
const timeLine = TimeLine;
const calendar = Calendar;
const popoverCalendar = PopoverCalendar;
const dataviewRightsManager = DataviewRightsManager;
const elementSwitcher = ElementSwitcher;
const codeListCreator = CodelistCreator;
const eventDispatcher = EventDispatcher;
const favorites = Favorites;
const lastEntities = LastEntities;
const lastActions = LastActions;
const parameterManager = ParameterManager;
const menuLink = MenuLink;
const relationshipCreator = RelationshipCreator;
const typeMenuTree = TypeMenuTree;
const hTMLPageRights = HTMLPageRights;
const relationshipEditor = RelationshipEditor;
const typeCreator = TypeCreator;
const relationshipOrder = RelationshipOrder;
const geoTableCreator = GeoTableCreator;
const singleRecordForm = SingleRecordForm;
const typeDomainManager = TypeDomainManager;
const metadataLinks = MetadataLinks;
const entityMetadataLinks = EntityMetadataLinks;
const dataImportLinks = DataImportLinks;
const domainTreeCreator = DomainTreeCreator;
const resetPassword = ResetPassword;
const metadataDownload = MetadataDownload;
const viewGenerator = ViewGenerator;
const dataDownloader = DataDownloader;
const logout = Logout;
const settingsMigrator = SettingsMigrator;
const userSearch = UserSearch;
const entityTableGenerator = EntityTableGenerator;
const searchBuilder = SearchBuilder;
const batchEditForm = BatchEditForm;
const copyForm = CopyForm;
const addUserAction = AddUserAction;
const userForms = UserForms;
const dashboardTabCreator = DashboardTabCreator;
const pinnedEntities = PinnedEntities;
const alterTableGenerator = AlterTableGenerator;
const typeChooserInThemeTree = TypeChooserInThemeTree;
const codelistCreatorManager = CodelistCreatorManager;
const coreViews = CoreViews;
const typeRelationshipElementManager = TypeRelationshipElementManager;
const scaleLine = ScaleLine;
const mapQuickSearch = MapQuickSearch;
const mapQuickSearchOverlay = MapQuickSearchOverlay;
const nativeDateTimePicker = NativeDateTimePicker;
const mapWindowSearch = MapWindowSearch;
const mapBackgroundSwitcher = MapBackgroundSwitcher;
const formContainer = FormContainer;
const uploadContainer = UploadContainer;
const urlParameterDispatcher = UrlParameterDispatcher;
const mapAddImageButton = MapAddImageButton;
const addImageLayer = AddImageLayer;
const customPopover = CustomPopover;
const userRights = UserRights;
const icon = FaIcon;
const dataviewViewRights = DataviewViewRights;
const themeTree = ThemeTree;
const button = Button;
const bcSwitch = Switch;
const applicationStarter = ApplicationStarter;
const tabs = Tabs;
const tablink = TabLink;
const tabpanel = TabPanel;
const sideOverlay = SideOverlay;
const userMenu = UserMenu;
const headerBar = HeaderBar;
const pdfExporter = PdfExporter;
const dataviewTriggerDisplay = DataviewTriggerDisplay;
const dataviewSelector = DataviewSelector;
const iconButton = IconButton;
const entityRelations = EntityRelations;
const entityMap = EntityMap;
const entityDocuments = EntityDocuments;
const entityActions = EntityActions;
const copyDataview = CopyDataview;
const entityMeasuremnts = EntityMeasurements;
const bcExport = BcExport;
const pDFViewer = PDFViewer;
const themeTreeSelector = ThemeTreeSelector;
const advancedBackendTable = AdvancedBackendTable;
const datatypeIcon = DatatypeIcon;
const addDatabasePrecedure = AddDatabasePrecedure;
const artifactTextArea = ArtifactTextArea;
const editDatabasePrecedure = EditDatabasePrecedure;
const checkbox = Checkbox;
const relaodViewDescription = ReloadViewDescription;
const pagination = Pagination;
const artifactRichText = ArtifactRichText;
const singleRelationship = SingleRelationship;
const attributeHandler = AttributeHandler;
const editStatusList = EditStatusList;
const previewMap = PreviewMap;
const searchMap = SearchMap;
const simpleMap = SimpleMap;
const compositionConstructor = CompositionConstructor;
const popOverOption = PopOverOption;
const backDrop = BackDrop;
const router = Router;
const uploadDataMetadata = UploadDataMetadata;
const spinner = Spinner;
const entityChooser = EntityChooser;
const title = Title;
const portal = Portal;
const portalContent = PortalContent;
const dropDownList = DropDownList;
const alert = Alert;
const stepper = Stepper;
const measurementProgramChooser = MeasurementProgramChooser;
const measurementManual = MeasurementManual;
const mapShareMapLocation = MapShareMapLocation;
const drawTool = DrawTool;
const test = Test;
const anyObjectRenderer = AnyObjectRenderer;
const bc_version = "4.0.01.002";
successLog("VERSION", bc_version);
subscribeToWindowResize();

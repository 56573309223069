import {isMobileStore} from "./store.js";
let currentIsMobileValue = false;
isMobileStore.subscribe((value) => {
  currentIsMobileValue = value;
});
export const subscribeToWindowResize = () => {
  const resizeObserver = new ResizeObserver((entries) => {
    for (let entry of entries) {
      if (entry.contentBoxSize[0].inlineSize < 767 && currentIsMobileValue === false) {
        isMobileStore.set(true);
      }
      if (entry.contentBoxSize[0].inlineSize > 767 && currentIsMobileValue === true) {
        isMobileStore.set(false);
      }
    }
  });
  resizeObserver.observe(document.querySelector("body"));
};

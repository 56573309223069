import Swal from "../../web_modules/sweetalert2.js";
import bc from "../bcShim.js";
import {copyToClipboard} from "./generalUtils.js";
export const errorToast = (title, message, timeout) => {
  bc.messageService.publish("TOAST", {
    infoType: "error",
    title,
    message: message ? message : "",
    timeout: timeout ? timeout : 2e3
  });
};
export const warn = (title, message, timeout) => {
  bc.messageService.publish("TOAST", {
    infoType: "warning",
    title,
    message: message ? message : "",
    timeout: timeout ? timeout : 2e3
  });
};
export const notify = (title, message, timeout) => {
  bc.messageService.publish("TOAST", {
    infoType: "info",
    title,
    message: message ? message : "",
    timeout: timeout ? timeout : 2e3
  });
};
export const success = (title, message, timeout) => {
  bc.messageService.publish("TOAST", {
    infoType: "success",
    title,
    message: message ? message : "",
    timeout: timeout ? timeout : 3e3
  });
};
export const appendErrorInformation = (error, message) => {
  if (typeof error === "object") {
    if (!error["information"]) {
      error.information = message;
    } else {
      error.information = error.information + "\n" + message;
    }
  } else {
    error = error + "\nInformation : " + message;
  }
  return error;
};
export const customAlert = (message) => {
  Swal.fire({
    icon: "info",
    title: message
  });
};
export const handleErrorInAlert = (error, title) => {
  let errorString = "";
  if (typeof error === "object") {
    errorString = JSON.stringify(error, null, 4);
  } else {
    errorString = error.toString();
  }
  Swal.fire({
    icon: "error",
    title: title ? title : "Ein Fehler ist aufgetreten",
    html: '<span style="display:flex; text-align:left; white-space: pre; flex-direction: column;">' + errorString + "</span>",
    showDenyButton: true,
    denyButtonText: "Kopieren",
    denyButtonColor: "#3781dc",
    preDeny: () => {
      copyToClipboard(errorString).then((hasCopied) => {
        if (hasCopied) {
          success("In die Zwischenablage kopiert");
        } else {
          errorToast("Kopieren nicht unterst\xFCtzt");
        }
      });
      return false;
    }
  });
};

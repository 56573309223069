/* src\components\ObjectForm\FormContainer.svelte generated by Svelte v3.57.0 */
import {
	SvelteElement,
	add_location,
	append_dev,
	attribute_to_object,
	component_subscribe,
	detach_dev,
	dispatch_dev,
	element,
	flush,
	globals,
	init as init_1,
	insert_dev,
	listen_dev,
	noop,
	safe_not_equal,
	set_custom_element_data,
	toggle_class,
	validate_slots,
	validate_store
} from "../../../web_modules/svelte/internal.js";

const { Object: Object_1, console: console_1 } = globals;
import bc from '../../bcShim.js';
import { v4 as uuidv4 } from '../../../web_modules/uuid.js';
import { onMount, tick } from '../../../web_modules/svelte.js';

import {
	attachNormalize,
	componentInitAndAttributeHandler,
	dispatchMountedEvent,
	handleComponentShowAttribute,
	singletonComponentExists
} from "../../utilities/componentUtils.js";

import { get_current_component } from "../../../web_modules/svelte/internal.js";
import { applicationReady } from "../../utilities/store.js";
import { getComponentText } from "../../utilities/generalUtils.js";
import { infoLog } from "../../utilities/consoleUtils.js";
const file = "src\\components\\ObjectForm\\FormContainer.svelte";

// (246:4) {#if ready && showForm}
function create_if_block(ctx) {
	let full_overlay;
	let object_form;
	let object_form_cancelon_value;
	let full_overlay_insteadofcloseevent_value;
	let mounted;
	let dispose;

	const block = {
		c: function create() {
			full_overlay = element("full-overlay");
			object_form = element("object-form");
			set_custom_element_data(object_form, "navigationtopic", /*navigationtopic*/ ctx[14]);
			set_custom_element_data(object_form, "class", "overflow-auto h-full");
			set_custom_element_data(object_form, "show", /*showForm*/ ctx[16]);
			set_custom_element_data(object_form, "init", /*initForm*/ ctx[17]);
			set_custom_element_data(object_form, "slot", "content");
			set_custom_element_data(object_form, "formtype", /*formtype*/ ctx[20]);
			set_custom_element_data(object_form, "editobjectid", /*editobjectid*/ ctx[21]);
			set_custom_element_data(object_form, "actionentityguid", /*actionentityguid*/ ctx[24]);
			set_custom_element_data(object_form, "dataviewguid", /*dataviewguid*/ ctx[18]);
			set_custom_element_data(object_form, "typedomain", /*typedomain*/ ctx[19]);
			set_custom_element_data(object_form, "display", "inline");
			set_custom_element_data(object_form, "cancelevent", /*cancelevent*/ ctx[22]);
			set_custom_element_data(object_form, "saveevent", /*saveevent*/ ctx[23]);
			set_custom_element_data(object_form, "additionalbuttonevent", /*additionalbuttonevent*/ ctx[31]);
			set_custom_element_data(object_form, "additionalbuttontext", /*additionalbuttontext*/ ctx[32]);
			set_custom_element_data(object_form, "additionalbuttonicon", /*additionalbuttonicon*/ ctx[33]);
			set_custom_element_data(object_form, "relationshiptype", /*relationshiptype*/ ctx[26]);
			set_custom_element_data(object_form, "rldirection", /*rldirection*/ ctx[27]);
			set_custom_element_data(object_form, "rlitemguid", /*rlitemguid*/ ctx[28]);
			set_custom_element_data(object_form, "sourceview", /*sourceview*/ ctx[29]);
			set_custom_element_data(object_form, "initvalues", /*initvalues*/ ctx[30]);
			set_custom_element_data(object_form, "displayintabs", /*displayintabs*/ ctx[37]);
			set_custom_element_data(object_form, "displaymenuopen", /*displaymenuopen*/ ctx[0]);
			set_custom_element_data(object_form, "displaymenuedit", /*displaymenuedit*/ ctx[1]);
			set_custom_element_data(object_form, "displaymenushowonmap", /*displaymenushowonmap*/ ctx[2]);
			set_custom_element_data(object_form, "displaymenufileupload", /*displaymenufileupload*/ ctx[3]);
			set_custom_element_data(object_form, "displaymenuemptyfields", /*displaymenuemptyfields*/ ctx[4]);
			set_custom_element_data(object_form, "displaymenudelete", /*displaymenudelete*/ ctx[5]);
			set_custom_element_data(object_form, "displaymenupreview", /*displaymenupreview*/ ctx[6]);
			set_custom_element_data(object_form, "showbuttonmore", /*showbuttonmore*/ ctx[7]);
			set_custom_element_data(object_form, "closeonsave", /*closeonsave*/ ctx[8]);
			set_custom_element_data(object_form, "closeonnavigate", /*closeonnavigate*/ ctx[9]);
			set_custom_element_data(object_form, "handlerelationships", /*handlerelationships*/ ctx[11]);
			set_custom_element_data(object_form, "handledocuments", /*handledocuments*/ ctx[12]);
			set_custom_element_data(object_form, "canlinkdocuments", /*canlinkdocuments*/ ctx[13]);
			set_custom_element_data(object_form, "editparent", /*editparent*/ ctx[10]);
			set_custom_element_data(object_form, "cancelon", object_form_cancelon_value = 'ASK_FOR_CLOSING' + /*subscribeGuid*/ ctx[38]);
			set_custom_element_data(object_form, "infulloverlay", "true");
			add_location(object_form, file, 255, 12, 7704);
			set_custom_element_data(full_overlay, "show", /*showForm*/ ctx[16]);
			set_custom_element_data(full_overlay, "init", /*initForm*/ ctx[17]);
			set_custom_element_data(full_overlay, "title", /*title*/ ctx[25]);
			set_custom_element_data(full_overlay, "fullscreen", /*fullscreen*/ ctx[34]);
			set_custom_element_data(full_overlay, "minwidth", /*minwidth*/ ctx[35]);
			set_custom_element_data(full_overlay, "maxwidth", /*maxwidth*/ ctx[36]);
			set_custom_element_data(full_overlay, "insteadofcloseevent", full_overlay_insteadofcloseevent_value = 'ASK_FOR_CLOSING' + /*subscribeGuid*/ ctx[38]);
			add_location(full_overlay, file, 246, 8, 7383);
		},
		m: function mount(target, anchor) {
			insert_dev(target, full_overlay, anchor);
			append_dev(full_overlay, object_form);

			if (!mounted) {
				dispose = listen_dev(object_form, "close", /*close_handler*/ ctx[45], false, false, false, false);
				mounted = true;
			}
		},
		p: function update(ctx, dirty) {
			if (dirty[0] & /*navigationtopic*/ 16384) {
				set_custom_element_data(object_form, "navigationtopic", /*navigationtopic*/ ctx[14]);
			}

			if (dirty[0] & /*showForm*/ 65536) {
				set_custom_element_data(object_form, "show", /*showForm*/ ctx[16]);
			}

			if (dirty[0] & /*initForm*/ 131072) {
				set_custom_element_data(object_form, "init", /*initForm*/ ctx[17]);
			}

			if (dirty[0] & /*formtype*/ 1048576) {
				set_custom_element_data(object_form, "formtype", /*formtype*/ ctx[20]);
			}

			if (dirty[0] & /*editobjectid*/ 2097152) {
				set_custom_element_data(object_form, "editobjectid", /*editobjectid*/ ctx[21]);
			}

			if (dirty[0] & /*actionentityguid*/ 16777216) {
				set_custom_element_data(object_form, "actionentityguid", /*actionentityguid*/ ctx[24]);
			}

			if (dirty[0] & /*dataviewguid*/ 262144) {
				set_custom_element_data(object_form, "dataviewguid", /*dataviewguid*/ ctx[18]);
			}

			if (dirty[0] & /*typedomain*/ 524288) {
				set_custom_element_data(object_form, "typedomain", /*typedomain*/ ctx[19]);
			}

			if (dirty[0] & /*cancelevent*/ 4194304) {
				set_custom_element_data(object_form, "cancelevent", /*cancelevent*/ ctx[22]);
			}

			if (dirty[0] & /*saveevent*/ 8388608) {
				set_custom_element_data(object_form, "saveevent", /*saveevent*/ ctx[23]);
			}

			if (dirty[1] & /*additionalbuttonevent*/ 1) {
				set_custom_element_data(object_form, "additionalbuttonevent", /*additionalbuttonevent*/ ctx[31]);
			}

			if (dirty[1] & /*additionalbuttontext*/ 2) {
				set_custom_element_data(object_form, "additionalbuttontext", /*additionalbuttontext*/ ctx[32]);
			}

			if (dirty[1] & /*additionalbuttonicon*/ 4) {
				set_custom_element_data(object_form, "additionalbuttonicon", /*additionalbuttonicon*/ ctx[33]);
			}

			if (dirty[0] & /*relationshiptype*/ 67108864) {
				set_custom_element_data(object_form, "relationshiptype", /*relationshiptype*/ ctx[26]);
			}

			if (dirty[0] & /*rldirection*/ 134217728) {
				set_custom_element_data(object_form, "rldirection", /*rldirection*/ ctx[27]);
			}

			if (dirty[0] & /*rlitemguid*/ 268435456) {
				set_custom_element_data(object_form, "rlitemguid", /*rlitemguid*/ ctx[28]);
			}

			if (dirty[0] & /*sourceview*/ 536870912) {
				set_custom_element_data(object_form, "sourceview", /*sourceview*/ ctx[29]);
			}

			if (dirty[0] & /*initvalues*/ 1073741824) {
				set_custom_element_data(object_form, "initvalues", /*initvalues*/ ctx[30]);
			}

			if (dirty[1] & /*displayintabs*/ 64) {
				set_custom_element_data(object_form, "displayintabs", /*displayintabs*/ ctx[37]);
			}

			if (dirty[0] & /*displaymenuopen*/ 1) {
				set_custom_element_data(object_form, "displaymenuopen", /*displaymenuopen*/ ctx[0]);
			}

			if (dirty[0] & /*displaymenuedit*/ 2) {
				set_custom_element_data(object_form, "displaymenuedit", /*displaymenuedit*/ ctx[1]);
			}

			if (dirty[0] & /*displaymenushowonmap*/ 4) {
				set_custom_element_data(object_form, "displaymenushowonmap", /*displaymenushowonmap*/ ctx[2]);
			}

			if (dirty[0] & /*displaymenufileupload*/ 8) {
				set_custom_element_data(object_form, "displaymenufileupload", /*displaymenufileupload*/ ctx[3]);
			}

			if (dirty[0] & /*displaymenuemptyfields*/ 16) {
				set_custom_element_data(object_form, "displaymenuemptyfields", /*displaymenuemptyfields*/ ctx[4]);
			}

			if (dirty[0] & /*displaymenudelete*/ 32) {
				set_custom_element_data(object_form, "displaymenudelete", /*displaymenudelete*/ ctx[5]);
			}

			if (dirty[0] & /*displaymenupreview*/ 64) {
				set_custom_element_data(object_form, "displaymenupreview", /*displaymenupreview*/ ctx[6]);
			}

			if (dirty[0] & /*showbuttonmore*/ 128) {
				set_custom_element_data(object_form, "showbuttonmore", /*showbuttonmore*/ ctx[7]);
			}

			if (dirty[0] & /*closeonsave*/ 256) {
				set_custom_element_data(object_form, "closeonsave", /*closeonsave*/ ctx[8]);
			}

			if (dirty[0] & /*closeonnavigate*/ 512) {
				set_custom_element_data(object_form, "closeonnavigate", /*closeonnavigate*/ ctx[9]);
			}

			if (dirty[0] & /*handlerelationships*/ 2048) {
				set_custom_element_data(object_form, "handlerelationships", /*handlerelationships*/ ctx[11]);
			}

			if (dirty[0] & /*handledocuments*/ 4096) {
				set_custom_element_data(object_form, "handledocuments", /*handledocuments*/ ctx[12]);
			}

			if (dirty[0] & /*canlinkdocuments*/ 8192) {
				set_custom_element_data(object_form, "canlinkdocuments", /*canlinkdocuments*/ ctx[13]);
			}

			if (dirty[0] & /*editparent*/ 1024) {
				set_custom_element_data(object_form, "editparent", /*editparent*/ ctx[10]);
			}

			if (dirty[0] & /*showForm*/ 65536) {
				set_custom_element_data(full_overlay, "show", /*showForm*/ ctx[16]);
			}

			if (dirty[0] & /*initForm*/ 131072) {
				set_custom_element_data(full_overlay, "init", /*initForm*/ ctx[17]);
			}

			if (dirty[0] & /*title*/ 33554432) {
				set_custom_element_data(full_overlay, "title", /*title*/ ctx[25]);
			}

			if (dirty[1] & /*fullscreen*/ 8) {
				set_custom_element_data(full_overlay, "fullscreen", /*fullscreen*/ ctx[34]);
			}

			if (dirty[1] & /*minwidth*/ 16) {
				set_custom_element_data(full_overlay, "minwidth", /*minwidth*/ ctx[35]);
			}

			if (dirty[1] & /*maxwidth*/ 32) {
				set_custom_element_data(full_overlay, "maxwidth", /*maxwidth*/ ctx[36]);
			}
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(full_overlay);
			mounted = false;
			dispose();
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block.name,
		type: "if",
		source: "(246:4) {#if ready && showForm}",
		ctx
	});

	return block;
}

function create_fragment(ctx) {
	let div;
	let if_block = /*ready*/ ctx[15] && /*showForm*/ ctx[16] && create_if_block(ctx);

	const block = {
		c: function create() {
			div = element("div");
			if (if_block) if_block.c();
			this.c = noop;
			toggle_class(div, "hidden", /*showForm*/ ctx[16] === 'false');
			add_location(div, file, 244, 0, 7304);
		},
		l: function claim(nodes) {
			throw new Error("options.hydrate only works if the component was compiled with the `hydratable: true` option");
		},
		m: function mount(target, anchor) {
			insert_dev(target, div, anchor);
			if (if_block) if_block.m(div, null);
		},
		p: function update(ctx, dirty) {
			if (/*ready*/ ctx[15] && /*showForm*/ ctx[16]) {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block(ctx);
					if_block.c();
					if_block.m(div, null);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}

			if (dirty[0] & /*showForm*/ 65536) {
				toggle_class(div, "hidden", /*showForm*/ ctx[16] === 'false');
			}
		},
		i: noop,
		o: noop,
		d: function destroy(detaching) {
			if (detaching) detach_dev(div);
			if (if_block) if_block.d();
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_fragment.name,
		type: "component",
		source: "",
		ctx
	});

	return block;
}

function instance($$self, $$props, $$invalidate) {
	let $applicationReady;
	validate_store(applicationReady, 'applicationReady');
	component_subscribe($$self, applicationReady, $$value => $$invalidate(44, $applicationReady = $$value));
	let { $$slots: slots = {}, $$scope } = $$props;
	validate_slots('form-container', slots, []);
	let { show = 'true' } = $$props;
	let { init = 'true' } = $$props;
	let { navigationtopic = 'default' } = $$props;
	let { displaymenuopen = 'true' } = $$props;
	let { displaymenuedit = 'true' } = $$props;
	let { displaymenushowonmap = 'true' } = $$props;
	let { displaymenufileupload = 'true' } = $$props;
	let { displaymenuemptyfields = 'true' } = $$props;
	let { displaymenudelete = 'false' } = $$props;
	let { displaymenupreview = 'false' } = $$props;
	let { showbuttonmore = 'true' } = $$props;
	let { closeonsave = 'true' } = $$props;
	let { closeonnavigate = 'true' } = $$props;
	let { editparent = 'true' } = $$props;
	let { handlerelationships = 'true' } = $$props;
	let { handledocuments = 'true' } = $$props;
	let { canlinkdocuments = 'true' } = $$props;

	// Base variables
	//================================================================
	// Base Variables
	let firstInitialized = false;

	let subscribeGuid = uuidv4();
	let ready = false;
	const generalText = getComponentText('general');
	let component = get_current_component();
	let mounted = false;
	let canReload = false;

	// Component variables
	//================================================================
	let showForm = 'false';

	let initForm = 'false';
	let dataviewguid = "";
	let typedomain = "";
	let formtype = "";
	let editobjectid = "";
	let cancelevent = "";
	let saveevent = "";
	let actionentityguid = '';
	let title = '';
	let relationshiptype = '';
	let rldirection = '';
	let rlitemguid = '';
	let sourceview = '';
	let initvalues = '';
	let additionalbuttonevent = '';
	let additionalbuttontext = '';
	let additionalbuttonicon = '';
	let fullscreen = 'true';
	let minwidth = '';
	let maxwidth = '';
	let finalevent = '';
	let displayintabs = 'false';

	let defaultParams = {
		dataviewguid: '',
		typedomain: '',
		formtype: 'New',
		editobjectid: '',
		cancelevent: '',
		saveevent: '',
		title: '',
		actionentityguid: '',
		relationshiptype: '',
		rldirection: '',
		rlitemguid: '',
		sourceview: '',
		initvalues: '',
		additionalbuttonevent: '',
		additionalbuttontext: '',
		additionalbuttonicon: '',
		closeonsave,
		closeonnavigate,
		handlerelationships,
		handledocuments,
		canlinkdocuments,
		editparent,
		fullscreen: 'true',
		minwidth: '',
		maxwidth: '',
		finalevent: '',
		displayintabs: 'false',
		displaymenuopen,
		displaymenuedit,
		displaymenushowonmap,
		displaymenufileupload,
		displaymenuemptyfields,
		displaymenudelete,
		displaymenupreview,
		showbuttonmore
	};

	// HTML Mount & Component initialization
	//================================================================
	onMount(() => {
		attachNormalize(component);

		tick().then(() => {
			dispatchMountedEvent(component);
			$$invalidate(43, mounted = true);
		});

		return () => {
			bc.messageService.unsubscribeComponent(subscribeGuid);
		};
	});

	const initComponent = () => {
		$$invalidate(42, firstInitialized = true);

		if (!singletonComponentExists('FORM-CONTAINER', true)) {
			bc.messageService.subscribe('SET_FORM_CONTAINER', subscribeGuid, updateVariables); // this not only checks for singleton but sets it
		}

		infoLog('Form-Container for object-forms', 'subscribeGuid', subscribeGuid);
	};

	// Component functions
	//================================================================
	const updateVariables = args => {
		args = Object.assign(Object.assign({}, defaultParams), args);
		$$invalidate(18, { dataviewguid, typedomain, formtype, editobjectid, cancelevent, saveevent, title, actionentityguid, relationshiptype, rldirection, rlitemguid, sourceview, initvalues, additionalbuttonevent, additionalbuttontext, additionalbuttonicon, closeonsave, closeonnavigate, handlerelationships, handledocuments, canlinkdocuments, editparent, fullscreen, displayintabs, minwidth, maxwidth, finalevent, displaymenuopen, displaymenuedit, displaymenushowonmap, displaymenufileupload, displaymenuemptyfields, displaymenudelete, displaymenupreview, showbuttonmore } = args, dataviewguid, $$invalidate(19, typedomain), $$invalidate(20, formtype), $$invalidate(21, editobjectid), $$invalidate(22, cancelevent), $$invalidate(23, saveevent), $$invalidate(25, title), $$invalidate(24, actionentityguid), $$invalidate(26, relationshiptype), $$invalidate(27, rldirection), $$invalidate(28, rlitemguid), $$invalidate(29, sourceview), $$invalidate(30, initvalues), $$invalidate(31, additionalbuttonevent), $$invalidate(32, additionalbuttontext), $$invalidate(33, additionalbuttonicon), $$invalidate(8, closeonsave), $$invalidate(9, closeonnavigate), $$invalidate(11, handlerelationships), $$invalidate(12, handledocuments), $$invalidate(13, canlinkdocuments), $$invalidate(10, editparent), $$invalidate(34, fullscreen), $$invalidate(37, displayintabs), $$invalidate(35, minwidth), $$invalidate(36, maxwidth), $$invalidate(0, displaymenuopen), $$invalidate(1, displaymenuedit), $$invalidate(2, displaymenushowonmap), $$invalidate(3, displaymenufileupload), $$invalidate(4, displaymenuemptyfields), $$invalidate(5, displaymenudelete), $$invalidate(6, displaymenupreview), $$invalidate(7, showbuttonmore));

		/*
        dataviewguid = args.dataviewguid
        typedomain = args.typedomain
        editobjectid = args.editobjectid
        formtype = args.formtype
        title = args.title
        actionentityguid = args.actionentityguid
        relationshiptype = args.relationshiptype
        rldirection = args.rldirection
        rlitemguid = args.rlitemguid
        sourceview = args.sourceview
*/
		console.log('initvalues', initvalues);

		console.log('Form arguments', args, dataviewguid, editobjectid, formtype);

		if (title === '') {
			if (formtype === 'New') {
				$$invalidate(25, title = generalText.add);
			} else {
				$$invalidate(25, title = generalText.edit);
			}
		}

		$$invalidate(16, showForm = 'true');
		$$invalidate(17, initForm = 'true');
		$$invalidate(15, ready = true);
	};

	const overlayClosedContainer = () => {
		$$invalidate(15, ready = false);
		$$invalidate(16, showForm = 'false');
		$$invalidate(17, initForm = 'false');
		$$invalidate(18, dataviewguid = "");
		$$invalidate(19, typedomain = "");
		$$invalidate(20, formtype = "");
		$$invalidate(21, editobjectid = "");
		$$invalidate(22, cancelevent = "");
		$$invalidate(23, saveevent = "");
	};

	const hideFormContainer = () => {
		$$invalidate(15, ready = false);
		$$invalidate(16, showForm = 'false');
		$$invalidate(17, initForm = 'false');
		$$invalidate(18, dataviewguid = "");
		$$invalidate(19, typedomain = "");
		$$invalidate(20, formtype = "");
		$$invalidate(21, editobjectid = "");
		$$invalidate(22, cancelevent = "");
		$$invalidate(23, saveevent = "");
		console.log('hideFormContainer');
	};

	const writable_props = [
		'show',
		'init',
		'navigationtopic',
		'displaymenuopen',
		'displaymenuedit',
		'displaymenushowonmap',
		'displaymenufileupload',
		'displaymenuemptyfields',
		'displaymenudelete',
		'displaymenupreview',
		'showbuttonmore',
		'closeonsave',
		'closeonnavigate',
		'editparent',
		'handlerelationships',
		'handledocuments',
		'canlinkdocuments'
	];

	Object_1.keys($$props).forEach(key => {
		if (!~writable_props.indexOf(key) && key.slice(0, 2) !== '$$' && key !== 'slot') console_1.warn(`<form-container> was created with unknown prop '${key}'`);
	});

	const close_handler = e => {
		e.stopPropagation();
		console.log('Formcontainer closing ' + subscribeGuid, e);
		overlayClosedContainer();
	};

	$$self.$$set = $$props => {
		if ('show' in $$props) $$invalidate(40, show = $$props.show);
		if ('init' in $$props) $$invalidate(41, init = $$props.init);
		if ('navigationtopic' in $$props) $$invalidate(14, navigationtopic = $$props.navigationtopic);
		if ('displaymenuopen' in $$props) $$invalidate(0, displaymenuopen = $$props.displaymenuopen);
		if ('displaymenuedit' in $$props) $$invalidate(1, displaymenuedit = $$props.displaymenuedit);
		if ('displaymenushowonmap' in $$props) $$invalidate(2, displaymenushowonmap = $$props.displaymenushowonmap);
		if ('displaymenufileupload' in $$props) $$invalidate(3, displaymenufileupload = $$props.displaymenufileupload);
		if ('displaymenuemptyfields' in $$props) $$invalidate(4, displaymenuemptyfields = $$props.displaymenuemptyfields);
		if ('displaymenudelete' in $$props) $$invalidate(5, displaymenudelete = $$props.displaymenudelete);
		if ('displaymenupreview' in $$props) $$invalidate(6, displaymenupreview = $$props.displaymenupreview);
		if ('showbuttonmore' in $$props) $$invalidate(7, showbuttonmore = $$props.showbuttonmore);
		if ('closeonsave' in $$props) $$invalidate(8, closeonsave = $$props.closeonsave);
		if ('closeonnavigate' in $$props) $$invalidate(9, closeonnavigate = $$props.closeonnavigate);
		if ('editparent' in $$props) $$invalidate(10, editparent = $$props.editparent);
		if ('handlerelationships' in $$props) $$invalidate(11, handlerelationships = $$props.handlerelationships);
		if ('handledocuments' in $$props) $$invalidate(12, handledocuments = $$props.handledocuments);
		if ('canlinkdocuments' in $$props) $$invalidate(13, canlinkdocuments = $$props.canlinkdocuments);
	};

	$$self.$capture_state = () => ({
		bc,
		uuidv4,
		onMount,
		tick,
		attachNormalize,
		componentInitAndAttributeHandler,
		dispatchMountedEvent,
		handleComponentShowAttribute,
		singletonComponentExists,
		get_current_component,
		applicationReady,
		getComponentText,
		infoLog,
		show,
		init,
		navigationtopic,
		displaymenuopen,
		displaymenuedit,
		displaymenushowonmap,
		displaymenufileupload,
		displaymenuemptyfields,
		displaymenudelete,
		displaymenupreview,
		showbuttonmore,
		closeonsave,
		closeonnavigate,
		editparent,
		handlerelationships,
		handledocuments,
		canlinkdocuments,
		firstInitialized,
		subscribeGuid,
		ready,
		generalText,
		component,
		mounted,
		canReload,
		showForm,
		initForm,
		dataviewguid,
		typedomain,
		formtype,
		editobjectid,
		cancelevent,
		saveevent,
		actionentityguid,
		title,
		relationshiptype,
		rldirection,
		rlitemguid,
		sourceview,
		initvalues,
		additionalbuttonevent,
		additionalbuttontext,
		additionalbuttonicon,
		fullscreen,
		minwidth,
		maxwidth,
		finalevent,
		displayintabs,
		defaultParams,
		initComponent,
		updateVariables,
		overlayClosedContainer,
		hideFormContainer,
		$applicationReady
	});

	$$self.$inject_state = $$props => {
		if ('show' in $$props) $$invalidate(40, show = $$props.show);
		if ('init' in $$props) $$invalidate(41, init = $$props.init);
		if ('navigationtopic' in $$props) $$invalidate(14, navigationtopic = $$props.navigationtopic);
		if ('displaymenuopen' in $$props) $$invalidate(0, displaymenuopen = $$props.displaymenuopen);
		if ('displaymenuedit' in $$props) $$invalidate(1, displaymenuedit = $$props.displaymenuedit);
		if ('displaymenushowonmap' in $$props) $$invalidate(2, displaymenushowonmap = $$props.displaymenushowonmap);
		if ('displaymenufileupload' in $$props) $$invalidate(3, displaymenufileupload = $$props.displaymenufileupload);
		if ('displaymenuemptyfields' in $$props) $$invalidate(4, displaymenuemptyfields = $$props.displaymenuemptyfields);
		if ('displaymenudelete' in $$props) $$invalidate(5, displaymenudelete = $$props.displaymenudelete);
		if ('displaymenupreview' in $$props) $$invalidate(6, displaymenupreview = $$props.displaymenupreview);
		if ('showbuttonmore' in $$props) $$invalidate(7, showbuttonmore = $$props.showbuttonmore);
		if ('closeonsave' in $$props) $$invalidate(8, closeonsave = $$props.closeonsave);
		if ('closeonnavigate' in $$props) $$invalidate(9, closeonnavigate = $$props.closeonnavigate);
		if ('editparent' in $$props) $$invalidate(10, editparent = $$props.editparent);
		if ('handlerelationships' in $$props) $$invalidate(11, handlerelationships = $$props.handlerelationships);
		if ('handledocuments' in $$props) $$invalidate(12, handledocuments = $$props.handledocuments);
		if ('canlinkdocuments' in $$props) $$invalidate(13, canlinkdocuments = $$props.canlinkdocuments);
		if ('firstInitialized' in $$props) $$invalidate(42, firstInitialized = $$props.firstInitialized);
		if ('subscribeGuid' in $$props) $$invalidate(38, subscribeGuid = $$props.subscribeGuid);
		if ('ready' in $$props) $$invalidate(15, ready = $$props.ready);
		if ('component' in $$props) $$invalidate(48, component = $$props.component);
		if ('mounted' in $$props) $$invalidate(43, mounted = $$props.mounted);
		if ('canReload' in $$props) $$invalidate(49, canReload = $$props.canReload);
		if ('showForm' in $$props) $$invalidate(16, showForm = $$props.showForm);
		if ('initForm' in $$props) $$invalidate(17, initForm = $$props.initForm);
		if ('dataviewguid' in $$props) $$invalidate(18, dataviewguid = $$props.dataviewguid);
		if ('typedomain' in $$props) $$invalidate(19, typedomain = $$props.typedomain);
		if ('formtype' in $$props) $$invalidate(20, formtype = $$props.formtype);
		if ('editobjectid' in $$props) $$invalidate(21, editobjectid = $$props.editobjectid);
		if ('cancelevent' in $$props) $$invalidate(22, cancelevent = $$props.cancelevent);
		if ('saveevent' in $$props) $$invalidate(23, saveevent = $$props.saveevent);
		if ('actionentityguid' in $$props) $$invalidate(24, actionentityguid = $$props.actionentityguid);
		if ('title' in $$props) $$invalidate(25, title = $$props.title);
		if ('relationshiptype' in $$props) $$invalidate(26, relationshiptype = $$props.relationshiptype);
		if ('rldirection' in $$props) $$invalidate(27, rldirection = $$props.rldirection);
		if ('rlitemguid' in $$props) $$invalidate(28, rlitemguid = $$props.rlitemguid);
		if ('sourceview' in $$props) $$invalidate(29, sourceview = $$props.sourceview);
		if ('initvalues' in $$props) $$invalidate(30, initvalues = $$props.initvalues);
		if ('additionalbuttonevent' in $$props) $$invalidate(31, additionalbuttonevent = $$props.additionalbuttonevent);
		if ('additionalbuttontext' in $$props) $$invalidate(32, additionalbuttontext = $$props.additionalbuttontext);
		if ('additionalbuttonicon' in $$props) $$invalidate(33, additionalbuttonicon = $$props.additionalbuttonicon);
		if ('fullscreen' in $$props) $$invalidate(34, fullscreen = $$props.fullscreen);
		if ('minwidth' in $$props) $$invalidate(35, minwidth = $$props.minwidth);
		if ('maxwidth' in $$props) $$invalidate(36, maxwidth = $$props.maxwidth);
		if ('finalevent' in $$props) finalevent = $$props.finalevent;
		if ('displayintabs' in $$props) $$invalidate(37, displayintabs = $$props.displayintabs);
		if ('defaultParams' in $$props) defaultParams = $$props.defaultParams;
	};

	if ($$props && "$$inject" in $$props) {
		$$self.$inject_state($$props.$$inject);
	}

	$$self.$$.update = () => {
		if ($$self.$$.dirty[1] & /*show*/ 512) {
			$: if (show) {
				handleComponentShowAttribute(show, component);
			}
		}

		if ($$self.$$.dirty[1] & /*$applicationReady, mounted, init, firstInitialized*/ 15360) {
			$: if ($applicationReady && mounted && init) {
				componentInitAndAttributeHandler({
					mounted,
					handler: initComponent,
					init,
					firstInitialized,
					canReload
				});
			}
		}
	};

	return [
		displaymenuopen,
		displaymenuedit,
		displaymenushowonmap,
		displaymenufileupload,
		displaymenuemptyfields,
		displaymenudelete,
		displaymenupreview,
		showbuttonmore,
		closeonsave,
		closeonnavigate,
		editparent,
		handlerelationships,
		handledocuments,
		canlinkdocuments,
		navigationtopic,
		ready,
		showForm,
		initForm,
		dataviewguid,
		typedomain,
		formtype,
		editobjectid,
		cancelevent,
		saveevent,
		actionentityguid,
		title,
		relationshiptype,
		rldirection,
		rlitemguid,
		sourceview,
		initvalues,
		additionalbuttonevent,
		additionalbuttontext,
		additionalbuttonicon,
		fullscreen,
		minwidth,
		maxwidth,
		displayintabs,
		subscribeGuid,
		overlayClosedContainer,
		show,
		init,
		firstInitialized,
		mounted,
		$applicationReady,
		close_handler
	];
}

class FormContainer extends SvelteElement {
	constructor(options) {
		super();
		const style = document.createElement('style');
		style.textContent = `[display~="inline"]{display:inline}.hidden{display:none}.h-full{height:100%}.overflow-auto{overflow:auto}`;
		this.shadowRoot.appendChild(style);

		init_1(
			this,
			{
				target: this.shadowRoot,
				props: attribute_to_object(this.attributes),
				customElement: true
			},
			instance,
			create_fragment,
			safe_not_equal,
			{
				show: 40,
				init: 41,
				navigationtopic: 14,
				displaymenuopen: 0,
				displaymenuedit: 1,
				displaymenushowonmap: 2,
				displaymenufileupload: 3,
				displaymenuemptyfields: 4,
				displaymenudelete: 5,
				displaymenupreview: 6,
				showbuttonmore: 7,
				closeonsave: 8,
				closeonnavigate: 9,
				editparent: 10,
				handlerelationships: 11,
				handledocuments: 12,
				canlinkdocuments: 13
			},
			null,
			[-1, -1]
		);

		if (options) {
			if (options.target) {
				insert_dev(options.target, this, options.anchor);
			}

			if (options.props) {
				this.$set(options.props);
				flush();
			}
		}
	}

	static get observedAttributes() {
		return [
			"show",
			"init",
			"navigationtopic",
			"displaymenuopen",
			"displaymenuedit",
			"displaymenushowonmap",
			"displaymenufileupload",
			"displaymenuemptyfields",
			"displaymenudelete",
			"displaymenupreview",
			"showbuttonmore",
			"closeonsave",
			"closeonnavigate",
			"editparent",
			"handlerelationships",
			"handledocuments",
			"canlinkdocuments"
		];
	}

	get show() {
		return this.$$.ctx[40];
	}

	set show(show) {
		this.$$set({ show });
		flush();
	}

	get init() {
		return this.$$.ctx[41];
	}

	set init(init) {
		this.$$set({ init });
		flush();
	}

	get navigationtopic() {
		return this.$$.ctx[14];
	}

	set navigationtopic(navigationtopic) {
		this.$$set({ navigationtopic });
		flush();
	}

	get displaymenuopen() {
		return this.$$.ctx[0];
	}

	set displaymenuopen(displaymenuopen) {
		this.$$set({ displaymenuopen });
		flush();
	}

	get displaymenuedit() {
		return this.$$.ctx[1];
	}

	set displaymenuedit(displaymenuedit) {
		this.$$set({ displaymenuedit });
		flush();
	}

	get displaymenushowonmap() {
		return this.$$.ctx[2];
	}

	set displaymenushowonmap(displaymenushowonmap) {
		this.$$set({ displaymenushowonmap });
		flush();
	}

	get displaymenufileupload() {
		return this.$$.ctx[3];
	}

	set displaymenufileupload(displaymenufileupload) {
		this.$$set({ displaymenufileupload });
		flush();
	}

	get displaymenuemptyfields() {
		return this.$$.ctx[4];
	}

	set displaymenuemptyfields(displaymenuemptyfields) {
		this.$$set({ displaymenuemptyfields });
		flush();
	}

	get displaymenudelete() {
		return this.$$.ctx[5];
	}

	set displaymenudelete(displaymenudelete) {
		this.$$set({ displaymenudelete });
		flush();
	}

	get displaymenupreview() {
		return this.$$.ctx[6];
	}

	set displaymenupreview(displaymenupreview) {
		this.$$set({ displaymenupreview });
		flush();
	}

	get showbuttonmore() {
		return this.$$.ctx[7];
	}

	set showbuttonmore(showbuttonmore) {
		this.$$set({ showbuttonmore });
		flush();
	}

	get closeonsave() {
		return this.$$.ctx[8];
	}

	set closeonsave(closeonsave) {
		this.$$set({ closeonsave });
		flush();
	}

	get closeonnavigate() {
		return this.$$.ctx[9];
	}

	set closeonnavigate(closeonnavigate) {
		this.$$set({ closeonnavigate });
		flush();
	}

	get editparent() {
		return this.$$.ctx[10];
	}

	set editparent(editparent) {
		this.$$set({ editparent });
		flush();
	}

	get handlerelationships() {
		return this.$$.ctx[11];
	}

	set handlerelationships(handlerelationships) {
		this.$$set({ handlerelationships });
		flush();
	}

	get handledocuments() {
		return this.$$.ctx[12];
	}

	set handledocuments(handledocuments) {
		this.$$set({ handledocuments });
		flush();
	}

	get canlinkdocuments() {
		return this.$$.ctx[13];
	}

	set canlinkdocuments(canlinkdocuments) {
		this.$$set({ canlinkdocuments });
		flush();
	}
}

customElements.define("form-container", FormContainer);
export default FormContainer;
export const checkIsEditableCalendarTimeline = (startDateProperty, endDateProperty, objectMetadata) => {
  let isEditable = true;
  let fields = objectMetadata.properties.fields;
  for (const field of fields) {
    if (startDateProperty && startDateProperty !== "") {
      if (field.name === startDateProperty) {
        if (field.editable === false) {
          isEditable = false;
        }
      }
    }
    if (endDateProperty && endDateProperty !== "") {
      if (field.name === endDateProperty) {
        if (field.editable === false) {
          isEditable = false;
        }
      }
    }
  }
  return isEditable;
};

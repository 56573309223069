import bc from "../bcShim.js";
import {getViewRights} from "./dataviewUtils.js";
import {getThemeTreeViewGuids} from "./generalUtils.js";
export const setupRelationships = async (metadata) => {
  console.log("setupRelationships", metadata);
  let relationships = [];
  if (metadata && metadata.relations && metadata.relations.length > 0) {
    metadata.relations.forEach((relation, index) => {
      if (relation && relation.viewId && relation.relationShip) {
        relationships.push(relation);
      }
    });
  }
  let hasRelationships = false;
  let relationshipViewRights = [];
  let relationshipErrors = [];
  let limitRelationshipsToThemetree = bc.parameterService.get("LimitRelationshipsToThemeTree");
  if (limitRelationshipsToThemetree && limitRelationshipsToThemetree === "YES") {
    relationships = limitRelationShipsToThemeTree(relationships);
  }
  if (relationships.length > 0) {
    console.log("Relationships to show", relationships);
    hasRelationships = true;
    const {
      viewRights,
      relationships: finalRelationShips,
      errors
    } = await getAllRelationshipRights(relationships);
    relationshipViewRights = [...viewRights];
    relationships = [...finalRelationShips];
    relationshipErrors = [...errors];
    let tempRels = [];
    let tempRights = [];
    relationships.forEach((relation, index) => {
      if (relationshipViewRights[index].canRead) {
        tempRels.push(relation);
        tempRights.push(relationshipViewRights[index]);
      }
    });
    relationships = [...tempRels];
    relationshipViewRights = [...tempRights];
  }
  return {
    hasRelationships,
    relationships,
    relationshipViewRights,
    relationshipErrors
  };
};
const limitRelationShipsToThemeTree = (relationShips) => {
  let themeTreeViewGuids = getThemeTreeViewGuids();
  if (themeTreeViewGuids) {
    let filtredRelationShips = relationShips.filter((r) => themeTreeViewGuids.includes(r.viewId));
    relationShips = [...filtredRelationShips];
  }
  return relationShips;
};
const getAllRelationshipRights = async (relationShips) => {
  let rights = await bc.metadataManager.fetchRights();
  let relationshipViewRights = [];
  let finalRelationShips = [];
  let errors = [];
  for (let i = 0; i < relationShips.length; i++) {
    try {
      if (rights && rights[relationShips[i].viewId]) {
        relationshipViewRights.push(rights[relationShips[i].viewId]);
        finalRelationShips.push(relationShips[i]);
      } else {
        let relationshipMetadata;
        try {
          relationshipMetadata = await bc.parameterService.getMetadata(relationShips[i].viewId);
        } catch (error) {
          console.error("ERROR GETTING METADATA FOR VIEW ", relationShips[i].viewId, relationShips[i], error);
          errors.push(relationShips[i]);
        }
        if (relationshipMetadata) {
          let viewRights = await getViewRights({
            isBackend: false,
            objectMetadata: relationshipMetadata,
            sourceviewguid: null
          });
          relationshipViewRights.push(viewRights);
          finalRelationShips.push(relationShips[i]);
          if (rights) {
            rights[relationShips[i].viewId] = viewRights;
            bc.parameterService.set("RIGHTS", rights);
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
  return {viewRights: relationshipViewRights, relationships: finalRelationShips, errors};
};
export const cardinalitiesConstants = {
  zeroToManyOptional: "2c9c5feb-e527-4b69-b4de-0ec299ee853f",
  zeroTo1Optional: "05fa8a8b-7c74-434d-83b8-e90631cbb6ec",
  oneToManyRequired: "33ada57a-3a7a-49f7-9354-b7da1d3e761f",
  oneToOneRequired: "143b36c0-0b1a-4119-a054-10cab7379720"
};
export const checkCardinality = (relationShip, direction, amount) => {
  if (direction === "FROM") {
    if (relationShip.RA_TO_CARDINALITY === cardinalitiesConstants.zeroTo1Optional || relationShip.RA_TO_CARDINALITY === cardinalitiesConstants.oneToOneRequired) {
      return amount === 0;
    } else {
      return true;
    }
  }
  if (direction === "TO") {
    if (relationShip.RA_FROM_CARDINALITY === cardinalitiesConstants.zeroTo1Optional || relationShip.RA_FROM_CARDINALITY === cardinalitiesConstants.oneToOneRequired) {
      return amount === 0;
    } else {
      return true;
    }
  }
  return false;
};
export const countVisibleRelationships = (relationshipCount, keys) => {
  let tempValue = 0;
  for (let key of keys) {
    tempValue = tempValue + +relationshipCount[key];
  }
  return tempValue;
};
export const updateAllRelationshipCounts = async (relationships, entityguid) => {
  let relationshipCount = {};
  try {
    if (entityguid !== "" && relationships) {
      let request = await bc.metadataManager.fetchAllRelationshipCounts(entityguid);
      let tempRepartition = [];
      if (request) {
        let value = request["value"];
        for (let i = 0; i < value.length; i++) {
          if (value[i].RL_FROM === entityguid) {
            let repartition = tempRepartition.find((tr) => tr.type === value[i].RL_TYPE);
            if (repartition) {
              repartition.count = repartition.count + 1;
            } else {
              let rel = relationships.filter((r) => r.rlType === value[i].RL_TYPE && r.direction === "FROM");
              tempRepartition = [...tempRepartition, {
                type: value[i].RL_TYPE,
                count: 1,
                direction: "FROM",
                relationShip: rel
              }];
            }
          }
        }
        for (let i = 0; i < value.length; i++) {
          if (value[i].RL_TO === entityguid) {
            let repartition = tempRepartition.find((tr) => tr.type === value[i].RL_TYPE);
            if (repartition) {
              repartition.count = repartition.count + 1;
            } else {
              let rel = relationships.filter((r) => r.rlType === value[i].RL_TYPE && r.direction === "TO");
              tempRepartition = [...tempRepartition, {
                type: value[i].RL_TYPE,
                count: 1,
                direction: "TO",
                relationShip: rel
              }];
            }
          }
        }
      }
      for (let i = 0; i < relationships.length; i++) {
        let hasRel = null;
        let tempRep = null;
        for (let j = 0; j < tempRepartition.length; j++) {
          let repartition = tempRepartition[j];
          hasRel = repartition.relationShip.find((rl) => rl.relationShip.TY_ID === relationships[i].relationShip.TY_ID);
          if (hasRel) {
            tempRep = tempRepartition[j];
            break;
          }
        }
        if (tempRep) {
          relationshipCount[relationships[i].relationShip.TY_ID] = tempRep.count;
        } else {
          relationshipCount[relationships[i].relationShip.TY_ID] = 0;
        }
      }
    }
  } catch (error) {
    console.error(error);
  }
  return relationshipCount;
};

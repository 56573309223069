export function numberWithSpaces(value, groupsymbol) {
  if (!groupsymbol) {
    groupsymbol = " ";
  }
  if (value.toString().length >= 5 && groupsymbol !== "") {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, groupsymbol);
  } else {
    return value.toString();
  }
}
export function numberWithSpacesFloat(value, groupsymbol, precision) {
  if (!groupsymbol) {
    groupsymbol = " ";
  }
  if (value.toString().length >= 5) {
    let parts = value.toString().split(".");
    if (groupsymbol !== "") {
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, groupsymbol);
    }
    return parts.join(".");
  } else {
    return value.toString();
  }
}
export function formatNumber(value, groupsymbol, precision) {
  if (value) {
    if (value.toString().indexOf(".") !== -1 || precision && precision > 0) {
      value = numberWithSpacesFloat(value, groupsymbol, precision);
    } else {
      value = numberWithSpaces(value, groupsymbol);
    }
  }
  return value;
}
export let transformForDB = (value) => {
  if (value !== "") {
    let newValue = value.replace(/\s+/g, "_");
    newValue = replaceUmlaute(newValue).toUpperCase();
    newValue = newValue.replace(/[^A-Z0-9]+/ig, "_");
    if (!isNaN(Number.parseInt(newValue.charAt(0)))) {
      newValue = "N_" + newValue;
    }
    return newValue;
  } else {
    return "";
  }
};
export const replaceUmlaute = (str) => {
  return str.replace(/Â|À|Å|Ã/g, "A").replace(/â|à|å|ã/g, "a").replace(/Ä/g, "AE").replace(/ä/g, "ae").replace(/Ç/g, "C").replace(/ç/g, "c").replace(/É|Ê|È|Ë/g, "E").replace(/é|ê|è|ë/g, "e").replace(/Ó|Ô|Ò|Õ|Ø/g, "O").replace(/ó|ô|ò|õ/g, "o").replace(/Ö/g, "OE").replace(/ö/g, "oe").replace(/Š/g, "S").replace(/š/g, "s").replace(/ß/g, "ss").replace(/Ú|Û|Ù/g, "U").replace(/ú|û|ù/g, "u").replace(/Ü/g, "UE").replace(/ü/g, "ue").replace(/Ý|Ÿ/g, "Y").replace(/ý|ÿ/g, "y").replace(/Ž/g, "Z").replace(/ž/, "z");
};
export const prepareUppercaseDBString = (toconvert) => {
  let newName = toconvert.replace(/\s+/g, "_");
  newName = newName.replace(/[&\/\\#, +()$~%.'":*?<>{}^]/g, "_");
  newName = replaceUmlaute(newName).toUpperCase();
  return newName;
};

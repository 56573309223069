/* src\components\Map\MapTools\ZoomIn\ZoomIn.svelte generated by Svelte v3.57.0 */
import {
	SvelteElement,
	add_location,
	append_dev,
	attr_dev,
	attribute_to_object,
	detach_dev,
	dispatch_dev,
	element,
	flush,
	init,
	insert_dev,
	listen_dev,
	noop,
	safe_not_equal,
	validate_slots
} from "../../../../../web_modules/svelte/internal.js";

import '../../../../bcShim.js';
import { v4 as uuidv4 } from "../../../../../web_modules/uuid.js";
import { get_current_component } from "../../../../../web_modules/svelte/internal.js";
import { onMount, tick } from "../../../../../web_modules/svelte.js";

import {
	attachNormalize,
	dispatchMountedEvent
} from "../../../../utilities/componentUtils.js";

import { getComponentText } from "../../../../utilities/generalUtils.js";
const file = "src\\components\\Map\\MapTools\\ZoomIn\\ZoomIn.svelte";

// (2091:4) {#if ready}
function create_if_block(ctx) {
	let div;
	let i;
	let div_title_value;
	let mounted;
	let dispose;

	const block = {
		c: function create() {
			div = element("div");
			i = element("i");
			attr_dev(i, "class", "far fa-search-plus fa-lg !h-10 !w-10 text-white hover:text-orange-500 flex items-center justify-center rounded");
			add_location(i, file, 2097, 12, 38891);
			attr_dev(div, "class", "!h-10 !w-10 relative cursor-pointer");
			attr_dev(div, "title", div_title_value = /*componentText*/ ctx[1].componentText);
			add_location(div, file, 2091, 8, 38682);
		},
		m: function mount(target, anchor) {
			insert_dev(target, div, anchor);
			append_dev(div, i);

			if (!mounted) {
				dispose = listen_dev(div, "click", /*click_handler*/ ctx[4], false, false, false, false);
				mounted = true;
			}
		},
		p: noop,
		d: function destroy(detaching) {
			if (detaching) detach_dev(div);
			mounted = false;
			dispose();
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block.name,
		type: "if",
		source: "(2091:4) {#if ready}",
		ctx
	});

	return block;
}

function create_fragment(ctx) {
	let div;
	let if_block = /*ready*/ ctx[0] && create_if_block(ctx);

	const block = {
		c: function create() {
			div = element("div");
			if (if_block) if_block.c();
			this.c = noop;
			add_location(div, file, 2089, 0, 38650);
		},
		l: function claim(nodes) {
			throw new Error("options.hydrate only works if the component was compiled with the `hydratable: true` option");
		},
		m: function mount(target, anchor) {
			insert_dev(target, div, anchor);
			if (if_block) if_block.m(div, null);
		},
		p: function update(ctx, [dirty]) {
			if (/*ready*/ ctx[0]) {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block(ctx);
					if_block.c();
					if_block.m(div, null);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}
		},
		i: noop,
		o: noop,
		d: function destroy(detaching) {
			if (detaching) detach_dev(div);
			if (if_block) if_block.d();
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_fragment.name,
		type: "component",
		source: "",
		ctx
	});

	return block;
}

function instance($$self, $$props, $$invalidate) {
	let { $$slots: slots = {}, $$scope } = $$props;
	validate_slots('map-tool-zoom-in', slots, []);
	let ready = false;
	const generalText = getComponentText('general');
	const componentText = getComponentText('zoomIn');
	const component = get_current_component();
	let mounted = false;

	// Component variables
	//================================================================
	let map;

	let componentId = uuidv4();

	// HTML Mount & Component initialization
	//================================================================
	onMount(() => {
		attachNormalize(component);

		tick().then(() => {
			dispatchMountedEvent(component);
			mounted = true;
		});

		return () => {
			
		};
	});

	const initComponent = newMap => {
		map = newMap;
		$$invalidate(0, ready = true);
	};

	// Component functions
	//================================================================
	const zoom = () => {
		map.getView().animate({
			zoom: map.getView().getZoom() + 1,
			duration: 250
		});
	};

	const cleanup = () => {
		
	};

	const writable_props = [];

	

	const click_handler = e => {
		zoom();
	};

	$$self.$capture_state = () => ({
		uuidv4,
		get_current_component,
		onMount,
		tick,
		attachNormalize,
		dispatchMountedEvent,
		getComponentText,
		ready,
		generalText,
		componentText,
		component,
		mounted,
		map,
		componentId,
		initComponent,
		zoom,
		cleanup
	});

	$$self.$inject_state = $$props => {
		if ('ready' in $$props) $$invalidate(0, ready = $$props.ready);
		if ('mounted' in $$props) mounted = $$props.mounted;
		if ('map' in $$props) map = $$props.map;
		if ('componentId' in $$props) componentId = $$props.componentId;
	};

	if ($$props && "$$inject" in $$props) {
		$$self.$inject_state($$props.$$inject);
	}

	return [ready, componentText, zoom, initComponent, click_handler];
}

class ZoomIn extends SvelteElement {
	constructor(options) {
		super();
		const style = document.createElement('style');
		style.textContent = `100%{left:0;right:0}100%{left:0;right:0}100%{opacity:1}100%{opacity:1}.fa-lg{font-size:1.25em;line-height:0.05em;vertical-align:-0.075em}45%{-webkit-transform:scale(var(--fa-beat-scale, 1.25));transform:scale(var(--fa-beat-scale, 1.25))}45%{-webkit-transform:scale(var(--fa-beat-scale, 1.25));transform:scale(var(--fa-beat-scale, 1.25))}50%{opacity:1;-webkit-transform:scale(var(--fa-beat-fade-scale, 1.125));transform:scale(var(--fa-beat-fade-scale, 1.125))}50%{opacity:1;-webkit-transform:scale(var(--fa-beat-fade-scale, 1.125));transform:scale(var(--fa-beat-fade-scale, 1.125))}100%{-webkit-transform:rotate(360deg);transform:rotate(360deg)}100%{-webkit-transform:rotate(360deg);transform:rotate(360deg)}.fa-search-plus::before{content:""}.far{font-weight:400 !important}.far{font-family:"Font Awesome 6 Pro" !important;font-style:normal !important}100%{left:0;right:0}.rounded{border-radius:0.25rem}.cursor-pointer{cursor:pointer}.flex{display:-webkit-box;display:-ms-flexbox;display:-webkit-flex;display:flex}.items-center{-webkit-box-align:center;-ms-flex-align:center;-webkit-align-items:center;align-items:center}.justify-center{-webkit-box-pack:center;-ms-flex-pack:center;-webkit-justify-content:center;justify-content:center}.\\!h-10{height:2.5rem !important}.relative{position:relative}.text-white{--tw-text-opacity:1;color:rgba(255, 255, 255, var(--tw-text-opacity))}.hover\\:text-orange-500:hover{--tw-text-opacity:1;color:rgba(249, 115, 22, var(--tw-text-opacity))}.\\!w-10{width:2.5rem !important}`;
		this.shadowRoot.appendChild(style);

		init(
			this,
			{
				target: this.shadowRoot,
				props: attribute_to_object(this.attributes),
				customElement: true
			},
			instance,
			create_fragment,
			safe_not_equal,
			{ initComponent: 3 },
			null
		);

		if (options) {
			if (options.target) {
				insert_dev(options.target, this, options.anchor);
			}

			if (options.props) {
				this.$set(options.props);
				flush();
			}
		}
	}

	static get observedAttributes() {
		return ["initComponent"];
	}

	get initComponent() {
		return this.$$.ctx[3];
	}

	set initComponent(value) {
		throw new Error("<map-tool-zoom-in>: Cannot set read-only property 'initComponent'");
	}
}

customElements.define("map-tool-zoom-in", ZoomIn);
export default ZoomIn;
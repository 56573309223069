/* src\components\Measurement\MeasurementProgramChooser\MeasurementProgramChooser.svelte generated by Svelte v3.57.0 */
import {
	SvelteElement,
	add_location,
	append_dev,
	attr_dev,
	attribute_to_object,
	binding_callbacks,
	component_subscribe,
	destroy_each,
	detach_dev,
	dispatch_dev,
	element,
	empty,
	flush,
	globals,
	init as init_1,
	insert_dev,
	listen_dev,
	noop,
	prop_dev,
	safe_not_equal,
	set_data_dev,
	text,
	validate_each_argument,
	validate_slots,
	validate_store
} from "../../../../web_modules/svelte/internal.js";

const { console: console_1 } = globals;
import bc from '../../../bcShim.js';
import { v4 as uuidv4 } from '../../../../web_modules/uuid.js';
import { onMount, tick } from '../../../../web_modules/svelte.js';
import "../../../utilities/visibilityResizeUtils.js";

import {
	attachNormalize,
	dispatchMountedEvent,
	handleComponentInitAttribute,
	handleComponentProps,
	handleComponentShowAttribute
} from "../../../utilities/componentUtils.js";

import { getViewRights } from "../../../utilities/dataviewUtils.js";
import { get_current_component } from "../../../../web_modules/svelte/internal.js";
import { applicationReady } from "../../../utilities/store.js";
import "../../../constants.js";
import { createEvent } from "../../../utilities/generalUtils.js";
const file = "src\\components\\Measurement\\MeasurementProgramChooser\\MeasurementProgramChooser.svelte";

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[22] = list[i];
	child_ctx[24] = i;
	return child_ctx;
}

// (2101:4) {#if ready}
function create_if_block(ctx) {
	let if_block_anchor;

	function select_block_type(ctx, dirty) {
		if (/*hasMeasurements*/ ctx[2] && /*measurementProgramms*/ ctx[3].length > 0) return create_if_block_1;
		return create_else_block;
	}

	let current_block_type = select_block_type(ctx, -1);
	let if_block = current_block_type(ctx);

	const block = {
		c: function create() {
			if_block.c();
			if_block_anchor = empty();
		},
		m: function mount(target, anchor) {
			if_block.m(target, anchor);
			insert_dev(target, if_block_anchor, anchor);
		},
		p: function update(ctx, dirty) {
			if (current_block_type === (current_block_type = select_block_type(ctx, dirty)) && if_block) {
				if_block.p(ctx, dirty);
			} else {
				if_block.d(1);
				if_block = current_block_type(ctx);

				if (if_block) {
					if_block.c();
					if_block.m(if_block_anchor.parentNode, if_block_anchor);
				}
			}
		},
		d: function destroy(detaching) {
			if_block.d(detaching);
			if (detaching) detach_dev(if_block_anchor);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block.name,
		type: "if",
		source: "(2101:4) {#if ready}",
		ctx
	});

	return block;
}

// (2110:8) {:else}
function create_else_block(ctx) {
	let p;

	const block = {
		c: function create() {
			p = element("p");
			p.textContent = "Keine Messprogramme gefunden.";
			add_location(p, file, 2110, 12, 41252);
		},
		m: function mount(target, anchor) {
			insert_dev(target, p, anchor);
		},
		p: noop,
		d: function destroy(detaching) {
			if (detaching) detach_dev(p);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_else_block.name,
		type: "else",
		source: "(2110:8) {:else}",
		ctx
	});

	return block;
}

// (2103:8) {#if hasMeasurements && measurementProgramms.length > 0}
function create_if_block_1(ctx) {
	let select;
	let option;
	let mounted;
	let dispose;
	let each_value = /*measurementProgramms*/ ctx[3];
	validate_each_argument(each_value);
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	const block = {
		c: function create() {
			select = element("select");
			option = element("option");
			option.textContent = "-- Select Measurement-Program --";

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			option.__value = "-- Select Measurement-Program --";
			option.value = option.__value;
			add_location(option, file, 2104, 16, 40978);
			attr_dev(select, "class", "border border-solid border-gray-300 rounded h-10");
			add_location(select, file, 2103, 12, 40868);
		},
		m: function mount(target, anchor) {
			insert_dev(target, select, anchor);
			append_dev(select, option);

			for (let i = 0; i < each_blocks.length; i += 1) {
				if (each_blocks[i]) {
					each_blocks[i].m(select, null);
				}
			}

			if (!mounted) {
				dispose = listen_dev(select, "change", /*selectProgramm*/ ctx[4], false, false, false, false);
				mounted = true;
			}
		},
		p: function update(ctx, dirty) {
			if (dirty & /*measurementProgramms*/ 8) {
				each_value = /*measurementProgramms*/ ctx[3];
				validate_each_argument(each_value);
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(select, null);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value.length;
			}
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(select);
			destroy_each(each_blocks, detaching);
			mounted = false;
			dispose();
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block_1.name,
		type: "if",
		source: "(2103:8) {#if hasMeasurements && measurementProgramms.length > 0}",
		ctx
	});

	return block;
}

// (2106:16) {#each measurementProgramms as program, index}
function create_each_block(ctx) {
	let option;
	let t_value = /*program*/ ctx[22].TY_LONGNAME + "";
	let t;
	let option_value_value;

	const block = {
		c: function create() {
			option = element("option");
			t = text(t_value);
			option.__value = option_value_value = /*program*/ ctx[22].TY_ID;
			option.value = option.__value;
			add_location(option, file, 2106, 20, 41113);
		},
		m: function mount(target, anchor) {
			insert_dev(target, option, anchor);
			append_dev(option, t);
		},
		p: function update(ctx, dirty) {
			if (dirty & /*measurementProgramms*/ 8 && t_value !== (t_value = /*program*/ ctx[22].TY_LONGNAME + "")) set_data_dev(t, t_value);

			if (dirty & /*measurementProgramms*/ 8 && option_value_value !== (option_value_value = /*program*/ ctx[22].TY_ID)) {
				prop_dev(option, "__value", option_value_value);
				option.value = option.__value;
			}
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(option);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_each_block.name,
		type: "each",
		source: "(2106:16) {#each measurementProgramms as program, index}",
		ctx
	});

	return block;
}

function create_fragment(ctx) {
	let div;
	let if_block = /*ready*/ ctx[1] && create_if_block(ctx);

	const block = {
		c: function create() {
			div = element("div");
			if (if_block) if_block.c();
			this.c = noop;
			attr_dev(div, "class", "w-full h-full bg-white");
			add_location(div, file, 2099, 0, 40711);
		},
		l: function claim(nodes) {
			throw new Error("options.hydrate only works if the component was compiled with the `hydratable: true` option");
		},
		m: function mount(target, anchor) {
			insert_dev(target, div, anchor);
			if (if_block) if_block.m(div, null);
			/*div_binding*/ ctx[12](div);
		},
		p: function update(ctx, [dirty]) {
			if (/*ready*/ ctx[1]) {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block(ctx);
					if_block.c();
					if_block.m(div, null);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}
		},
		i: noop,
		o: noop,
		d: function destroy(detaching) {
			if (detaching) detach_dev(div);
			if (if_block) if_block.d();
			/*div_binding*/ ctx[12](null);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_fragment.name,
		type: "component",
		source: "",
		ctx
	});

	return block;
}

function instance($$self, $$props, $$invalidate) {
	let $applicationReady;
	validate_store(applicationReady, 'applicationReady');
	component_subscribe($$self, applicationReady, $$value => $$invalidate(11, $applicationReady = $$value));
	let { $$slots: slots = {}, $$scope } = $$props;
	validate_slots('measurement-programm-chooser', slots, []);
	let { show = 'true' } = $$props;
	let { init = 'true' } = $$props;
	let { entityguid = '' } = $$props;
	let { dataviewguid = '' } = $$props;

	// Base Variables
	//===========================================================
	let container;

	let firstInitialized = false;
	let subscribeGuid = uuidv4();
	let ready = false;
	const generalText = getComponentText('general');
	let component = get_current_component();
	let mounted = false;
	let canReload = true;

	// Component Variables
	//===========================================================
	let objectMetadata;

	let viewRights;
	let hasMeasurements = false;
	let measurementProgrammsTypeRelations = [];
	let measurementProgramms = [];

	// HTML Mount & Component initialization
	//================================================================
	onMount(() => {
		attachNormalize(component);

		tick().then(() => {
			dispatchMountedEvent(component);
			$$invalidate(10, mounted = true);
		});

		return () => {
			bc.messageService.unsubscribeComponent(subscribeGuid);
		};
	});

	const initComponent = async guid => {
		$$invalidate(9, firstInitialized = true);

		if (guid !== '') {
			// Get Metadata from view
			objectMetadata = await bc.parameterService.getMetadata(dataviewguid);

			viewRights = await getViewRights({
				isBackend: false,
				objectMetadata,
				sourceviewguid: null
			});

			$$invalidate(2, hasMeasurements = await bc.metadataManager.checkMeasurementExistence(entityguid));

			if (hasMeasurements) {
				let measurementProgrammsTypeRelationshipPromise = await bc.metadataManager.fetchMeasurementProgrammesForEntityType(objectMetadata.entityType);

				if (measurementProgrammsTypeRelationshipPromise && measurementProgrammsTypeRelationshipPromise.value) {
					measurementProgrammsTypeRelations = measurementProgrammsTypeRelationshipPromise.value;
				} else {
					measurementProgrammsTypeRelations = [];
				}

				console.log('measurementProgrammsTypeRelations', measurementProgrammsTypeRelations);
				let measurementProgramTypePromise = [];

				for (let i = 0; i < measurementProgrammsTypeRelations.length; i++) {
					measurementProgramTypePromise.push(bc.metadataManager.getUgdmTypeById(measurementProgrammsTypeRelations[i].TR_FROM));
				}

				$$invalidate(3, measurementProgramms = await Promise.all(measurementProgramTypePromise));
				console.log('measuremntProgramms', measurementProgramms);
			}

			$$invalidate(1, ready = true);
		}
	};

	const applyProps = afterInit => {
		let shouldInit = afterInit && firstInitialized || !afterInit && !firstInitialized;

		if (shouldInit) {
			handleComponentProps(
				{
					ready: $applicationReady,
					componentMounted: mounted,
					init,
					firstInitialized,
					canReload,
					handler: initComponent
				},
				entityguid,
				dataviewguid
			);
		}
	};

	// Component functions
	//================================================================
	const selectProgramm = e => {
		let programmId = e.target.value;
		console.log('programmId', programmId);

		let event = createEvent("select", programmId, {
			bubbles: false,
			cancelable: true,
			composed: false
		});

		component.dispatchEvent(event);
	};

	const writable_props = ['show', 'init', 'entityguid', 'dataviewguid'];

	

	function div_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			container = $$value;
			$$invalidate(0, container);
		});
	}

	$$self.$$set = $$props => {
		if ('show' in $$props) $$invalidate(5, show = $$props.show);
		if ('init' in $$props) $$invalidate(6, init = $$props.init);
		if ('entityguid' in $$props) $$invalidate(7, entityguid = $$props.entityguid);
		if ('dataviewguid' in $$props) $$invalidate(8, dataviewguid = $$props.dataviewguid);
	};

	$$self.$capture_state = () => ({
		bc,
		uuidv4,
		onMount,
		tick,
		attachNormalize,
		dispatchMountedEvent,
		handleComponentInitAttribute,
		handleComponentProps,
		handleComponentShowAttribute,
		getViewRights,
		get_current_component,
		applicationReady,
		createEvent,
		show,
		init,
		entityguid,
		dataviewguid,
		container,
		firstInitialized,
		subscribeGuid,
		ready,
		generalText,
		component,
		mounted,
		canReload,
		objectMetadata,
		viewRights,
		hasMeasurements,
		measurementProgrammsTypeRelations,
		measurementProgramms,
		initComponent,
		applyProps,
		selectProgramm,
		$applicationReady
	});

	$$self.$inject_state = $$props => {
		if ('show' in $$props) $$invalidate(5, show = $$props.show);
		if ('init' in $$props) $$invalidate(6, init = $$props.init);
		if ('entityguid' in $$props) $$invalidate(7, entityguid = $$props.entityguid);
		if ('dataviewguid' in $$props) $$invalidate(8, dataviewguid = $$props.dataviewguid);
		if ('container' in $$props) $$invalidate(0, container = $$props.container);
		if ('firstInitialized' in $$props) $$invalidate(9, firstInitialized = $$props.firstInitialized);
		if ('subscribeGuid' in $$props) subscribeGuid = $$props.subscribeGuid;
		if ('ready' in $$props) $$invalidate(1, ready = $$props.ready);
		if ('component' in $$props) $$invalidate(18, component = $$props.component);
		if ('mounted' in $$props) $$invalidate(10, mounted = $$props.mounted);
		if ('canReload' in $$props) canReload = $$props.canReload;
		if ('objectMetadata' in $$props) objectMetadata = $$props.objectMetadata;
		if ('viewRights' in $$props) viewRights = $$props.viewRights;
		if ('hasMeasurements' in $$props) $$invalidate(2, hasMeasurements = $$props.hasMeasurements);
		if ('measurementProgrammsTypeRelations' in $$props) measurementProgrammsTypeRelations = $$props.measurementProgrammsTypeRelations;
		if ('measurementProgramms' in $$props) $$invalidate(3, measurementProgramms = $$props.measurementProgramms);
	};

	if ($$props && "$$inject" in $$props) {
		$$self.$inject_state($$props.$$inject);
	}

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*show*/ 32) {
			$: if (show) {
				handleComponentShowAttribute(show, component);
			}
		}

		if ($$self.$$.dirty & /*init, firstInitialized, mounted, $applicationReady*/ 3648) {
			/**
 * Handle the component init attribute, for the case that the component has not been initialized yet.
 */
			$: handleComponentInitAttribute(init, firstInitialized, mounted, $applicationReady, applyProps);
		}

		if ($$self.$$.dirty & /*entityguid, dataviewguid*/ 384) {
			/**
 * Handles any component attribute changes after the component is initialized.
 */
			$: (entityguid, dataviewguid, applyProps(true));
		}
	};

	return [
		container,
		ready,
		hasMeasurements,
		measurementProgramms,
		selectProgramm,
		show,
		init,
		entityguid,
		dataviewguid,
		firstInitialized,
		mounted,
		$applicationReady,
		div_binding
	];
}

class MeasurementProgramChooser extends SvelteElement {
	constructor(options) {
		super();
		const style = document.createElement('style');
		style.textContent = `100%{left:0;right:0}100%{left:0;right:0}100%{opacity:1}100%{opacity:1}45%{-webkit-transform:scale(var(--fa-beat-scale, 1.25));transform:scale(var(--fa-beat-scale, 1.25))}45%{-webkit-transform:scale(var(--fa-beat-scale, 1.25));transform:scale(var(--fa-beat-scale, 1.25))}50%{opacity:1;-webkit-transform:scale(var(--fa-beat-fade-scale, 1.125));transform:scale(var(--fa-beat-fade-scale, 1.125))}50%{opacity:1;-webkit-transform:scale(var(--fa-beat-fade-scale, 1.125));transform:scale(var(--fa-beat-fade-scale, 1.125))}100%{-webkit-transform:rotate(360deg);transform:rotate(360deg)}100%{-webkit-transform:rotate(360deg);transform:rotate(360deg)}.bg-white{--tw-bg-opacity:1;background-color:rgba(255, 255, 255, var(--tw-bg-opacity))}.border-gray-300{--tw-border-opacity:1;border-color:rgba(209, 213, 219, var(--tw-border-opacity))}.rounded{border-radius:0.25rem}.border-solid{border-style:solid}.border{border-width:1px}.h-full{height:100%}.h-10{height:2.5rem}.w-full{width:100%}`;
		this.shadowRoot.appendChild(style);

		init_1(
			this,
			{
				target: this.shadowRoot,
				props: attribute_to_object(this.attributes),
				customElement: true
			},
			instance,
			create_fragment,
			safe_not_equal,
			{
				show: 5,
				init: 6,
				entityguid: 7,
				dataviewguid: 8
			},
			null
		);

		if (options) {
			if (options.target) {
				insert_dev(options.target, this, options.anchor);
			}

			if (options.props) {
				this.$set(options.props);
				flush();
			}
		}
	}

	static get observedAttributes() {
		return ["show", "init", "entityguid", "dataviewguid"];
	}

	get show() {
		return this.$$.ctx[5];
	}

	set show(show) {
		this.$$set({ show });
		flush();
	}

	get init() {
		return this.$$.ctx[6];
	}

	set init(init) {
		this.$$set({ init });
		flush();
	}

	get entityguid() {
		return this.$$.ctx[7];
	}

	set entityguid(entityguid) {
		this.$$set({ entityguid });
		flush();
	}

	get dataviewguid() {
		return this.$$.ctx[8];
	}

	set dataviewguid(dataviewguid) {
		this.$$set({ dataviewguid });
		flush();
	}
}

customElements.define("measurement-programm-chooser", MeasurementProgramChooser);
export default MeasurementProgramChooser;
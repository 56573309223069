import bc from "../bcShim.js";
import {PARAMS} from "../services/parameterService.js";
import {errorLog} from "./consoleUtils.js";
export const isUserDefinedView = (resourceName) => {
  switch (resourceName) {
    case "RELATIONSHIP":
    case "UgdmActions":
    case "UgdmArtifacts":
    case "UgdmAssociations":
    case "UgdmAttachments":
    case "UgdmAttributes":
    case "UgdmDomainRoles":
    case "UgdmDomains":
    case "UgdmEntities":
    case "UgdmEntityDomains":
    case "UgdmEntityLocations":
    case "UgdmEntityLocs":
    case "UgdmEntityMeasurementTypes":
    case "UgdmEntityTypeAttributes":
    case "UgdmEntityTypes":
    case "UgdmEntityUsers":
    case "UgdmEntityViewAttributes":
    case "UgdmEntityViews":
    case "UgdmLocationTypes":
    case "UgdmMeasurements":
    case "UgdmMeasurementTypes":
    case "UgdmReferences":
    case "UgdmRelationshipAssociations":
    case "UgdmRelationships":
    case "UgdmRoles":
    case "UgdmRouteEvents":
    case "UgdmSrs":
    case "UgdmSystems":
    case "UgdmTypeDomains":
    case "UgdmTypeRelationships":
    case "UgdmTypeRoles":
    case "UgdmTypes":
    case "UgdmUnits":
    case "UgdmUserClaims":
    case "UgdmUserLogins":
    case "UgdmUserRoles":
    case "UgdmUsers": {
      return false;
    }
    default: {
      return true;
    }
  }
};
export const metadataHasGeo = (objectMetadata) => {
  return objectMetadata.geometryField && objectMetadata.geometryField !== "";
};
export const getViewRights = async (data) => {
  if (bc.parameterService.appParameters.authenticationRequired === false) {
    return {
      canCreate: true,
      canDelete: true,
      canModify: true,
      canRead: true
    };
  }
  const {objectMetadata, sourceviewguid, isBackend} = data;
  let id = objectMetadata.resourceId;
  let viewRights = {
    canCreate: true,
    canDelete: true,
    canModify: true,
    canRead: true
  };
  if (isBackend) {
    return viewRights;
  } else {
    if (objectMetadata.resourceName === "UgdmTypes" || objectMetadata.resourceName === "UgdmAttributes" || objectMetadata.resourceName === "UgdmDomains" || objectMetadata.resourceName === "UgdmUsers") {
      viewRights = {
        canCreate: true,
        canDelete: true,
        canModify: true,
        canRead: true
      };
      return viewRights;
    }
    if (objectMetadata.resourceName === "UgdmActions") {
      if (isBackend || objectMetadata.resourceId === "8ba03c3e-429c-479b-bfa8-8df102adec48") {
        viewRights = {
          canCreate: true,
          canDelete: true,
          canModify: true,
          canRead: true
        };
        return viewRights;
      } else {
        id = sourceviewguid;
      }
    }
    if (objectMetadata.resourceName === "UgdmMeasurements") {
      id = sourceviewguid;
    }
    if (objectMetadata.resourceName === "UgdmActions") {
      id = sourceviewguid;
    }
    if (objectMetadata.resourceName === "UgdmArtifacts") {
      id = sourceviewguid;
    }
    let rights = bc.parameterService.get("RIGHTS");
    if (rights && rights[id]) {
      viewRights = rights[id];
    } else {
      let userRoles = bc.parameterService.get(PARAMS.user.roleIds);
      if (userRoles) {
        viewRights = await bc.metadataManager.fetchViewRights(userRoles, id);
        if (rights) {
          rights[id] = viewRights;
        }
        bc.parameterService.set("RIGHTS", rights);
      } else {
        errorLog("VIEWRIGHTS", "USER has no assigned ROLE");
        return viewRights = {
          canCreate: false,
          canDelete: false,
          canModify: false,
          canRead: false
        };
      }
    }
    return viewRights;
  }
};

/* src\components\Portal\PortalContent.svelte generated by Svelte v3.57.0 */
import {
	SvelteElement,
	add_location,
	attribute_to_object,
	binding_callbacks,
	component_subscribe,
	compute_slots,
	detach_dev,
	dispatch_dev,
	element,
	flush,
	get_custom_elements_slots,
	globals,
	init as init_1,
	insert_dev,
	noop,
	safe_not_equal,
	validate_slots,
	validate_store
} from "../../../web_modules/svelte/internal.js";

const { console: console_1 } = globals;
import bc from '../../bcShim.js';
import { v4 as uuidv4 } from '../../../web_modules/uuid.js';
import { onMount, tick } from '../../../web_modules/svelte.js';

import {
	attachNormalize,
	componentInitAndAttributeHandler,
	dispatchMountedEvent,
	handleComponentShowAttribute
} from "../../utilities/componentUtils.js";

import { get_current_component } from "../../../web_modules/svelte/internal.js";
import { applicationReady } from "../../utilities/store.js";
import { getComponentText } from "../../utilities/generalUtils.js";
const file = "src\\components\\Portal\\PortalContent.svelte";

function create_fragment(ctx) {
	let slot;

	const block = {
		c: function create() {
			slot = element("slot");
			this.c = noop;
			add_location(slot, file, 2059, 0, 38790);
		},
		l: function claim(nodes) {
			throw new Error("options.hydrate only works if the component was compiled with the `hydratable: true` option");
		},
		m: function mount(target, anchor) {
			insert_dev(target, slot, anchor);
			/*slot_binding*/ ctx[12](slot);
		},
		p: noop,
		i: noop,
		o: noop,
		d: function destroy(detaching) {
			if (detaching) detach_dev(slot);
			/*slot_binding*/ ctx[12](null);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_fragment.name,
		type: "component",
		source: "",
		ctx
	});

	return block;
}

function instance($$self, $$props, $$invalidate) {
	let $applicationReady;
	validate_store(applicationReady, 'applicationReady');
	component_subscribe($$self, applicationReady, $$value => $$invalidate(11, $applicationReady = $$value));
	let { $$slots: slots = {}, $$scope } = $$props;
	validate_slots('portal-content', slots, []);
	const $$slots = compute_slots(slots);
	let { show = 'true' } = $$props;
	let { init = 'true' } = $$props;
	let { portal = 'true' } = $$props;
	let { guid = '' } = $$props;
	let { size = '' } = $$props;

	// Base variables
	//================================================================
	let component = get_current_component();

	let firstInitialized = false;
	let subscribeGuid = uuidv4();
	let ready = false;
	const generalText = getComponentText('general');
	let mounted = false;
	let canReload = false;

	// Component Variables
	let slotContainer;

	let send = false;
	let slotChanged = false;
	let content;

	// HTML Mount & Component initialization
	//================================================================
	onMount(() => {
		attachNormalize(component);

		tick().then(() => {
			dispatchMountedEvent(component);
			$$invalidate(7, mounted = true);
		});

		return () => {
			bc.messageService.unsubscribeComponent(subscribeGuid);
			bc.messageService.publish('HIDE_PORTAL', guid);
		};
	});

	const initComponent = () => {
		$$invalidate(6, firstInitialized = true);
		ready = true;
	};

	const writable_props = ['show', 'init', 'portal', 'guid', 'size'];

	

	function slot_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			slotContainer = $$value;
			$$invalidate(0, slotContainer);
		});
	}

	$$self.$$set = $$props => {
		if ('show' in $$props) $$invalidate(1, show = $$props.show);
		if ('init' in $$props) $$invalidate(2, init = $$props.init);
		if ('portal' in $$props) $$invalidate(3, portal = $$props.portal);
		if ('guid' in $$props) $$invalidate(4, guid = $$props.guid);
		if ('size' in $$props) $$invalidate(5, size = $$props.size);
	};

	$$self.$capture_state = () => ({
		bc,
		uuidv4,
		onMount,
		tick,
		attachNormalize,
		componentInitAndAttributeHandler,
		dispatchMountedEvent,
		handleComponentShowAttribute,
		get_current_component,
		applicationReady,
		getComponentText,
		show,
		init,
		portal,
		guid,
		size,
		component,
		firstInitialized,
		subscribeGuid,
		ready,
		generalText,
		mounted,
		canReload,
		slotContainer,
		send,
		slotChanged,
		content,
		initComponent,
		$applicationReady
	});

	$$self.$inject_state = $$props => {
		if ('show' in $$props) $$invalidate(1, show = $$props.show);
		if ('init' in $$props) $$invalidate(2, init = $$props.init);
		if ('portal' in $$props) $$invalidate(3, portal = $$props.portal);
		if ('guid' in $$props) $$invalidate(4, guid = $$props.guid);
		if ('size' in $$props) $$invalidate(5, size = $$props.size);
		if ('component' in $$props) $$invalidate(14, component = $$props.component);
		if ('firstInitialized' in $$props) $$invalidate(6, firstInitialized = $$props.firstInitialized);
		if ('subscribeGuid' in $$props) subscribeGuid = $$props.subscribeGuid;
		if ('ready' in $$props) ready = $$props.ready;
		if ('mounted' in $$props) $$invalidate(7, mounted = $$props.mounted);
		if ('canReload' in $$props) $$invalidate(17, canReload = $$props.canReload);
		if ('slotContainer' in $$props) $$invalidate(0, slotContainer = $$props.slotContainer);
		if ('send' in $$props) $$invalidate(8, send = $$props.send);
		if ('slotChanged' in $$props) $$invalidate(9, slotChanged = $$props.slotChanged);
		if ('content' in $$props) $$invalidate(10, content = $$props.content);
	};

	if ($$props && "$$inject" in $$props) {
		$$self.$inject_state($$props.$$inject);
	}

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*show*/ 2) {
			$: if (show) {
				handleComponentShowAttribute(show, component);
			}
		}

		if ($$self.$$.dirty & /*$applicationReady, mounted, init, firstInitialized*/ 2244) {
			$: if ($applicationReady && mounted && init) {
				componentInitAndAttributeHandler({
					mounted,
					handler: initComponent,
					init,
					firstInitialized,
					canReload
				});
			}
		}

		if ($$self.$$.dirty & /*slotContainer, portal, guid, size*/ 57) {
			$: if (slotContainer) {
				slotContainer.addEventListener('slotchange', e => {
					$$invalidate(9, slotChanged = true);

					if (portal === 'true') {
						let element = slotContainer.assignedElements()[0];

						if (element) {
							console.log('element', element, element.getBoundingClientRect());
							$$invalidate(10, content = element);
							bc.messageService.publish('PORTAL', guid, element, component, size ? size : null);
							$$invalidate(8, send = true);
						}
					}
				});
			}
		}

		if ($$self.$$.dirty & /*portal, slotChanged, send, slotContainer, guid, size*/ 825) {
			$: if (portal === 'true' && slotChanged && send == false) {
				$$invalidate(10, content = slotContainer.assignedElements()[0]);
				bc.messageService.publish('PORTAL', guid, slotContainer.assignedElements()[0], component, size ? size : null);
				$$invalidate(8, send = true);
			}
		}

		if ($$self.$$.dirty & /*portal, guid, send, content*/ 1304) {
			$: if (portal === 'false') {
				bc.messageService.publish('HIDE_PORTAL', guid);

				if (send === true) {
					console.log('content', content);
					component.append(content);
				}

				$$invalidate(8, send = false);
			}
		}
	};

	$: console.log('slots', $$slots);

	return [
		slotContainer,
		show,
		init,
		portal,
		guid,
		size,
		firstInitialized,
		mounted,
		send,
		slotChanged,
		content,
		$applicationReady,
		slot_binding
	];
}

class PortalContent extends SvelteElement {
	constructor(options) {
		super();
		const style = document.createElement('style');
		style.textContent = `100%{left:0;right:0}100%{left:0;right:0}100%{opacity:1}100%{opacity:1}45%{-webkit-transform:scale(var(--fa-beat-scale, 1.25));transform:scale(var(--fa-beat-scale, 1.25))}45%{-webkit-transform:scale(var(--fa-beat-scale, 1.25));transform:scale(var(--fa-beat-scale, 1.25))}50%{opacity:1;-webkit-transform:scale(var(--fa-beat-fade-scale, 1.125));transform:scale(var(--fa-beat-fade-scale, 1.125))}50%{opacity:1;-webkit-transform:scale(var(--fa-beat-fade-scale, 1.125));transform:scale(var(--fa-beat-fade-scale, 1.125))}100%{-webkit-transform:rotate(360deg);transform:rotate(360deg)}100%{-webkit-transform:rotate(360deg);transform:rotate(360deg)}:host{display:block;width:100%;height:100%}:host([hidden]){display:none}`;
		this.shadowRoot.appendChild(style);

		init_1(
			this,
			{
				target: this.shadowRoot,
				props: {
					...attribute_to_object(this.attributes),
					$$slots: get_custom_elements_slots(this)
				},
				customElement: true
			},
			instance,
			create_fragment,
			safe_not_equal,
			{
				show: 1,
				init: 2,
				portal: 3,
				guid: 4,
				size: 5
			},
			null
		);

		if (options) {
			if (options.target) {
				insert_dev(options.target, this, options.anchor);
			}

			if (options.props) {
				this.$set(options.props);
				flush();
			}
		}
	}

	static get observedAttributes() {
		return ["show", "init", "portal", "guid", "size"];
	}

	get show() {
		return this.$$.ctx[1];
	}

	set show(show) {
		this.$$set({ show });
		flush();
	}

	get init() {
		return this.$$.ctx[2];
	}

	set init(init) {
		this.$$set({ init });
		flush();
	}

	get portal() {
		return this.$$.ctx[3];
	}

	set portal(portal) {
		this.$$set({ portal });
		flush();
	}

	get guid() {
		return this.$$.ctx[4];
	}

	set guid(guid) {
		this.$$set({ guid });
		flush();
	}

	get size() {
		return this.$$.ctx[5];
	}

	set size(size) {
		this.$$set({ size });
		flush();
	}
}

customElements.define("portal-content", PortalContent);
export default PortalContent;
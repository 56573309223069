/* src\components\Portal\Portal.svelte generated by Svelte v3.57.0 */
import {
	SvelteElement,
	add_location,
	attr_dev,
	attribute_to_object,
	binding_callbacks,
	component_subscribe,
	detach_dev,
	dispatch_dev,
	element,
	flush,
	globals,
	init as init_1,
	insert_dev,
	noop,
	safe_not_equal,
	toggle_class,
	validate_slots,
	validate_store
} from "../../../web_modules/svelte/internal.js";

const { console: console_1 } = globals;
import bc from '../../bcShim.js';
import { v4 as uuidv4 } from '../../../web_modules/uuid.js';
import { onMount, tick } from '../../../web_modules/svelte.js';

import {
	attachNormalize,
	componentInitAndAttributeHandler,
	dispatchMountedEvent,
	handleComponentShowAttribute
} from "../../utilities/componentUtils.js";

import { get_current_component } from "../../../web_modules/svelte/internal.js";
import { applicationReady } from "../../utilities/store.js";
import { getComponentText } from "../../utilities/generalUtils.js";
const file = "src\\components\\Portal\\Portal.svelte";

function create_fragment(ctx) {
	let div;
	let div_style_value;

	const block = {
		c: function create() {
			div = element("div");
			this.c = noop;
			attr_dev(div, "class", "fixed");
			attr_dev(div, "style", div_style_value = `left: ${/*left*/ ctx[1]}px;top:${/*top*/ ctx[2]}px; z-index: 9999`);
			toggle_class(div, "hidden", /*hidden*/ ctx[3] === true);
			add_location(div, file, 2055, 0, 38663);
		},
		l: function claim(nodes) {
			throw new Error("options.hydrate only works if the component was compiled with the `hydratable: true` option");
		},
		m: function mount(target, anchor) {
			insert_dev(target, div, anchor);
			/*div_binding*/ ctx[12](div);
		},
		p: function update(ctx, [dirty]) {
			if (dirty & /*left, top*/ 6 && div_style_value !== (div_style_value = `left: ${/*left*/ ctx[1]}px;top:${/*top*/ ctx[2]}px; z-index: 9999`)) {
				attr_dev(div, "style", div_style_value);
			}

			if (dirty & /*hidden*/ 8) {
				toggle_class(div, "hidden", /*hidden*/ ctx[3] === true);
			}
		},
		i: noop,
		o: noop,
		d: function destroy(detaching) {
			if (detaching) detach_dev(div);
			/*div_binding*/ ctx[12](null);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_fragment.name,
		type: "component",
		source: "",
		ctx
	});

	return block;
}

function instance($$self, $$props, $$invalidate) {
	let $applicationReady;
	validate_store(applicationReady, 'applicationReady');
	component_subscribe($$self, applicationReady, $$value => $$invalidate(11, $applicationReady = $$value));
	let { $$slots: slots = {}, $$scope } = $$props;
	validate_slots('bc-portal', slots, []);
	let { show = 'true' } = $$props;
	let { init = 'true' } = $$props;
	let { guid = '' } = $$props;

	// Base variables
	//================================================================
	let component = get_current_component();

	let firstInitialized = false;
	let subscribeGuid = uuidv4();
	let ready = false;
	const generalText = getComponentText('general');
	let mounted = false;
	let canReload = false;

	// Component Variables
	let slotContainer = null;

	let left = 0;
	let top = 0;
	let hidden = true;

	// HTML Mount & Component initialization
	//================================================================
	onMount(() => {
		attachNormalize(component);

		tick().then(() => {
			dispatchMountedEvent(component);
			$$invalidate(10, mounted = true);
		});

		return () => {
			bc.messageService.unsubscribeComponent(subscribeGuid);
		};
	});

	const initComponent = () => {
		$$invalidate(9, firstInitialized = true);
		ready = true;
		bc.messageService.subscribe('PORTAL', subscribeGuid, showPortal);
		bc.messageService.subscribe('HIDE_PORTAL', subscribeGuid, hidePortal);
	};

	const showPortal = (id, content, container, size) => {
		if (id === guid) {
			$$invalidate(0, slotContainer.innerHTML = '', slotContainer);

			// content.style.opacity = '0'
			slotContainer.append(content);

			$$invalidate(3, hidden = false);

			setTimeout(() => {
				let contentRect = content.getBoundingClientRect();
				let rect = container.getBoundingClientRect();
				$$invalidate(1, left = rect.left);
				$$invalidate(2, top = rect.top);
				console.log(left, contentRect.width, left + contentRect.width, window.innerWidth);

				if (size) {
					size = Number.parseInt(size);

					if (left + size > window.innerWidth) {
						$$invalidate(1, left = rect.right - size);
					}
				}
			});
		}
	};

	const hidePortal = id => {
		if (id === guid) {
			$$invalidate(3, hidden = true);
			$$invalidate(0, slotContainer.innerHTML = '', slotContainer);
		}
	};

	const writable_props = ['show', 'init', 'guid'];

	

	function div_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			slotContainer = $$value;
			$$invalidate(0, slotContainer);
		});
	}

	$$self.$$set = $$props => {
		if ('show' in $$props) $$invalidate(4, show = $$props.show);
		if ('init' in $$props) $$invalidate(5, init = $$props.init);
		if ('guid' in $$props) $$invalidate(6, guid = $$props.guid);
	};

	$$self.$capture_state = () => ({
		bc,
		uuidv4,
		onMount,
		tick,
		attachNormalize,
		componentInitAndAttributeHandler,
		dispatchMountedEvent,
		handleComponentShowAttribute,
		get_current_component,
		applicationReady,
		getComponentText,
		show,
		init,
		guid,
		component,
		firstInitialized,
		subscribeGuid,
		ready,
		generalText,
		mounted,
		canReload,
		slotContainer,
		left,
		top,
		hidden,
		initComponent,
		showPortal,
		hidePortal,
		$applicationReady
	});

	$$self.$inject_state = $$props => {
		if ('show' in $$props) $$invalidate(4, show = $$props.show);
		if ('init' in $$props) $$invalidate(5, init = $$props.init);
		if ('guid' in $$props) $$invalidate(6, guid = $$props.guid);
		if ('component' in $$props) $$invalidate(14, component = $$props.component);
		if ('firstInitialized' in $$props) $$invalidate(9, firstInitialized = $$props.firstInitialized);
		if ('subscribeGuid' in $$props) subscribeGuid = $$props.subscribeGuid;
		if ('ready' in $$props) ready = $$props.ready;
		if ('mounted' in $$props) $$invalidate(10, mounted = $$props.mounted);
		if ('canReload' in $$props) $$invalidate(17, canReload = $$props.canReload);
		if ('slotContainer' in $$props) $$invalidate(0, slotContainer = $$props.slotContainer);
		if ('left' in $$props) $$invalidate(1, left = $$props.left);
		if ('top' in $$props) $$invalidate(2, top = $$props.top);
		if ('hidden' in $$props) $$invalidate(3, hidden = $$props.hidden);
	};

	if ($$props && "$$inject" in $$props) {
		$$self.$inject_state($$props.$$inject);
	}

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*show*/ 16) {
			$: if (show) {
				handleComponentShowAttribute(show, component);
			}
		}

		if ($$self.$$.dirty & /*$applicationReady, mounted, init, firstInitialized*/ 3616) {
			$: if ($applicationReady && mounted && init) {
				componentInitAndAttributeHandler({
					mounted,
					handler: initComponent,
					init,
					firstInitialized,
					canReload
				});
			}
		}
	};

	return [
		slotContainer,
		left,
		top,
		hidden,
		show,
		init,
		guid,
		showPortal,
		hidePortal,
		firstInitialized,
		mounted,
		$applicationReady,
		div_binding
	];
}

class Portal extends SvelteElement {
	constructor(options) {
		super();
		const style = document.createElement('style');
		style.textContent = `100%{left:0;right:0}100%{left:0;right:0}100%{opacity:1}100%{opacity:1}45%{-webkit-transform:scale(var(--fa-beat-scale, 1.25));transform:scale(var(--fa-beat-scale, 1.25))}45%{-webkit-transform:scale(var(--fa-beat-scale, 1.25));transform:scale(var(--fa-beat-scale, 1.25))}50%{opacity:1;-webkit-transform:scale(var(--fa-beat-fade-scale, 1.125));transform:scale(var(--fa-beat-fade-scale, 1.125))}50%{opacity:1;-webkit-transform:scale(var(--fa-beat-fade-scale, 1.125));transform:scale(var(--fa-beat-fade-scale, 1.125))}100%{-webkit-transform:rotate(360deg);transform:rotate(360deg)}100%{-webkit-transform:rotate(360deg);transform:rotate(360deg)}.hidden{display:none}.fixed{position:fixed}`;
		this.shadowRoot.appendChild(style);

		init_1(
			this,
			{
				target: this.shadowRoot,
				props: attribute_to_object(this.attributes),
				customElement: true
			},
			instance,
			create_fragment,
			safe_not_equal,
			{
				show: 4,
				init: 5,
				guid: 6,
				showPortal: 7,
				hidePortal: 8
			},
			null
		);

		if (options) {
			if (options.target) {
				insert_dev(options.target, this, options.anchor);
			}

			if (options.props) {
				this.$set(options.props);
				flush();
			}
		}
	}

	static get observedAttributes() {
		return ["show", "init", "guid", "showPortal", "hidePortal"];
	}

	get show() {
		return this.$$.ctx[4];
	}

	set show(show) {
		this.$$set({ show });
		flush();
	}

	get init() {
		return this.$$.ctx[5];
	}

	set init(init) {
		this.$$set({ init });
		flush();
	}

	get guid() {
		return this.$$.ctx[6];
	}

	set guid(guid) {
		this.$$set({ guid });
		flush();
	}

	get showPortal() {
		return this.$$.ctx[7];
	}

	set showPortal(value) {
		throw new Error("<bc-portal>: Cannot set read-only property 'showPortal'");
	}

	get hidePortal() {
		return this.$$.ctx[8];
	}

	set hidePortal(value) {
		throw new Error("<bc-portal>: Cannot set read-only property 'hidePortal'");
	}
}

customElements.define("bc-portal", Portal);
export default Portal;
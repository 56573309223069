/* src\components\Map\MapTools\MapPluginRequestHandler\MapPluginRequestHandler.svelte generated by Svelte v3.57.0 */
import {
	SvelteElement,
	add_location,
	attribute_to_object,
	detach_dev,
	dispatch_dev,
	element,
	flush,
	init,
	insert_dev,
	noop,
	safe_not_equal,
	validate_slots
} from "../../../../../web_modules/svelte/internal.js";

import bc from '../../../../bcShim.js';
import { buffer } from '../../../../../web_modules/ol/extent.js';
import { createVectorLayer, getMaxZIndex } from '../../map-utils.js';
import { v4 as uuidv4 } from "../../../../../web_modules/uuid.js";
import { wktHelper } from "../../../../utilities/WKTHelper.js";
import { get_current_component } from "../../../../../web_modules/svelte/internal.js";
import { onMount, tick } from "../../../../../web_modules/svelte.js";

import {
	attachNormalize,
	dispatchMountedEvent
} from "../../../../utilities/componentUtils.js";

import { getComponentText } from "../../../../utilities/generalUtils.js";
const file = "src\\components\\Map\\MapTools\\MapPluginRequestHandler\\MapPluginRequestHandler.svelte";

function create_fragment(ctx) {
	let div;

	const block = {
		c: function create() {
			div = element("div");
			this.c = noop;
			add_location(div, file, 1762, 0, 32611);
		},
		l: function claim(nodes) {
			throw new Error("options.hydrate only works if the component was compiled with the `hydratable: true` option");
		},
		m: function mount(target, anchor) {
			insert_dev(target, div, anchor);
		},
		p: noop,
		i: noop,
		o: noop,
		d: function destroy(detaching) {
			if (detaching) detach_dev(div);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_fragment.name,
		type: "component",
		source: "",
		ctx
	});

	return block;
}

function instance($$self, $$props, $$invalidate) {
	let { $$slots: slots = {}, $$scope } = $$props;
	validate_slots('map-tool-plugin-request-handler', slots, []);
	let { maptool } = $$props;

	// Base variables
	//================================================================
	const generalText = getComponentText('general');

	const component = get_current_component();
	let mounted = false;
	let ready = false;

	// Component variables
	//================================================================
	let vectorLayer;

	let vectorSource;
	let map;
	let componentId = uuidv4();
	let isMainMap = false;

	// HTML Mount & Component initialization
	//================================================================
	onMount(() => {
		attachNormalize(component);

		tick().then(() => {
			dispatchMountedEvent(component);
			mounted = true;
		});

		return () => {
			
		};
	});

	const initComponent = (newMap, main) => {
		map = newMap;
		isMainMap = main;
		bc.messageService.subscribe('PLUGIN_SHOW_WKT', 'MAP_PLUGIN_REQUEST_HANDLER', showWKT);
		ready = true;
	};

	// Component functions
	//================================================================
	const showWKT = wkt => {
		if (isMainMap) {
			vectorSource.clear();
			const feature = wktHelper.readFeature(wkt);
			vectorSource.addFeature(feature);
			const bufferedExtend = buffer(feature.getGeometry().getExtent(), 100);
			map.getView().fit(bufferedExtend, { size: map.getSize(), duration: 250 });
		}
	};

	const handleMapTool = tool => {
		if (maptool !== 'Plugin') {
			cleanup();
		}

		if (maptool == 'Plugin') {
			if (!vectorLayer) {
				vectorLayer = createVectorLayer('PLUGIN_REQUEST' + componentId);
				vectorSource = vectorLayer.getSource();
				let zIndex = getMaxZIndex(map);
				vectorLayer.setZIndex(zIndex + 100);
				map.addLayer(vectorLayer);
			}
		}
	};

	const cleanup = () => {
		if (vectorLayer) {
			map.removeLayer(vectorLayer);
		}

		if (vectorSource) {
			vectorSource.clear();
		}

		vectorLayer = undefined;
		vectorSource = undefined;
	};

	$$self.$$.on_mount.push(function () {
		if (maptool === undefined && !('maptool' in $$props || $$self.$$.bound[$$self.$$.props['maptool']])) {
			console.warn("<map-tool-plugin-request-handler> was created without expected prop 'maptool'");
		}
	});

	const writable_props = ['maptool'];

	

	$$self.$$set = $$props => {
		if ('maptool' in $$props) $$invalidate(0, maptool = $$props.maptool);
	};

	$$self.$capture_state = () => ({
		bc,
		buffer,
		createVectorLayer,
		getMaxZIndex,
		uuidv4,
		wktHelper,
		get_current_component,
		onMount,
		tick,
		attachNormalize,
		dispatchMountedEvent,
		getComponentText,
		maptool,
		generalText,
		component,
		mounted,
		ready,
		vectorLayer,
		vectorSource,
		map,
		componentId,
		isMainMap,
		initComponent,
		showWKT,
		handleMapTool,
		cleanup
	});

	$$self.$inject_state = $$props => {
		if ('maptool' in $$props) $$invalidate(0, maptool = $$props.maptool);
		if ('mounted' in $$props) mounted = $$props.mounted;
		if ('ready' in $$props) ready = $$props.ready;
		if ('vectorLayer' in $$props) vectorLayer = $$props.vectorLayer;
		if ('vectorSource' in $$props) vectorSource = $$props.vectorSource;
		if ('map' in $$props) map = $$props.map;
		if ('componentId' in $$props) componentId = $$props.componentId;
		if ('isMainMap' in $$props) isMainMap = $$props.isMainMap;
	};

	if ($$props && "$$inject" in $$props) {
		$$self.$inject_state($$props.$$inject);
	}

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*maptool*/ 1) {
			$: handleMapTool(maptool);
		}
	};

	return [maptool, initComponent];
}

class MapPluginRequestHandler extends SvelteElement {
	constructor(options) {
		super();
		const style = document.createElement('style');
		style.textContent = `45%{-webkit-transform:scale(var(--fa-beat-scale, 1.25));transform:scale(var(--fa-beat-scale, 1.25))}45%{-webkit-transform:scale(var(--fa-beat-scale, 1.25));transform:scale(var(--fa-beat-scale, 1.25))}50%{opacity:1;-webkit-transform:scale(var(--fa-beat-fade-scale, 1.125));transform:scale(var(--fa-beat-fade-scale, 1.125))}50%{opacity:1;-webkit-transform:scale(var(--fa-beat-fade-scale, 1.125));transform:scale(var(--fa-beat-fade-scale, 1.125))}100%{-webkit-transform:rotate(360deg);transform:rotate(360deg)}100%{-webkit-transform:rotate(360deg);transform:rotate(360deg)}100%{left:0;right:0}`;
		this.shadowRoot.appendChild(style);

		init(
			this,
			{
				target: this.shadowRoot,
				props: attribute_to_object(this.attributes),
				customElement: true
			},
			instance,
			create_fragment,
			safe_not_equal,
			{ maptool: 0, initComponent: 1 },
			null
		);

		if (options) {
			if (options.target) {
				insert_dev(options.target, this, options.anchor);
			}

			if (options.props) {
				this.$set(options.props);
				flush();
			}
		}
	}

	static get observedAttributes() {
		return ["maptool", "initComponent"];
	}

	get maptool() {
		return this.$$.ctx[0];
	}

	set maptool(maptool) {
		this.$$set({ maptool });
		flush();
	}

	get initComponent() {
		return this.$$.ctx[1];
	}

	set initComponent(value) {
		throw new Error("<map-tool-plugin-request-handler>: Cannot set read-only property 'initComponent'");
	}
}

customElements.define("map-tool-plugin-request-handler", MapPluginRequestHandler);
export default MapPluginRequestHandler;
/* src\components\Pagination\Pagination.svelte generated by Svelte v3.57.0 */
import {
	SvelteElement,
	add_location,
	append_dev,
	attr_dev,
	attribute_to_object,
	binding_callbacks,
	component_subscribe,
	destroy_each,
	detach_dev,
	dispatch_dev,
	element,
	empty,
	flush,
	init as init_1,
	insert_dev,
	listen_dev,
	noop,
	prop_dev,
	run_all,
	safe_not_equal,
	set_custom_element_data,
	space,
	text,
	toggle_class,
	validate_each_argument,
	validate_slots,
	validate_store
} from "../../../web_modules/svelte/internal.js";

import bc from '../../bcShim.js';
import { v4 as uuidv4 } from '../../../web_modules/uuid.js';
import { onMount, tick } from '../../../web_modules/svelte.js';
import { get_current_component } from "../../../web_modules/svelte/internal.js";

import {
	attachNormalize,
	dispatchMountedEvent,
	handleComponentInitAttribute,
	handleComponentProps,
	handleComponentShowAttribute
} from "../../utilities/componentUtils.js";

import { applicationReady } from "../../utilities/store.js";
import { getComponentText } from "../../utilities/generalUtils.js";
const file = "src\\components\\Pagination\\Pagination.svelte";

function get_each_context_3(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[36] = list[i];
	child_ctx[38] = i;
	return child_ctx;
}

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[36] = list[i];
	child_ctx[38] = i;
	return child_ctx;
}

function get_each_context_1(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[36] = list[i];
	child_ctx[38] = i;
	return child_ctx;
}

function get_each_context_2(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[36] = list[i];
	child_ctx[38] = i;
	return child_ctx;
}

// (2249:4) {:else}
function create_else_block_1(ctx) {
	let loading_spinner;

	const block = {
		c: function create() {
			loading_spinner = element("loading-spinner");
			set_custom_element_data(loading_spinner, "text", "Analysieren der Attribute in der Datenbank...");
			add_location(loading_spinner, file, 2249, 8, 46747);
		},
		m: function mount(target, anchor) {
			insert_dev(target, loading_spinner, anchor);
		},
		p: noop,
		d: function destroy(detaching) {
			if (detaching) detach_dev(loading_spinner);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_else_block_1.name,
		type: "else",
		source: "(2249:4) {:else}",
		ctx
	});

	return block;
}

// (2158:4) {#if ready}
function create_if_block(ctx) {
	let div1;
	let div0;
	let icon_button0;
	let icon_button0_disabled_value;
	let t0;
	let icon_button1;
	let icon_button1_disabled_value;
	let t1;
	let t2;
	let icon_button2;
	let icon_button2_disabled_value;
	let t3;
	let icon_button3;
	let icon_button3_disabled_value;
	let t4;
	let select;
	let option0;
	let t5;
	let option0_selected_value;
	let option1;
	let t6;
	let option1_selected_value;
	let option2;
	let t7;
	let option2_selected_value;
	let option3;
	let t8;
	let option3_selected_value;
	let option4;
	let t9;
	let option4_selected_value;
	let mounted;
	let dispose;

	function select_block_type_1(ctx, dirty) {
		if (/*numberOfPages*/ ctx[4] > 5) return create_if_block_1;
		return create_else_block;
	}

	let current_block_type = select_block_type_1(ctx, [-1, -1]);
	let if_block = current_block_type(ctx);

	const block = {
		c: function create() {
			div1 = element("div");
			div0 = element("div");
			icon_button0 = element("icon-button");
			t0 = space();
			icon_button1 = element("icon-button");
			t1 = space();
			if_block.c();
			t2 = space();
			icon_button2 = element("icon-button");
			t3 = space();
			icon_button3 = element("icon-button");
			t4 = space();
			select = element("select");
			option0 = element("option");
			t5 = text("5");
			option1 = element("option");
			t6 = text("10");
			option2 = element("option");
			t7 = text("20");
			option3 = element("option");
			t8 = text("50");
			option4 = element("option");
			t9 = text("100");
			set_custom_element_data(icon_button0, "type", "secondary");
			set_custom_element_data(icon_button0, "color", "white");
			set_custom_element_data(icon_button0, "size", /*size*/ ctx[0]);
			set_custom_element_data(icon_button0, "icon", "fas fa-chevrons-left");
			set_custom_element_data(icon_button0, "tooltiptext", "Information");
			set_custom_element_data(icon_button0, "groupposition", "first");
			set_custom_element_data(icon_button0, "disabled", icon_button0_disabled_value = /*currentPage*/ ctx[3] === 1 ? 'true' : 'false');
			add_location(icon_button0, file, 2161, 16, 41064);
			set_custom_element_data(icon_button1, "type", "secondary");
			set_custom_element_data(icon_button1, "color", "white");
			set_custom_element_data(icon_button1, "size", /*size*/ ctx[0]);
			set_custom_element_data(icon_button1, "icon", "fas fa-chevron-left");
			set_custom_element_data(icon_button1, "tooltiptext", "Information");
			set_custom_element_data(icon_button1, "groupposition", "middle");
			set_custom_element_data(icon_button1, "disabled", icon_button1_disabled_value = /*currentPage*/ ctx[3] === 1 ? 'true' : 'false');
			add_location(icon_button1, file, 2164, 16, 41361);
			set_custom_element_data(icon_button2, "type", "secondary");
			set_custom_element_data(icon_button2, "color", "white");
			set_custom_element_data(icon_button2, "size", /*size*/ ctx[0]);
			set_custom_element_data(icon_button2, "icon", "fas fa-chevron-right");
			set_custom_element_data(icon_button2, "tooltiptext", "Information");
			set_custom_element_data(icon_button2, "groupposition", "middle");

			set_custom_element_data(icon_button2, "disabled", icon_button2_disabled_value = /*currentPage*/ ctx[3] === /*numberOfPages*/ ctx[4]
			? 'true'
			: 'false');

			add_location(icon_button2, file, 2222, 16, 45181);
			set_custom_element_data(icon_button3, "type", "secondary");
			set_custom_element_data(icon_button3, "color", "white");
			set_custom_element_data(icon_button3, "size", /*size*/ ctx[0]);
			set_custom_element_data(icon_button3, "icon", "fas fa-chevrons-right");
			set_custom_element_data(icon_button3, "tooltiptext", "Information");
			set_custom_element_data(icon_button3, "groupposition", "last");

			set_custom_element_data(icon_button3, "disabled", icon_button3_disabled_value = /*currentPage*/ ctx[3] === /*numberOfPages*/ ctx[4]
			? 'true'
			: 'false');

			add_location(icon_button3, file, 2225, 16, 45492);
			attr_dev(div0, "class", "flex items-center flex-wrap");
			add_location(div0, file, 2159, 12, 41003);
			attr_dev(option0, "class", "p-2");
			option0.__value = "5";
			option0.value = option0.__value;
			option0.selected = option0_selected_value = /*itemsPerPage*/ ctx[2] === 5;
			add_location(option0, file, 2240, 16, 46246);
			attr_dev(option1, "class", "p-2");
			option1.__value = "10";
			option1.value = option1.__value;
			option1.selected = option1_selected_value = /*itemsPerPage*/ ctx[2] === 10;
			add_location(option1, file, 2241, 16, 46334);
			attr_dev(option2, "class", "p-2");
			option2.__value = "20";
			option2.value = option2.__value;
			option2.selected = option2_selected_value = /*itemsPerPage*/ ctx[2] === 20;
			add_location(option2, file, 2242, 16, 46425);
			attr_dev(option3, "class", "p-2");
			option3.__value = "50";
			option3.value = option3.__value;
			option3.selected = option3_selected_value = /*itemsPerPage*/ ctx[2] === 50;
			add_location(option3, file, 2243, 16, 46516);
			attr_dev(option4, "class", "p-2");
			option4.__value = "100";
			option4.value = option4.__value;
			option4.selected = option4_selected_value = /*itemsPerPage*/ ctx[2] === 100;
			add_location(option4, file, 2244, 16, 46607);
			attr_dev(select, "class", "hidden md:block border border-solid border-gray-300 rounded outline-none focus:outline-none");
			toggle_class(select, "h-10", /*size*/ ctx[0] === 'normal');
			toggle_class(select, "h-8", /*size*/ ctx[0] === 'small');
			add_location(select, file, 2232, 12, 45822);
			attr_dev(div1, "class", "flex items-start gap-4 flex-wrap");
			add_location(div1, file, 2158, 8, 40943);
		},
		m: function mount(target, anchor) {
			insert_dev(target, div1, anchor);
			append_dev(div1, div0);
			append_dev(div0, icon_button0);
			append_dev(div0, t0);
			append_dev(div0, icon_button1);
			append_dev(div0, t1);
			if_block.m(div0, null);
			append_dev(div0, t2);
			append_dev(div0, icon_button2);
			append_dev(div0, t3);
			append_dev(div0, icon_button3);
			append_dev(div1, t4);
			append_dev(div1, select);
			append_dev(select, option0);
			append_dev(option0, t5);
			append_dev(select, option1);
			append_dev(option1, t6);
			append_dev(select, option2);
			append_dev(option2, t7);
			append_dev(select, option3);
			append_dev(option3, t8);
			append_dev(select, option4);
			append_dev(option4, t9);
			/*select_binding*/ ctx[28](select);

			if (!mounted) {
				dispose = [
					listen_dev(icon_button0, "click", /*goToFirstPage*/ ctx[8], false, false, false, false),
					listen_dev(icon_button1, "click", /*goOnePageBack*/ ctx[9], false, false, false, false),
					listen_dev(icon_button2, "click", /*goOnePageForth*/ ctx[11], false, false, false, false),
					listen_dev(icon_button3, "click", /*goToLastPage*/ ctx[12], false, false, false, false),
					listen_dev(select, "change", /*change_handler*/ ctx[29], false, false, false, false)
				];

				mounted = true;
			}
		},
		p: function update(ctx, dirty) {
			if (dirty[0] & /*size*/ 1) {
				set_custom_element_data(icon_button0, "size", /*size*/ ctx[0]);
			}

			if (dirty[0] & /*currentPage*/ 8 && icon_button0_disabled_value !== (icon_button0_disabled_value = /*currentPage*/ ctx[3] === 1 ? 'true' : 'false')) {
				set_custom_element_data(icon_button0, "disabled", icon_button0_disabled_value);
			}

			if (dirty[0] & /*size*/ 1) {
				set_custom_element_data(icon_button1, "size", /*size*/ ctx[0]);
			}

			if (dirty[0] & /*currentPage*/ 8 && icon_button1_disabled_value !== (icon_button1_disabled_value = /*currentPage*/ ctx[3] === 1 ? 'true' : 'false')) {
				set_custom_element_data(icon_button1, "disabled", icon_button1_disabled_value);
			}

			if (current_block_type === (current_block_type = select_block_type_1(ctx, dirty)) && if_block) {
				if_block.p(ctx, dirty);
			} else {
				if_block.d(1);
				if_block = current_block_type(ctx);

				if (if_block) {
					if_block.c();
					if_block.m(div0, t2);
				}
			}

			if (dirty[0] & /*size*/ 1) {
				set_custom_element_data(icon_button2, "size", /*size*/ ctx[0]);
			}

			if (dirty[0] & /*currentPage, numberOfPages*/ 24 && icon_button2_disabled_value !== (icon_button2_disabled_value = /*currentPage*/ ctx[3] === /*numberOfPages*/ ctx[4]
			? 'true'
			: 'false')) {
				set_custom_element_data(icon_button2, "disabled", icon_button2_disabled_value);
			}

			if (dirty[0] & /*size*/ 1) {
				set_custom_element_data(icon_button3, "size", /*size*/ ctx[0]);
			}

			if (dirty[0] & /*currentPage, numberOfPages*/ 24 && icon_button3_disabled_value !== (icon_button3_disabled_value = /*currentPage*/ ctx[3] === /*numberOfPages*/ ctx[4]
			? 'true'
			: 'false')) {
				set_custom_element_data(icon_button3, "disabled", icon_button3_disabled_value);
			}

			if (dirty[0] & /*itemsPerPage*/ 4 && option0_selected_value !== (option0_selected_value = /*itemsPerPage*/ ctx[2] === 5)) {
				prop_dev(option0, "selected", option0_selected_value);
			}

			if (dirty[0] & /*itemsPerPage*/ 4 && option1_selected_value !== (option1_selected_value = /*itemsPerPage*/ ctx[2] === 10)) {
				prop_dev(option1, "selected", option1_selected_value);
			}

			if (dirty[0] & /*itemsPerPage*/ 4 && option2_selected_value !== (option2_selected_value = /*itemsPerPage*/ ctx[2] === 20)) {
				prop_dev(option2, "selected", option2_selected_value);
			}

			if (dirty[0] & /*itemsPerPage*/ 4 && option3_selected_value !== (option3_selected_value = /*itemsPerPage*/ ctx[2] === 50)) {
				prop_dev(option3, "selected", option3_selected_value);
			}

			if (dirty[0] & /*itemsPerPage*/ 4 && option4_selected_value !== (option4_selected_value = /*itemsPerPage*/ ctx[2] === 100)) {
				prop_dev(option4, "selected", option4_selected_value);
			}

			if (dirty[0] & /*size*/ 1) {
				toggle_class(select, "h-10", /*size*/ ctx[0] === 'normal');
			}

			if (dirty[0] & /*size*/ 1) {
				toggle_class(select, "h-8", /*size*/ ctx[0] === 'small');
			}
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(div1);
			if_block.d();
			/*select_binding*/ ctx[28](null);
			mounted = false;
			run_all(dispose);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block.name,
		type: "if",
		source: "(2158:4) {#if ready}",
		ctx
	});

	return block;
}

// (2216:16) {:else}
function create_else_block(ctx) {
	let each_1_anchor;
	let each_value_3 = { length: /*numberOfPages*/ ctx[4] };
	validate_each_argument(each_value_3);
	let each_blocks = [];

	for (let i = 0; i < each_value_3.length; i += 1) {
		each_blocks[i] = create_each_block_3(get_each_context_3(ctx, each_value_3, i));
	}

	const block = {
		c: function create() {
			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			each_1_anchor = empty();
		},
		m: function mount(target, anchor) {
			for (let i = 0; i < each_blocks.length; i += 1) {
				if (each_blocks[i]) {
					each_blocks[i].m(target, anchor);
				}
			}

			insert_dev(target, each_1_anchor, anchor);
		},
		p: function update(ctx, dirty) {
			if (dirty[0] & /*size, currentPage, goToPage, numberOfPages*/ 1049) {
				each_value_3 = { length: /*numberOfPages*/ ctx[4] };
				validate_each_argument(each_value_3);
				let i;

				for (i = 0; i < each_value_3.length; i += 1) {
					const child_ctx = get_each_context_3(ctx, each_value_3, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block_3(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(each_1_anchor.parentNode, each_1_anchor);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value_3.length;
			}
		},
		d: function destroy(detaching) {
			destroy_each(each_blocks, detaching);
			if (detaching) detach_dev(each_1_anchor);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_else_block.name,
		type: "else",
		source: "(2216:16) {:else}",
		ctx
	});

	return block;
}

// (2171:16) {#if numberOfPages > 5}
function create_if_block_1(ctx) {
	let t0;
	let t1;
	let if_block2_anchor;
	let if_block0 = /*currentPage*/ ctx[3] <= 3 && create_if_block_6(ctx);
	let if_block1 = /*currentPage*/ ctx[3] > 3 && /*currentPage*/ ctx[3] <= /*numberOfPages*/ ctx[4] - 3 && create_if_block_4(ctx);
	let if_block2 = /*currentPage*/ ctx[3] >= /*numberOfPages*/ ctx[4] - 2 && create_if_block_2(ctx);

	const block = {
		c: function create() {
			if (if_block0) if_block0.c();
			t0 = space();
			if (if_block1) if_block1.c();
			t1 = space();
			if (if_block2) if_block2.c();
			if_block2_anchor = empty();
		},
		m: function mount(target, anchor) {
			if (if_block0) if_block0.m(target, anchor);
			insert_dev(target, t0, anchor);
			if (if_block1) if_block1.m(target, anchor);
			insert_dev(target, t1, anchor);
			if (if_block2) if_block2.m(target, anchor);
			insert_dev(target, if_block2_anchor, anchor);
		},
		p: function update(ctx, dirty) {
			if (/*currentPage*/ ctx[3] <= 3) {
				if (if_block0) {
					if_block0.p(ctx, dirty);
				} else {
					if_block0 = create_if_block_6(ctx);
					if_block0.c();
					if_block0.m(t0.parentNode, t0);
				}
			} else if (if_block0) {
				if_block0.d(1);
				if_block0 = null;
			}

			if (/*currentPage*/ ctx[3] > 3 && /*currentPage*/ ctx[3] <= /*numberOfPages*/ ctx[4] - 3) {
				if (if_block1) {
					if_block1.p(ctx, dirty);
				} else {
					if_block1 = create_if_block_4(ctx);
					if_block1.c();
					if_block1.m(t1.parentNode, t1);
				}
			} else if (if_block1) {
				if_block1.d(1);
				if_block1 = null;
			}

			if (/*currentPage*/ ctx[3] >= /*numberOfPages*/ ctx[4] - 2) {
				if (if_block2) {
					if_block2.p(ctx, dirty);
				} else {
					if_block2 = create_if_block_2(ctx);
					if_block2.c();
					if_block2.m(if_block2_anchor.parentNode, if_block2_anchor);
				}
			} else if (if_block2) {
				if_block2.d(1);
				if_block2 = null;
			}
		},
		d: function destroy(detaching) {
			if (if_block0) if_block0.d(detaching);
			if (detaching) detach_dev(t0);
			if (if_block1) if_block1.d(detaching);
			if (detaching) detach_dev(t1);
			if (if_block2) if_block2.d(detaching);
			if (detaching) detach_dev(if_block2_anchor);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block_1.name,
		type: "if",
		source: "(2171:16) {#if numberOfPages > 5}",
		ctx
	});

	return block;
}

// (2217:20) {#each {length: numberOfPages} as _, i}
function create_each_block_3(ctx) {
	let bc_button;
	let bc_button_text_value;
	let bc_button_selected_value;
	let mounted;
	let dispose;

	function click_handler_7(...args) {
		return /*click_handler_7*/ ctx[27](/*i*/ ctx[38], ...args);
	}

	const block = {
		c: function create() {
			bc_button = element("bc-button");
			set_custom_element_data(bc_button, "type", "secondary");
			set_custom_element_data(bc_button, "color", "white");
			set_custom_element_data(bc_button, "size", /*size*/ ctx[0]);
			set_custom_element_data(bc_button, "text", bc_button_text_value = /*i*/ ctx[38] + 1);
			set_custom_element_data(bc_button, "tooltiptext", "Information");
			set_custom_element_data(bc_button, "groupposition", "middle");

			set_custom_element_data(bc_button, "selected", bc_button_selected_value = /*currentPage*/ ctx[3] === /*i*/ ctx[38] + 1
			? 'true'
			: 'false');

			add_location(bc_button, file, 2217, 24, 44832);
		},
		m: function mount(target, anchor) {
			insert_dev(target, bc_button, anchor);

			if (!mounted) {
				dispose = listen_dev(bc_button, "click", click_handler_7, false, false, false, false);
				mounted = true;
			}
		},
		p: function update(new_ctx, dirty) {
			ctx = new_ctx;

			if (dirty[0] & /*size*/ 1) {
				set_custom_element_data(bc_button, "size", /*size*/ ctx[0]);
			}

			if (dirty[0] & /*currentPage*/ 8 && bc_button_selected_value !== (bc_button_selected_value = /*currentPage*/ ctx[3] === /*i*/ ctx[38] + 1
			? 'true'
			: 'false')) {
				set_custom_element_data(bc_button, "selected", bc_button_selected_value);
			}
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(bc_button);
			mounted = false;
			dispose();
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_each_block_3.name,
		type: "each",
		source: "(2217:20) {#each {length: numberOfPages} as _, i}",
		ctx
	});

	return block;
}

// (2172:20) {#if currentPage <= 3}
function create_if_block_6(ctx) {
	let t;
	let bc_button;
	let mounted;
	let dispose;
	let each_value_2 = { length: /*numberOfPages*/ ctx[4] };
	validate_each_argument(each_value_2);
	let each_blocks = [];

	for (let i = 0; i < each_value_2.length; i += 1) {
		each_blocks[i] = create_each_block_2(get_each_context_2(ctx, each_value_2, i));
	}

	const block = {
		c: function create() {
			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			t = space();
			bc_button = element("bc-button");
			set_custom_element_data(bc_button, "type", "secondary");
			set_custom_element_data(bc_button, "color", "white");
			set_custom_element_data(bc_button, "size", /*size*/ ctx[0]);
			set_custom_element_data(bc_button, "text", "...");
			set_custom_element_data(bc_button, "tooltiptext", "Information");
			set_custom_element_data(bc_button, "groupposition", "middle");
			add_location(bc_button, file, 2179, 24, 42278);
		},
		m: function mount(target, anchor) {
			for (let i = 0; i < each_blocks.length; i += 1) {
				if (each_blocks[i]) {
					each_blocks[i].m(target, anchor);
				}
			}

			insert_dev(target, t, anchor);
			insert_dev(target, bc_button, anchor);

			if (!mounted) {
				dispose = listen_dev(bc_button, "click", /*click_handler_1*/ ctx[21], false, false, false, false);
				mounted = true;
			}
		},
		p: function update(ctx, dirty) {
			if (dirty[0] & /*size, currentPage, goToPage, numberOfPages*/ 1049) {
				each_value_2 = { length: /*numberOfPages*/ ctx[4] };
				validate_each_argument(each_value_2);
				let i;

				for (i = 0; i < each_value_2.length; i += 1) {
					const child_ctx = get_each_context_2(ctx, each_value_2, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block_2(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(t.parentNode, t);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value_2.length;
			}

			if (dirty[0] & /*size*/ 1) {
				set_custom_element_data(bc_button, "size", /*size*/ ctx[0]);
			}
		},
		d: function destroy(detaching) {
			destroy_each(each_blocks, detaching);
			if (detaching) detach_dev(t);
			if (detaching) detach_dev(bc_button);
			mounted = false;
			dispose();
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block_6.name,
		type: "if",
		source: "(2172:20) {#if currentPage <= 3}",
		ctx
	});

	return block;
}

// (2174:28) {#if i <= 4}
function create_if_block_7(ctx) {
	let bc_button;
	let bc_button_text_value;
	let bc_button_selected_value;
	let mounted;
	let dispose;

	function click_handler(...args) {
		return /*click_handler*/ ctx[20](/*i*/ ctx[38], ...args);
	}

	const block = {
		c: function create() {
			bc_button = element("bc-button");
			set_custom_element_data(bc_button, "type", "secondary");
			set_custom_element_data(bc_button, "color", "white");
			set_custom_element_data(bc_button, "size", /*size*/ ctx[0]);
			set_custom_element_data(bc_button, "text", bc_button_text_value = /*i*/ ctx[38] + 1);
			set_custom_element_data(bc_button, "tooltiptext", "Information");
			set_custom_element_data(bc_button, "groupposition", "middle");

			set_custom_element_data(bc_button, "selected", bc_button_selected_value = /*currentPage*/ ctx[3] === /*i*/ ctx[38] + 1
			? 'true'
			: 'false');

			add_location(bc_button, file, 2174, 32, 41888);
		},
		m: function mount(target, anchor) {
			insert_dev(target, bc_button, anchor);

			if (!mounted) {
				dispose = listen_dev(bc_button, "click", click_handler, false, false, false, false);
				mounted = true;
			}
		},
		p: function update(new_ctx, dirty) {
			ctx = new_ctx;

			if (dirty[0] & /*size*/ 1) {
				set_custom_element_data(bc_button, "size", /*size*/ ctx[0]);
			}

			if (dirty[0] & /*currentPage*/ 8 && bc_button_selected_value !== (bc_button_selected_value = /*currentPage*/ ctx[3] === /*i*/ ctx[38] + 1
			? 'true'
			: 'false')) {
				set_custom_element_data(bc_button, "selected", bc_button_selected_value);
			}
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(bc_button);
			mounted = false;
			dispose();
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block_7.name,
		type: "if",
		source: "(2174:28) {#if i <= 4}",
		ctx
	});

	return block;
}

// (2173:24) {#each {length: numberOfPages} as _, i}
function create_each_block_2(ctx) {
	let if_block_anchor;
	let if_block = /*i*/ ctx[38] <= 4 && create_if_block_7(ctx);

	const block = {
		c: function create() {
			if (if_block) if_block.c();
			if_block_anchor = empty();
		},
		m: function mount(target, anchor) {
			if (if_block) if_block.m(target, anchor);
			insert_dev(target, if_block_anchor, anchor);
		},
		p: function update(ctx, dirty) {
			if (/*i*/ ctx[38] <= 4) if_block.p(ctx, dirty);
		},
		d: function destroy(detaching) {
			if (if_block) if_block.d(detaching);
			if (detaching) detach_dev(if_block_anchor);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_each_block_2.name,
		type: "each",
		source: "(2173:24) {#each {length: numberOfPages} as _, i}",
		ctx
	});

	return block;
}

// (2184:20) {#if currentPage > 3 && currentPage <= numberOfPages - 3}
function create_if_block_4(ctx) {
	let bc_button0;
	let t0;
	let t1;
	let bc_button1;
	let mounted;
	let dispose;
	let each_value_1 = { length: /*numberOfPages*/ ctx[4] };
	validate_each_argument(each_value_1);
	let each_blocks = [];

	for (let i = 0; i < each_value_1.length; i += 1) {
		each_blocks[i] = create_each_block_1(get_each_context_1(ctx, each_value_1, i));
	}

	const block = {
		c: function create() {
			bc_button0 = element("bc-button");
			t0 = space();

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			t1 = space();
			bc_button1 = element("bc-button");
			set_custom_element_data(bc_button0, "type", "secondary");
			set_custom_element_data(bc_button0, "color", "white");
			set_custom_element_data(bc_button0, "size", /*size*/ ctx[0]);
			set_custom_element_data(bc_button0, "text", "...");
			set_custom_element_data(bc_button0, "tooltiptext", "Information");
			set_custom_element_data(bc_button0, "groupposition", "middle");
			add_location(bc_button0, file, 2185, 24, 42614);
			set_custom_element_data(bc_button1, "type", "secondary");
			set_custom_element_data(bc_button1, "color", "white");
			set_custom_element_data(bc_button1, "size", /*size*/ ctx[0]);
			set_custom_element_data(bc_button1, "text", "...");
			set_custom_element_data(bc_button1, "tooltiptext", "Information");
			set_custom_element_data(bc_button1, "groupposition", "middle");
			add_location(bc_button1, file, 2197, 24, 43496);
		},
		m: function mount(target, anchor) {
			insert_dev(target, bc_button0, anchor);
			insert_dev(target, t0, anchor);

			for (let i = 0; i < each_blocks.length; i += 1) {
				if (each_blocks[i]) {
					each_blocks[i].m(target, anchor);
				}
			}

			insert_dev(target, t1, anchor);
			insert_dev(target, bc_button1, anchor);

			if (!mounted) {
				dispose = [
					listen_dev(bc_button0, "click", /*click_handler_2*/ ctx[22], false, false, false, false),
					listen_dev(bc_button1, "click", /*click_handler_4*/ ctx[24], false, false, false, false)
				];

				mounted = true;
			}
		},
		p: function update(ctx, dirty) {
			if (dirty[0] & /*size*/ 1) {
				set_custom_element_data(bc_button0, "size", /*size*/ ctx[0]);
			}

			if (dirty[0] & /*size, currentPage, goToPage, numberOfPages*/ 1049) {
				each_value_1 = { length: /*numberOfPages*/ ctx[4] };
				validate_each_argument(each_value_1);
				let i;

				for (i = 0; i < each_value_1.length; i += 1) {
					const child_ctx = get_each_context_1(ctx, each_value_1, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block_1(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(t1.parentNode, t1);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value_1.length;
			}

			if (dirty[0] & /*size*/ 1) {
				set_custom_element_data(bc_button1, "size", /*size*/ ctx[0]);
			}
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(bc_button0);
			if (detaching) detach_dev(t0);
			destroy_each(each_blocks, detaching);
			if (detaching) detach_dev(t1);
			if (detaching) detach_dev(bc_button1);
			mounted = false;
			run_all(dispose);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block_4.name,
		type: "if",
		source: "(2184:20) {#if currentPage > 3 && currentPage <= numberOfPages - 3}",
		ctx
	});

	return block;
}

// (2190:28) {#if i + 1 === currentPage - 2 || i + 1 === currentPage - 1 || i + 1 === currentPage || i + 1 === currentPage + 1 || i + 1 === currentPage + 2}
function create_if_block_5(ctx) {
	let bc_button;
	let bc_button_text_value;
	let bc_button_selected_value;
	let mounted;
	let dispose;

	function click_handler_3(...args) {
		return /*click_handler_3*/ ctx[23](/*i*/ ctx[38], ...args);
	}

	const block = {
		c: function create() {
			bc_button = element("bc-button");
			set_custom_element_data(bc_button, "type", "secondary");
			set_custom_element_data(bc_button, "color", "white");
			set_custom_element_data(bc_button, "size", /*size*/ ctx[0]);
			set_custom_element_data(bc_button, "text", bc_button_text_value = /*i*/ ctx[38] + 1);
			set_custom_element_data(bc_button, "tooltiptext", "Information");
			set_custom_element_data(bc_button, "groupposition", "middle");

			set_custom_element_data(bc_button, "selected", bc_button_selected_value = /*currentPage*/ ctx[3] === /*i*/ ctx[38] + 1
			? 'true'
			: 'false');

			add_location(bc_button, file, 2191, 32, 43104);
		},
		m: function mount(target, anchor) {
			insert_dev(target, bc_button, anchor);

			if (!mounted) {
				dispose = listen_dev(bc_button, "click", click_handler_3, false, false, false, false);
				mounted = true;
			}
		},
		p: function update(new_ctx, dirty) {
			ctx = new_ctx;

			if (dirty[0] & /*size*/ 1) {
				set_custom_element_data(bc_button, "size", /*size*/ ctx[0]);
			}

			if (dirty[0] & /*currentPage*/ 8 && bc_button_selected_value !== (bc_button_selected_value = /*currentPage*/ ctx[3] === /*i*/ ctx[38] + 1
			? 'true'
			: 'false')) {
				set_custom_element_data(bc_button, "selected", bc_button_selected_value);
			}
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(bc_button);
			mounted = false;
			dispose();
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block_5.name,
		type: "if",
		source: "(2190:28) {#if i + 1 === currentPage - 2 || i + 1 === currentPage - 1 || i + 1 === currentPage || i + 1 === currentPage + 1 || i + 1 === currentPage + 2}",
		ctx
	});

	return block;
}

// (2189:24) {#each {length: numberOfPages} as _, i}
function create_each_block_1(ctx) {
	let if_block_anchor;
	let if_block = (/*i*/ ctx[38] + 1 === /*currentPage*/ ctx[3] - 2 || /*i*/ ctx[38] + 1 === /*currentPage*/ ctx[3] - 1 || /*i*/ ctx[38] + 1 === /*currentPage*/ ctx[3] || /*i*/ ctx[38] + 1 === /*currentPage*/ ctx[3] + 1 || /*i*/ ctx[38] + 1 === /*currentPage*/ ctx[3] + 2) && create_if_block_5(ctx);

	const block = {
		c: function create() {
			if (if_block) if_block.c();
			if_block_anchor = empty();
		},
		m: function mount(target, anchor) {
			if (if_block) if_block.m(target, anchor);
			insert_dev(target, if_block_anchor, anchor);
		},
		p: function update(ctx, dirty) {
			if (/*i*/ ctx[38] + 1 === /*currentPage*/ ctx[3] - 2 || /*i*/ ctx[38] + 1 === /*currentPage*/ ctx[3] - 1 || /*i*/ ctx[38] + 1 === /*currentPage*/ ctx[3] || /*i*/ ctx[38] + 1 === /*currentPage*/ ctx[3] + 1 || /*i*/ ctx[38] + 1 === /*currentPage*/ ctx[3] + 2) {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block_5(ctx);
					if_block.c();
					if_block.m(if_block_anchor.parentNode, if_block_anchor);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}
		},
		d: function destroy(detaching) {
			if (if_block) if_block.d(detaching);
			if (detaching) detach_dev(if_block_anchor);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_each_block_1.name,
		type: "each",
		source: "(2189:24) {#each {length: numberOfPages} as _, i}",
		ctx
	});

	return block;
}

// (2203:20) {#if currentPage >= numberOfPages - 2}
function create_if_block_2(ctx) {
	let bc_button;
	let t;
	let each_1_anchor;
	let mounted;
	let dispose;
	let each_value = { length: /*numberOfPages*/ ctx[4] };
	validate_each_argument(each_value);
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	const block = {
		c: function create() {
			bc_button = element("bc-button");
			t = space();

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			each_1_anchor = empty();
			set_custom_element_data(bc_button, "type", "secondary");
			set_custom_element_data(bc_button, "color", "white");
			set_custom_element_data(bc_button, "size", /*size*/ ctx[0]);
			set_custom_element_data(bc_button, "text", "...");
			set_custom_element_data(bc_button, "tooltiptext", "Information");
			set_custom_element_data(bc_button, "groupposition", "middle");
			add_location(bc_button, file, 2204, 24, 43829);
		},
		m: function mount(target, anchor) {
			insert_dev(target, bc_button, anchor);
			insert_dev(target, t, anchor);

			for (let i = 0; i < each_blocks.length; i += 1) {
				if (each_blocks[i]) {
					each_blocks[i].m(target, anchor);
				}
			}

			insert_dev(target, each_1_anchor, anchor);

			if (!mounted) {
				dispose = listen_dev(bc_button, "click", /*click_handler_5*/ ctx[25], false, false, false, false);
				mounted = true;
			}
		},
		p: function update(ctx, dirty) {
			if (dirty[0] & /*size*/ 1) {
				set_custom_element_data(bc_button, "size", /*size*/ ctx[0]);
			}

			if (dirty[0] & /*size, currentPage, goToPage, numberOfPages*/ 1049) {
				each_value = { length: /*numberOfPages*/ ctx[4] };
				validate_each_argument(each_value);
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(each_1_anchor.parentNode, each_1_anchor);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value.length;
			}
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(bc_button);
			if (detaching) detach_dev(t);
			destroy_each(each_blocks, detaching);
			if (detaching) detach_dev(each_1_anchor);
			mounted = false;
			dispose();
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block_2.name,
		type: "if",
		source: "(2203:20) {#if currentPage >= numberOfPages - 2}",
		ctx
	});

	return block;
}

// (2209:28) {#if i + 1 === numberOfPages - 4 || i + 1 === numberOfPages - 3 || i + 1 === numberOfPages - 2 || i + 1 === numberOfPages - 1 || i + 1 === numberOfPages}
function create_if_block_3(ctx) {
	let bc_button;
	let bc_button_text_value;
	let bc_button_selected_value;
	let mounted;
	let dispose;

	function click_handler_6(...args) {
		return /*click_handler_6*/ ctx[26](/*i*/ ctx[38], ...args);
	}

	const block = {
		c: function create() {
			bc_button = element("bc-button");
			set_custom_element_data(bc_button, "type", "secondary");
			set_custom_element_data(bc_button, "color", "white");
			set_custom_element_data(bc_button, "size", /*size*/ ctx[0]);
			set_custom_element_data(bc_button, "text", bc_button_text_value = /*i*/ ctx[38] + 1);
			set_custom_element_data(bc_button, "tooltiptext", "Information");
			set_custom_element_data(bc_button, "groupposition", "middle");

			set_custom_element_data(bc_button, "selected", bc_button_selected_value = /*currentPage*/ ctx[3] === /*i*/ ctx[38] + 1
			? 'true'
			: 'false');

			add_location(bc_button, file, 2209, 32, 44329);
		},
		m: function mount(target, anchor) {
			insert_dev(target, bc_button, anchor);

			if (!mounted) {
				dispose = listen_dev(bc_button, "click", click_handler_6, false, false, false, false);
				mounted = true;
			}
		},
		p: function update(new_ctx, dirty) {
			ctx = new_ctx;

			if (dirty[0] & /*size*/ 1) {
				set_custom_element_data(bc_button, "size", /*size*/ ctx[0]);
			}

			if (dirty[0] & /*currentPage*/ 8 && bc_button_selected_value !== (bc_button_selected_value = /*currentPage*/ ctx[3] === /*i*/ ctx[38] + 1
			? 'true'
			: 'false')) {
				set_custom_element_data(bc_button, "selected", bc_button_selected_value);
			}
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(bc_button);
			mounted = false;
			dispose();
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block_3.name,
		type: "if",
		source: "(2209:28) {#if i + 1 === numberOfPages - 4 || i + 1 === numberOfPages - 3 || i + 1 === numberOfPages - 2 || i + 1 === numberOfPages - 1 || i + 1 === numberOfPages}",
		ctx
	});

	return block;
}

// (2208:24) {#each {length: numberOfPages} as _, i}
function create_each_block(ctx) {
	let if_block_anchor;
	let if_block = (/*i*/ ctx[38] + 1 === /*numberOfPages*/ ctx[4] - 4 || /*i*/ ctx[38] + 1 === /*numberOfPages*/ ctx[4] - 3 || /*i*/ ctx[38] + 1 === /*numberOfPages*/ ctx[4] - 2 || /*i*/ ctx[38] + 1 === /*numberOfPages*/ ctx[4] - 1 || /*i*/ ctx[38] + 1 === /*numberOfPages*/ ctx[4]) && create_if_block_3(ctx);

	const block = {
		c: function create() {
			if (if_block) if_block.c();
			if_block_anchor = empty();
		},
		m: function mount(target, anchor) {
			if (if_block) if_block.m(target, anchor);
			insert_dev(target, if_block_anchor, anchor);
		},
		p: function update(ctx, dirty) {
			if (/*i*/ ctx[38] + 1 === /*numberOfPages*/ ctx[4] - 4 || /*i*/ ctx[38] + 1 === /*numberOfPages*/ ctx[4] - 3 || /*i*/ ctx[38] + 1 === /*numberOfPages*/ ctx[4] - 2 || /*i*/ ctx[38] + 1 === /*numberOfPages*/ ctx[4] - 1 || /*i*/ ctx[38] + 1 === /*numberOfPages*/ ctx[4]) {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block_3(ctx);
					if_block.c();
					if_block.m(if_block_anchor.parentNode, if_block_anchor);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}
		},
		d: function destroy(detaching) {
			if (if_block) if_block.d(detaching);
			if (detaching) detach_dev(if_block_anchor);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_each_block.name,
		type: "each",
		source: "(2208:24) {#each {length: numberOfPages} as _, i}",
		ctx
	});

	return block;
}

function create_fragment(ctx) {
	let div;

	function select_block_type(ctx, dirty) {
		if (/*ready*/ ctx[1]) return create_if_block;
		return create_else_block_1;
	}

	let current_block_type = select_block_type(ctx, [-1, -1]);
	let if_block = current_block_type(ctx);

	const block = {
		c: function create() {
			div = element("div");
			if_block.c();
			this.c = noop;
			add_location(div, file, 2156, 0, 40911);
		},
		l: function claim(nodes) {
			throw new Error("options.hydrate only works if the component was compiled with the `hydratable: true` option");
		},
		m: function mount(target, anchor) {
			insert_dev(target, div, anchor);
			if_block.m(div, null);
		},
		p: function update(ctx, dirty) {
			if (current_block_type === (current_block_type = select_block_type(ctx, dirty)) && if_block) {
				if_block.p(ctx, dirty);
			} else {
				if_block.d(1);
				if_block = current_block_type(ctx);

				if (if_block) {
					if_block.c();
					if_block.m(div, null);
				}
			}
		},
		i: noop,
		o: noop,
		d: function destroy(detaching) {
			if (detaching) detach_dev(div);
			if_block.d();
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_fragment.name,
		type: "component",
		source: "",
		ctx
	});

	return block;
}

function instance($$self, $$props, $$invalidate) {
	let $applicationReady;
	validate_store(applicationReady, 'applicationReady');
	component_subscribe($$self, applicationReady, $$value => $$invalidate(19, $applicationReady = $$value));
	let { $$slots: slots = {}, $$scope } = $$props;
	validate_slots('bc-pagination', slots, []);
	let { show = 'true' } = $$props;
	let { init = 'true' } = $$props;
	let { maxelements = '' } = $$props;
	let { pagesize = '' } = $$props;
	let { size = 'normal' } = $$props;

	// Base variables
	//================================================================
	let firstInitialized = false;

	let subscribeGuid = uuidv4();
	let ready = false;
	const generalText = getComponentText('general');
	const component = get_current_component();
	let mounted = false;
	let canReload = true;

	// Component variables
	//================================================================
	let itemsPerPage = 10;

	let currentPage = 1;
	let numberOfPages = 1;
	let itemsPerPageSelectBottom;

	// HTML Mount & Component initialization
	//================================================================
	onMount(() => {
		attachNormalize(component);

		tick().then(() => {
			dispatchMountedEvent(component);
			$$invalidate(18, mounted = true);
		});

		return () => {
			bc.messageService.unsubscribeComponent(subscribeGuid);
		};
	});

	const initComponent = async (size, max) => {
		$$invalidate(17, firstInitialized = true);

		if (size !== '' && max !== '') {
			$$invalidate(1, ready = false);
			await tick();
			$$invalidate(2, itemsPerPage = Number.parseInt(pagesize));
			$$invalidate(4, numberOfPages = Math.ceil(Number.parseInt(maxelements) / itemsPerPage));
			$$invalidate(1, ready = true);
		}
	};

	const applyProps = afterInit => {
		let shouldInit = afterInit && firstInitialized || !afterInit && !firstInitialized;

		if (shouldInit) {
			handleComponentProps(
				{
					ready: $applicationReady,
					componentMounted: mounted,
					init,
					firstInitialized,
					canReload,
					handler: initComponent
				},
				pagesize,
				maxelements
			);
		}
	};

	// Component functions
	//================================================================
	const changePageNumber = pageNumber => {
		$$invalidate(3, currentPage = pageNumber);

		let event = new Event('change',
		{
				bubbles: true,
				composed: true,
				cancelable: true
			});

		//@ts-ignore
		event.detail = currentPage;

		component.dispatchEvent(event);
	};

	const onItemsPerPageChange = () => {
		$$invalidate(2, itemsPerPage = +itemsPerPageSelectBottom.options[itemsPerPageSelectBottom.selectedIndex].value);
		$$invalidate(3, currentPage = 1);

		let event = new Event('size',
		{
				bubbles: false,
				composed: true,
				cancelable: true
			});

		//@ts-ignore
		event.detail = itemsPerPage;

		component.dispatchEvent(event);
	};

	const goToFirstPage = e => {
		if (currentPage !== 1) {
			changePageNumber(1);
		}
	};

	const goOnePageBack = e => {
		if (currentPage !== 1) {
			changePageNumber(1);
		}
	};

	const goToPage = pageNumber => {
		if (currentPage !== pageNumber) {
			changePageNumber(pageNumber);
		}
	};

	const goOnePageForth = e => {
		if (currentPage < numberOfPages) {
			changePageNumber(currentPage + 1);
		}
	};

	const goToLastPage = e => {
		if (currentPage !== numberOfPages) {
			changePageNumber(numberOfPages);
		}
	};

	const writable_props = ['show', 'init', 'maxelements', 'pagesize', 'size'];

	

	const click_handler = (i, e) => {
		goToPage(i + 1);
	};

	const click_handler_1 = e => {
		changePageNumber(6);
	};

	const click_handler_2 = e => {
		changePageNumber(currentPage - 3);
	};

	const click_handler_3 = (i, e) => {
		goToPage(i + 1);
	};

	const click_handler_4 = e => {
		changePageNumber(currentPage + 3);
	};

	const click_handler_5 = e => {
		changePageNumber(numberOfPages - 3);
	};

	const click_handler_6 = (i, e) => {
		goToPage(i + 1);
	};

	const click_handler_7 = (i, e) => {
		goToPage(i + 1);
	};

	function select_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			itemsPerPageSelectBottom = $$value;
			$$invalidate(5, itemsPerPageSelectBottom);
		});
	}

	const change_handler = e => {
		onItemsPerPageChange();
	};

	$$self.$$set = $$props => {
		if ('show' in $$props) $$invalidate(13, show = $$props.show);
		if ('init' in $$props) $$invalidate(14, init = $$props.init);
		if ('maxelements' in $$props) $$invalidate(15, maxelements = $$props.maxelements);
		if ('pagesize' in $$props) $$invalidate(16, pagesize = $$props.pagesize);
		if ('size' in $$props) $$invalidate(0, size = $$props.size);
	};

	$$self.$capture_state = () => ({
		bc,
		uuidv4,
		onMount,
		tick,
		get_current_component,
		attachNormalize,
		dispatchMountedEvent,
		handleComponentInitAttribute,
		handleComponentProps,
		handleComponentShowAttribute,
		applicationReady,
		getComponentText,
		show,
		init,
		maxelements,
		pagesize,
		size,
		firstInitialized,
		subscribeGuid,
		ready,
		generalText,
		component,
		mounted,
		canReload,
		itemsPerPage,
		currentPage,
		numberOfPages,
		itemsPerPageSelectBottom,
		initComponent,
		applyProps,
		changePageNumber,
		onItemsPerPageChange,
		goToFirstPage,
		goOnePageBack,
		goToPage,
		goOnePageForth,
		goToLastPage,
		$applicationReady
	});

	$$self.$inject_state = $$props => {
		if ('show' in $$props) $$invalidate(13, show = $$props.show);
		if ('init' in $$props) $$invalidate(14, init = $$props.init);
		if ('maxelements' in $$props) $$invalidate(15, maxelements = $$props.maxelements);
		if ('pagesize' in $$props) $$invalidate(16, pagesize = $$props.pagesize);
		if ('size' in $$props) $$invalidate(0, size = $$props.size);
		if ('firstInitialized' in $$props) $$invalidate(17, firstInitialized = $$props.firstInitialized);
		if ('subscribeGuid' in $$props) subscribeGuid = $$props.subscribeGuid;
		if ('ready' in $$props) $$invalidate(1, ready = $$props.ready);
		if ('mounted' in $$props) $$invalidate(18, mounted = $$props.mounted);
		if ('canReload' in $$props) canReload = $$props.canReload;
		if ('itemsPerPage' in $$props) $$invalidate(2, itemsPerPage = $$props.itemsPerPage);
		if ('currentPage' in $$props) $$invalidate(3, currentPage = $$props.currentPage);
		if ('numberOfPages' in $$props) $$invalidate(4, numberOfPages = $$props.numberOfPages);
		if ('itemsPerPageSelectBottom' in $$props) $$invalidate(5, itemsPerPageSelectBottom = $$props.itemsPerPageSelectBottom);
	};

	if ($$props && "$$inject" in $$props) {
		$$self.$inject_state($$props.$$inject);
	}

	$$self.$$.update = () => {
		if ($$self.$$.dirty[0] & /*show*/ 8192) {
			$: if (show) {
				handleComponentShowAttribute(show, component);
			}
		}

		if ($$self.$$.dirty[0] & /*init, firstInitialized, mounted, $applicationReady*/ 933888) {
			/**
 * Handle the component init attribute, for the case that the component has not been initialized yet.
 */
			$: handleComponentInitAttribute(init, firstInitialized, mounted, $applicationReady, applyProps);
		}

		if ($$self.$$.dirty[0] & /*pagesize, maxelements*/ 98304) {
			/**
 * Handles any component attribute changes after the component is initialized.
 */
			$: (pagesize, maxelements, applyProps(true));
		}
	};

	return [
		size,
		ready,
		itemsPerPage,
		currentPage,
		numberOfPages,
		itemsPerPageSelectBottom,
		changePageNumber,
		onItemsPerPageChange,
		goToFirstPage,
		goOnePageBack,
		goToPage,
		goOnePageForth,
		goToLastPage,
		show,
		init,
		maxelements,
		pagesize,
		firstInitialized,
		mounted,
		$applicationReady,
		click_handler,
		click_handler_1,
		click_handler_2,
		click_handler_3,
		click_handler_4,
		click_handler_5,
		click_handler_6,
		click_handler_7,
		select_binding,
		change_handler
	];
}

class Pagination extends SvelteElement {
	constructor(options) {
		super();
		const style = document.createElement('style');
		style.textContent = `100%{left:0;right:0}100%{left:0;right:0}100%{opacity:1}100%{opacity:1}45%{-webkit-transform:scale(var(--fa-beat-scale, 1.25));transform:scale(var(--fa-beat-scale, 1.25))}45%{-webkit-transform:scale(var(--fa-beat-scale, 1.25));transform:scale(var(--fa-beat-scale, 1.25))}50%{opacity:1;-webkit-transform:scale(var(--fa-beat-fade-scale, 1.125));transform:scale(var(--fa-beat-fade-scale, 1.125))}50%{opacity:1;-webkit-transform:scale(var(--fa-beat-fade-scale, 1.125));transform:scale(var(--fa-beat-fade-scale, 1.125))}100%{-webkit-transform:rotate(360deg);transform:rotate(360deg)}100%{-webkit-transform:rotate(360deg);transform:rotate(360deg)}.border-gray-300{--tw-border-opacity:1;border-color:rgba(209, 213, 219, var(--tw-border-opacity))}.rounded{border-radius:0.25rem}.border-solid{border-style:solid}.border{border-width:1px}.flex{display:-webkit-box;display:-ms-flexbox;display:-webkit-flex;display:flex}.hidden{display:none}.flex-wrap{-ms-flex-wrap:wrap;-webkit-flex-wrap:wrap;flex-wrap:wrap}.items-start{-webkit-box-align:start;-ms-flex-align:start;-webkit-align-items:flex-start;align-items:flex-start}.items-center{-webkit-box-align:center;-ms-flex-align:center;-webkit-align-items:center;align-items:center}.h-10{height:2.5rem}.h-8{height:2rem}.outline-none{outline:2px solid transparent;outline-offset:2px}.focus\\:outline-none:focus{outline:2px solid transparent;outline-offset:2px}.p-2{padding:0.5rem}.gap-4{grid-gap:1rem;gap:1rem}@media(min-width: 768px){.md\\:block{display:block}}`;
		this.shadowRoot.appendChild(style);

		init_1(
			this,
			{
				target: this.shadowRoot,
				props: attribute_to_object(this.attributes),
				customElement: true
			},
			instance,
			create_fragment,
			safe_not_equal,
			{
				show: 13,
				init: 14,
				maxelements: 15,
				pagesize: 16,
				size: 0
			},
			null,
			[-1, -1]
		);

		if (options) {
			if (options.target) {
				insert_dev(options.target, this, options.anchor);
			}

			if (options.props) {
				this.$set(options.props);
				flush();
			}
		}
	}

	static get observedAttributes() {
		return ["show", "init", "maxelements", "pagesize", "size"];
	}

	get show() {
		return this.$$.ctx[13];
	}

	set show(show) {
		this.$$set({ show });
		flush();
	}

	get init() {
		return this.$$.ctx[14];
	}

	set init(init) {
		this.$$set({ init });
		flush();
	}

	get maxelements() {
		return this.$$.ctx[15];
	}

	set maxelements(maxelements) {
		this.$$set({ maxelements });
		flush();
	}

	get pagesize() {
		return this.$$.ctx[16];
	}

	set pagesize(pagesize) {
		this.$$set({ pagesize });
		flush();
	}

	get size() {
		return this.$$.ctx[0];
	}

	set size(size) {
		this.$$set({ size });
		flush();
	}
}

customElements.define("bc-pagination", Pagination);
export default Pagination;
import qs from "../../web_modules/qs.js";
import bc from "../bcShim.js";
export const createNavigationLink = (metadata, viewguid, sourceviewguid, objectid, entityguid, documententity) => {
  let baseURL = bc.parameterService.appParameters.pageName;
  if (baseURL.endsWith("/")) {
    baseURL = baseURL.substring(0, baseURL.length - 1);
  }
  let parameterString = "";
  switch (metadata.resourceName) {
    case "UgdmActions": {
      let urlObject = {
        dataviewGuid: sourceviewguid,
        entityGuid: entityguid,
        actionGuid: objectid,
        entityTab: {type: "actions", value: viewguid}
      };
      parameterString = qs.stringify(urlObject);
      break;
    }
    case "UgdmTypes": {
      return null;
    }
    case "UgdmArtifacts": {
      let urlObject = {
        dataviewGuid: sourceviewguid,
        entityGuid: documententity,
        documentGuid: objectid,
        entityTab: {type: "documents"}
      };
      parameterString = qs.stringify(urlObject);
      break;
    }
    default: {
      let urlObject = {
        dataviewGuid: viewguid,
        entityGuid: objectid
      };
      parameterString = qs.stringify(urlObject);
      break;
    }
  }
  if (parameterString) {
    return baseURL + "?" + parameterString;
  }
  return null;
};
export let lastStatesPerDataview = [];

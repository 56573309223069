export const successLog = (file, message, ...args) => {
  let baseStyles = ["color: white", "background-color: green", "padding: 2px 6px", "border-radius: 2px"].join(";");
  console.log(`%c${file.toUpperCase()}`, baseStyles, message, ...args);
};
export const infoLog = (file, message, ...args) => {
  let baseStyles = ["color: white", "background-color: #2563eb", "padding: 2px 6px", "border-radius: 2px"].join(";");
  console.log(`%c${file.toUpperCase()}`, baseStyles, message, ...args);
};
export const errorLog = (file, message, ...args) => {
  let baseStyles = ["color: white", "background-color: red", "padding: 2px 6px", "border-radius: 2px"].join(";");
  console.log(`%c${file.toUpperCase()}`, baseStyles, message, ...args);
};
export const warningLog = (file, message, ...args) => {
  let baseStyles = ["color: white", "background-color: orange", "padding: 2px 6px", "border-radius: 2px"].join(";");
  console.log(`%c${file.toUpperCase()}`, baseStyles, message, ...args);
};

/* src\components\URLParameterDispatcher\UrlParameterDispatcher.svelte generated by Svelte v3.57.0 */
import {
	SvelteElement,
	add_location,
	attr_dev,
	attribute_to_object,
	binding_callbacks,
	component_subscribe,
	detach_dev,
	dispatch_dev,
	element,
	flush,
	init as init_1,
	insert_dev,
	noop,
	safe_not_equal,
	validate_slots,
	validate_store
} from "../../../web_modules/svelte/internal.js";

import bc from '../../bcShim.js';
import { v4 as uuidv4 } from '../../../web_modules/uuid.js';
import { onMount, tick } from '../../../web_modules/svelte.js';

import {
	attachNormalize,
	componentInitAndAttributeHandler,
	dispatchMountedEvent,
	handleComponentShowAttribute
} from "../../utilities/componentUtils.js";

import { get_current_component } from "../../../web_modules/svelte/internal.js";
import { applicationReady } from "../../utilities/store.js";
import { getComponentText } from "../../utilities/generalUtils.js";
const file = "src\\components\\URLParameterDispatcher\\UrlParameterDispatcher.svelte";

// (2042:4) {#if ready}
function create_if_block(ctx) {
	let slot;

	const block = {
		c: function create() {
			slot = element("slot");
			add_location(slot, file, 2042, 8, 38298);
		},
		m: function mount(target, anchor) {
			insert_dev(target, slot, anchor);
			/*slot_binding*/ ctx[7](slot);
		},
		p: noop,
		d: function destroy(detaching) {
			if (detaching) detach_dev(slot);
			/*slot_binding*/ ctx[7](null);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block.name,
		type: "if",
		source: "(2042:4) {#if ready}",
		ctx
	});

	return block;
}

function create_fragment(ctx) {
	let div;
	let if_block = /*ready*/ ctx[1] && create_if_block(ctx);

	const block = {
		c: function create() {
			div = element("div");
			if (if_block) if_block.c();
			this.c = noop;
			attr_dev(div, "class", "w-full h-full");
			add_location(div, file, 2040, 0, 38244);
		},
		l: function claim(nodes) {
			throw new Error("options.hydrate only works if the component was compiled with the `hydratable: true` option");
		},
		m: function mount(target, anchor) {
			insert_dev(target, div, anchor);
			if (if_block) if_block.m(div, null);
		},
		p: function update(ctx, [dirty]) {
			if (/*ready*/ ctx[1]) {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block(ctx);
					if_block.c();
					if_block.m(div, null);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}
		},
		i: noop,
		o: noop,
		d: function destroy(detaching) {
			if (detaching) detach_dev(div);
			if (if_block) if_block.d();
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_fragment.name,
		type: "component",
		source: "",
		ctx
	});

	return block;
}

function instance($$self, $$props, $$invalidate) {
	let $applicationReady;
	validate_store(applicationReady, 'applicationReady');
	component_subscribe($$self, applicationReady, $$value => $$invalidate(6, $applicationReady = $$value));
	let { $$slots: slots = {}, $$scope } = $$props;
	validate_slots('url-parameter-dispatcher', slots, []);
	let { show = 'true' } = $$props;
	let { init = 'true' } = $$props;

	// Base variables
	//================================================================
	let component = get_current_component();

	let firstInitialized = false;
	let subscribeGuid = uuidv4();
	let ready = false;
	let urlParams = [];
	let componentSlot;
	const generalText = getComponentText('general');
	let mounted = false;
	let canReload = false;

	// HTML Mount & Component initialization
	//================================================================
	onMount(() => {
		attachNormalize(component);

		tick().then(() => {
			dispatchMountedEvent(component);
			$$invalidate(5, mounted = true);
		});

		return () => {
			bc.messageService.unsubscribeComponent(subscribeGuid);
		};
	});

	const initComponent = () => {
		$$invalidate(4, firstInitialized = true);
		let params = new URLSearchParams(window.location.search);
		const entries = params.entries();

		for (const entry of entries) {
			urlParams = [...urlParams, { attribute: entry[0], value: entry[1] }];
		}

		$$invalidate(1, ready = true);
	};

	// Component functions
	//================================================================
	const handleSlot = slot => {
		if (slot) {
			slot.addEventListener('slotchange', e => {
				let slotContent = slot.assignedElements()[0];

				for (let i = 0; i < urlParams.length; i++) {
					slotContent.setAttribute(urlParams[i].attribute.toLowerCase(), urlParams[i].value);
				}
			});
		}
	};

	const writable_props = ['show', 'init'];

	

	function slot_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			componentSlot = $$value;
			$$invalidate(0, componentSlot);
		});
	}

	$$self.$$set = $$props => {
		if ('show' in $$props) $$invalidate(2, show = $$props.show);
		if ('init' in $$props) $$invalidate(3, init = $$props.init);
	};

	$$self.$capture_state = () => ({
		bc,
		uuidv4,
		onMount,
		tick,
		attachNormalize,
		componentInitAndAttributeHandler,
		dispatchMountedEvent,
		handleComponentShowAttribute,
		get_current_component,
		applicationReady,
		getComponentText,
		show,
		init,
		component,
		firstInitialized,
		subscribeGuid,
		ready,
		urlParams,
		componentSlot,
		generalText,
		mounted,
		canReload,
		initComponent,
		handleSlot,
		$applicationReady
	});

	$$self.$inject_state = $$props => {
		if ('show' in $$props) $$invalidate(2, show = $$props.show);
		if ('init' in $$props) $$invalidate(3, init = $$props.init);
		if ('component' in $$props) $$invalidate(9, component = $$props.component);
		if ('firstInitialized' in $$props) $$invalidate(4, firstInitialized = $$props.firstInitialized);
		if ('subscribeGuid' in $$props) subscribeGuid = $$props.subscribeGuid;
		if ('ready' in $$props) $$invalidate(1, ready = $$props.ready);
		if ('urlParams' in $$props) urlParams = $$props.urlParams;
		if ('componentSlot' in $$props) $$invalidate(0, componentSlot = $$props.componentSlot);
		if ('mounted' in $$props) $$invalidate(5, mounted = $$props.mounted);
		if ('canReload' in $$props) $$invalidate(12, canReload = $$props.canReload);
	};

	if ($$props && "$$inject" in $$props) {
		$$self.$inject_state($$props.$$inject);
	}

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*show*/ 4) {
			$: if (show) {
				handleComponentShowAttribute(show, component);
			}
		}

		if ($$self.$$.dirty & /*$applicationReady, mounted, init, firstInitialized*/ 120) {
			$: if ($applicationReady && mounted && init) {
				componentInitAndAttributeHandler({
					mounted,
					handler: initComponent,
					init,
					firstInitialized,
					canReload
				});
			}
		}

		if ($$self.$$.dirty & /*componentSlot*/ 1) {
			$: handleSlot(componentSlot);
		}
	};

	return [
		componentSlot,
		ready,
		show,
		init,
		firstInitialized,
		mounted,
		$applicationReady,
		slot_binding
	];
}

class UrlParameterDispatcher extends SvelteElement {
	constructor(options) {
		super();
		const style = document.createElement('style');
		style.textContent = `100%{left:0;right:0}100%{left:0;right:0}100%{opacity:1}100%{opacity:1}45%{-webkit-transform:scale(var(--fa-beat-scale, 1.25));transform:scale(var(--fa-beat-scale, 1.25))}45%{-webkit-transform:scale(var(--fa-beat-scale, 1.25));transform:scale(var(--fa-beat-scale, 1.25))}50%{opacity:1;-webkit-transform:scale(var(--fa-beat-fade-scale, 1.125));transform:scale(var(--fa-beat-fade-scale, 1.125))}50%{opacity:1;-webkit-transform:scale(var(--fa-beat-fade-scale, 1.125));transform:scale(var(--fa-beat-fade-scale, 1.125))}100%{-webkit-transform:rotate(360deg);transform:rotate(360deg)}100%{-webkit-transform:rotate(360deg);transform:rotate(360deg)}.h-full{height:100%}.w-full{width:100%}`;
		this.shadowRoot.appendChild(style);

		init_1(
			this,
			{
				target: this.shadowRoot,
				props: attribute_to_object(this.attributes),
				customElement: true
			},
			instance,
			create_fragment,
			safe_not_equal,
			{ show: 2, init: 3 },
			null
		);

		if (options) {
			if (options.target) {
				insert_dev(options.target, this, options.anchor);
			}

			if (options.props) {
				this.$set(options.props);
				flush();
			}
		}
	}

	static get observedAttributes() {
		return ["show", "init"];
	}

	get show() {
		return this.$$.ctx[2];
	}

	set show(show) {
		this.$$set({ show });
		flush();
	}

	get init() {
		return this.$$.ctx[3];
	}

	set init(init) {
		this.$$set({ init });
		flush();
	}
}

customElements.define("url-parameter-dispatcher", UrlParameterDispatcher);
export default UrlParameterDispatcher;
/* src\components\Entity\FormField.svelte generated by Svelte v3.57.0 */
import {
	SvelteElement,
	add_location,
	append_dev,
	attr_dev,
	attribute_to_object,
	binding_callbacks,
	component_subscribe,
	detach_dev,
	dispatch_dev,
	element,
	empty,
	flush,
	globals,
	init as init_1,
	insert_dev,
	listen_dev,
	noop,
	safe_not_equal,
	set_custom_element_data,
	space,
	validate_slots,
	validate_store
} from "../../../web_modules/svelte/internal.js";

const { Object: Object_1, console: console_1 } = globals;
import bc from '../../bcShim.js';
import { v4 as uuidv4 } from '../../../web_modules/uuid.js';
import { onMount, tick } from '../../../web_modules/svelte.js';
import { requestHeaders } from '../../services/ajaxService.js';

import {
	getNullablesMap,
	getSimpleDisplayValue,
	getUniqueMap,
	parseFieldGroups
} from '../../utilities/formUtilities.js';

import '../../services/parameterService.js';
import { roundOff } from '../Map/map-utils.js';
import { get_current_component } from "../../../web_modules/svelte/internal.js";
import { constants, numericFieldTypes } from "../../constants.js";
import "../../interfaces.js";
import "../../../web_modules/sweetalert2.js";

import {
	addValuesToAdvancedFilter,
	createFilter
} from "../../utilities/filterUtils.js";

import { infoLog } from "../../utilities/consoleUtils.js";
import "../../utilities/dateUtils.js";
import { sizeObserver } from "../../utilities/visibilityResizeUtils.js";

import {
	attachNormalize,
	dispatchMountedEvent,
	handleComponentInitAttribute,
	handleComponentProps,
	handleComponentShowAttribute,
	updateComponentAttribute
} from "../../utilities/componentUtils.js";

import {
	appendErrorInformation,
	errorToast,
	handleErrorInAlert,
	notify,
	success
} from "../../utilities/alertNotificationUtils.js";

import {
	getObjectItem,
	updateEntity,
	getValidationEntry
} from "../../utilities/entityUtils.js";

import { isUserDefinedView, metadataHasGeo } from "../../utilities/dataviewUtils.js";
import { getComponentText } from "../../utilities/generalUtils.js";
import { applicationReady, isMobileStore } from "../../utilities/store.js";
const file = "src\\components\\Entity\\FormField.svelte";

// (3742:4) {#if !ready}
function create_if_block_11(ctx) {
	let loading_spinner;
	let loading_spinner_text_value;

	const block = {
		c: function create() {
			loading_spinner = element("loading-spinner");
			set_custom_element_data(loading_spinner, "text", loading_spinner_text_value = /*generalText*/ ctx[18].loading);
			add_location(loading_spinner, file, 3742, 8, 117575);
		},
		m: function mount(target, anchor) {
			insert_dev(target, loading_spinner, anchor);
		},
		p: noop,
		d: function destroy(detaching) {
			if (detaching) detach_dev(loading_spinner);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block_11.name,
		type: "if",
		source: "(3742:4) {#if !ready}",
		ctx
	});

	return block;
}

// (3746:4) {#if ready && initError}
function create_if_block_10(ctx) {
	let div1;
	let div0;
	let p;

	const block = {
		c: function create() {
			div1 = element("div");
			div0 = element("div");
			p = element("p");
			p.textContent = `${/*generalText*/ ctx[18].componentInitError}`;
			add_location(p, file, 3748, 16, 117827);
			attr_dev(div0, "class", "flex items-center justify-center bg-gray-50 border border-solid border-gray-300 rounded p-4");
			add_location(div0, file, 3747, 12, 117704);
			attr_dev(div1, "class", "p-4");
			add_location(div1, file, 3746, 8, 117673);
		},
		m: function mount(target, anchor) {
			insert_dev(target, div1, anchor);
			append_dev(div1, div0);
			append_dev(div0, p);
		},
		p: noop,
		d: function destroy(detaching) {
			if (detaching) detach_dev(div1);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block_10.name,
		type: "if",
		source: "(3746:4) {#if ready && initError}",
		ctx
	});

	return block;
}

// (3754:4) {#if ready && !initError}
function create_if_block(ctx) {
	let if_block_anchor;

	function select_block_type(ctx, dirty) {
		if (/*datatype*/ ctx[1] === 'List' || /*datatype*/ ctx[1] === 'EntityList') return create_if_block_1;
		return create_else_block;
	}

	let current_block_type = select_block_type(ctx, [-1, -1, -1, -1, -1, -1]);
	let if_block = current_block_type(ctx);

	const block = {
		c: function create() {
			if_block.c();
			if_block_anchor = empty();
		},
		m: function mount(target, anchor) {
			if_block.m(target, anchor);
			insert_dev(target, if_block_anchor, anchor);
		},
		p: function update(ctx, dirty) {
			if (current_block_type === (current_block_type = select_block_type(ctx, dirty)) && if_block) {
				if_block.p(ctx, dirty);
			} else {
				if_block.d(1);
				if_block = current_block_type(ctx);

				if (if_block) {
					if_block.c();
					if_block.m(if_block_anchor.parentNode, if_block_anchor);
				}
			}
		},
		d: function destroy(detaching) {
			if_block.d(detaching);
			if (detaching) detach_dev(if_block_anchor);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block.name,
		type: "if",
		source: "(3754:4) {#if ready && !initError}",
		ctx
	});

	return block;
}

// (3776:8) {:else}
function create_else_block(ctx) {
	let t0;
	let show_if_1;
	let show_if_2;
	let t1;
	let t2;
	let show_if = numericFieldTypes.includes(/*datatype*/ ctx[1]);
	let t3;
	let t4;
	let if_block5_anchor;
	let if_block0 = /*datatype*/ ctx[1] === 'Guid' && create_if_block_9(ctx);

	function select_block_type_1(ctx, dirty) {
		if (dirty[0] & /*datatype, metadata, fieldvalidation, textfieldlength*/ 1031) show_if_1 = null;
		if (dirty[0] & /*datatype, metadata, fieldvalidation, textfieldlength*/ 1031) show_if_2 = null;
		if (show_if_1 == null) show_if_1 = !!(/*datatype*/ ctx[1] === 'String' && (/*metadata*/ ctx[0].getField(/*fieldvalidation*/ ctx[2].column) !== null && /*metadata*/ ctx[0].getField(/*fieldvalidation*/ ctx[2].column).viewLength <= /*textfieldlength*/ ctx[10] || /*metadata*/ ctx[0].getTypeLength(/*fieldvalidation*/ ctx[2].column) !== null && /*metadata*/ ctx[0].getTypeLength(/*fieldvalidation*/ ctx[2].column) <= /*textfieldlength*/ ctx[10]));
		if (show_if_1) return create_if_block_7;
		if (show_if_2 == null) show_if_2 = !!(/*datatype*/ ctx[1] === 'String' && (/*metadata*/ ctx[0].getField(/*fieldvalidation*/ ctx[2].column) !== null && /*metadata*/ ctx[0].getField(/*fieldvalidation*/ ctx[2].column).viewLength > /*textfieldlength*/ ctx[10] || /*metadata*/ ctx[0].getTypeLength(/*fieldvalidation*/ ctx[2].column) === null || /*metadata*/ ctx[0].getTypeLength(/*fieldvalidation*/ ctx[2].column) > /*textfieldlength*/ ctx[10]));
		if (show_if_2) return create_if_block_8;
	}

	let current_block_type = select_block_type_1(ctx, [-1, -1, -1, -1, -1, -1]);
	let if_block1 = current_block_type && current_block_type(ctx);
	let if_block2 = /*datatype*/ ctx[1] === 'Binary' && create_if_block_6(ctx);
	let if_block3 = show_if && create_if_block_4(ctx);
	let if_block4 = /*datatype*/ ctx[1] === 'DateTime' && create_if_block_3(ctx);
	let if_block5 = /*datatype*/ ctx[1] === 'Boolean' && create_if_block_2(ctx);

	const block = {
		c: function create() {
			if (if_block0) if_block0.c();
			t0 = space();
			if (if_block1) if_block1.c();
			t1 = space();
			if (if_block2) if_block2.c();
			t2 = space();
			if (if_block3) if_block3.c();
			t3 = space();
			if (if_block4) if_block4.c();
			t4 = space();
			if (if_block5) if_block5.c();
			if_block5_anchor = empty();
		},
		m: function mount(target, anchor) {
			if (if_block0) if_block0.m(target, anchor);
			insert_dev(target, t0, anchor);
			if (if_block1) if_block1.m(target, anchor);
			insert_dev(target, t1, anchor);
			if (if_block2) if_block2.m(target, anchor);
			insert_dev(target, t2, anchor);
			if (if_block3) if_block3.m(target, anchor);
			insert_dev(target, t3, anchor);
			if (if_block4) if_block4.m(target, anchor);
			insert_dev(target, t4, anchor);
			if (if_block5) if_block5.m(target, anchor);
			insert_dev(target, if_block5_anchor, anchor);
		},
		p: function update(ctx, dirty) {
			if (/*datatype*/ ctx[1] === 'Guid') {
				if (if_block0) {
					if_block0.p(ctx, dirty);
				} else {
					if_block0 = create_if_block_9(ctx);
					if_block0.c();
					if_block0.m(t0.parentNode, t0);
				}
			} else if (if_block0) {
				if_block0.d(1);
				if_block0 = null;
			}

			if (current_block_type === (current_block_type = select_block_type_1(ctx, dirty)) && if_block1) {
				if_block1.p(ctx, dirty);
			} else {
				if (if_block1) if_block1.d(1);
				if_block1 = current_block_type && current_block_type(ctx);

				if (if_block1) {
					if_block1.c();
					if_block1.m(t1.parentNode, t1);
				}
			}

			if (/*datatype*/ ctx[1] === 'Binary') {
				if (if_block2) {
					if_block2.p(ctx, dirty);
				} else {
					if_block2 = create_if_block_6(ctx);
					if_block2.c();
					if_block2.m(t2.parentNode, t2);
				}
			} else if (if_block2) {
				if_block2.d(1);
				if_block2 = null;
			}

			if (dirty[0] & /*datatype*/ 2) show_if = numericFieldTypes.includes(/*datatype*/ ctx[1]);

			if (show_if) {
				if (if_block3) {
					if_block3.p(ctx, dirty);
				} else {
					if_block3 = create_if_block_4(ctx);
					if_block3.c();
					if_block3.m(t3.parentNode, t3);
				}
			} else if (if_block3) {
				if_block3.d(1);
				if_block3 = null;
			}

			if (/*datatype*/ ctx[1] === 'DateTime') {
				if (if_block4) {
					if_block4.p(ctx, dirty);
				} else {
					if_block4 = create_if_block_3(ctx);
					if_block4.c();
					if_block4.m(t4.parentNode, t4);
				}
			} else if (if_block4) {
				if_block4.d(1);
				if_block4 = null;
			}

			if (/*datatype*/ ctx[1] === 'Boolean') {
				if (if_block5) {
					if_block5.p(ctx, dirty);
				} else {
					if_block5 = create_if_block_2(ctx);
					if_block5.c();
					if_block5.m(if_block5_anchor.parentNode, if_block5_anchor);
				}
			} else if (if_block5) {
				if_block5.d(1);
				if_block5 = null;
			}
		},
		d: function destroy(detaching) {
			if (if_block0) if_block0.d(detaching);
			if (detaching) detach_dev(t0);

			if (if_block1) {
				if_block1.d(detaching);
			}

			if (detaching) detach_dev(t1);
			if (if_block2) if_block2.d(detaching);
			if (detaching) detach_dev(t2);
			if (if_block3) if_block3.d(detaching);
			if (detaching) detach_dev(t3);
			if (if_block4) if_block4.d(detaching);
			if (detaching) detach_dev(t4);
			if (if_block5) if_block5.d(detaching);
			if (detaching) detach_dev(if_block5_anchor);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_else_block.name,
		type: "else",
		source: "(3776:8) {:else}",
		ctx
	});

	return block;
}

// (3758:8) {#if datatype === 'List' || datatype === 'EntityList'}
function create_if_block_1(ctx) {
	let drop_down;
	let drop_down_isvalid_value;
	let drop_down_haschanged_value;
	let drop_down_defaultvalue_value;
	let drop_down_description_value;
	let drop_down_column_value;
	let mounted;
	let dispose;

	const block = {
		c: function create() {
			drop_down = element("drop-down");
			set_custom_element_data(drop_down, "isvalid", drop_down_isvalid_value = /*fieldvalidation*/ ctx[2].valid ? 'true' : 'false');
			set_custom_element_data(drop_down, "haschanged", drop_down_haschanged_value = /*fieldvalidation*/ ctx[2].changed ? 'true' : 'false');
			set_custom_element_data(drop_down, "label", /*label*/ ctx[3]);
			set_custom_element_data(drop_down, "defaultvalue", drop_down_defaultvalue_value = /*fieldvalidation*/ ctx[2].value);
			set_custom_element_data(drop_down, "description", drop_down_description_value = /*description*/ ctx[4] ? /*description*/ ctx[4] : '');
			set_custom_element_data(drop_down, "dataviewguid", /*dataviewguid*/ ctx[9]);
			set_custom_element_data(drop_down, "placeholder", "");
			set_custom_element_data(drop_down, "display", "stack");
			set_custom_element_data(drop_down, "column", drop_down_column_value = /*fieldvalidation*/ ctx[2].column);
			set_custom_element_data(drop_down, "iseditable", /*iseditable*/ ctx[5]);
			set_custom_element_data(drop_down, "isnullable", /*isnullable*/ ctx[6]);
			set_custom_element_data(drop_down, "showlabel", "true");
			set_custom_element_data(drop_down, "filter", /*filter*/ ctx[7]);
			set_custom_element_data(drop_down, "reversefilter", /*reversefilter*/ ctx[8]);
			set_custom_element_data(drop_down, "formid", /*componentId*/ ctx[14]);
			add_location(drop_down, file, 3758, 12, 118038);
		},
		m: function mount(target, anchor) {
			insert_dev(target, drop_down, anchor);

			if (!mounted) {
				dispose = listen_dev(drop_down, "change", /*change_handler*/ ctx[75], false, false, false, false);
				mounted = true;
			}
		},
		p: function update(ctx, dirty) {
			if (dirty[0] & /*fieldvalidation*/ 4 && drop_down_isvalid_value !== (drop_down_isvalid_value = /*fieldvalidation*/ ctx[2].valid ? 'true' : 'false')) {
				set_custom_element_data(drop_down, "isvalid", drop_down_isvalid_value);
			}

			if (dirty[0] & /*fieldvalidation*/ 4 && drop_down_haschanged_value !== (drop_down_haschanged_value = /*fieldvalidation*/ ctx[2].changed ? 'true' : 'false')) {
				set_custom_element_data(drop_down, "haschanged", drop_down_haschanged_value);
			}

			if (dirty[0] & /*label*/ 8) {
				set_custom_element_data(drop_down, "label", /*label*/ ctx[3]);
			}

			if (dirty[0] & /*fieldvalidation*/ 4 && drop_down_defaultvalue_value !== (drop_down_defaultvalue_value = /*fieldvalidation*/ ctx[2].value)) {
				set_custom_element_data(drop_down, "defaultvalue", drop_down_defaultvalue_value);
			}

			if (dirty[0] & /*description*/ 16 && drop_down_description_value !== (drop_down_description_value = /*description*/ ctx[4] ? /*description*/ ctx[4] : '')) {
				set_custom_element_data(drop_down, "description", drop_down_description_value);
			}

			if (dirty[0] & /*dataviewguid*/ 512) {
				set_custom_element_data(drop_down, "dataviewguid", /*dataviewguid*/ ctx[9]);
			}

			if (dirty[0] & /*fieldvalidation*/ 4 && drop_down_column_value !== (drop_down_column_value = /*fieldvalidation*/ ctx[2].column)) {
				set_custom_element_data(drop_down, "column", drop_down_column_value);
			}

			if (dirty[0] & /*iseditable*/ 32) {
				set_custom_element_data(drop_down, "iseditable", /*iseditable*/ ctx[5]);
			}

			if (dirty[0] & /*isnullable*/ 64) {
				set_custom_element_data(drop_down, "isnullable", /*isnullable*/ ctx[6]);
			}

			if (dirty[0] & /*filter*/ 128) {
				set_custom_element_data(drop_down, "filter", /*filter*/ ctx[7]);
			}

			if (dirty[0] & /*reversefilter*/ 256) {
				set_custom_element_data(drop_down, "reversefilter", /*reversefilter*/ ctx[8]);
			}

			if (dirty[0] & /*componentId*/ 16384) {
				set_custom_element_data(drop_down, "formid", /*componentId*/ ctx[14]);
			}
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(drop_down);
			mounted = false;
			dispose();
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block_1.name,
		type: "if",
		source: "(3758:8) {#if datatype === 'List' || datatype === 'EntityList'}",
		ctx
	});

	return block;
}

// (3777:12) {#if datatype === 'Guid'}
function create_if_block_9(ctx) {
	let text_field;
	let text_field_isvalid_value;
	let text_field_haschanged_value;
	let text_field_value_value;
	let text_field_description_value;
	let text_field_column_value;
	let text_field_maxlength_value;
	let mounted;
	let dispose;

	const block = {
		c: function create() {
			text_field = element("text-field");
			set_custom_element_data(text_field, "isvalid", text_field_isvalid_value = /*fieldvalidation*/ ctx[2].valid ? 'true' : 'false');
			set_custom_element_data(text_field, "haschanged", text_field_haschanged_value = /*fieldvalidation*/ ctx[2].changed ? 'true' : 'false');
			set_custom_element_data(text_field, "value", text_field_value_value = /*fieldvalidation*/ ctx[2].value);
			set_custom_element_data(text_field, "fieldtype", "text");
			set_custom_element_data(text_field, "label", /*label*/ ctx[3]);
			set_custom_element_data(text_field, "description", text_field_description_value = /*description*/ ctx[4] ? /*description*/ ctx[4] : '');
			set_custom_element_data(text_field, "column", text_field_column_value = /*fieldvalidation*/ ctx[2].column);
			set_custom_element_data(text_field, "display", "stack");
			set_custom_element_data(text_field, "iseditable", /*iseditable*/ ctx[5]);

			set_custom_element_data(text_field, "maxlength", text_field_maxlength_value = /*metadata*/ ctx[0].getTypeLength(/*fieldvalidation*/ ctx[2].column) !== 0
			? /*metadata*/ ctx[0].getTypeLength(/*fieldvalidation*/ ctx[2].column)
			: 36);

			set_custom_element_data(text_field, "isnullable", /*isnullable*/ ctx[6]);
			set_custom_element_data(text_field, "formid", /*componentId*/ ctx[14]);
			add_location(text_field, file, 3777, 16, 118856);
		},
		m: function mount(target, anchor) {
			insert_dev(target, text_field, anchor);

			if (!mounted) {
				dispose = listen_dev(text_field, "change", /*change_handler_1*/ ctx[76], false, false, false, false);
				mounted = true;
			}
		},
		p: function update(ctx, dirty) {
			if (dirty[0] & /*fieldvalidation*/ 4 && text_field_isvalid_value !== (text_field_isvalid_value = /*fieldvalidation*/ ctx[2].valid ? 'true' : 'false')) {
				set_custom_element_data(text_field, "isvalid", text_field_isvalid_value);
			}

			if (dirty[0] & /*fieldvalidation*/ 4 && text_field_haschanged_value !== (text_field_haschanged_value = /*fieldvalidation*/ ctx[2].changed ? 'true' : 'false')) {
				set_custom_element_data(text_field, "haschanged", text_field_haschanged_value);
			}

			if (dirty[0] & /*fieldvalidation*/ 4 && text_field_value_value !== (text_field_value_value = /*fieldvalidation*/ ctx[2].value)) {
				set_custom_element_data(text_field, "value", text_field_value_value);
			}

			if (dirty[0] & /*label*/ 8) {
				set_custom_element_data(text_field, "label", /*label*/ ctx[3]);
			}

			if (dirty[0] & /*description*/ 16 && text_field_description_value !== (text_field_description_value = /*description*/ ctx[4] ? /*description*/ ctx[4] : '')) {
				set_custom_element_data(text_field, "description", text_field_description_value);
			}

			if (dirty[0] & /*fieldvalidation*/ 4 && text_field_column_value !== (text_field_column_value = /*fieldvalidation*/ ctx[2].column)) {
				set_custom_element_data(text_field, "column", text_field_column_value);
			}

			if (dirty[0] & /*iseditable*/ 32) {
				set_custom_element_data(text_field, "iseditable", /*iseditable*/ ctx[5]);
			}

			if (dirty[0] & /*metadata, fieldvalidation*/ 5 && text_field_maxlength_value !== (text_field_maxlength_value = /*metadata*/ ctx[0].getTypeLength(/*fieldvalidation*/ ctx[2].column) !== 0
			? /*metadata*/ ctx[0].getTypeLength(/*fieldvalidation*/ ctx[2].column)
			: 36)) {
				set_custom_element_data(text_field, "maxlength", text_field_maxlength_value);
			}

			if (dirty[0] & /*isnullable*/ 64) {
				set_custom_element_data(text_field, "isnullable", /*isnullable*/ ctx[6]);
			}

			if (dirty[0] & /*componentId*/ 16384) {
				set_custom_element_data(text_field, "formid", /*componentId*/ ctx[14]);
			}
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(text_field);
			mounted = false;
			dispose();
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block_9.name,
		type: "if",
		source: "(3777:12) {#if datatype === 'Guid'}",
		ctx
	});

	return block;
}

// (3814:16) {#if datatype === 'String'                      && (                           (metadata.getField(fieldvalidation.column) !== null && metadata.getField(fieldvalidation.column).viewLength > textfieldlength)                          || metadata.getTypeLength(fieldvalidation.column) === null                          || metadata.getTypeLength(fieldvalidation.column) > textfieldlength                      )}
function create_if_block_8(ctx) {
	let text_area;
	let text_area_value_value;
	let text_area_column_value;
	let text_area_isvalid_value;
	let text_area_haschanged_value;
	let text_area_description_value;
	let text_area_maxlength_value;
	let text_area_valuepicker_value;
	let text_area_newline_value;
	let text_area_textareadisabled_value;
	let mounted;
	let dispose;

	const block = {
		c: function create() {
			text_area = element("text-area");
			set_custom_element_data(text_area, "value", text_area_value_value = /*fieldvalidation*/ ctx[2].value);
			set_custom_element_data(text_area, "label", /*label*/ ctx[3]);
			set_custom_element_data(text_area, "column", text_area_column_value = /*fieldvalidation*/ ctx[2].column);
			set_custom_element_data(text_area, "isvalid", text_area_isvalid_value = /*fieldvalidation*/ ctx[2].valid ? 'true' : 'false');
			set_custom_element_data(text_area, "haschanged", text_area_haschanged_value = /*fieldvalidation*/ ctx[2].changed ? 'true' : 'false');
			set_custom_element_data(text_area, "description", text_area_description_value = /*description*/ ctx[4] ? /*description*/ ctx[4] : '');
			set_custom_element_data(text_area, "display", "stack");
			set_custom_element_data(text_area, "isnullable", /*isnullable*/ ctx[6]);
			set_custom_element_data(text_area, "iseditable", /*iseditable*/ ctx[5]);
			set_custom_element_data(text_area, "maxlength", text_area_maxlength_value = /*metadata*/ ctx[0].getTypeLength(/*fieldvalidation*/ ctx[2].column));

			set_custom_element_data(text_area, "valuepicker", text_area_valuepicker_value = /*textFieldValues*/ ctx[16].find(/*func*/ ctx[78])
			? /*textFieldValues*/ ctx[16].find(/*func_1*/ ctx[79]).values.join(';')
			: '');

			set_custom_element_data(text_area, "newline", text_area_newline_value = /*textFieldValues*/ ctx[16].find(/*func_2*/ ctx[80])
			? /*textFieldValues*/ ctx[16].find(/*func_3*/ ctx[81]).newLine.toString()
			: 'false');

			set_custom_element_data(text_area, "textareadisabled", text_area_textareadisabled_value = /*textFieldValues*/ ctx[16].find(/*func_4*/ ctx[82])
			? /*textFieldValues*/ ctx[16].find(/*func_5*/ ctx[83]).textfieldDisabled.toString()
			: 'false');

			set_custom_element_data(text_area, "formid", /*componentId*/ ctx[14]);
			add_location(text_area, file, 3819, 20, 121252);
		},
		m: function mount(target, anchor) {
			insert_dev(target, text_area, anchor);

			if (!mounted) {
				dispose = listen_dev(text_area, "change", /*change_handler_3*/ ctx[84], false, false, false, false);
				mounted = true;
			}
		},
		p: function update(ctx, dirty) {
			if (dirty[0] & /*fieldvalidation*/ 4 && text_area_value_value !== (text_area_value_value = /*fieldvalidation*/ ctx[2].value)) {
				set_custom_element_data(text_area, "value", text_area_value_value);
			}

			if (dirty[0] & /*label*/ 8) {
				set_custom_element_data(text_area, "label", /*label*/ ctx[3]);
			}

			if (dirty[0] & /*fieldvalidation*/ 4 && text_area_column_value !== (text_area_column_value = /*fieldvalidation*/ ctx[2].column)) {
				set_custom_element_data(text_area, "column", text_area_column_value);
			}

			if (dirty[0] & /*fieldvalidation*/ 4 && text_area_isvalid_value !== (text_area_isvalid_value = /*fieldvalidation*/ ctx[2].valid ? 'true' : 'false')) {
				set_custom_element_data(text_area, "isvalid", text_area_isvalid_value);
			}

			if (dirty[0] & /*fieldvalidation*/ 4 && text_area_haschanged_value !== (text_area_haschanged_value = /*fieldvalidation*/ ctx[2].changed ? 'true' : 'false')) {
				set_custom_element_data(text_area, "haschanged", text_area_haschanged_value);
			}

			if (dirty[0] & /*description*/ 16 && text_area_description_value !== (text_area_description_value = /*description*/ ctx[4] ? /*description*/ ctx[4] : '')) {
				set_custom_element_data(text_area, "description", text_area_description_value);
			}

			if (dirty[0] & /*isnullable*/ 64) {
				set_custom_element_data(text_area, "isnullable", /*isnullable*/ ctx[6]);
			}

			if (dirty[0] & /*iseditable*/ 32) {
				set_custom_element_data(text_area, "iseditable", /*iseditable*/ ctx[5]);
			}

			if (dirty[0] & /*metadata, fieldvalidation*/ 5 && text_area_maxlength_value !== (text_area_maxlength_value = /*metadata*/ ctx[0].getTypeLength(/*fieldvalidation*/ ctx[2].column))) {
				set_custom_element_data(text_area, "maxlength", text_area_maxlength_value);
			}

			if (dirty[0] & /*textFieldValues, fieldvalidation*/ 65540 && text_area_valuepicker_value !== (text_area_valuepicker_value = /*textFieldValues*/ ctx[16].find(/*func*/ ctx[78])
			? /*textFieldValues*/ ctx[16].find(/*func_1*/ ctx[79]).values.join(';')
			: '')) {
				set_custom_element_data(text_area, "valuepicker", text_area_valuepicker_value);
			}

			if (dirty[0] & /*textFieldValues, fieldvalidation*/ 65540 && text_area_newline_value !== (text_area_newline_value = /*textFieldValues*/ ctx[16].find(/*func_2*/ ctx[80])
			? /*textFieldValues*/ ctx[16].find(/*func_3*/ ctx[81]).newLine.toString()
			: 'false')) {
				set_custom_element_data(text_area, "newline", text_area_newline_value);
			}

			if (dirty[0] & /*textFieldValues, fieldvalidation*/ 65540 && text_area_textareadisabled_value !== (text_area_textareadisabled_value = /*textFieldValues*/ ctx[16].find(/*func_4*/ ctx[82])
			? /*textFieldValues*/ ctx[16].find(/*func_5*/ ctx[83]).textfieldDisabled.toString()
			: 'false')) {
				set_custom_element_data(text_area, "textareadisabled", text_area_textareadisabled_value);
			}

			if (dirty[0] & /*componentId*/ 16384) {
				set_custom_element_data(text_area, "formid", /*componentId*/ ctx[14]);
			}
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(text_area);
			mounted = false;
			dispose();
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block_8.name,
		type: "if",
		source: "(3814:16) {#if datatype === 'String'                      && (                           (metadata.getField(fieldvalidation.column) !== null && metadata.getField(fieldvalidation.column).viewLength > textfieldlength)                          || metadata.getTypeLength(fieldvalidation.column) === null                          || metadata.getTypeLength(fieldvalidation.column) > textfieldlength                      )}",
		ctx
	});

	return block;
}

// (3794:12) {#if datatype === 'String'                  && (                       (metadata.getField(fieldvalidation.column) !== null && metadata.getField(fieldvalidation.column).viewLength <= textfieldlength)                      || (metadata.getTypeLength(fieldvalidation.column) !== null && metadata.getTypeLength(fieldvalidation.column) <= textfieldlength)                  )}
function create_if_block_7(ctx) {
	let text_field;
	let text_field_isvalid_value;
	let text_field_haschanged_value;
	let text_field_value_value;
	let text_field_description_value;
	let text_field_column_value;
	let text_field_maxlength_value;
	let mounted;
	let dispose;

	const block = {
		c: function create() {
			text_field = element("text-field");
			set_custom_element_data(text_field, "isvalid", text_field_isvalid_value = /*fieldvalidation*/ ctx[2].valid ? 'true' : 'false');
			set_custom_element_data(text_field, "haschanged", text_field_haschanged_value = /*fieldvalidation*/ ctx[2].changed ? 'true' : 'false');
			set_custom_element_data(text_field, "value", text_field_value_value = /*fieldvalidation*/ ctx[2].value);
			set_custom_element_data(text_field, "fieldtype", "text");
			set_custom_element_data(text_field, "label", /*label*/ ctx[3]);
			set_custom_element_data(text_field, "description", text_field_description_value = /*description*/ ctx[4] ? /*description*/ ctx[4] : '');
			set_custom_element_data(text_field, "column", text_field_column_value = /*fieldvalidation*/ ctx[2].column);
			set_custom_element_data(text_field, "display", "stack");
			set_custom_element_data(text_field, "iseditable", /*iseditable*/ ctx[5]);
			set_custom_element_data(text_field, "maxlength", text_field_maxlength_value = /*metadata*/ ctx[0].getTypeLength(/*fieldvalidation*/ ctx[2].column));
			set_custom_element_data(text_field, "isnullable", /*isnullable*/ ctx[6]);
			set_custom_element_data(text_field, "formid", /*componentId*/ ctx[14]);
			add_location(text_field, file, 3798, 16, 120061);
		},
		m: function mount(target, anchor) {
			insert_dev(target, text_field, anchor);

			if (!mounted) {
				dispose = listen_dev(text_field, "change", /*change_handler_2*/ ctx[77], false, false, false, false);
				mounted = true;
			}
		},
		p: function update(ctx, dirty) {
			if (dirty[0] & /*fieldvalidation*/ 4 && text_field_isvalid_value !== (text_field_isvalid_value = /*fieldvalidation*/ ctx[2].valid ? 'true' : 'false')) {
				set_custom_element_data(text_field, "isvalid", text_field_isvalid_value);
			}

			if (dirty[0] & /*fieldvalidation*/ 4 && text_field_haschanged_value !== (text_field_haschanged_value = /*fieldvalidation*/ ctx[2].changed ? 'true' : 'false')) {
				set_custom_element_data(text_field, "haschanged", text_field_haschanged_value);
			}

			if (dirty[0] & /*fieldvalidation*/ 4 && text_field_value_value !== (text_field_value_value = /*fieldvalidation*/ ctx[2].value)) {
				set_custom_element_data(text_field, "value", text_field_value_value);
			}

			if (dirty[0] & /*label*/ 8) {
				set_custom_element_data(text_field, "label", /*label*/ ctx[3]);
			}

			if (dirty[0] & /*description*/ 16 && text_field_description_value !== (text_field_description_value = /*description*/ ctx[4] ? /*description*/ ctx[4] : '')) {
				set_custom_element_data(text_field, "description", text_field_description_value);
			}

			if (dirty[0] & /*fieldvalidation*/ 4 && text_field_column_value !== (text_field_column_value = /*fieldvalidation*/ ctx[2].column)) {
				set_custom_element_data(text_field, "column", text_field_column_value);
			}

			if (dirty[0] & /*iseditable*/ 32) {
				set_custom_element_data(text_field, "iseditable", /*iseditable*/ ctx[5]);
			}

			if (dirty[0] & /*metadata, fieldvalidation*/ 5 && text_field_maxlength_value !== (text_field_maxlength_value = /*metadata*/ ctx[0].getTypeLength(/*fieldvalidation*/ ctx[2].column))) {
				set_custom_element_data(text_field, "maxlength", text_field_maxlength_value);
			}

			if (dirty[0] & /*isnullable*/ 64) {
				set_custom_element_data(text_field, "isnullable", /*isnullable*/ ctx[6]);
			}

			if (dirty[0] & /*componentId*/ 16384) {
				set_custom_element_data(text_field, "formid", /*componentId*/ ctx[14]);
			}
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(text_field);
			mounted = false;
			dispose();
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block_7.name,
		type: "if",
		source: "(3794:12) {#if datatype === 'String'                  && (                       (metadata.getField(fieldvalidation.column) !== null && metadata.getField(fieldvalidation.column).viewLength <= textfieldlength)                      || (metadata.getTypeLength(fieldvalidation.column) !== null && metadata.getTypeLength(fieldvalidation.column) <= textfieldlength)                  )}",
		ctx
	});

	return block;
}

// (3841:12) {#if datatype === 'Binary'}
function create_if_block_6(ctx) {
	let artifact_text_area;
	let artifact_text_area_value_value;
	let artifact_text_area_column_value;
	let artifact_text_area_isvalid_value;
	let artifact_text_area_haschanged_value;
	let artifact_text_area_description_value;
	let mounted;
	let dispose;

	const block = {
		c: function create() {
			artifact_text_area = element("artifact-text-area");
			set_custom_element_data(artifact_text_area, "value", artifact_text_area_value_value = /*fieldvalidation*/ ctx[2].value);
			set_custom_element_data(artifact_text_area, "label", /*label*/ ctx[3]);
			set_custom_element_data(artifact_text_area, "column", artifact_text_area_column_value = /*fieldvalidation*/ ctx[2].column);
			set_custom_element_data(artifact_text_area, "isvalid", artifact_text_area_isvalid_value = /*fieldvalidation*/ ctx[2].valid ? 'true' : 'false');
			set_custom_element_data(artifact_text_area, "haschanged", artifact_text_area_haschanged_value = /*fieldvalidation*/ ctx[2].changed ? 'true' : 'false');
			set_custom_element_data(artifact_text_area, "description", artifact_text_area_description_value = /*description*/ ctx[4] ? /*description*/ ctx[4] : '');
			set_custom_element_data(artifact_text_area, "display", "stack");
			set_custom_element_data(artifact_text_area, "isnullable", /*isnullable*/ ctx[6]);
			set_custom_element_data(artifact_text_area, "iseditable", /*iseditable*/ ctx[5]);
			set_custom_element_data(artifact_text_area, "formid", /*componentId*/ ctx[14]);
			add_location(artifact_text_area, file, 3841, 16, 122678);
		},
		m: function mount(target, anchor) {
			insert_dev(target, artifact_text_area, anchor);

			if (!mounted) {
				dispose = listen_dev(artifact_text_area, "change", /*change_handler_4*/ ctx[85], false, false, false, false);
				mounted = true;
			}
		},
		p: function update(ctx, dirty) {
			if (dirty[0] & /*fieldvalidation*/ 4 && artifact_text_area_value_value !== (artifact_text_area_value_value = /*fieldvalidation*/ ctx[2].value)) {
				set_custom_element_data(artifact_text_area, "value", artifact_text_area_value_value);
			}

			if (dirty[0] & /*label*/ 8) {
				set_custom_element_data(artifact_text_area, "label", /*label*/ ctx[3]);
			}

			if (dirty[0] & /*fieldvalidation*/ 4 && artifact_text_area_column_value !== (artifact_text_area_column_value = /*fieldvalidation*/ ctx[2].column)) {
				set_custom_element_data(artifact_text_area, "column", artifact_text_area_column_value);
			}

			if (dirty[0] & /*fieldvalidation*/ 4 && artifact_text_area_isvalid_value !== (artifact_text_area_isvalid_value = /*fieldvalidation*/ ctx[2].valid ? 'true' : 'false')) {
				set_custom_element_data(artifact_text_area, "isvalid", artifact_text_area_isvalid_value);
			}

			if (dirty[0] & /*fieldvalidation*/ 4 && artifact_text_area_haschanged_value !== (artifact_text_area_haschanged_value = /*fieldvalidation*/ ctx[2].changed ? 'true' : 'false')) {
				set_custom_element_data(artifact_text_area, "haschanged", artifact_text_area_haschanged_value);
			}

			if (dirty[0] & /*description*/ 16 && artifact_text_area_description_value !== (artifact_text_area_description_value = /*description*/ ctx[4] ? /*description*/ ctx[4] : '')) {
				set_custom_element_data(artifact_text_area, "description", artifact_text_area_description_value);
			}

			if (dirty[0] & /*isnullable*/ 64) {
				set_custom_element_data(artifact_text_area, "isnullable", /*isnullable*/ ctx[6]);
			}

			if (dirty[0] & /*iseditable*/ 32) {
				set_custom_element_data(artifact_text_area, "iseditable", /*iseditable*/ ctx[5]);
			}

			if (dirty[0] & /*componentId*/ 16384) {
				set_custom_element_data(artifact_text_area, "formid", /*componentId*/ ctx[14]);
			}
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(artifact_text_area);
			mounted = false;
			dispose();
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block_6.name,
		type: "if",
		source: "(3841:12) {#if datatype === 'Binary'}",
		ctx
	});

	return block;
}

// (3874:12) {#if numericFieldTypes.includes(datatype)}
function create_if_block_4(ctx) {
	let if_block_anchor;

	function select_block_type_2(ctx, dirty) {
		if (/*ranges*/ ctx[15][/*fieldvalidation*/ ctx[2].column]) return create_if_block_5;
		return create_else_block_1;
	}

	let current_block_type = select_block_type_2(ctx, [-1, -1, -1, -1, -1, -1]);
	let if_block = current_block_type(ctx);

	const block = {
		c: function create() {
			if_block.c();
			if_block_anchor = empty();
		},
		m: function mount(target, anchor) {
			if_block.m(target, anchor);
			insert_dev(target, if_block_anchor, anchor);
		},
		p: function update(ctx, dirty) {
			if (current_block_type === (current_block_type = select_block_type_2(ctx, dirty)) && if_block) {
				if_block.p(ctx, dirty);
			} else {
				if_block.d(1);
				if_block = current_block_type(ctx);

				if (if_block) {
					if_block.c();
					if_block.m(if_block_anchor.parentNode, if_block_anchor);
				}
			}
		},
		d: function destroy(detaching) {
			if_block.d(detaching);
			if (detaching) detach_dev(if_block_anchor);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block_4.name,
		type: "if",
		source: "(3874:12) {#if numericFieldTypes.includes(datatype)}",
		ctx
	});

	return block;
}

// (3896:16) {:else}
function create_else_block_1(ctx) {
	let text_field;
	let text_field_isvalid_value;
	let text_field_haschanged_value;
	let text_field_value_value;
	let text_field_description_value;
	let text_field_column_value;
	let text_field_typelength_value;
	let text_field_typeprecision_value;
	let mounted;
	let dispose;

	const block = {
		c: function create() {
			text_field = element("text-field");
			set_custom_element_data(text_field, "isvalid", text_field_isvalid_value = /*fieldvalidation*/ ctx[2].valid ? 'true' : 'false');
			set_custom_element_data(text_field, "haschanged", text_field_haschanged_value = /*fieldvalidation*/ ctx[2].changed ? 'true' : 'false');
			set_custom_element_data(text_field, "value", text_field_value_value = /*fieldvalidation*/ ctx[2].value);
			set_custom_element_data(text_field, "fieldtype", "Number");
			set_custom_element_data(text_field, "label", /*label*/ ctx[3]);
			set_custom_element_data(text_field, "description", text_field_description_value = /*description*/ ctx[4] ? /*description*/ ctx[4] : '');
			set_custom_element_data(text_field, "column", text_field_column_value = /*fieldvalidation*/ ctx[2].column);
			set_custom_element_data(text_field, "display", "stack");

			set_custom_element_data(text_field, "typelength", text_field_typelength_value = /*datatype*/ ctx[1] === 'Decimal' || /*datatype*/ ctx[1] === 'Double'
			? /*metadata*/ ctx[0].getField(/*fieldvalidation*/ ctx[2].column).typeLength
			: 0);

			set_custom_element_data(text_field, "typeprecision", text_field_typeprecision_value = /*datatype*/ ctx[1] === 'Decimal' || /*datatype*/ ctx[1] === 'Double'
			? /*metadata*/ ctx[0].getField(/*fieldvalidation*/ ctx[2].column).typePrecision
			: 0);

			set_custom_element_data(text_field, "iseditable", /*iseditable*/ ctx[5]);
			set_custom_element_data(text_field, "isnullable", /*isnullable*/ ctx[6]);
			set_custom_element_data(text_field, "formid", /*componentId*/ ctx[14]);
			add_location(text_field, file, 3896, 20, 124908);
		},
		m: function mount(target, anchor) {
			insert_dev(target, text_field, anchor);

			if (!mounted) {
				dispose = listen_dev(text_field, "change", /*change_handler_6*/ ctx[87], false, false, false, false);
				mounted = true;
			}
		},
		p: function update(ctx, dirty) {
			if (dirty[0] & /*fieldvalidation*/ 4 && text_field_isvalid_value !== (text_field_isvalid_value = /*fieldvalidation*/ ctx[2].valid ? 'true' : 'false')) {
				set_custom_element_data(text_field, "isvalid", text_field_isvalid_value);
			}

			if (dirty[0] & /*fieldvalidation*/ 4 && text_field_haschanged_value !== (text_field_haschanged_value = /*fieldvalidation*/ ctx[2].changed ? 'true' : 'false')) {
				set_custom_element_data(text_field, "haschanged", text_field_haschanged_value);
			}

			if (dirty[0] & /*fieldvalidation*/ 4 && text_field_value_value !== (text_field_value_value = /*fieldvalidation*/ ctx[2].value)) {
				set_custom_element_data(text_field, "value", text_field_value_value);
			}

			if (dirty[0] & /*label*/ 8) {
				set_custom_element_data(text_field, "label", /*label*/ ctx[3]);
			}

			if (dirty[0] & /*description*/ 16 && text_field_description_value !== (text_field_description_value = /*description*/ ctx[4] ? /*description*/ ctx[4] : '')) {
				set_custom_element_data(text_field, "description", text_field_description_value);
			}

			if (dirty[0] & /*fieldvalidation*/ 4 && text_field_column_value !== (text_field_column_value = /*fieldvalidation*/ ctx[2].column)) {
				set_custom_element_data(text_field, "column", text_field_column_value);
			}

			if (dirty[0] & /*datatype, metadata, fieldvalidation*/ 7 && text_field_typelength_value !== (text_field_typelength_value = /*datatype*/ ctx[1] === 'Decimal' || /*datatype*/ ctx[1] === 'Double'
			? /*metadata*/ ctx[0].getField(/*fieldvalidation*/ ctx[2].column).typeLength
			: 0)) {
				set_custom_element_data(text_field, "typelength", text_field_typelength_value);
			}

			if (dirty[0] & /*datatype, metadata, fieldvalidation*/ 7 && text_field_typeprecision_value !== (text_field_typeprecision_value = /*datatype*/ ctx[1] === 'Decimal' || /*datatype*/ ctx[1] === 'Double'
			? /*metadata*/ ctx[0].getField(/*fieldvalidation*/ ctx[2].column).typePrecision
			: 0)) {
				set_custom_element_data(text_field, "typeprecision", text_field_typeprecision_value);
			}

			if (dirty[0] & /*iseditable*/ 32) {
				set_custom_element_data(text_field, "iseditable", /*iseditable*/ ctx[5]);
			}

			if (dirty[0] & /*isnullable*/ 64) {
				set_custom_element_data(text_field, "isnullable", /*isnullable*/ ctx[6]);
			}

			if (dirty[0] & /*componentId*/ 16384) {
				set_custom_element_data(text_field, "formid", /*componentId*/ ctx[14]);
			}
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(text_field);
			mounted = false;
			dispose();
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_else_block_1.name,
		type: "else",
		source: "(3896:16) {:else}",
		ctx
	});

	return block;
}

// (3876:16) {#if ranges[fieldvalidation.column]}
function create_if_block_5(ctx) {
	let text_field;
	let text_field_isvalid_value;
	let text_field_haschanged_value;
	let text_field_value_value;
	let text_field_min_value;
	let text_field_max_value;
	let text_field_step_value;
	let text_field_description_value;
	let text_field_column_value;
	let text_field_typelength_value;
	let text_field_typeprecision_value;
	let mounted;
	let dispose;

	const block = {
		c: function create() {
			text_field = element("text-field");
			set_custom_element_data(text_field, "isvalid", text_field_isvalid_value = /*fieldvalidation*/ ctx[2].valid ? 'true' : 'false');
			set_custom_element_data(text_field, "haschanged", text_field_haschanged_value = /*fieldvalidation*/ ctx[2].changed ? 'true' : 'false');
			set_custom_element_data(text_field, "value", text_field_value_value = /*fieldvalidation*/ ctx[2].value);
			set_custom_element_data(text_field, "fieldtype", "Range");
			set_custom_element_data(text_field, "min", text_field_min_value = /*ranges*/ ctx[15][/*fieldvalidation*/ ctx[2].column].min + '');
			set_custom_element_data(text_field, "max", text_field_max_value = /*ranges*/ ctx[15][/*fieldvalidation*/ ctx[2].column].max + '');
			set_custom_element_data(text_field, "step", text_field_step_value = /*ranges*/ ctx[15][/*fieldvalidation*/ ctx[2].column].step + '');
			set_custom_element_data(text_field, "label", /*label*/ ctx[3]);
			set_custom_element_data(text_field, "description", text_field_description_value = /*description*/ ctx[4] ? /*description*/ ctx[4] : '');
			set_custom_element_data(text_field, "column", text_field_column_value = /*fieldvalidation*/ ctx[2].column);
			set_custom_element_data(text_field, "display", "stack");

			set_custom_element_data(text_field, "typelength", text_field_typelength_value = /*datatype*/ ctx[1] === 'Decimal' || /*datatype*/ ctx[1] === 'Double'
			? /*metadata*/ ctx[0].getField(/*fieldvalidation*/ ctx[2].column).typeLength
			: 0);

			set_custom_element_data(text_field, "typeprecision", text_field_typeprecision_value = /*datatype*/ ctx[1] === 'Decimal' || /*datatype*/ ctx[1] === 'Double'
			? /*metadata*/ ctx[0].getField(/*fieldvalidation*/ ctx[2].column).typePrecision
			: 0);

			set_custom_element_data(text_field, "iseditable", /*iseditable*/ ctx[5]);
			set_custom_element_data(text_field, "isnullable", /*isnullable*/ ctx[6]);
			set_custom_element_data(text_field, "formid", /*componentId*/ ctx[14]);
			add_location(text_field, file, 3876, 20, 123663);
		},
		m: function mount(target, anchor) {
			insert_dev(target, text_field, anchor);

			if (!mounted) {
				dispose = listen_dev(text_field, "change", /*change_handler_5*/ ctx[86], false, false, false, false);
				mounted = true;
			}
		},
		p: function update(ctx, dirty) {
			if (dirty[0] & /*fieldvalidation*/ 4 && text_field_isvalid_value !== (text_field_isvalid_value = /*fieldvalidation*/ ctx[2].valid ? 'true' : 'false')) {
				set_custom_element_data(text_field, "isvalid", text_field_isvalid_value);
			}

			if (dirty[0] & /*fieldvalidation*/ 4 && text_field_haschanged_value !== (text_field_haschanged_value = /*fieldvalidation*/ ctx[2].changed ? 'true' : 'false')) {
				set_custom_element_data(text_field, "haschanged", text_field_haschanged_value);
			}

			if (dirty[0] & /*fieldvalidation*/ 4 && text_field_value_value !== (text_field_value_value = /*fieldvalidation*/ ctx[2].value)) {
				set_custom_element_data(text_field, "value", text_field_value_value);
			}

			if (dirty[0] & /*ranges, fieldvalidation*/ 32772 && text_field_min_value !== (text_field_min_value = /*ranges*/ ctx[15][/*fieldvalidation*/ ctx[2].column].min + '')) {
				set_custom_element_data(text_field, "min", text_field_min_value);
			}

			if (dirty[0] & /*ranges, fieldvalidation*/ 32772 && text_field_max_value !== (text_field_max_value = /*ranges*/ ctx[15][/*fieldvalidation*/ ctx[2].column].max + '')) {
				set_custom_element_data(text_field, "max", text_field_max_value);
			}

			if (dirty[0] & /*ranges, fieldvalidation*/ 32772 && text_field_step_value !== (text_field_step_value = /*ranges*/ ctx[15][/*fieldvalidation*/ ctx[2].column].step + '')) {
				set_custom_element_data(text_field, "step", text_field_step_value);
			}

			if (dirty[0] & /*label*/ 8) {
				set_custom_element_data(text_field, "label", /*label*/ ctx[3]);
			}

			if (dirty[0] & /*description*/ 16 && text_field_description_value !== (text_field_description_value = /*description*/ ctx[4] ? /*description*/ ctx[4] : '')) {
				set_custom_element_data(text_field, "description", text_field_description_value);
			}

			if (dirty[0] & /*fieldvalidation*/ 4 && text_field_column_value !== (text_field_column_value = /*fieldvalidation*/ ctx[2].column)) {
				set_custom_element_data(text_field, "column", text_field_column_value);
			}

			if (dirty[0] & /*datatype, metadata, fieldvalidation*/ 7 && text_field_typelength_value !== (text_field_typelength_value = /*datatype*/ ctx[1] === 'Decimal' || /*datatype*/ ctx[1] === 'Double'
			? /*metadata*/ ctx[0].getField(/*fieldvalidation*/ ctx[2].column).typeLength
			: 0)) {
				set_custom_element_data(text_field, "typelength", text_field_typelength_value);
			}

			if (dirty[0] & /*datatype, metadata, fieldvalidation*/ 7 && text_field_typeprecision_value !== (text_field_typeprecision_value = /*datatype*/ ctx[1] === 'Decimal' || /*datatype*/ ctx[1] === 'Double'
			? /*metadata*/ ctx[0].getField(/*fieldvalidation*/ ctx[2].column).typePrecision
			: 0)) {
				set_custom_element_data(text_field, "typeprecision", text_field_typeprecision_value);
			}

			if (dirty[0] & /*iseditable*/ 32) {
				set_custom_element_data(text_field, "iseditable", /*iseditable*/ ctx[5]);
			}

			if (dirty[0] & /*isnullable*/ 64) {
				set_custom_element_data(text_field, "isnullable", /*isnullable*/ ctx[6]);
			}

			if (dirty[0] & /*componentId*/ 16384) {
				set_custom_element_data(text_field, "formid", /*componentId*/ ctx[14]);
			}
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(text_field);
			mounted = false;
			dispose();
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block_5.name,
		type: "if",
		source: "(3876:16) {#if ranges[fieldvalidation.column]}",
		ctx
	});

	return block;
}

// (3915:12) {#if datatype === 'DateTime'}
function create_if_block_3(ctx) {
	let date_picker_form;
	let date_picker_form_startdate_value;
	let date_picker_form_enddate_value;
	let date_picker_form_value_value;
	let date_picker_form_column_value;
	let date_picker_form_description_value;
	let date_picker_form_isvalid_value;
	let date_picker_form_viewlength_value;
	let date_picker_form_viewprecision_value;
	let mounted;
	let dispose;

	const block = {
		c: function create() {
			date_picker_form = element("date-picker-form");

			set_custom_element_data(date_picker_form, "startdate", date_picker_form_startdate_value = /*metadata*/ ctx[0].startDateProperty === /*fieldvalidation*/ ctx[2].column
			? 'true'
			: 'false');

			set_custom_element_data(date_picker_form, "enddate", date_picker_form_enddate_value = /*metadata*/ ctx[0].endDateProperty === /*fieldvalidation*/ ctx[2].column
			? 'true'
			: 'false');

			set_custom_element_data(date_picker_form, "label", /*label*/ ctx[3]);
			set_custom_element_data(date_picker_form, "value", date_picker_form_value_value = /*fieldvalidation*/ ctx[2].value);
			set_custom_element_data(date_picker_form, "placeholder", "");
			set_custom_element_data(date_picker_form, "display", "stack");
			set_custom_element_data(date_picker_form, "column", date_picker_form_column_value = /*fieldvalidation*/ ctx[2].column);
			set_custom_element_data(date_picker_form, "description", date_picker_form_description_value = /*description*/ ctx[4] ? /*description*/ ctx[4] : '');
			set_custom_element_data(date_picker_form, "isvalid", date_picker_form_isvalid_value = /*fieldvalidation*/ ctx[2].valid ? 'true' : 'false');

			set_custom_element_data(date_picker_form, "viewlength", date_picker_form_viewlength_value = /*metadata*/ ctx[0].getField(/*fieldvalidation*/ ctx[2].column).viewLength
			? /*metadata*/ ctx[0].getField(/*fieldvalidation*/ ctx[2].column).viewLength.toString()
			: '0');

			set_custom_element_data(date_picker_form, "viewprecision", date_picker_form_viewprecision_value = /*metadata*/ ctx[0].getField(/*fieldvalidation*/ ctx[2].column).viewPrecision
			? /*metadata*/ ctx[0].getField(/*fieldvalidation*/ ctx[2].column).viewPrecision.toString()
			: '0');

			set_custom_element_data(date_picker_form, "isnullable", /*isnullable*/ ctx[6]);
			set_custom_element_data(date_picker_form, "iseditable", /*iseditable*/ ctx[5]);
			set_custom_element_data(date_picker_form, "formid", /*componentId*/ ctx[14]);
			add_location(date_picker_form, file, 3915, 16, 126001);
		},
		m: function mount(target, anchor) {
			insert_dev(target, date_picker_form, anchor);

			if (!mounted) {
				dispose = listen_dev(date_picker_form, "change", /*change_handler_7*/ ctx[88], false, false, false, false);
				mounted = true;
			}
		},
		p: function update(ctx, dirty) {
			if (dirty[0] & /*metadata, fieldvalidation*/ 5 && date_picker_form_startdate_value !== (date_picker_form_startdate_value = /*metadata*/ ctx[0].startDateProperty === /*fieldvalidation*/ ctx[2].column
			? 'true'
			: 'false')) {
				set_custom_element_data(date_picker_form, "startdate", date_picker_form_startdate_value);
			}

			if (dirty[0] & /*metadata, fieldvalidation*/ 5 && date_picker_form_enddate_value !== (date_picker_form_enddate_value = /*metadata*/ ctx[0].endDateProperty === /*fieldvalidation*/ ctx[2].column
			? 'true'
			: 'false')) {
				set_custom_element_data(date_picker_form, "enddate", date_picker_form_enddate_value);
			}

			if (dirty[0] & /*label*/ 8) {
				set_custom_element_data(date_picker_form, "label", /*label*/ ctx[3]);
			}

			if (dirty[0] & /*fieldvalidation*/ 4 && date_picker_form_value_value !== (date_picker_form_value_value = /*fieldvalidation*/ ctx[2].value)) {
				set_custom_element_data(date_picker_form, "value", date_picker_form_value_value);
			}

			if (dirty[0] & /*fieldvalidation*/ 4 && date_picker_form_column_value !== (date_picker_form_column_value = /*fieldvalidation*/ ctx[2].column)) {
				set_custom_element_data(date_picker_form, "column", date_picker_form_column_value);
			}

			if (dirty[0] & /*description*/ 16 && date_picker_form_description_value !== (date_picker_form_description_value = /*description*/ ctx[4] ? /*description*/ ctx[4] : '')) {
				set_custom_element_data(date_picker_form, "description", date_picker_form_description_value);
			}

			if (dirty[0] & /*fieldvalidation*/ 4 && date_picker_form_isvalid_value !== (date_picker_form_isvalid_value = /*fieldvalidation*/ ctx[2].valid ? 'true' : 'false')) {
				set_custom_element_data(date_picker_form, "isvalid", date_picker_form_isvalid_value);
			}

			if (dirty[0] & /*metadata, fieldvalidation*/ 5 && date_picker_form_viewlength_value !== (date_picker_form_viewlength_value = /*metadata*/ ctx[0].getField(/*fieldvalidation*/ ctx[2].column).viewLength
			? /*metadata*/ ctx[0].getField(/*fieldvalidation*/ ctx[2].column).viewLength.toString()
			: '0')) {
				set_custom_element_data(date_picker_form, "viewlength", date_picker_form_viewlength_value);
			}

			if (dirty[0] & /*metadata, fieldvalidation*/ 5 && date_picker_form_viewprecision_value !== (date_picker_form_viewprecision_value = /*metadata*/ ctx[0].getField(/*fieldvalidation*/ ctx[2].column).viewPrecision
			? /*metadata*/ ctx[0].getField(/*fieldvalidation*/ ctx[2].column).viewPrecision.toString()
			: '0')) {
				set_custom_element_data(date_picker_form, "viewprecision", date_picker_form_viewprecision_value);
			}

			if (dirty[0] & /*isnullable*/ 64) {
				set_custom_element_data(date_picker_form, "isnullable", /*isnullable*/ ctx[6]);
			}

			if (dirty[0] & /*iseditable*/ 32) {
				set_custom_element_data(date_picker_form, "iseditable", /*iseditable*/ ctx[5]);
			}

			if (dirty[0] & /*componentId*/ 16384) {
				set_custom_element_data(date_picker_form, "formid", /*componentId*/ ctx[14]);
			}
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(date_picker_form);
			mounted = false;
			dispose();
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block_3.name,
		type: "if",
		source: "(3915:12) {#if datatype === 'DateTime'}",
		ctx
	});

	return block;
}

// (3933:12) {#if datatype === 'Boolean' }
function create_if_block_2(ctx) {
	let radio_button;
	let radio_button_isvalid_value;
	let radio_button_haschanged_value;
	let radio_button_value_value;
	let radio_button_description_value;
	let radio_button_column_value;
	let mounted;
	let dispose;

	const block = {
		c: function create() {
			radio_button = element("radio-button");
			set_custom_element_data(radio_button, "isvalid", radio_button_isvalid_value = /*fieldvalidation*/ ctx[2].valid ? 'true' : 'false');
			set_custom_element_data(radio_button, "haschanged", radio_button_haschanged_value = /*fieldvalidation*/ ctx[2].changed ? 'true' : 'false');
			set_custom_element_data(radio_button, "label", /*label*/ ctx[3]);
			set_custom_element_data(radio_button, "value", radio_button_value_value = /*fieldvalidation*/ ctx[2].value);
			set_custom_element_data(radio_button, "description", radio_button_description_value = /*description*/ ctx[4] ? /*description*/ ctx[4] : '');
			set_custom_element_data(radio_button, "placeholder", "");
			set_custom_element_data(radio_button, "display", "stack");
			set_custom_element_data(radio_button, "column", radio_button_column_value = /*fieldvalidation*/ ctx[2].column);
			set_custom_element_data(radio_button, "isnullable", /*isnullable*/ ctx[6]);
			set_custom_element_data(radio_button, "iseditable", /*iseditable*/ ctx[5]);
			set_custom_element_data(radio_button, "formid", /*componentId*/ ctx[14]);
			add_location(radio_button, file, 3933, 16, 127187);
		},
		m: function mount(target, anchor) {
			insert_dev(target, radio_button, anchor);

			if (!mounted) {
				dispose = listen_dev(radio_button, "change", /*change_handler_8*/ ctx[89], false, false, false, false);
				mounted = true;
			}
		},
		p: function update(ctx, dirty) {
			if (dirty[0] & /*fieldvalidation*/ 4 && radio_button_isvalid_value !== (radio_button_isvalid_value = /*fieldvalidation*/ ctx[2].valid ? 'true' : 'false')) {
				set_custom_element_data(radio_button, "isvalid", radio_button_isvalid_value);
			}

			if (dirty[0] & /*fieldvalidation*/ 4 && radio_button_haschanged_value !== (radio_button_haschanged_value = /*fieldvalidation*/ ctx[2].changed ? 'true' : 'false')) {
				set_custom_element_data(radio_button, "haschanged", radio_button_haschanged_value);
			}

			if (dirty[0] & /*label*/ 8) {
				set_custom_element_data(radio_button, "label", /*label*/ ctx[3]);
			}

			if (dirty[0] & /*fieldvalidation*/ 4 && radio_button_value_value !== (radio_button_value_value = /*fieldvalidation*/ ctx[2].value)) {
				set_custom_element_data(radio_button, "value", radio_button_value_value);
			}

			if (dirty[0] & /*description*/ 16 && radio_button_description_value !== (radio_button_description_value = /*description*/ ctx[4] ? /*description*/ ctx[4] : '')) {
				set_custom_element_data(radio_button, "description", radio_button_description_value);
			}

			if (dirty[0] & /*fieldvalidation*/ 4 && radio_button_column_value !== (radio_button_column_value = /*fieldvalidation*/ ctx[2].column)) {
				set_custom_element_data(radio_button, "column", radio_button_column_value);
			}

			if (dirty[0] & /*isnullable*/ 64) {
				set_custom_element_data(radio_button, "isnullable", /*isnullable*/ ctx[6]);
			}

			if (dirty[0] & /*iseditable*/ 32) {
				set_custom_element_data(radio_button, "iseditable", /*iseditable*/ ctx[5]);
			}

			if (dirty[0] & /*componentId*/ 16384) {
				set_custom_element_data(radio_button, "formid", /*componentId*/ ctx[14]);
			}
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(radio_button);
			mounted = false;
			dispose();
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block_2.name,
		type: "if",
		source: "(3933:12) {#if datatype === 'Boolean' }",
		ctx
	});

	return block;
}

function create_fragment(ctx) {
	let div;
	let t0;
	let t1;
	let if_block0 = !/*ready*/ ctx[13] && create_if_block_11(ctx);
	let if_block1 = /*ready*/ ctx[13] && /*initError*/ ctx[12] && create_if_block_10(ctx);
	let if_block2 = /*ready*/ ctx[13] && !/*initError*/ ctx[12] && create_if_block(ctx);

	const block = {
		c: function create() {
			div = element("div");
			if (if_block0) if_block0.c();
			t0 = space();
			if (if_block1) if_block1.c();
			t1 = space();
			if (if_block2) if_block2.c();
			this.c = noop;
			attr_dev(div, "class", "h-full flex flex-col object-form");
			attr_dev(div, "id", /*subscribeGuid*/ ctx[17]);
			add_location(div, file, 3740, 0, 117460);
		},
		l: function claim(nodes) {
			throw new Error("options.hydrate only works if the component was compiled with the `hydratable: true` option");
		},
		m: function mount(target, anchor) {
			insert_dev(target, div, anchor);
			if (if_block0) if_block0.m(div, null);
			append_dev(div, t0);
			if (if_block1) if_block1.m(div, null);
			append_dev(div, t1);
			if (if_block2) if_block2.m(div, null);
			/*div_binding*/ ctx[90](div);
		},
		p: function update(ctx, dirty) {
			if (!/*ready*/ ctx[13]) {
				if (if_block0) {
					if_block0.p(ctx, dirty);
				} else {
					if_block0 = create_if_block_11(ctx);
					if_block0.c();
					if_block0.m(div, t0);
				}
			} else if (if_block0) {
				if_block0.d(1);
				if_block0 = null;
			}

			if (/*ready*/ ctx[13] && /*initError*/ ctx[12]) {
				if (if_block1) {
					if_block1.p(ctx, dirty);
				} else {
					if_block1 = create_if_block_10(ctx);
					if_block1.c();
					if_block1.m(div, t1);
				}
			} else if (if_block1) {
				if_block1.d(1);
				if_block1 = null;
			}

			if (/*ready*/ ctx[13] && !/*initError*/ ctx[12]) {
				if (if_block2) {
					if_block2.p(ctx, dirty);
				} else {
					if_block2 = create_if_block(ctx);
					if_block2.c();
					if_block2.m(div, null);
				}
			} else if (if_block2) {
				if_block2.d(1);
				if_block2 = null;
			}
		},
		i: noop,
		o: noop,
		d: function destroy(detaching) {
			if (detaching) detach_dev(div);
			if (if_block0) if_block0.d();
			if (if_block1) if_block1.d();
			if (if_block2) if_block2.d();
			/*div_binding*/ ctx[90](null);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_fragment.name,
		type: "component",
		source: "",
		ctx
	});

	return block;
}

function instance($$self, $$props, $$invalidate) {
	let $isMobileStore;
	let $applicationReady;
	validate_store(isMobileStore, 'isMobileStore');
	component_subscribe($$self, isMobileStore, $$value => $$invalidate(73, $isMobileStore = $$value));
	validate_store(applicationReady, 'applicationReady');
	component_subscribe($$self, applicationReady, $$value => $$invalidate(74, $applicationReady = $$value));
	let { $$slots: slots = {}, $$scope } = $$props;
	validate_slots('form-field', slots, []);
	let { datatype = 'String' } = $$props;

	let { fieldvalidation = {
		column: '',
		value: '',
		valid: true,
		changed: false,
		min: undefined,
		max: undefined,
		default: undefined
	} } = $$props;

	let { label = '' } = $$props;
	let { description = '' } = $$props;
	let { iseditable = 'true' } = $$props;
	let { isnullable = 'true' } = $$props;
	let { filter = '' } = $$props;
	let { reversefilter = '' } = $$props;
	let { dataviewguid = '' } = $$props;
	let { editobjectid = '' } = $$props;
	let { actionentityguid = '' } = $$props;
	let { formtype = 'New' } = $$props;
	let { forminrenderer = 'false' } = $$props;
	let { metadata } = $$props;
	let { mappedEditable = new Map() } = $$props;
	let { nullableMap = new Map() } = $$props;
	let { relationshiptype = '' } = $$props;
	let { rlitemguid = '' } = $$props;
	let { rldirection = 'FROM' } = $$props;
	let { sourceview = '' } = $$props;
	let { typedomain = '' } = $$props;
	let { showgeoedit = 'true' } = $$props;
	let { getwkt = 'false' } = $$props;
	let { initvalues = '' } = $$props;
	let { jsondata = '' } = $$props;

	let { displayintabs = bc.parameterService.get('FormWithTabs')
	? bc.parameterService.get('FormWithTabs')
	: 'false' } = $$props;

	let { tabformviews = bc.parameterService.get('TabFormViews')
	? bc.parameterService.get('TabFormViews')
	: '' } = $$props;

	let { displaymapintab = bc.parameterService.get('MapInTab')
	? bc.parameterService.get('MapInTab')
	: 'false' } = $$props;

	let { nestedform = bc.parameterService.get('NestedForms')
	? bc.parameterService.get('NestedForms')
	: 'false' } = $$props;

	let { nestedformlevel = bc.parameterService.get('NestedFormLevel')
	? bc.parameterService.get('NestedFormLevel')
	: '2' } = $$props;

	let { nestedformviews = bc.parameterService.get('NestedFormViews')
	? bc.parameterService.get('NestedFormViews')
	: '' } = $$props;

	let { nestedformviewlevels = bc.parameterService.get('NestedFormViewLevels')
	? bc.parameterService.get('NestedFormViewLevels')
	: '' } = $$props;

	let { currentnestinglevel = '1' } = $$props;
	let { handlerelationships = 'true' } = $$props;
	let { handledocuments = 'true' } = $$props;
	let { saveevent = '' } = $$props;
	let { cancelevent = '' } = $$props;
	let { additionalbuttonevent = '' } = $$props;
	let { cancelon = '' } = $$props;
	let { closeonsave = 'true' } = $$props;
	let { isbackend = 'false' } = $$props;
	let { title = '' } = $$props;
	let { show = 'true' } = $$props;
	let { init = 'true' } = $$props;
	let { closeonnavigate = 'true' } = $$props;
	let { formrules = '' } = $$props;
	let { hideadditionalgeotools = 'false' } = $$props;
	let { bgwkt = '' } = $$props;
	let { bgwktstrokecolor = '' } = $$props;
	let { bgwktfillcolor = '' } = $$props;
	let { bgwktcenteron = 'false' } = $$props;
	let { bgwktviewguid = '' } = $$props;
	let { bgwktentityguid = '' } = $$props;
	let { wktboundary = '' } = $$props;
	let { shownotifications = 'true' } = $$props;
	let { textfieldlength = 100 } = $$props;

	// Base variables
	//================================================================
	let container;

	let firstInitialized = false;
	let subscribeGuid = uuidv4();
	let initError = false; // used
	const generalText = getComponentText('general'); // used
	const componentText = getComponentText('objectForm');
	console.log('generalText', generalText);
	console.log('componentText', componentText);
	const component = get_current_component();
	let mounted = false;
	let canReload = true;

	// internal variables
	let ready = false; // used

	// Component variables
	//================================================================
	let externalValidateFunction = null;

	let overflowContainer;
	let showHelp = false;
	let slottedElement;
	let hasHelp = false;
	let editObject;
	let keys = [];
	let componentId = '';
	let { validation = [] } = $$props;
	let formHasChanged = false;
	let newWKT;
	let composedProperties = [];
	let existingSearchObject;
	let wktUpdated = false;
	let isEntityForm = false;
	let isNestedForm = false;
	let nestedFormLevel = 1;
	let currentNestingLevel = 1;
	let composedObjectArray = [];
	let groups = [];
	let groupIds = [];
	let groupMap = new Map();
	let closedGroups = [];
	let geoTitle;
	let dataTitle;
	let innerContainer;
	let tab = 'data';
	let isTablet = false;
	let hasGeometry = false;
	let mapFullScreen = false;
	let canEditGeo = true;
	let updateWKTEventName = '';
	let tabEvent = 'SET_FORM_TAB_' + subscribeGuid;
	let showMapTab = false;
	let isSaving = false; // steers the saving spin wheel
	let viewGroups;
	let tabsWithMissingFiels = [];
	let mapResetEventsFired = true;
	let badgeValues = [];
	let formStyle = 'FieldsOneTabWithSideMap';

	// 1: Accordion with side map;
	// 2: Accordion with map in tab;
	// if $isMobileStore
	// 3: Tabs for form groups and map
	// displayintabs === "true" OR formLayout.displayGroupsInTabs
	// used to send message 'SET_TAB', which is handled by Tabs, TabPanel and TabLink
	let tabComponent;

	let formRules = [];
	let visibleGroups = [];
	let visibleFields = [];
	let hasOverflow = false;
	let firstDefaultValues = new Map();
	let parser = new jsts.io.WKTReader();
	let mappedListFilters = new Map();
	let mappedReverseListFilters = new Map();
	let formLayout;
	let textMessages = [];
	let infoMessages = [];
	let warningMessages = [];
	let titles = [];
	let dividerTop = [];
	let dividerBottom = [];
	let ranges = [];
	let textFieldValues = [];

	onMount(() => {
		component.setAttribute('register', componentId); // should add the component to the window['components'] registry where it could be fetched
		console.log('ObjectForm mount', component);
		attachNormalize(component);

		tick().then(() => {
			dispatchMountedEvent(component);
			$$invalidate(71, mounted = true);
		});

		return () => {
			if (closeonnavigate === 'true') {
				let formMethods = bc.parameterService.get("OPEN_FORMS");

				if (formMethods) {
					bc.parameterService.set("OPEN_FORMS", [...formMethods.filter(f => f.id !== subscribeGuid)]);
				}
			}

			// there is no subscription on subscribeGuid
			bc.messageService.unsubscribeComponent(subscribeGuid);

			// all event subscriptions are on componentId
			bc.messageService.unsubscribeComponent(componentId);
		};
	});

	const initComponent = async (dataviewguid, editobjectid, initvalues, jsondata) => {
		$$invalidate(70, firstInitialized = true);

		try {
			if (dataviewguid === '') {
				console.warn("dataviewguid for ObjectForm not set.", dataviewguid, editobjectid, initvalues, jsondata);
				return;
			}

			// treats only New or Edit and Edit only with editobjectid
			if (formtype !== 'Edit' && formtype !== 'New' || formtype === 'Edit' && (!editobjectid || editobjectid === '' || editobjectid === 'null' || editobjectid === 'undefined')) {
				console.warn("Wrong form type for ObjectForm or editobjectid missing for edit form.", formtype, editobjectid);
				return;
			}

			// If component gets reloaded, remove existing subscriptions
			if (componentId !== '') {
				bc.messageService.unsubscribeComponent(componentId);
			}

			// (Re)Setup componentID
			$$invalidate(14, componentId = uuidv4());

			// Reset values to default
			mapResetEventsFired = true;

			$$invalidate(12, initError = false);
			$$invalidate(24, validation = []); // reset the validation / editing object
			$$invalidate(13, ready = false);
			await tick();
			updateWKTEventName = 'UPDATE_WKT_' + componentId;
			formHasChanged = false;
			newWKT = null;
			composedProperties = [];
			existingSearchObject = null;
			mappedEditable.clear();
			$$invalidate(72, groups = []);
			closedGroups = [];
			visibleGroups = [];
			visibleFields = [];
			groupMap = new Map();
			composedProperties = [];
			wktUpdated = false;
			isEntityForm = false;
			isSaving = false;
			showMapTab = false;
			tabsWithMissingFiels = [];

			// isMobile = false
			bc.parameterService.set('FOCUS', null);

			if (closeonnavigate === 'true') {
				let formMethods = bc.parameterService.get("OPEN_FORMS");

				if (!formMethods) {
					bc.parameterService.set("OPEN_FORMS", [{ id: subscribeGuid, hasChanged, closeForm }]);
				} else {
					bc.parameterService.set("OPEN_FORMS", [...formMethods, { id: subscribeGuid, hasChanged, closeForm }]);
				}
			}

			// Get metadata & create Helperfunctions
			$$invalidate(0, metadata = await bc.parameterService.getMetadata(dataviewguid));

			isNestedForm = false;
			nestedFormLevel = parseInt(nestedformlevel);
			currentNestingLevel = parseInt(currentnestinglevel);

			if (nestedform.toLowerCase() === 'true') {
				isNestedForm = true;
				nestedFormLevel = parseInt(nestedformlevel);
			}

			let nestedViews = [];
			nestedViews = nestedformviews.toLowerCase().split(',').map(el => el.trim());

			for (let j = 0; j < nestedViews.length; j++) {
				if (nestedViews[j] === dataviewguid.toLowerCase()) {
					isNestedForm = true;
					let nestedViewLevels = [];
					nestedViewLevels = nestedformviewlevels.split(',').map(el => el.trim()); //.map((s) => parseFloat(s))

					if (nestedViewLevels.length > j) {
						nestedFormLevel = parseInt(nestedViewLevels[j]); // else it is the default
					}

					break;
				}
			}

			if (currentNestingLevel >= nestedFormLevel) {
				isNestedForm = false;
			}

			if (isNestedForm === true) {
				$$invalidate(21, displaymapintab = 'true');
			} // formStyle = "FieldsMultiTabWithMapTab"

			if (displaymapintab === 'false') {
				formStyle = "FieldsOneTabWithSideMap";
			} else {
				formStyle = 'FieldsOneTabWithMapTab';
			}

			let tabViews = [];
			tabViews = tabformviews.toLowerCase().split(',').map(el => el.trim());

			if (tabViews.includes(dataviewguid.toLowerCase())) {
				$$invalidate(20, displayintabs = "true");
			}

			if (displayintabs === "true") {
				formStyle = "FieldsMultiTabWithMapTab";
			}

			if (formrules !== '') {
				try {
					formRules = JSON.parse(formrules);

					// ??? why does a form rule overrule the tab layout ???
					if (displaymapintab === 'false') {
						formStyle = "FieldsOneTabWithSideMap";
					} else {
						formStyle = 'FieldsOneTabWithMapTab';
					}
				} catch(error) {
					console.error('INVALID FormRules', formrules);
				}
			}

			if (bc.parameterService.get(metadata.resourceId + '_VISIBILITY_RULES')) {
				try {
					formRules = bc.parameterService.get(metadata.resourceId + '_VISIBILITY_RULES');

					if (displaymapintab === 'false') {
						formStyle = "FieldsOneTabWithSideMap";
					} else {
						formStyle = 'FieldsOneTabWithMapTab';
					}
				} catch(error) {
					console.error('INVALID FormRules', formrules);
				}
			}

			if (bc.parameterService.get('FORM_LAYOUT_' + dataviewguid)) {
				formLayout = bc.parameterService.get('FORM_LAYOUT_' + dataviewguid);

				if (formLayout.infos && formLayout.infos.length > 0) {
					for (let j = 0; j < formLayout.infos.length; j++) {
						infoMessages[formLayout.infos[j].field] = formLayout.infos[j];
					}
				}

				if (formLayout.texts && formLayout.texts.length > 0) {
					for (let j = 0; j < formLayout.texts.length; j++) {
						textMessages[formLayout.texts[j].field] = formLayout.texts[j];
					}
				}

				if (formLayout.warnings && formLayout.warnings.length > 0) {
					for (let j = 0; j < formLayout.warnings.length; j++) {
						warningMessages[formLayout.warnings[j].field] = formLayout.warnings[j];
					}
				}

				if (formLayout.titles && formLayout.titles.length > 0) {
					for (let j = 0; j < formLayout.titles.length; j++) {
						titles[formLayout.titles[j].field] = formLayout.titles[j];
					}
				}

				if (formLayout.ranges && formLayout.ranges.length > 0) {
					for (let j = 0; j < formLayout.ranges.length; j++) {
						$$invalidate(15, ranges[formLayout.ranges[j].field] = formLayout.ranges[j], ranges);
					}
				}

				if (formLayout.validationFunction) {
					externalValidateFunction = formLayout.validationFunction;
				}

				if (formLayout.textFieldValues) {
					$$invalidate(16, textFieldValues = [...formLayout.textFieldValues]);
				}

				if (formLayout.displayGroupsInTabs !== undefined) {
					if (formLayout.displayGroupsInTabs) {
						formStyle = "FieldsMultiTabWithMapTab";
					} else {
						if (displaymapintab === 'false') {
							formStyle = "FieldsOneTabWithSideMap";
						} else {
							formStyle = 'FieldsOneTabWithMapTab';
						}
					}
				}
			}

			// console.log('formLayout', formLayout)
			// No specific FormStyle for Mobile
			if ($isMobileStore) {
				formStyle = 'FieldsOneTabWithMapTab';
			}

			// console.log('formStyle', formStyle)
			// console.log('formRules', formRules)
			if (formLayout && formLayout.requiredFields) {
				for (let i = 0; i < metadata.properties.fields.length; i++) {
					let field = metadata.properties.fields[i];
					nullableMap.set(field.name, true);
				}

				for (let i = 0; i < formLayout.requiredFields.length; i++) {
					nullableMap.set(formLayout.requiredFields[i], false);
				}

				nullableMap.set(metadata.idProperty, false);
			} else {
				for (let i = 0; i < metadata.properties.fields.length; i++) {
					let field = metadata.properties.fields[i];

					nullableMap.set(field.name, metadata.isNullable(field.name) !== null
					? metadata.isNullable(field.name)
					: true);
				}
			}

			if (metadata.relations) {
				for (let i = 0; i < metadata.relations.length; i++) {
					badgeValues[metadata.relations[i].relationShip.TY_ID] = 0;
				}
			}

			if (metadata.entityType === constants.type.UgdmArtifacts) {
				$$invalidate(23, handledocuments = 'false');
				$$invalidate(22, handlerelationships = 'false');
			}

			viewGroups = metadata.groups;

			// Keys
			keys = metadata.properties.fields.map(f => f.name);

			infoLog('Object-Form', 'metadata', metadata);

			// Set Boolean if form is for a User created Entity or not
			isEntityForm = isUserDefinedView(metadata.resourceName);

			$$invalidate(72, { groups, hiddenGroups: closedGroups, groupIds } = await parseFieldGroups(metadata), groups);

			for (let i = 0; i < groups.length; i++) {
				groupMap.set(groupIds[i], groups[i]);
			}

			console.log('groups', groups);

			if (displayintabs === 'true') {
				closedGroups = [];
			}

			// Check if metadata has Geometry
			hasGeometry = metadataHasGeo(metadata);

			// Check if user can edit the geometry or not (default: true)
			if (hasGeometry) {
				let WKTField = metadata.properties.fields.find(field => field.name.endsWith('WellKnownText'));

				if (WKTField && WKTField.editable === false) {
					canEditGeo = false;
				}
			}

			// an injected json object supersedes the database or memory object
			if (jsondata && jsondata.length > 0) {
				editObject = JSON.parse(jsondata);
			}

			// Set up validation array for Edit Form  + subscribe to WKT update if metadata has geometry and showgeoedit is 'true'
			if (formtype === 'Edit') {
				await createEditValidationObject();
			}

			// Set up validation array for New Form
			if (formtype === 'New') {
				// Remove Sidebar from Map if it has been visible
				bc.messageService.publish('REMOVE_PREVIEW');

				await createNewValidationObject();
			}

			// Subscript to formChanges so we can check if the form changed and warn the user that the chances won't be saved when he cancels
			// bc.messageService.subscribe('FORM_CHANGED_' + componentId, componentId, setChanged)
			if (cancelon !== '') {
				bc.messageService.subscribe(cancelon, componentId, () => {
					cancelForm(cancelevent);
				});
			}

			// Handle Map Component if existing (fix map to form-container and send "check_map" event to make sure it displays well)
			if (hasGeometry && showgeoedit === 'true') {
				mapResetEventsFired = false;
			}

			if (isbackend === 'true') {
				$$invalidate(20, displayintabs = 'false');
			}

			// Init visibileGroups && visibleFields
			visibleGroups = [...groups];

			visibleFields = [
				...metadata.properties.fields.filter(f => mappedEditable.get(f.name) === true || metadata.isVisibleList(f.name) === true).map(f => f.name)
			];

			if (formLayout && formLayout.groups && formLayout.groups.length > 0) {
				let fieldArrays = formLayout.groups.map(g => g.fields);

				for (let i = 0; i < fieldArrays.length; i++) {
					visibleFields = visibleFields.concat(fieldArrays[i]);
				}
			}

			// console.log('visibleFields', visibleFields)
			// console.log('nullableMap', nullableMap)
			infoLog(
				'Object-Form',
				'FORM LAYOUT',
				`Data view ${dataviewguid}
            , tabs ${displayintabs}
            , is nested form: ${isNestedForm}
            , nesting level ${nestedFormLevel}
            , formStyle ${formStyle}
            , formtype ${formtype}
            , hasGeometry ${hasGeometry}`,
				metadata
			);

			// set the default values after initialising for reset
			for (let i = 0; i < validation.length; i++) {
				$$invalidate(24, validation[i].default = validation[i].value, validation);
			}

			await applyFormRules();
			$$invalidate(13, ready = true);
		} catch(error) {
			$$invalidate(12, initError = true);
			handleErrorInAlert(error, generalText.componentInitError + ':Form');
			console.error(generalText.componentInitError + ':Form', error);
		}
	};

	const applyProps = afterInit => {
		let shouldInit = afterInit && firstInitialized || !afterInit && !firstInitialized;

		if (shouldInit) {
			handleComponentProps(
				{
					ready: $applicationReady,
					componentMounted: mounted,
					init,
					firstInitialized,
					canReload,
					handler: initComponent
				},
				dataviewguid,
				editobjectid,
				initvalues,
				jsondata
			);
		}
	};

	const setChanged = async data => {
		if (data) {
			// console.trace('DATA', data)
			let validationElement = getValidationEntry(validation, data.column);

			// console.trace('validationElement', validationElement)
			if (validationElement) {
				if (data.value !== validationElement.value) {
					validationElement.changed = true;
					validationElement.value = data.value;
					validationElement.valid = true;
				}
			}

			$$invalidate(24, validation);
			await applyFormRules();
		}

		formHasChanged = true;
	};

	const applyFormRules = async () => {
		// console.log('validation', validation)
		if (formRules && formRules.length > 0) {
			// 2023-07-26 order changed to remove first, then apply
			// Remove applied list filters / reverse list filters
			for (let i = 0; i < formRules.length; i++) {
				let rule = formRules[i];

				// filter lists according to rule conditions
				// Only remove list if field exists
				let field = metadata.properties.fields.find(f => f.name === rule.field);

				if (field) {
					let validationEntry = getValidationEntry(validation, field.name);

					if (validationEntry) {
						for (let n = 0; n < rule.conditions.length; n++) {
							let value = rule.conditions[n].value === null
							? ''
							: rule.conditions[n].value;

							let valueArray = [];

							if (Array.isArray(value)) {
								valueArray = value;
							} else {
								valueArray = [value];
							}

							let found = false;

							for (let k = 0; k < valueArray.length; k++) {
								if (value[k] === validationEntry.value) {
									found = true;
									break;
								}
							}

							// handle listFilters
							if (rule.conditions[n].listFilters.length > 0) {
								if (rule.conditions[n].reverseValue === false) {
									if (!found) {
										console.log('remove list filter', field.name, value, 'current value: ', validationEntry.value);

										for (let m = 0; m < rule.conditions[n].listFilters.length; m++) {
											mappedListFilters.delete(rule.conditions[n].listFilters[m].field);
										}
									}
								} else {
									if (found) {
										console.log('remove list filter', field.name, value, 'current value: ', validationEntry.value);

										for (let m = 0; m < rule.conditions[n].listFilters.length; m++) {
											mappedListFilters.delete(rule.conditions[n].listFilters[m].field);
										}
									}
								}
							}

							// handle reverseListFilters
							if (rule.conditions[n].reverseListFilters.length > 0) {
								if (rule.conditions[n].reverseValue === false) {
									if (!found) {
										// console.log('remove reverseListFilters', field.name, value, 'current value: ', validationEntry.value)
										for (let m = 0; m < rule.conditions[n].reverseListFilters.length; m++) {
											mappedReverseListFilters.delete(rule.conditions[n].reverseListFilters[m].field);
										}
									}
								} else {
									if (found) {
										// console.log('remove reverseListFilters', field.name, value, 'current value: ', validationEntry.value)
										for (let m = 0; m < rule.conditions[n].reverseListFilters.length; m++) {
											mappedReverseListFilters.delete(rule.conditions[n].reverseListFilters[m].field);
										}
									}
								}
							}
						} // Reverse list filters NOT reverseValue
						// if (rule.conditions[n].reverseListFilters.length > 0  && rule.conditions[n].reverseValue === false) {
					} //     let value = rule.conditions[n].value === null ? '' : rule.conditions[n].value
					//     if(Array.isArray(value)){
				} //         let found = false
				//         for(let k = 0; k< value.length; k++){
			} //             if (value[k] === validationEntry.value) {
			//                 found = true

			//                 break
			//             }
			//         }
			//         if(!found){
			//             //console.log('remove reverseList filter', field.name, value, 'current value: ', validationEntry.value)
			//             for(let m = 0; m < rule.conditions[n].reverseListFilters.length; m++) {
			//                 mappedReverseListFilters.delete(rule.conditions[n].reverseListFilters[m].field)
			//             }
			//         }
			//     }else{
			//         if (value !== validationEntry.value) {
			//             // console.log('remove reverseList filter', field.name, value, 'current value: ', validationEntry.value)
			//             for(let m = 0; m < rule.conditions[n].reverseListFilters.length; m++) {
			//                 mappedReverseListFilters.delete(rule.conditions[n].reverseListFilters[m].field)
			//             }
			//         }
			//     }
			// }
			//
			// // Reverse list filters REVERSEVALUE
			// if (rule.conditions[n].reverseListFilters.length > 0  && rule.conditions[n].reverseValue === true) {
			//     let value = rule.conditions[n].value === null ? '' : rule.conditions[n].value
			//     if(Array.isArray(value)){
			//         let found = false
			//         for(let k = 0; k< value.length; k++){
			//             if (value[k] === validationEntry.value) {
			//                 found = true
			//                 break
			//             }
			//         }
			//         if(found){
			//             //console.log('remove reverseList filter', field.name, value, 'current value: ', validationEntry.value)
			//             for(let m = 0; m < rule.conditions[n].reverseListFilters.length; m++) {
			//                 mappedReverseListFilters.delete(rule.conditions[n].reverseListFilters[m].field)
			//             }
			//         }
			//     }else{
			//         if (value === validationEntry.value) {
			//             // console.log('remove reverseList filter', field.name, value, 'current value: ', validationEntry.value)
			//             for(let m = 0; m < rule.conditions[n].reverseListFilters.length; m++) {
			//                 mappedReverseListFilters.delete(rule.conditions[n].reverseListFilters[m].field)
			//             }
			//         }
			//     }
			// }
			// End if (validationEntry)
			// End if (field)
			// End for formRules.length
			// apply conditional rules
			for (let i = 0; i < formRules.length; i++) {
				let rule = formRules[i];

				// Only apply rule if field exists
				let field = metadata.properties.fields.find(f => f.name === rule.field);

				if (field) {
					let validationEntry = getValidationEntry(validation, field.name);

					if (validationEntry) {
						for (let n = 0; n < rule.conditions.length; n++) {
							let tempValue = rule.conditions[n].value === null
							? ''
							: rule.conditions[n].value;

							// console.log('validationEntry.value === value', validationEntry.value === value)
							// console.log('(value !== validationEntry.value && rule.conditions[n].reverseValue === true)', (value !== validationEntry.value && rule.conditions[n].reverseValue === true))
							let values = [];

							if (Array.isArray(tempValue)) {
								values = tempValue;
							} else {
								values = [tempValue];
							}

							for (let k = 0; k < values.length; k++) {
								let value = values[k]; // this is the value for which the rule applys

								if (validationEntry.value === value && !rule.conditions[n].reverseValue || !values.includes(validationEntry.value) && rule.conditions[n].reverseValue === true) {
									if (rule.conditions[n].otherFields) {
										let applyRules = true;

										for (let m = 0; m < rule.conditions[n].otherFields.length; m++) {
											let otherFieldEntry = getValidationEntry(validation, rule.conditions[n].otherFields[m].field);

											if (otherFieldEntry) {
												let otherValue = rule.conditions[n].otherFields[m].value === null
												? ''
												: rule.conditions[n].otherFields[m].value;

												if (otherFieldEntry.value !== otherValue) {
													applyRules = false;
													break;
												}
											}
										}

										if (applyRules) {
											applyGroupChanges(rule.conditions[n].groupChanges);
											applyFieldChanges(rule.conditions[n].fieldChanges);
											applyValueChanges(rule.conditions[n].valueChanges);
											applyListChanges(rule.conditions[n].listFilters);
											applyReverseListChanges(rule.conditions[n].reverseListFilters);
											await applyCallback(rule.conditions[n].callback, validationEntry.value);
										}
									} else {
										applyGroupChanges(rule.conditions[n].groupChanges);
										applyFieldChanges(rule.conditions[n].fieldChanges);
										applyValueChanges(rule.conditions[n].otherFields);
										applyValueChanges(rule.conditions[n].valueChanges);
										applyListChanges(rule.conditions[n].listFilters);
										applyReverseListChanges(rule.conditions[n].reverseListFilters);
										await applyCallback(rule.conditions[n].callback, validationEntry.value);
									}
								}
							}
						} // End For rule.conditions.length
					} // End if (validationEntry)
				} // End if (field)
			} // End for formRules.length

			// console.log('mappedListFilters', mappedListFilters)
			$$invalidate(24, validation = [...validation]);

			$$invalidate(72, groups);
			keys = keys;
		}
	};

	const applyGroupChanges = changes => {
		// console.log('applyGroupChanges', changes)
		for (let i = 0; i < changes.length; i++) {
			let change = changes[i];

			if (change.group.length > 0) {
				// Get right group
				let targetGroup = groupMap.get(change.group[0]);

				if (!targetGroup) {
					targetGroup = groupMap.get(change.group[1]);
				}

				if (change.visible === true) {
					if (!visibleGroups.includes(targetGroup)) {
						visibleGroups = [...visibleGroups, targetGroup];
					}
				} else {
					if (visibleGroups.includes(targetGroup)) {
						visibleGroups = [...visibleGroups.filter(g => g !== targetGroup)];
					}
				}

				if (change.visible === false) {
					if (visibleGroups.includes(targetGroup)) {
						visibleGroups = [...visibleGroups.filter(g => g !== targetGroup)];
					}
				}

				if (change.requiredType) {
					if (change.requiredType === 'allRequired') {
						for (let j = 0; j < metadata.properties.fields.length; j++) {
							if (metadata.getField(metadata.properties.fields[j].name).atGroup === targetGroup) {
								nullableMap.set(metadata.properties.fields[j].name, false);
							}
						}
					}

					if (change.requiredType === 'allNotRequired') {
						for (let j = 0; j < metadata.properties.fields.length; j++) {
							if (metadata.getField(metadata.properties.fields[j].name).atGroup === targetGroup) {
								nullableMap.set(metadata.properties.fields[j].name, true);
							}
						}
					}

					if (change.requiredType === 'metadata') {
						for (let j = 0; j < metadata.properties.fields.length; j++) {
							if (metadata.getField(metadata.properties.fields[j].name).atGroup === targetGroup) {
								nullableMap.set(metadata.properties.fields[j].name, metadata.isNullable(metadata.properties.fields[j].name));
							}
						}
					}
				}

				if (change.requiredFields) {
					for (let j = 0; j < change.requiredFields.length; j++) {
						let field = change.requiredFields[j];

						for (let n = 0; n < metadata.properties.fields.length; n++) {
							if (metadata.properties.fields[n].atGroup === targetGroup && metadata.properties.fields[n].name === field) {
								nullableMap.set(metadata.properties.fields[n].name, false);
							}
						}
					}
				}

				if (change.notRequiredFields) {
					for (let j = 0; j < change.notRequiredFields.length; j++) {
						let field = change.notRequiredFields[j];

						for (let n = 0; n < metadata.properties.fields.length; n++) {
							if (metadata.properties.fields[n].atGroup === targetGroup && metadata.properties.fields[n].name === field) {
								nullableMap.set(metadata.properties.fields[n].name, true);
								console.log(metadata.properties.fields[n].name, 'nullable TRUE');
							}
						}
					}
				}
			}
		}
	};

	const applyFieldChanges = changes => {
		// console.log('applyFieldChanges ->', changes)
		if (changes) {
			for (let i = 0; i < changes.length; i++) {
				let change = changes[i];
				let field = metadata.getField(change.field);

				if (field) {
					let validationEntry = getValidationEntry(validation, field.name);

					if (validationEntry) {
						// Handle required
						if (change.required === 'true' || change.required === true) {
							nullableMap.set(field.name, false);
						}

						if (change.required === 'false' || change.required === false) {
							nullableMap.set(field.name, true);
						}

						if (change.required === 'metadata') {
							nullableMap.set(field.name, metadata.isNullable(field.name));
						}

						// Handle visible
						if (change.visible !== 'keep') {
							if (change.visible === true) {
								if (!visibleFields.includes(field.name)) {
									visibleFields = [...visibleFields, field.name];
								}
							}

							if (change.visible === false) {
								if (visibleFields.includes(field.name)) {
									visibleFields = [...visibleFields.filter(vf => vf !== field.name)];
								}

								if (change.resetOnHide !== undefined && change.resetOnHide === true) {
									// reset to default
									validationEntry.value = validationEntry.default;
								}
							}
						}

						// Handle newValue if existing
						if (change.newValue !== undefined) {
							if (change.newValue === null) {
								validationEntry.value = '';
							} else {
								validationEntry.value = change.newValue;
							}
						}

						if (change.disabled !== undefined) {
							console.log('editable', !change.disabled);
							mappedEditable.set(field.name, !change.disabled);
						}
					}
				}
			}
		}
	};

	const applyListChanges = changes => {
		if (changes) {
			for (let i = 0; i < changes.length; i++) {
				let change = changes[i];
				let field = metadata.getField(change.field);

				if (field) {
					console.log('applyListChanges', change.field, change, field, changes);
					mappedListFilters.set(field.name, change.values);
				}
			}
		}
	};

	const applyReverseListChanges = changes => {
		if (changes) {
			for (let i = 0; i < changes.length; i++) {
				let change = changes[i];
				let field = metadata.getField(change.field);

				if (field) {
					//console.log('applyReverseListChanges', field.name, change.values)
					mappedReverseListFilters.set(field.name, change.values);
				}
			}
		}
	};

	// callback receives the value object of the field and the validation object with all fields and the id (subscribeGuid) of the form 
	const applyCallback = async (callback, value) => {
		if (callback && callback.length > 0) {
			for (let i = 0; i < callback.length; i++) {
				if (callback[i]) {
					console.log('applyCallback ->', callback[i], value, validation, subscribeGuid, relationshiptype, rldirection, rlitemguid);
					await callback[i](value, validation, subscribeGuid, relationshiptype, rldirection, rlitemguid);
				}
			}
		}
	};

	const applyValueChanges = changes => {
		if (changes) {
			// console.log('applyValueChanges ->', changes)
			for (let i = 0; i < changes.length; i++) {
				let change = changes[i];
				let field = metadata.getField(change.field);

				if (field) {
					let validationEntry = getValidationEntry(validation, field.name);

					if (validationEntry) {
						validationEntry.value = change.value; // this is the vaulue change applied
					}
				}
			}
		}
	};

	const checkHasOverflow = element => {
		if (element) {
			hasOverflow = element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;
		}
	};

	// $: initComponent(dataviewguid, editobjectid)
	// PostObject Sanity-Check
	const postObjectSanityCheck = () => {
		const postObject = {};
		const keys = validation.map(v => v.column);

		// Set values to null for all empty fields of a certain type
		for (let key of keys) {
			let validationEntry = getValidationEntry(validation, key);

			if (validationEntry) {
				// set the value from the validation entry/form field
				postObject[key] = validationEntry.value;
			} else {
				// continue;
				postObject[key] = null;
			}

			const field = metadata.getField(key);

			// Set empty value to null or Update properties with previous value if in edit mode
			if (postObject[key] === undefined || postObject[key] === null || postObject[key] === '' || postObject[key] === '[{}]') {
				if (field.dataType === 'Guid' || field.dataType === 'Int16' || field.dataType === 'Int32' || field.dataType === 'Int64' || field.dataType === 'DateTime' || field.dataType === 'Decimal' || field.dataType === 'Double' || field.dataType === 'Boolean' || field.dataType === 'String' || field.dataType === 'EntityList' || field.dataType === 'List' || field.dataType === 'Collection') {
					// delete postObject[key]
					postObject[key] = null;
				}
			}

			// Handle boolean types if not empty
			if ((postObject[key] === 'null' || postObject[key] === 'false' || postObject[key] === 'true' || postObject[key] === '0' || postObject[key] === '1') && field.dataType === 'Boolean') {
				if (postObject[key] === 'null') {
					postObject[key] = null;
				}

				if (postObject[field.name] === 'False' || postObject[field.name] === 'false' || postObject[field.name] === '0') {
					postObject[field.name] = false;
				}

				if (postObject[field.name] === 'True' || postObject[field.name] === 'true' || postObject[field.name] === '1') {
					postObject[field.name] = true;
				}
			}
		}

		// Add all missing properties because the backend does not handle "MERGE-UPDATES"
		if (editObject) {
			const editObjectKeys = Object.keys(editObject);

			for (let key of editObjectKeys) {
				if (postObject[key] === undefined) {
					postObject[key] = editObject[key];
				}
			}
		}

		// If you add a new object, all not set properties have to be set to the default value defined by the metadata
		if (formtype === 'New') {
			const fields = metadata.properties.fields;

			for (let field of fields) {
				if (postObject[field.name] === undefined && field.defaultValue) {
					postObject[field.name] = field.defaultValue;

					if ((postObject[field.name] === 'null' || postObject[field.name] === 'false' || postObject[field.name] === 'true' || postObject[field.name] === '0' || postObject[field.name] === '1') && field.dataType === 'Boolean') {
						if (postObject[field.name] === 'null') {
							postObject[field.name] = null;
						}

						if (postObject[field.name] === 'False' || postObject[field.name] === 'false' || postObject[field.name] === '0') {
							postObject[field.name] = false;
						}

						if (postObject[field.name] === 'True' || postObject[field.name] === 'true' || postObject[field.name] === '1') {
							postObject[field.name] = true;
						}
					}
				}
			}

			// Add geometry fields, the default value will be updated later
			for (let field of fields) {
				if (field.name.endsWith('Geometry.CoordinateSystemId')) {
					const tab = field.name.split('.');

					postObject[tab[0]] = {
						Geometry: { CoordinateSystemId: field.defaultValue }
					};
				}

				if (field.name.endsWith('Geometry.WellKnownText')) {
					const tab = field.name.split('.');

					postObject[tab[0]] = {
						Geometry: { WellKnownText: field.defaultValue }
					};
				}
			}
		}

		// Set all GUIDs to lowercase if not empty
		for (let field of metadata.properties.fields) {
			if (field.dataType === 'Guid') {
				if (postObject[field.name] !== undefined && postObject[field.name] !== null && postObject[field.name] !== '') {
					postObject[field.name] === postObject[field.name].toLowerCase();
				}
			}
		}

		// Handle geometry-columns
		// The field has to be merged in a single property  (geometryField) and the other 2 properties
		// have to be deleted (geometryField + '.Geometry.CoordinateSystemId' AND geometryField + '.Geometry.WellKnownText')
		if (metadata.geometryField !== undefined && metadata.geometryField !== '') {
			const geometryField = metadata.geometryField;
			const valueCoordinateSystemId = postObject[geometryField].Geometry.CoordinateSystemId;
			const valueWellKnownText = postObject[geometryField].Geometry.WellKnownText;

			const value = {
				Geometry: {
					CoordinateSystemId: '' + valueCoordinateSystemId,
					WellKnownText: valueWellKnownText
				}
			};

			// If the geometry has been edited
			if (newWKT) {
				value.Geometry.WellKnownText = newWKT;
			}

			postObject[geometryField] = value;
			delete postObject[geometryField + '.Geometry.CoordinateSystemId'];
			delete postObject[geometryField + '.Geometry.WellKnownText'];
		}

		if (metadata.resourceName === 'UgdmActions' && formtype === 'New') {
			postObject['AC_ENTITY'] = actionentityguid;
		}

		return postObject;
	};

	const passGeometryCheck = () => {
		let checkPassed = true;

		// infoLog('Object-Form', 'passGeometryCheck', newWKT)
		if (hasGeometry) {
			if (formtype === 'New' && (newWKT === undefined || newWKT === null) || newWKT === '') {
				checkPassed = false;
			}
		}

		return checkPassed;
	};

	const checkAllFieldsRequired = postObject => {
		const nullablesMap = getNullablesMap(keys, metadata);

		for (const [key, value] of nullableMap.entries()) {
			if (value === false) {
				if (key.indexOf('.Geometry.') !== -1) {
					const realKey = key.split('.')[0];

					if (postObject[realKey] === null || postObject[realKey] === undefined) {
						infoLog('Object-Form', 'missing field', key);
						return false;
					}
				} else {
					if (postObject[key] === null || postObject[key] === undefined) {
						infoLog('Object-Form', 'missing field', key);
						return false;
					}
				}
			}
		}

		return true;
	};

	const checkAllUniqueFields = async postObject => {
		if (isEntityForm) {
			const uniqueMap = getUniqueMap(keys, metadata);

			for (const [key, value] of uniqueMap.entries()) {
				let isNextId = false;
				const field = metadata.getField(key);
				mappedEditable.set(key, field.editable);
				let defaultValue = undefined;

				if (field.defaultValue) {
					defaultValue = field.defaultValue;

					if (typeof defaultValue === 'string' && defaultValue.startsWith('NextId')) {
						isNextId = true;
					}
				}

				// If unique, no NextId & postObject of field contains a value
				if (value === true && isNextId === false && postObject[key]) {
					// If it's not a geometry field
					if (key.indexOf('.Geometry.') === -1) {
						// If if't not a primary key
						if (metadata.isPrimaryKey(key) === false) {
							// If it's not a guid
							if (datatype !== 'Guid' && datatype !== 'List' && datatype !== 'EntityList') {
								// If if's a new entry
								if (formtype === 'New') {
									const keyFilter = createFilter({
										filterExpressionNumber: 0,
										filterExpressionJoinType: 'and',
										columnName: key,
										joinType: 'and',
										metadata,
										comparator: '=',
										intable: false
									});

									addValuesToAdvancedFilter(keyFilter, [{ name: label, value: postObject[key] }]);

									const data = await bc.metadataManager.fetchJsonData({
										filters: [keyFilter],
										resourceName: metadata.resourceName,
										inlinecount: 'allpages',
										top: 1,
										offset: 0,
										orderBy: []
									});

									if (data) {
										return label;
									}
								} else {
									const keyFilter = createFilter({
										filterExpressionNumber: 0, // If if's an update of an existing entry (edit)
										filterExpressionJoinType: 'and',
										columnName: key,
										joinType: 'and',
										metadata,
										comparator: '=',
										intable: false
									});

									addValuesToAdvancedFilter(keyFilter, [{ name: label, value: postObject[key] }]);

									const data = await bc.metadataManager.fetchJsonData({
										filters: [keyFilter],
										resourceName: metadata.resourceName,
										inlinecount: 'allpages',
										top: 2,
										offset: 0,
										orderBy: []
									});

									// console.log('data', data)
									if (data && data.Items) {
										let items = data.Items;
										let count = items.length;

										if (count > 1) {
											return label;
										} else {
											let item = items[0];

											if (item[metadata.idProperty] === postObject[metadata.idProperty]) {
												return null;
											} else {
												return label;
											}
										}
									}
								}
							}
						}
					}
				}
			}

			return null;
		} else {
			return null;
		}
	};

	const signalRequiredFields = postObject => {
		const nullablesMap = getNullablesMap(keys, metadata);
		tabsWithMissingFiels = [];
		console.log('nullableMap', nullableMap);
		console.log('postObject', postObject);

		for (const [key, value] of nullableMap.entries()) {
			if (value === false) {
				if (postObject[key] === null || postObject[key] === undefined) {
					let v = getValidationEntry(validation, key);
					v.valid = false;
					let currentGroup = metadata.getField(key).atGroup;

					if (!tabsWithMissingFiels.includes(currentGroup)) {
						tabsWithMissingFiels.push(currentGroup);
					}

					if (closedGroups.includes(currentGroup)) {
						closedGroups = [...closedGroups.filter(hg => hg !== currentGroup)];
					}
				}
			}
		}

		// console.log('tabsWithMissingFiels', tabsWithMissingFiels)
		$$invalidate(72, groups);

		$$invalidate(24, validation);
	};

	const signalField = key => {
		let v = getValidationEntry(validation, key);

		if (v) {
			v.valid = false;
			$$invalidate(72, groups);
			$$invalidate(24, validation);
		}
	};

	const fillUpComposedFields = async postObject => {
		// console.log('composedObject', composedObjectArray, composedProperties)
		for (let i = 0; i < composedProperties.length; i++) {
			let composedProperty = composedProperties[i];
			let composedObject = composedObjectArray[composedProperty];
			let field = metadata.getField(composedProperty);

			if (composedObject.Composed.method === 'concat') {
				let fields = composedObject.Composed.fields;
				let prefix = composedObject.Composed.prefix;
				let suffix = composedObject.Composed.suffix;
				let empty = composedObject.Composed.empty;
				let finalValue = '';

				for (let j = 0; j < fields.length; j++) {
					let field = fields[j];

					if (postObject[field] !== undefined && postObject[field] !== null && postObject[field] !== '') {
						const fieldMetadata = metadata.getField(field);
						let value = await getSimpleDisplayValue(postObject[field], fieldMetadata, metadata);
						finalValue = finalValue + prefix[j] + value + suffix[j];
					} else {
						if (empty[j] !== '') {
							finalValue = finalValue + prefix[j] + empty[j] + suffix[j];
						}
					}
				} // console.log('Composed: ', finalValue)

				let length = field.typeLength;

				if (length) {
					if (finalValue.length > length) {
						finalValue = finalValue.substring(0, length);
					}
				}

				postObject[composedProperty] = finalValue;
			} else if (composedObject.Composed.method === 'math') {
				let fields = composedObject.Composed.fields;
				let operators = composedObject.Composed.operator;
				let round = +composedObject.Composed.round;
				let empty = composedObject.Composed.empty;
				let finalValue;

				for (let j = 0; j < fields.length; j++) {
					let field = fields[j];

					// console.log('field', field)
					if (isNaN(+field) && (postObject[field] !== undefined && postObject[field] !== null)) {
						// First set the start value
						if (!finalValue) {
							let value = +field;

							if (Number.isNaN(value)) {
								finalValue = postObject[field];
							} else {
								finalValue = value;
							}
						} else {
							let value = +field; // For the next value, apply calculations

							// console.log('value', value)
							if (Number.isNaN(value)) {
								value = postObject[field];
							}

							// console.log('value2', value)
							// console.log('operator', operators[j])
							if (operators[j] === '+') {
								finalValue = +finalValue + +value;
							}

							if (operators[j] === '-') {
								finalValue = finalValue - value;
							}

							if (operators[j] === '*') {
								// console.log('finalValue * value', finalValue, value, finalValue * value)
								finalValue = finalValue * value;
							}

							if (operators[j] === '/') {
								finalValue = finalValue / value;
							}
						}
					} else if (!isNaN(+field)) {
						if (!finalValue) {
							let value = +field;
							finalValue = value;
						} else {
							let value = +field; // For the next value, apply calculations

							// console.log('value2', value)
							// console.log('operator', operators[j])
							if (operators[j] === '+') {
								finalValue = +finalValue + +value;
							}

							if (operators[j] === '-') {
								finalValue = finalValue - value;
							}

							if (operators[j] === '*') {
								// console.log('finalValue * value', finalValue, value, finalValue * value)
								finalValue = finalValue * value;
							}

							if (operators[j] === '/') {
								finalValue = finalValue / value;
							}
						}
					} else {
						if (empty[j] !== '') {
							if (finalValue) {
								let value = +empty[j];

								if (operators[j] === '+') {
									finalValue = +finalValue + +value;
								}

								if (operators[j] === '-') {
									finalValue = finalValue - value;
								}

								if (operators[j] === '*') {
									finalValue = finalValue * value;
								}

								if (operators[j] === '/') {
									finalValue = finalValue / value;
								}
							} else {
								finalValue = +empty[j];
							}
						}
					}
				}

				finalValue = roundOff(finalValue, round);
				postObject[composedProperty] = finalValue;
			}
		}
	};

	// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
	// TODO EXACTLY THE SAME FUNCTION AS IN ObjectForm, line 2202
	const startBeforeEndCheck = postObject => {
		metadata.startDateProperty;
		let startDate = postObject[metadata.startDateProperty];
		let endDate = postObject[metadata.endDateProperty];

		if (startDate && endDate) {
			let sd = new Date(startDate).getTime();
			let ed = new Date(endDate).getTime();
			console.log('startDate', sd, startDate);
			console.log('endDate', ed, endDate);

			if (sd > ed) {
				handleErrorInAlert(componentText.endDateBeforeStartDate);
				return false;
			} else {
				return true;
			}
		} else {
			return true;
		}
	};

	const passBoundaryCheck = () => {
		let checkPassed = true;

		// infoLog('Object-Form', 'passGeometryCheck', newWKT)
		if (hasGeometry && wktboundary) {
			try {
				checkPassed = parser.read(wktboundary).contains(parser.read(newWKT));
			} catch(error) {
				console.error(error);
				console.warn('INVALID WKT BOUNDARY IN FORM', wktboundary);
			}
		}

		return checkPassed;
	};

	const validate = async e => {
		console.log('validate!!!');

		try {
			if (e) {
				e.preventDefault();
			}

			if (passGeometryCheck()) {
				if (passBoundaryCheck()) {
					// Create postObject and pass sanity-check
					const postObject = postObjectSanityCheck();

					await fillUpComposedFields(postObject);
					const requiredFieldsFilled = checkAllFieldsRequired(postObject);

					// const checkUniqueFields = await checkAllUniqueFields(postObject)
					//
					// if (!checkUniqueFields) {
					if (requiredFieldsFilled) {
						tabsWithMissingFiels = [];

						// TODO: check should be conditional, if end date is used for something special
						const endDatumChecked = startBeforeEndCheck(postObject);

						if (endDatumChecked) {
							let goOn = true;

							if (externalValidateFunction) {
								goOn = await externalValidateFunction(postObject, signalField);
							}

							if (goOn) {
								// Construct url to post object
								let url = '';

								const urlAndResourceParams = bc.metadataManager.getResourceUrlAndParameters(metadata.resourceName, '');
								url = urlAndResourceParams.url;
								infoLog('Object-Form', 'PostObject', postObject);

								if (typedomain) {
									// console.log('languageGuid', bc.parameterService.appParameters.languageGuid)
									// console.log('TY_CULTURE', postObject['TY_CULTURE'])
									postObject['TY_DOMAIN'] = typedomain;

									if (postObject['TY_CULTURE'] === null) {
										postObject['TY_CULTURE'] = bc.parameterService.appParameters.languageGuid;
									}
								}

								isSaving = true;

								// here the record is saved
								const answer = await bc.ajaxService.fetchPost(requestHeaders.jsonPost, url, JSON.stringify(postObject));

								const newObject = await answer.json();
								let isEntityView = isUserDefinedView(metadata.resourceName);

								if (isEntityView) {
									let lastConsulted = {
										dataviewGuid: dataviewguid,
										dataviewName: metadata.resourceLongname,
										entityGuid: newObject[metadata.idProperty],
										entityName: newObject[metadata.titleProperty],
										actionGuid: null,
										actionName: null,
										actionType: null,
										documentGuid: null,
										documentName: null
									};

									bc.messageService.publish('PROCESS_CONSULTED', lastConsulted);

									if (hasGeometry) {
										bc.messageService.publish('UPDATE_LAYER', metadata.resourceName);
									}
								}

								// Get new object and delete unncessairy property 'odata.metadata' if present
								if (newObject['odata.metadata']) {
									delete newObject['odata.metadata'];
								}

								infoLog('Object-Form', 'New Object added', newObject);

								//Place new Object in ParameterService
								bc.parameterService.set('OBJECT_ITEM_' + dataviewguid + '_' + editobjectid, newObject);

								/**************************************
 * EDIT FORM
 **************************************/
								if (formtype === 'Edit') {
									if (forminrenderer === 'true') {
										bc.messageService.publish('OBJECT_FORM_CANCEL', componentId);
									}

									// editAfterPost(newObject)
									if (saveevent !== '') {
										bc.messageService.publish(saveevent, 'false');
									}

									if (shownotifications === 'true') {
										notify('Element updated');
									}

									if (closeonsave === 'false') {
										await initComponent(dataviewguid, editobjectid, initvalues, jsondata);
									}

									console.log('initcomp in validation over!', closeonsave);
								}

								/**************************************
 * NEW FORM
 **************************************/
								if (formtype === 'New') {
									// If we have an relationship to add
									if (relationshiptype !== '') {
										await newWithRelationAfterPost(newObject);
									}

									if (saveevent !== '') {
										bc.messageService.publish(saveevent, 'false');
									}

									if (closeonsave === 'false') {
										//ready = false
										//await tick()
										updateComponentAttribute(container, 'formtype', 'Edit');

										updateComponentAttribute(container, 'editobjectid', newObject[metadata.idProperty]);
									}

									// Notify USER of inserting success
									if (shownotifications === 'true') {
										notify('Element hinzufgefügt.');
									}
								}

								// Update source entity if necessary (if we are in a relationship / action)
								if (sourceview !== '') {
									let metadataSourceView = await bc.parameterService.getMetadata(sourceview);
									let entity = null;

									if (rlitemguid !== '') {
										entity = await getObjectItem(metadataSourceView, rlitemguid);
									} else if (actionentityguid !== '') {
										entity = await getObjectItem(metadataSourceView, actionentityguid);
									}

									await updateEntity(sourceview, entity);
								}

								isSaving = false;

								if (closeonsave === 'true') {
									closeAfterSave();
								}

								bc.messageService.publish('REFRESH_ALL');
								console.log('REFRESH ALL FORM CONTAINER');

								let formEvent = new Event('save',
								{
										bubbles: false,
										composed: true,
										cancelable: true
									});

								formEvent.detail = newObject[metadata.idProperty];
								component.dispatchEvent(formEvent);
								return true;
							}
						}
					} else {
						signalRequiredFields(postObject);
						errorToast(generalText.notAllRequiredFields);
						isSaving = false;
						return false;
					}
				} else //     await Swal.fire({
				//         title: 'Fehler',
				//         text: 'Folgendes Feld muss einen einzigartigen Wert enthalten: ' + checkUniqueFields,
				//         showCancelButton: false,
				//         confirmButtonText: 'OK',
				//
				//     })
				// }
				{
					alert(componentText.geometryOutOfBoundary); // } else {
					return false;
				}
			} else {
				alert(componentText.errorMissingGeometry);
				isSaving = false;
				return false;
			}
		} catch(error) {
			handleErrorInAlert(error, generalText.errorUpdatingElement);
			console.error(generalText.errorUpdatingElement, error);
			isSaving = false;
			return false;
		}
	};

	const hasChanged = () => {
		const hasChanged = validation.find(v => v.changed);

		if (hasChanged || wktUpdated) {
			return true;
		}

		return false;
	};

	const closeForm = () => {
		if (cancelevent !== '') {
			bc.messageService.publish(cancelevent, editObject
			? {
					objectid: editObject[metadata.idProperty],
					dataviewguid
				}
			: null);
		}

		let formEvent = new Event('close',
		{
				bubbles: false,
				composed: true,
				cancelable: true
			});

		component.dispatchEvent(formEvent);
	};

	const cancelForm = event => {
		const hasChanged = validation.find(v => v.changed);

		if (hasChanged || wktUpdated) {
			if (confirm(componentText.questionDoYouReallyWantToCancel)) {
				if (event !== '' && event !== cancelevent) {
					bc.messageService.publish(event, editObject
					? {
							objectid: editObject[metadata.idProperty],
							dataviewguid
						}
					: null);
				}

				if (cancelevent !== '') {
					bc.messageService.publish(event, editObject
					? {
							objectid: editObject[metadata.idProperty],
							dataviewguid
						}
					: null);
				}

				let formEvent = new Event('close',
				{
						bubbles: false,
						composed: true,
						cancelable: true
					});

				component.dispatchEvent(formEvent);
			}
		} else // Form has not changed
		{
			if (event !== '' && event !== cancelevent) {
				bc.messageService.publish(event, editObject
				? {
						objectid: editObject[metadata.idProperty],
						dataviewguid
					}
				: null);
			}

			if (cancelevent !== '') {
				bc.messageService.publish(event, editObject
				? {
						objectid: editObject[metadata.idProperty],
						dataviewguid
					}
				: null);
			}

			let formEvent = new Event('close',
			{
					bubbles: false,
					composed: true,
					cancelable: true
				});

			component.dispatchEvent(formEvent);
		}
	};

	const closeAfterSave = () => {
		console.log('closeAfterSave');

		let formEvent = new Event('close',
		{
				bubbles: false,
				composed: true,
				cancelable: true
			});

		component.dispatchEvent(formEvent);
	};

	const newWithRelationAfterPost = async newObject => {
		try {
			const url = bc.parameterService.appParameters.OdataBaseUrl + 'UgdmCore/UgdmRelationships';
			const idProperty = metadata.getBaseField('EY_ID').name;

			const relationShipObject = {
				RL_FROM: rldirection === 'TO'
				? newObject[idProperty]
				: rlitemguid,
				RL_TO: rldirection === 'TO'
				? rlitemguid
				: newObject[idProperty],
				RL_TYPE: relationshiptype
			};

			await bc.ajaxService.fetchPost(requestHeaders.jsonPost, url, JSON.stringify(relationShipObject));

			if (shownotifications === 'true') {
				success(componentText.relationshipAdded);
			}
		} catch(error) {
			appendErrorInformation(error, componentText.errorAddingRelationshipLink);
			throw error;
		}
	};

	const cancelButton = e => {
		e.stopPropagation();
		cancelForm(cancelevent);
	};

	const additionalButton = e => {
		e.stopPropagation();
		cancelForm(additionalbuttonevent);
	};

	const updateGeometryValue = wkt => {
		console.log('updateGeometryValue', wkt);
		newWKT = wkt;
		wktUpdated = true;
	};

	const setResizeObserver = c => {
		if (innerContainer) {
			sizeObserver(innerContainer, 1024, deviceSetter);
		}
	};

	const deviceSetter = width => {
		if (width <= 758) {
			isTablet = false;
		} else if (width > 758 && width <= 1380) {
			isTablet = true;
		} else if (width > 1380 && width <= 1920) {
			isTablet = false;
		} else if (width > 1920) {
			isTablet = false;
		}
	};

	const checkForSlotUpdates = slottedElement => {
		if (slottedElement) {
			slottedElement.addEventListener('slotchange', () => {
				hasHelp = true;
			});
		}
	};

	const createListFilter = (key, filterList) => {
		if (filterList) {
			let filterArray = filterList.get(key);

			if (filterArray) {
				if (filterArray.length < 1) {
					return constants.base.emptyGuid;
				} else {
					return filterArray.join(';');
				}
			}
		}

		return '';
	};

	$$self.$$.on_mount.push(function () {
		if (metadata === undefined && !('metadata' in $$props || $$self.$$.bound[$$self.$$.props['metadata']])) {
			console_1.warn("<form-field> was created without expected prop 'metadata'");
		}
	});

	const writable_props = [
		'datatype',
		'fieldvalidation',
		'label',
		'description',
		'iseditable',
		'isnullable',
		'filter',
		'reversefilter',
		'dataviewguid',
		'editobjectid',
		'actionentityguid',
		'formtype',
		'forminrenderer',
		'metadata',
		'mappedEditable',
		'nullableMap',
		'relationshiptype',
		'rlitemguid',
		'rldirection',
		'sourceview',
		'typedomain',
		'showgeoedit',
		'getwkt',
		'initvalues',
		'jsondata',
		'displayintabs',
		'tabformviews',
		'displaymapintab',
		'nestedform',
		'nestedformlevel',
		'nestedformviews',
		'nestedformviewlevels',
		'currentnestinglevel',
		'handlerelationships',
		'handledocuments',
		'saveevent',
		'cancelevent',
		'additionalbuttonevent',
		'cancelon',
		'closeonsave',
		'isbackend',
		'title',
		'show',
		'init',
		'closeonnavigate',
		'formrules',
		'hideadditionalgeotools',
		'bgwkt',
		'bgwktstrokecolor',
		'bgwktfillcolor',
		'bgwktcenteron',
		'bgwktviewguid',
		'bgwktentityguid',
		'wktboundary',
		'shownotifications',
		'textfieldlength',
		'validation'
	];

	Object_1.keys($$props).forEach(key => {
		if (!~writable_props.indexOf(key) && key.slice(0, 2) !== '$$' && key !== 'slot') console_1.warn(`<form-field> was created with unknown prop '${key}'`);
	});

	const change_handler = async e => {
		await setChanged(e.detail);
	};

	const change_handler_1 = async e => {
		await setChanged(e.detail);
	};

	const change_handler_2 = async e => {
		await setChanged(e.detail);
	};

	const func = x => x.field === fieldvalidation.column;
	const func_1 = x => x.field === fieldvalidation.column;
	const func_2 = x => x.field === fieldvalidation.column;
	const func_3 = x => x.field === fieldvalidation.column;
	const func_4 = x => x.field === fieldvalidation.column;
	const func_5 = x => x.field === fieldvalidation.column;

	const change_handler_3 = async e => {
		await setChanged(e.detail);
	};

	const change_handler_4 = async e => {
		await setChanged(e.detail);
	};

	const change_handler_5 = async e => {
		await setChanged(e.detail);
	};

	const change_handler_6 = async e => {
		await setChanged(e.detail);
	};

	const change_handler_7 = async e => {
		await setChanged(e.detail);
	};

	const change_handler_8 = async e => {
		await setChanged(e.detail);
	};

	function div_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			container = $$value;
			$$invalidate(11, container);
		});
	}

	$$self.$$set = $$props => {
		if ('datatype' in $$props) $$invalidate(1, datatype = $$props.datatype);
		if ('fieldvalidation' in $$props) $$invalidate(2, fieldvalidation = $$props.fieldvalidation);
		if ('label' in $$props) $$invalidate(3, label = $$props.label);
		if ('description' in $$props) $$invalidate(4, description = $$props.description);
		if ('iseditable' in $$props) $$invalidate(5, iseditable = $$props.iseditable);
		if ('isnullable' in $$props) $$invalidate(6, isnullable = $$props.isnullable);
		if ('filter' in $$props) $$invalidate(7, filter = $$props.filter);
		if ('reversefilter' in $$props) $$invalidate(8, reversefilter = $$props.reversefilter);
		if ('dataviewguid' in $$props) $$invalidate(9, dataviewguid = $$props.dataviewguid);
		if ('editobjectid' in $$props) $$invalidate(25, editobjectid = $$props.editobjectid);
		if ('actionentityguid' in $$props) $$invalidate(26, actionentityguid = $$props.actionentityguid);
		if ('formtype' in $$props) $$invalidate(27, formtype = $$props.formtype);
		if ('forminrenderer' in $$props) $$invalidate(28, forminrenderer = $$props.forminrenderer);
		if ('metadata' in $$props) $$invalidate(0, metadata = $$props.metadata);
		if ('mappedEditable' in $$props) $$invalidate(29, mappedEditable = $$props.mappedEditable);
		if ('nullableMap' in $$props) $$invalidate(30, nullableMap = $$props.nullableMap);
		if ('relationshiptype' in $$props) $$invalidate(31, relationshiptype = $$props.relationshiptype);
		if ('rlitemguid' in $$props) $$invalidate(32, rlitemguid = $$props.rlitemguid);
		if ('rldirection' in $$props) $$invalidate(33, rldirection = $$props.rldirection);
		if ('sourceview' in $$props) $$invalidate(34, sourceview = $$props.sourceview);
		if ('typedomain' in $$props) $$invalidate(35, typedomain = $$props.typedomain);
		if ('showgeoedit' in $$props) $$invalidate(36, showgeoedit = $$props.showgeoedit);
		if ('getwkt' in $$props) $$invalidate(37, getwkt = $$props.getwkt);
		if ('initvalues' in $$props) $$invalidate(38, initvalues = $$props.initvalues);
		if ('jsondata' in $$props) $$invalidate(39, jsondata = $$props.jsondata);
		if ('displayintabs' in $$props) $$invalidate(20, displayintabs = $$props.displayintabs);
		if ('tabformviews' in $$props) $$invalidate(40, tabformviews = $$props.tabformviews);
		if ('displaymapintab' in $$props) $$invalidate(21, displaymapintab = $$props.displaymapintab);
		if ('nestedform' in $$props) $$invalidate(41, nestedform = $$props.nestedform);
		if ('nestedformlevel' in $$props) $$invalidate(42, nestedformlevel = $$props.nestedformlevel);
		if ('nestedformviews' in $$props) $$invalidate(43, nestedformviews = $$props.nestedformviews);
		if ('nestedformviewlevels' in $$props) $$invalidate(44, nestedformviewlevels = $$props.nestedformviewlevels);
		if ('currentnestinglevel' in $$props) $$invalidate(45, currentnestinglevel = $$props.currentnestinglevel);
		if ('handlerelationships' in $$props) $$invalidate(22, handlerelationships = $$props.handlerelationships);
		if ('handledocuments' in $$props) $$invalidate(23, handledocuments = $$props.handledocuments);
		if ('saveevent' in $$props) $$invalidate(46, saveevent = $$props.saveevent);
		if ('cancelevent' in $$props) $$invalidate(47, cancelevent = $$props.cancelevent);
		if ('additionalbuttonevent' in $$props) $$invalidate(48, additionalbuttonevent = $$props.additionalbuttonevent);
		if ('cancelon' in $$props) $$invalidate(49, cancelon = $$props.cancelon);
		if ('closeonsave' in $$props) $$invalidate(50, closeonsave = $$props.closeonsave);
		if ('isbackend' in $$props) $$invalidate(51, isbackend = $$props.isbackend);
		if ('title' in $$props) $$invalidate(52, title = $$props.title);
		if ('show' in $$props) $$invalidate(53, show = $$props.show);
		if ('init' in $$props) $$invalidate(54, init = $$props.init);
		if ('closeonnavigate' in $$props) $$invalidate(55, closeonnavigate = $$props.closeonnavigate);
		if ('formrules' in $$props) $$invalidate(56, formrules = $$props.formrules);
		if ('hideadditionalgeotools' in $$props) $$invalidate(57, hideadditionalgeotools = $$props.hideadditionalgeotools);
		if ('bgwkt' in $$props) $$invalidate(58, bgwkt = $$props.bgwkt);
		if ('bgwktstrokecolor' in $$props) $$invalidate(59, bgwktstrokecolor = $$props.bgwktstrokecolor);
		if ('bgwktfillcolor' in $$props) $$invalidate(60, bgwktfillcolor = $$props.bgwktfillcolor);
		if ('bgwktcenteron' in $$props) $$invalidate(61, bgwktcenteron = $$props.bgwktcenteron);
		if ('bgwktviewguid' in $$props) $$invalidate(62, bgwktviewguid = $$props.bgwktviewguid);
		if ('bgwktentityguid' in $$props) $$invalidate(63, bgwktentityguid = $$props.bgwktentityguid);
		if ('wktboundary' in $$props) $$invalidate(64, wktboundary = $$props.wktboundary);
		if ('shownotifications' in $$props) $$invalidate(65, shownotifications = $$props.shownotifications);
		if ('textfieldlength' in $$props) $$invalidate(10, textfieldlength = $$props.textfieldlength);
		if ('validation' in $$props) $$invalidate(24, validation = $$props.validation);
	};

	$$self.$capture_state = () => ({
		bc,
		uuidv4,
		onMount,
		tick,
		requestHeaders,
		getNullablesMap,
		getSimpleDisplayValue,
		getUniqueMap,
		parseFieldGroups,
		roundOff,
		get_current_component,
		constants,
		numericFieldTypes,
		addValuesToAdvancedFilter,
		createFilter,
		infoLog,
		sizeObserver,
		attachNormalize,
		dispatchMountedEvent,
		handleComponentInitAttribute,
		handleComponentProps,
		handleComponentShowAttribute,
		updateComponentAttribute,
		appendErrorInformation,
		errorToast,
		handleErrorInAlert,
		notify,
		success,
		getObjectItem,
		updateEntity,
		getValidationEntry,
		isUserDefinedView,
		metadataHasGeo,
		getComponentText,
		applicationReady,
		isMobileStore,
		datatype,
		fieldvalidation,
		label,
		description,
		iseditable,
		isnullable,
		filter,
		reversefilter,
		dataviewguid,
		editobjectid,
		actionentityguid,
		formtype,
		forminrenderer,
		metadata,
		mappedEditable,
		nullableMap,
		relationshiptype,
		rlitemguid,
		rldirection,
		sourceview,
		typedomain,
		showgeoedit,
		getwkt,
		initvalues,
		jsondata,
		displayintabs,
		tabformviews,
		displaymapintab,
		nestedform,
		nestedformlevel,
		nestedformviews,
		nestedformviewlevels,
		currentnestinglevel,
		handlerelationships,
		handledocuments,
		saveevent,
		cancelevent,
		additionalbuttonevent,
		cancelon,
		closeonsave,
		isbackend,
		title,
		show,
		init,
		closeonnavigate,
		formrules,
		hideadditionalgeotools,
		bgwkt,
		bgwktstrokecolor,
		bgwktfillcolor,
		bgwktcenteron,
		bgwktviewguid,
		bgwktentityguid,
		wktboundary,
		shownotifications,
		textfieldlength,
		container,
		firstInitialized,
		subscribeGuid,
		initError,
		generalText,
		componentText,
		component,
		mounted,
		canReload,
		ready,
		externalValidateFunction,
		overflowContainer,
		showHelp,
		slottedElement,
		hasHelp,
		editObject,
		keys,
		componentId,
		validation,
		formHasChanged,
		newWKT,
		composedProperties,
		existingSearchObject,
		wktUpdated,
		isEntityForm,
		isNestedForm,
		nestedFormLevel,
		currentNestingLevel,
		composedObjectArray,
		groups,
		groupIds,
		groupMap,
		closedGroups,
		geoTitle,
		dataTitle,
		innerContainer,
		tab,
		isTablet,
		hasGeometry,
		mapFullScreen,
		canEditGeo,
		updateWKTEventName,
		tabEvent,
		showMapTab,
		isSaving,
		viewGroups,
		tabsWithMissingFiels,
		mapResetEventsFired,
		badgeValues,
		formStyle,
		tabComponent,
		formRules,
		visibleGroups,
		visibleFields,
		hasOverflow,
		firstDefaultValues,
		parser,
		mappedListFilters,
		mappedReverseListFilters,
		formLayout,
		textMessages,
		infoMessages,
		warningMessages,
		titles,
		dividerTop,
		dividerBottom,
		ranges,
		textFieldValues,
		initComponent,
		applyProps,
		setChanged,
		applyFormRules,
		applyGroupChanges,
		applyFieldChanges,
		applyListChanges,
		applyReverseListChanges,
		applyCallback,
		applyValueChanges,
		checkHasOverflow,
		postObjectSanityCheck,
		passGeometryCheck,
		checkAllFieldsRequired,
		checkAllUniqueFields,
		signalRequiredFields,
		signalField,
		fillUpComposedFields,
		startBeforeEndCheck,
		passBoundaryCheck,
		validate,
		hasChanged,
		closeForm,
		cancelForm,
		closeAfterSave,
		newWithRelationAfterPost,
		cancelButton,
		additionalButton,
		updateGeometryValue,
		setResizeObserver,
		deviceSetter,
		checkForSlotUpdates,
		createListFilter,
		$isMobileStore,
		$applicationReady
	});

	$$self.$inject_state = $$props => {
		if ('datatype' in $$props) $$invalidate(1, datatype = $$props.datatype);
		if ('fieldvalidation' in $$props) $$invalidate(2, fieldvalidation = $$props.fieldvalidation);
		if ('label' in $$props) $$invalidate(3, label = $$props.label);
		if ('description' in $$props) $$invalidate(4, description = $$props.description);
		if ('iseditable' in $$props) $$invalidate(5, iseditable = $$props.iseditable);
		if ('isnullable' in $$props) $$invalidate(6, isnullable = $$props.isnullable);
		if ('filter' in $$props) $$invalidate(7, filter = $$props.filter);
		if ('reversefilter' in $$props) $$invalidate(8, reversefilter = $$props.reversefilter);
		if ('dataviewguid' in $$props) $$invalidate(9, dataviewguid = $$props.dataviewguid);
		if ('editobjectid' in $$props) $$invalidate(25, editobjectid = $$props.editobjectid);
		if ('actionentityguid' in $$props) $$invalidate(26, actionentityguid = $$props.actionentityguid);
		if ('formtype' in $$props) $$invalidate(27, formtype = $$props.formtype);
		if ('forminrenderer' in $$props) $$invalidate(28, forminrenderer = $$props.forminrenderer);
		if ('metadata' in $$props) $$invalidate(0, metadata = $$props.metadata);
		if ('mappedEditable' in $$props) $$invalidate(29, mappedEditable = $$props.mappedEditable);
		if ('nullableMap' in $$props) $$invalidate(30, nullableMap = $$props.nullableMap);
		if ('relationshiptype' in $$props) $$invalidate(31, relationshiptype = $$props.relationshiptype);
		if ('rlitemguid' in $$props) $$invalidate(32, rlitemguid = $$props.rlitemguid);
		if ('rldirection' in $$props) $$invalidate(33, rldirection = $$props.rldirection);
		if ('sourceview' in $$props) $$invalidate(34, sourceview = $$props.sourceview);
		if ('typedomain' in $$props) $$invalidate(35, typedomain = $$props.typedomain);
		if ('showgeoedit' in $$props) $$invalidate(36, showgeoedit = $$props.showgeoedit);
		if ('getwkt' in $$props) $$invalidate(37, getwkt = $$props.getwkt);
		if ('initvalues' in $$props) $$invalidate(38, initvalues = $$props.initvalues);
		if ('jsondata' in $$props) $$invalidate(39, jsondata = $$props.jsondata);
		if ('displayintabs' in $$props) $$invalidate(20, displayintabs = $$props.displayintabs);
		if ('tabformviews' in $$props) $$invalidate(40, tabformviews = $$props.tabformviews);
		if ('displaymapintab' in $$props) $$invalidate(21, displaymapintab = $$props.displaymapintab);
		if ('nestedform' in $$props) $$invalidate(41, nestedform = $$props.nestedform);
		if ('nestedformlevel' in $$props) $$invalidate(42, nestedformlevel = $$props.nestedformlevel);
		if ('nestedformviews' in $$props) $$invalidate(43, nestedformviews = $$props.nestedformviews);
		if ('nestedformviewlevels' in $$props) $$invalidate(44, nestedformviewlevels = $$props.nestedformviewlevels);
		if ('currentnestinglevel' in $$props) $$invalidate(45, currentnestinglevel = $$props.currentnestinglevel);
		if ('handlerelationships' in $$props) $$invalidate(22, handlerelationships = $$props.handlerelationships);
		if ('handledocuments' in $$props) $$invalidate(23, handledocuments = $$props.handledocuments);
		if ('saveevent' in $$props) $$invalidate(46, saveevent = $$props.saveevent);
		if ('cancelevent' in $$props) $$invalidate(47, cancelevent = $$props.cancelevent);
		if ('additionalbuttonevent' in $$props) $$invalidate(48, additionalbuttonevent = $$props.additionalbuttonevent);
		if ('cancelon' in $$props) $$invalidate(49, cancelon = $$props.cancelon);
		if ('closeonsave' in $$props) $$invalidate(50, closeonsave = $$props.closeonsave);
		if ('isbackend' in $$props) $$invalidate(51, isbackend = $$props.isbackend);
		if ('title' in $$props) $$invalidate(52, title = $$props.title);
		if ('show' in $$props) $$invalidate(53, show = $$props.show);
		if ('init' in $$props) $$invalidate(54, init = $$props.init);
		if ('closeonnavigate' in $$props) $$invalidate(55, closeonnavigate = $$props.closeonnavigate);
		if ('formrules' in $$props) $$invalidate(56, formrules = $$props.formrules);
		if ('hideadditionalgeotools' in $$props) $$invalidate(57, hideadditionalgeotools = $$props.hideadditionalgeotools);
		if ('bgwkt' in $$props) $$invalidate(58, bgwkt = $$props.bgwkt);
		if ('bgwktstrokecolor' in $$props) $$invalidate(59, bgwktstrokecolor = $$props.bgwktstrokecolor);
		if ('bgwktfillcolor' in $$props) $$invalidate(60, bgwktfillcolor = $$props.bgwktfillcolor);
		if ('bgwktcenteron' in $$props) $$invalidate(61, bgwktcenteron = $$props.bgwktcenteron);
		if ('bgwktviewguid' in $$props) $$invalidate(62, bgwktviewguid = $$props.bgwktviewguid);
		if ('bgwktentityguid' in $$props) $$invalidate(63, bgwktentityguid = $$props.bgwktentityguid);
		if ('wktboundary' in $$props) $$invalidate(64, wktboundary = $$props.wktboundary);
		if ('shownotifications' in $$props) $$invalidate(65, shownotifications = $$props.shownotifications);
		if ('textfieldlength' in $$props) $$invalidate(10, textfieldlength = $$props.textfieldlength);
		if ('container' in $$props) $$invalidate(11, container = $$props.container);
		if ('firstInitialized' in $$props) $$invalidate(70, firstInitialized = $$props.firstInitialized);
		if ('subscribeGuid' in $$props) $$invalidate(17, subscribeGuid = $$props.subscribeGuid);
		if ('initError' in $$props) $$invalidate(12, initError = $$props.initError);
		if ('mounted' in $$props) $$invalidate(71, mounted = $$props.mounted);
		if ('canReload' in $$props) canReload = $$props.canReload;
		if ('ready' in $$props) $$invalidate(13, ready = $$props.ready);
		if ('externalValidateFunction' in $$props) externalValidateFunction = $$props.externalValidateFunction;
		if ('overflowContainer' in $$props) $$invalidate(130, overflowContainer = $$props.overflowContainer);
		if ('showHelp' in $$props) showHelp = $$props.showHelp;
		if ('slottedElement' in $$props) $$invalidate(132, slottedElement = $$props.slottedElement);
		if ('hasHelp' in $$props) hasHelp = $$props.hasHelp;
		if ('editObject' in $$props) editObject = $$props.editObject;
		if ('keys' in $$props) keys = $$props.keys;
		if ('componentId' in $$props) $$invalidate(14, componentId = $$props.componentId);
		if ('validation' in $$props) $$invalidate(24, validation = $$props.validation);
		if ('formHasChanged' in $$props) formHasChanged = $$props.formHasChanged;
		if ('newWKT' in $$props) newWKT = $$props.newWKT;
		if ('composedProperties' in $$props) composedProperties = $$props.composedProperties;
		if ('existingSearchObject' in $$props) existingSearchObject = $$props.existingSearchObject;
		if ('wktUpdated' in $$props) wktUpdated = $$props.wktUpdated;
		if ('isEntityForm' in $$props) isEntityForm = $$props.isEntityForm;
		if ('isNestedForm' in $$props) isNestedForm = $$props.isNestedForm;
		if ('nestedFormLevel' in $$props) nestedFormLevel = $$props.nestedFormLevel;
		if ('currentNestingLevel' in $$props) currentNestingLevel = $$props.currentNestingLevel;
		if ('composedObjectArray' in $$props) composedObjectArray = $$props.composedObjectArray;
		if ('groups' in $$props) $$invalidate(72, groups = $$props.groups);
		if ('groupIds' in $$props) groupIds = $$props.groupIds;
		if ('groupMap' in $$props) groupMap = $$props.groupMap;
		if ('closedGroups' in $$props) closedGroups = $$props.closedGroups;
		if ('geoTitle' in $$props) geoTitle = $$props.geoTitle;
		if ('dataTitle' in $$props) dataTitle = $$props.dataTitle;
		if ('innerContainer' in $$props) $$invalidate(136, innerContainer = $$props.innerContainer);
		if ('tab' in $$props) tab = $$props.tab;
		if ('isTablet' in $$props) isTablet = $$props.isTablet;
		if ('hasGeometry' in $$props) hasGeometry = $$props.hasGeometry;
		if ('mapFullScreen' in $$props) mapFullScreen = $$props.mapFullScreen;
		if ('canEditGeo' in $$props) canEditGeo = $$props.canEditGeo;
		if ('updateWKTEventName' in $$props) updateWKTEventName = $$props.updateWKTEventName;
		if ('tabEvent' in $$props) tabEvent = $$props.tabEvent;
		if ('showMapTab' in $$props) showMapTab = $$props.showMapTab;
		if ('isSaving' in $$props) isSaving = $$props.isSaving;
		if ('viewGroups' in $$props) viewGroups = $$props.viewGroups;
		if ('tabsWithMissingFiels' in $$props) tabsWithMissingFiels = $$props.tabsWithMissingFiels;
		if ('mapResetEventsFired' in $$props) mapResetEventsFired = $$props.mapResetEventsFired;
		if ('badgeValues' in $$props) badgeValues = $$props.badgeValues;
		if ('formStyle' in $$props) formStyle = $$props.formStyle;
		if ('tabComponent' in $$props) $$invalidate(140, tabComponent = $$props.tabComponent);
		if ('formRules' in $$props) formRules = $$props.formRules;
		if ('visibleGroups' in $$props) visibleGroups = $$props.visibleGroups;
		if ('visibleFields' in $$props) visibleFields = $$props.visibleFields;
		if ('hasOverflow' in $$props) hasOverflow = $$props.hasOverflow;
		if ('firstDefaultValues' in $$props) firstDefaultValues = $$props.firstDefaultValues;
		if ('parser' in $$props) parser = $$props.parser;
		if ('mappedListFilters' in $$props) mappedListFilters = $$props.mappedListFilters;
		if ('mappedReverseListFilters' in $$props) mappedReverseListFilters = $$props.mappedReverseListFilters;
		if ('formLayout' in $$props) formLayout = $$props.formLayout;
		if ('textMessages' in $$props) textMessages = $$props.textMessages;
		if ('infoMessages' in $$props) infoMessages = $$props.infoMessages;
		if ('warningMessages' in $$props) warningMessages = $$props.warningMessages;
		if ('titles' in $$props) titles = $$props.titles;
		if ('dividerTop' in $$props) dividerTop = $$props.dividerTop;
		if ('dividerBottom' in $$props) dividerBottom = $$props.dividerBottom;
		if ('ranges' in $$props) $$invalidate(15, ranges = $$props.ranges);
		if ('textFieldValues' in $$props) $$invalidate(16, textFieldValues = $$props.textFieldValues);
	};

	if ($$props && "$$inject" in $$props) {
		$$self.$inject_state($$props.$$inject);
	}

	$$self.$$.update = () => {
		if ($$self.$$.dirty[1] & /*show*/ 4194304) {
			// HTML Mount & Component initialization
			//================================================================
			$: if (show) {
				handleComponentShowAttribute(show, component);
			}
		}

		if ($$self.$$.dirty[1] & /*init*/ 8388608 | $$self.$$.dirty[2] & /*firstInitialized, mounted, $applicationReady*/ 4864) {
			/**
 * Handle the component init attribute, for the case that the component has not been initialized yet.
 */
			$: handleComponentInitAttribute(init, firstInitialized, mounted, $applicationReady, applyProps);
		}

		if ($$self.$$.dirty[0] & /*dataviewguid, editobjectid*/ 33554944 | $$self.$$.dirty[1] & /*initvalues, jsondata*/ 384) {
			/**
 * Handles any component attribute changes after the component is initialized.
 */
			$: (dataviewguid, editobjectid, initvalues, jsondata, applyProps(true));
		}

		if ($$self.$$.dirty[2] & /*$isMobileStore, groups*/ 3072) {
			// Component functions
			//================================================================
			$: if ($isMobileStore !== undefined) {
				if ($isMobileStore) {
					formStyle = 'FieldsOneTabWithMapTab';

					if (tabComponent) {
						let currentTab = tabComponent.getCurrentTab();

						if (groups.includes(currentTab) || currentTab === componentText.notGrouped) {
							console.log('set TAB!!!!');

							bc.messageService.publish('SET_TAB', {
								tab: componentText.form,
								groupId: `form_tabs_${subscribeGuid}`
							});
						}
					}
				}
			}
		}
	};

	$: checkHasOverflow(overflowContainer);
	$: setResizeObserver(innerContainer);
	$: checkForSlotUpdates(slottedElement);

	return [
		metadata,
		datatype,
		fieldvalidation,
		label,
		description,
		iseditable,
		isnullable,
		filter,
		reversefilter,
		dataviewguid,
		textfieldlength,
		container,
		initError,
		ready,
		componentId,
		ranges,
		textFieldValues,
		subscribeGuid,
		generalText,
		setChanged,
		displayintabs,
		displaymapintab,
		handlerelationships,
		handledocuments,
		validation,
		editobjectid,
		actionentityguid,
		formtype,
		forminrenderer,
		mappedEditable,
		nullableMap,
		relationshiptype,
		rlitemguid,
		rldirection,
		sourceview,
		typedomain,
		showgeoedit,
		getwkt,
		initvalues,
		jsondata,
		tabformviews,
		nestedform,
		nestedformlevel,
		nestedformviews,
		nestedformviewlevels,
		currentnestinglevel,
		saveevent,
		cancelevent,
		additionalbuttonevent,
		cancelon,
		closeonsave,
		isbackend,
		title,
		show,
		init,
		closeonnavigate,
		formrules,
		hideadditionalgeotools,
		bgwkt,
		bgwktstrokecolor,
		bgwktfillcolor,
		bgwktcenteron,
		bgwktviewguid,
		bgwktentityguid,
		wktboundary,
		shownotifications,
		signalField,
		validate,
		hasChanged,
		closeForm,
		firstInitialized,
		mounted,
		groups,
		$isMobileStore,
		$applicationReady,
		change_handler,
		change_handler_1,
		change_handler_2,
		func,
		func_1,
		func_2,
		func_3,
		func_4,
		func_5,
		change_handler_3,
		change_handler_4,
		change_handler_5,
		change_handler_6,
		change_handler_7,
		change_handler_8,
		div_binding
	];
}

class FormField extends SvelteElement {
	constructor(options) {
		super();
		const style = document.createElement('style');
		style.textContent = `:host{display:block}:host([hidden]){display:none}100%{left:0;right:0}100%{left:0;right:0}100%{opacity:1}100%{opacity:1}45%{-webkit-transform:scale(var(--fa-beat-scale, 1.25));transform:scale(var(--fa-beat-scale, 1.25))}45%{-webkit-transform:scale(var(--fa-beat-scale, 1.25));transform:scale(var(--fa-beat-scale, 1.25))}50%{opacity:1;-webkit-transform:scale(var(--fa-beat-fade-scale, 1.125));transform:scale(var(--fa-beat-fade-scale, 1.125))}50%{opacity:1;-webkit-transform:scale(var(--fa-beat-fade-scale, 1.125));transform:scale(var(--fa-beat-fade-scale, 1.125))}100%{-webkit-transform:rotate(360deg);transform:rotate(360deg)}100%{-webkit-transform:rotate(360deg);transform:rotate(360deg)}.bg-gray-50{--tw-bg-opacity:1;background-color:rgba(249, 250, 251, var(--tw-bg-opacity))}.border-gray-300{--tw-border-opacity:1;border-color:rgba(209, 213, 219, var(--tw-border-opacity))}.rounded{border-radius:0.25rem}.border-solid{border-style:solid}.border{border-width:1px}.flex{display:-webkit-box;display:-ms-flexbox;display:-webkit-flex;display:flex}.flex-col{-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;-webkit-flex-direction:column;flex-direction:column}.items-center{-webkit-box-align:center;-ms-flex-align:center;-webkit-align-items:center;align-items:center}.justify-center{-webkit-box-pack:center;-ms-flex-pack:center;-webkit-justify-content:center;justify-content:center}.h-full{height:100%}.p-4{padding:1rem}`;
		this.shadowRoot.appendChild(style);

		init_1(
			this,
			{
				target: this.shadowRoot,
				props: attribute_to_object(this.attributes),
				customElement: true
			},
			instance,
			create_fragment,
			safe_not_equal,
			{
				datatype: 1,
				fieldvalidation: 2,
				label: 3,
				description: 4,
				iseditable: 5,
				isnullable: 6,
				filter: 7,
				reversefilter: 8,
				dataviewguid: 9,
				editobjectid: 25,
				actionentityguid: 26,
				formtype: 27,
				forminrenderer: 28,
				metadata: 0,
				mappedEditable: 29,
				nullableMap: 30,
				relationshiptype: 31,
				rlitemguid: 32,
				rldirection: 33,
				sourceview: 34,
				typedomain: 35,
				showgeoedit: 36,
				getwkt: 37,
				initvalues: 38,
				jsondata: 39,
				displayintabs: 20,
				tabformviews: 40,
				displaymapintab: 21,
				nestedform: 41,
				nestedformlevel: 42,
				nestedformviews: 43,
				nestedformviewlevels: 44,
				currentnestinglevel: 45,
				handlerelationships: 22,
				handledocuments: 23,
				saveevent: 46,
				cancelevent: 47,
				additionalbuttonevent: 48,
				cancelon: 49,
				closeonsave: 50,
				isbackend: 51,
				title: 52,
				show: 53,
				init: 54,
				closeonnavigate: 55,
				formrules: 56,
				hideadditionalgeotools: 57,
				bgwkt: 58,
				bgwktstrokecolor: 59,
				bgwktfillcolor: 60,
				bgwktcenteron: 61,
				bgwktviewguid: 62,
				bgwktentityguid: 63,
				wktboundary: 64,
				shownotifications: 65,
				textfieldlength: 10,
				validation: 24,
				signalField: 66,
				validate: 67,
				hasChanged: 68,
				closeForm: 69
			},
			null,
			[-1, -1, -1, -1, -1, -1]
		);

		if (options) {
			if (options.target) {
				insert_dev(options.target, this, options.anchor);
			}

			if (options.props) {
				this.$set(options.props);
				flush();
			}
		}
	}

	static get observedAttributes() {
		return [
			"datatype",
			"fieldvalidation",
			"label",
			"description",
			"iseditable",
			"isnullable",
			"filter",
			"reversefilter",
			"dataviewguid",
			"editobjectid",
			"actionentityguid",
			"formtype",
			"forminrenderer",
			"metadata",
			"mappedEditable",
			"nullableMap",
			"relationshiptype",
			"rlitemguid",
			"rldirection",
			"sourceview",
			"typedomain",
			"showgeoedit",
			"getwkt",
			"initvalues",
			"jsondata",
			"displayintabs",
			"tabformviews",
			"displaymapintab",
			"nestedform",
			"nestedformlevel",
			"nestedformviews",
			"nestedformviewlevels",
			"currentnestinglevel",
			"handlerelationships",
			"handledocuments",
			"saveevent",
			"cancelevent",
			"additionalbuttonevent",
			"cancelon",
			"closeonsave",
			"isbackend",
			"title",
			"show",
			"init",
			"closeonnavigate",
			"formrules",
			"hideadditionalgeotools",
			"bgwkt",
			"bgwktstrokecolor",
			"bgwktfillcolor",
			"bgwktcenteron",
			"bgwktviewguid",
			"bgwktentityguid",
			"wktboundary",
			"shownotifications",
			"textfieldlength",
			"validation",
			"signalField",
			"validate",
			"hasChanged",
			"closeForm"
		];
	}

	get datatype() {
		return this.$$.ctx[1];
	}

	set datatype(datatype) {
		this.$$set({ datatype });
		flush();
	}

	get fieldvalidation() {
		return this.$$.ctx[2];
	}

	set fieldvalidation(fieldvalidation) {
		this.$$set({ fieldvalidation });
		flush();
	}

	get label() {
		return this.$$.ctx[3];
	}

	set label(label) {
		this.$$set({ label });
		flush();
	}

	get description() {
		return this.$$.ctx[4];
	}

	set description(description) {
		this.$$set({ description });
		flush();
	}

	get iseditable() {
		return this.$$.ctx[5];
	}

	set iseditable(iseditable) {
		this.$$set({ iseditable });
		flush();
	}

	get isnullable() {
		return this.$$.ctx[6];
	}

	set isnullable(isnullable) {
		this.$$set({ isnullable });
		flush();
	}

	get filter() {
		return this.$$.ctx[7];
	}

	set filter(filter) {
		this.$$set({ filter });
		flush();
	}

	get reversefilter() {
		return this.$$.ctx[8];
	}

	set reversefilter(reversefilter) {
		this.$$set({ reversefilter });
		flush();
	}

	get dataviewguid() {
		return this.$$.ctx[9];
	}

	set dataviewguid(dataviewguid) {
		this.$$set({ dataviewguid });
		flush();
	}

	get editobjectid() {
		return this.$$.ctx[25];
	}

	set editobjectid(editobjectid) {
		this.$$set({ editobjectid });
		flush();
	}

	get actionentityguid() {
		return this.$$.ctx[26];
	}

	set actionentityguid(actionentityguid) {
		this.$$set({ actionentityguid });
		flush();
	}

	get formtype() {
		return this.$$.ctx[27];
	}

	set formtype(formtype) {
		this.$$set({ formtype });
		flush();
	}

	get forminrenderer() {
		return this.$$.ctx[28];
	}

	set forminrenderer(forminrenderer) {
		this.$$set({ forminrenderer });
		flush();
	}

	get metadata() {
		return this.$$.ctx[0];
	}

	set metadata(metadata) {
		this.$$set({ metadata });
		flush();
	}

	get mappedEditable() {
		return this.$$.ctx[29];
	}

	set mappedEditable(mappedEditable) {
		this.$$set({ mappedEditable });
		flush();
	}

	get nullableMap() {
		return this.$$.ctx[30];
	}

	set nullableMap(nullableMap) {
		this.$$set({ nullableMap });
		flush();
	}

	get relationshiptype() {
		return this.$$.ctx[31];
	}

	set relationshiptype(relationshiptype) {
		this.$$set({ relationshiptype });
		flush();
	}

	get rlitemguid() {
		return this.$$.ctx[32];
	}

	set rlitemguid(rlitemguid) {
		this.$$set({ rlitemguid });
		flush();
	}

	get rldirection() {
		return this.$$.ctx[33];
	}

	set rldirection(rldirection) {
		this.$$set({ rldirection });
		flush();
	}

	get sourceview() {
		return this.$$.ctx[34];
	}

	set sourceview(sourceview) {
		this.$$set({ sourceview });
		flush();
	}

	get typedomain() {
		return this.$$.ctx[35];
	}

	set typedomain(typedomain) {
		this.$$set({ typedomain });
		flush();
	}

	get showgeoedit() {
		return this.$$.ctx[36];
	}

	set showgeoedit(showgeoedit) {
		this.$$set({ showgeoedit });
		flush();
	}

	get getwkt() {
		return this.$$.ctx[37];
	}

	set getwkt(getwkt) {
		this.$$set({ getwkt });
		flush();
	}

	get initvalues() {
		return this.$$.ctx[38];
	}

	set initvalues(initvalues) {
		this.$$set({ initvalues });
		flush();
	}

	get jsondata() {
		return this.$$.ctx[39];
	}

	set jsondata(jsondata) {
		this.$$set({ jsondata });
		flush();
	}

	get displayintabs() {
		return this.$$.ctx[20];
	}

	set displayintabs(displayintabs) {
		this.$$set({ displayintabs });
		flush();
	}

	get tabformviews() {
		return this.$$.ctx[40];
	}

	set tabformviews(tabformviews) {
		this.$$set({ tabformviews });
		flush();
	}

	get displaymapintab() {
		return this.$$.ctx[21];
	}

	set displaymapintab(displaymapintab) {
		this.$$set({ displaymapintab });
		flush();
	}

	get nestedform() {
		return this.$$.ctx[41];
	}

	set nestedform(nestedform) {
		this.$$set({ nestedform });
		flush();
	}

	get nestedformlevel() {
		return this.$$.ctx[42];
	}

	set nestedformlevel(nestedformlevel) {
		this.$$set({ nestedformlevel });
		flush();
	}

	get nestedformviews() {
		return this.$$.ctx[43];
	}

	set nestedformviews(nestedformviews) {
		this.$$set({ nestedformviews });
		flush();
	}

	get nestedformviewlevels() {
		return this.$$.ctx[44];
	}

	set nestedformviewlevels(nestedformviewlevels) {
		this.$$set({ nestedformviewlevels });
		flush();
	}

	get currentnestinglevel() {
		return this.$$.ctx[45];
	}

	set currentnestinglevel(currentnestinglevel) {
		this.$$set({ currentnestinglevel });
		flush();
	}

	get handlerelationships() {
		return this.$$.ctx[22];
	}

	set handlerelationships(handlerelationships) {
		this.$$set({ handlerelationships });
		flush();
	}

	get handledocuments() {
		return this.$$.ctx[23];
	}

	set handledocuments(handledocuments) {
		this.$$set({ handledocuments });
		flush();
	}

	get saveevent() {
		return this.$$.ctx[46];
	}

	set saveevent(saveevent) {
		this.$$set({ saveevent });
		flush();
	}

	get cancelevent() {
		return this.$$.ctx[47];
	}

	set cancelevent(cancelevent) {
		this.$$set({ cancelevent });
		flush();
	}

	get additionalbuttonevent() {
		return this.$$.ctx[48];
	}

	set additionalbuttonevent(additionalbuttonevent) {
		this.$$set({ additionalbuttonevent });
		flush();
	}

	get cancelon() {
		return this.$$.ctx[49];
	}

	set cancelon(cancelon) {
		this.$$set({ cancelon });
		flush();
	}

	get closeonsave() {
		return this.$$.ctx[50];
	}

	set closeonsave(closeonsave) {
		this.$$set({ closeonsave });
		flush();
	}

	get isbackend() {
		return this.$$.ctx[51];
	}

	set isbackend(isbackend) {
		this.$$set({ isbackend });
		flush();
	}

	get title() {
		return this.$$.ctx[52];
	}

	set title(title) {
		this.$$set({ title });
		flush();
	}

	get show() {
		return this.$$.ctx[53];
	}

	set show(show) {
		this.$$set({ show });
		flush();
	}

	get init() {
		return this.$$.ctx[54];
	}

	set init(init) {
		this.$$set({ init });
		flush();
	}

	get closeonnavigate() {
		return this.$$.ctx[55];
	}

	set closeonnavigate(closeonnavigate) {
		this.$$set({ closeonnavigate });
		flush();
	}

	get formrules() {
		return this.$$.ctx[56];
	}

	set formrules(formrules) {
		this.$$set({ formrules });
		flush();
	}

	get hideadditionalgeotools() {
		return this.$$.ctx[57];
	}

	set hideadditionalgeotools(hideadditionalgeotools) {
		this.$$set({ hideadditionalgeotools });
		flush();
	}

	get bgwkt() {
		return this.$$.ctx[58];
	}

	set bgwkt(bgwkt) {
		this.$$set({ bgwkt });
		flush();
	}

	get bgwktstrokecolor() {
		return this.$$.ctx[59];
	}

	set bgwktstrokecolor(bgwktstrokecolor) {
		this.$$set({ bgwktstrokecolor });
		flush();
	}

	get bgwktfillcolor() {
		return this.$$.ctx[60];
	}

	set bgwktfillcolor(bgwktfillcolor) {
		this.$$set({ bgwktfillcolor });
		flush();
	}

	get bgwktcenteron() {
		return this.$$.ctx[61];
	}

	set bgwktcenteron(bgwktcenteron) {
		this.$$set({ bgwktcenteron });
		flush();
	}

	get bgwktviewguid() {
		return this.$$.ctx[62];
	}

	set bgwktviewguid(bgwktviewguid) {
		this.$$set({ bgwktviewguid });
		flush();
	}

	get bgwktentityguid() {
		return this.$$.ctx[63];
	}

	set bgwktentityguid(bgwktentityguid) {
		this.$$set({ bgwktentityguid });
		flush();
	}

	get wktboundary() {
		return this.$$.ctx[64];
	}

	set wktboundary(wktboundary) {
		this.$$set({ wktboundary });
		flush();
	}

	get shownotifications() {
		return this.$$.ctx[65];
	}

	set shownotifications(shownotifications) {
		this.$$set({ shownotifications });
		flush();
	}

	get textfieldlength() {
		return this.$$.ctx[10];
	}

	set textfieldlength(textfieldlength) {
		this.$$set({ textfieldlength });
		flush();
	}

	get validation() {
		return this.$$.ctx[24];
	}

	set validation(validation) {
		this.$$set({ validation });
		flush();
	}

	get signalField() {
		return this.$$.ctx[66];
	}

	set signalField(value) {
		throw new Error("<form-field>: Cannot set read-only property 'signalField'");
	}

	get validate() {
		return this.$$.ctx[67];
	}

	set validate(value) {
		throw new Error("<form-field>: Cannot set read-only property 'validate'");
	}

	get hasChanged() {
		return this.$$.ctx[68];
	}

	set hasChanged(value) {
		throw new Error("<form-field>: Cannot set read-only property 'hasChanged'");
	}

	get closeForm() {
		return this.$$.ctx[69];
	}

	set closeForm(value) {
		throw new Error("<form-field>: Cannot set read-only property 'closeForm'");
	}
}

customElements.define("form-field", FormField);
export default FormField;
import {debounce} from "./generalUtils.js";
export const initializeVisibileObserver = (cont, handler, isalreadyVisible) => {
  let isVisible2 = isalreadyVisible && isalreadyVisible === true;
  let firstTimeVisible = false;
  const options = {
    root: null
  };
  const observer = new IntersectionObserver((entries, observer2) => {
    entries.forEach((entry) => {
      if (entry.intersectionRatio > 0) {
        if (!isVisible2) {
          isVisible2 = true;
          if (!firstTimeVisible) {
            firstTimeVisible = true;
          } else {
            handler();
          }
        }
      } else {
        isVisible2 = false;
      }
    });
  }, options);
  observer.observe(cont);
  return () => {
    return isVisible2;
  };
};
export const becameVisibleHandler = (cont, handler) => {
  const options = {
    root: null
  };
  const observer = new IntersectionObserver((entries, observer2) => {
    entries.forEach((entry) => {
      if (entry.intersectionRatio > 0) {
        observer2.disconnect();
        handler();
      }
    });
  }, options);
  observer.observe(cont);
};
export const isInViewport = (element) => {
  if (element) {
    const rect = element.getBoundingClientRect();
    return rect.top >= 0 && rect.left >= 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && rect.right <= (window.innerWidth || document.documentElement.clientWidth);
  }
  return false;
};
export const isInsideElement = (evt, element) => {
  let x, y = 0;
  if (evt.type == "touchstart" || evt.type == "touchmove" || evt.type == "touchend" || evt.type == "touchcancel") {
    let touch = evt.touches[0] || evt.changedTouches[0];
    x = touch.pageX;
    y = touch.pageY;
  } else if (evt.type == "mousedown" || evt.type == "mouseup" || evt.type == "mousemove" || evt.type == "mouseover" || evt.type == "mouseout" || evt.type == "mouseenter" || evt.type == "mouseleave" || evt.type == "click") {
    x = evt.clientX;
    y = evt.clientY;
  }
  if (element) {
    let rectangle = element.getBoundingClientRect();
    if (x >= rectangle.x && x <= rectangle.x + rectangle.width && y >= rectangle.y && y <= rectangle.y + rectangle.height) {
      return true;
    }
  }
  return false;
};
export const isInsideElementByXY = (clientX, clientY, element) => {
  if (element) {
    let rectangle = element.getBoundingClientRect();
    if (clientX >= rectangle.x && clientX <= rectangle.x + rectangle.width && clientY >= rectangle.y && clientY <= rectangle.y + rectangle.height) {
      return true;
    }
  }
  return false;
};
export const isElementXPercentInViewport = function(el, percentVisible) {
  let rect = el.getBoundingClientRect(), windowHeight = window.innerHeight || document.documentElement.clientHeight;
  return !(Math.floor(100 - (rect.top >= 0 ? 0 : rect.top) / +-rect.height * 100) < percentVisible || Math.floor(100 - (rect.bottom - windowHeight) / rect.height * 100) < percentVisible);
};
export const isVisible = (domElement) => {
  return new Promise((resolve) => {
    const o = new IntersectionObserver(([entry]) => {
      resolve(entry.intersectionRatio > 0);
      o.disconnect();
    });
    o.observe(domElement);
  });
};
export const sizeObserver = (element, width, callback) => {
  const resizeObserver = new ResizeObserver(debounce((entries) => {
    for (let entry of entries) {
      callback(entry.contentRect.width);
    }
  }, 250));
  resizeObserver.observe(element);
};
export const createResizableBox = async (data) => {
  const {element, side, containsMap, borderVisible, otherElement, grapHandle} = data;
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      let dragging = false;
      let elementWidth = 0;
      let elementHeight = 0;
      let otherElementWidth = 0;
      let otherElementHeight = 0;
      let xStart = 0;
      let yStart = 0;
      let left = null;
      let handle;
      const resizeHorizontal = (e) => {
        e.preventDefault();
        e.stopPropagation();
        dragging = true;
        xStart = e.clientX;
        yStart = e.clientY;
        const styles = window.getComputedStyle(element);
        elementWidth = parseInt(styles.width, 10);
        elementHeight = parseInt(styles.height, 10);
        if (otherElement) {
          const stylesOtherElement = window.getComputedStyle(otherElement);
          otherElementWidth = parseInt(stylesOtherElement.width, 10);
          otherElementHeight = parseInt(stylesOtherElement.height, 10);
        }
        document.addEventListener("mousemove", mouseMoveHorizontal);
        document.addEventListener("mouseup", mouseup);
      };
      const enter = (e) => {
        if (borderVisible === false) {
          left.style.backgroundColor = "red";
        }
        ;
        e.target.style.cursor = "col-resize";
        e.target.style.backgroundColor = "red";
        e.target.style.width = "3px";
      };
      const leave = (e) => {
        if (borderVisible === false) {
          left.style.backgroundColor = "transparent";
        }
        ;
        e.target.style.cursor = "default";
        e.target.style.backgroundColor = "#c1c1c1";
        e.target.style.width = "1px";
      };
      const mouseMoveHorizontal = (e) => {
        e.preventDefault();
        e.stopPropagation();
        let dx = 0;
        if (side === "left") {
          dx = xStart - e.clientX;
        } else if (side === "right") {
          dx = e.clientX - xStart;
        }
        element.style.width = `${elementWidth + dx}px`;
        element.style.maxWidth = `${elementWidth + dx}px`;
        element.style.minWidth = `${elementWidth + dx}px`;
        element.style.setProperty("width", `${elementWidth + dx}px`, "important");
        element.style.setProperty("min-width", `${elementWidth + dx}px`, "important");
        element.style.setProperty("max-width", `${elementWidth + dx}px`, "important");
        if (otherElement) {
          otherElement.style.width = `${otherElementWidth - dx}px`;
          otherElement.style.maxWidth = `${otherElementWidth - dx}px`;
          otherElement.style.minWidth = `${otherElementWidth - dx}px`;
        }
      };
      const mouseMoveVertical = (e) => {
      };
      const mouseup = (e) => {
        document.removeEventListener("mousemove", mouseMoveHorizontal);
        document.removeEventListener("mousemove", mouseMoveVertical);
        if (containsMap) {
        }
      };
      if (side === "left") {
        left = document.createElement("div");
        left.style.position = "absolute";
        left.style.left = "0px";
        left.style.top = "0.5%";
        left.style.height = "99%";
        left.style.width = "1px";
        left.style.background = "#c1c1c1";
        left.classList.add("resize-side");
        if (borderVisible === false) {
          left.style.border = "none";
        }
        left.addEventListener("mousedown", resizeHorizontal);
        left.addEventListener("mouseenter", enter);
        left.addEventListener("mouseleave", leave);
        element.appendChild(left);
        if (grapHandle) {
          handle = document.createElement("div");
          handle.style.position = "absolute";
          handle.style.height = "5rem";
          handle.style.width = "1rem";
          handle.style.background = "whitesmoke";
          handle.style.border = "solid thin gray";
          handle.style.top = "calc(50% - 2.5rem)";
          handle.style.left = "calc(50% - 0.5rem)";
          handle.style.borderRadius = ".25rem";
          left.append(handle);
        }
      }
      if (side === "right") {
        const boundingRect = element.getBoundingClientRect();
        left = document.createElement("div");
        left.style.position = "absolute";
        left.style.right = "0px";
        left.style.top = "0.5%";
        left.style.height = "99%";
        left.style.width = "1px";
        left.style.background = "#c1c1c1";
        left.classList.add("resize-side");
        if (borderVisible === false) {
          left.style.border = "none";
        }
        left.addEventListener("mousedown", resizeHorizontal);
        left.addEventListener("mouseenter", enter);
        left.addEventListener("mouseleave", leave);
        element.appendChild(left);
        if (grapHandle) {
          handle = document.createElement("div");
          handle.style.position = "absolute";
          handle.style.height = "5rem";
          handle.style.width = "1rem";
          handle.style.background = "whitesmoke";
          handle.style.border = "solid thin gray";
          handle.style.top = "calc(50% - 2.5rem)";
          handle.style.left = "calc(50% - 0.5rem)";
          handle.style.borderRadius = ".25rem";
          left.append(handle);
        }
      }
      const cleanUpFunction = () => {
        left.remove();
      };
      resolve(cleanUpFunction);
    }, 100);
  });
};
export function resizeHandler(element, width, minCallback, maxCallback) {
  let state = element.getBoundingClientRect().width < width ? "small" : "big";
  const resizeObserver = new ResizeObserver((entries) => {
    for (let entry of entries) {
      if (entry.contentRect.width < width && state === "big") {
        minCallback();
        state = "small";
      }
      if (entry.contentRect.width >= width && state === "small") {
        maxCallback();
        state = "big";
      }
    }
  });
  if (state === "small") {
    minCallback();
  } else if (state === "big") {
    maxCallback();
  }
  resizeObserver.observe(element);
}

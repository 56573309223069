const bc = window["bc"];
export const getRelatedActionViewGuids = async (dataviewguid) => {
  if (bc.parameterService.get("ACTION_VIEW_FOR_VIEW_" + dataviewguid) === null) {
    bc.parameterService.set("ACTION_VIEW_FOR_VIEW_" + dataviewguid, await bc.metadataManager.fetchActionViewsForView(dataviewguid));
  }
  return bc.parameterService.get("ACTION_VIEW_FOR_VIEW_" + dataviewguid);
};
export const getRelatedActionViewsMetadata = async (guids) => {
  try {
    let metadataArray = [];
    for (let i = 0; i < guids.length; i++) {
      metadataArray[i] = await bc.parameterService.getMetadata(guids[i]);
    }
    return metadataArray;
  } catch (error) {
    console.error("Fehler beim abfragen den action-metadaten", guids, error);
    throw new Error("Fehler beim abfragen den action-metadaten");
  }
};
export const getActionViewIcons = (metadataArray) => {
  try {
    let actionIcons = [];
    for (let i = 0; i < metadataArray.length; i++) {
      if (metadataArray[i].resource.TY_ARTIFACT && metadataArray[i].resource.TY_ARTIFACT !== "") {
        actionIcons[i] = atob(metadataArray[i].resource.TY_ARTIFACT);
      } else {
        actionIcons[i] = "fa-list";
      }
    }
    return actionIcons;
  } catch (error) {
    console.error("Fehler beim parsen der Actionview-Icons", metadataArray, error);
    throw new Error("Fehler beim abfragen den action-metadaten");
  }
};

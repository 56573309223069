import {v4 as uuidv4} from "../../web_modules/uuid.js";
import {errorToast, handleErrorInAlert} from "./alertNotificationUtils.js";
import {isUserDefinedView} from "./dataviewUtils.js";
const transformSettingsArray = (settings) => {
  settings.forEach((setting) => {
    convertSettingValue(setting);
  });
  return settings;
};
const convertSettingValue = (setting) => {
  try {
    setting.UG_VALUE = atob(setting.UG_VALUE);
    if (setting.UG_NAME === "LAYER-COMPOSITIONS" || setting.UG_NAME === "ORDER_BY" || setting.UG_NAME === "KANBAN_COLUMN_DISPLAY" || setting.UG_NAME === "FILTER" || setting.UG_NAME === "FAVORITES" || setting.UG_NAME === "MY_ACTIONS_FILTER" || setting.UG_NAME === "MY_ACTIONS_ORDER_BY" || setting.UG_NAME === "DASHBOARD_FILTER" || setting.UG_NAME === "DASHBOARD_ORDER_BY" || setting.UG_NAME === "USER_DASHBOARD" || setting.UG_NAME === "USER_DASHBOARD_TABS" || setting.UG_NAME === "USER_DASHBOARD_ENTITIES" || setting.UG_NAME === "TABLE_COLUMNS" || setting.UG_NAME === "SNAP" || setting.UG_NAME === "USER_ACTIONS") {
      if (setting.UG_NAME !== null) {
        setting.UG_VALUE = JSON.parse(setting.UG_VALUE);
      } else {
        setting.UG_VALUE = [];
      }
    } else {
      if (setting.UG_VALUE === null) {
        setting.UG_VALUE = "";
      }
    }
    return setting;
  } catch (error) {
    console.error(error);
    console.error(setting.UG_VALUE);
  }
};
export const getUserSettingsManager = (userId) => {
  let settings = [];
  let loaded = false;
  let loading = false;
  const getUserSettingAfterLoading = async (settingName, defaultValue, dataviewGuid, item) => {
    if (loaded === true) {
      if (!dataviewGuid) {
        let setting = settings.find((s) => s.UG_NAME === settingName);
        if (setting) {
          return setting;
        } else {
          let id = uuidv4();
          let newSetting = {
            UG_ID: id,
            UG_NAME: settingName,
            UG_ITEM: null,
            UG_VALUE: defaultValue,
            UG_CREATED: null,
            UG_MODIFIED: null,
            UG_TYPE: null,
            UserId: userId
          };
          let settingsManager = userId === "8ed2aee1-f709-4bdc-a268-f708e141d295" ? allUserSettingManager : userSettingManager;
          await settingsManager.setSetting(newSetting, defaultValue);
          newSetting = settings.find((s) => s.UG_ID === id);
          return newSetting;
        }
      } else {
        if (!item) {
          let setting = settings.find((s) => s.UG_NAME === settingName && s.UG_TYPE === dataviewGuid);
          if (setting) {
            return setting;
          } else {
            let id = uuidv4();
            let newSetting = {
              UG_ID: id,
              UG_NAME: settingName,
              UG_ITEM: null,
              UG_VALUE: defaultValue,
              UG_CREATED: null,
              UG_MODIFIED: null,
              UG_TYPE: dataviewGuid,
              UserId: userId
            };
            let settingsManager = userId === "8ed2aee1-f709-4bdc-a268-f708e141d295" ? allUserSettingManager : userSettingManager;
            await settingsManager.setSetting(newSetting, defaultValue);
            newSetting = settings.find((s) => s.UG_ID === id);
            return newSetting;
          }
        } else {
          let setting = settings.find((s) => s.UG_NAME === settingName && s.UG_TYPE === dataviewGuid && s.UG_ITEM === item);
          if (setting) {
            return setting;
          } else {
            let id = uuidv4();
            let newSetting = {
              UG_ID: id,
              UG_NAME: settingName,
              UG_ITEM: item,
              UG_VALUE: defaultValue,
              UG_CREATED: null,
              UG_MODIFIED: null,
              UG_TYPE: dataviewGuid,
              UserId: userId
            };
            let settingsManager = userId === "8ed2aee1-f709-4bdc-a268-f708e141d295" ? allUserSettingManager : userSettingManager;
            await settingsManager.setSetting(newSetting, defaultValue);
            newSetting = settings.find((s) => s.UG_ID === id);
            return newSetting;
          }
        }
      }
    } else {
      return null;
    }
  };
  return {
    userId,
    settings,
    loaded,
    getAllSettings: async () => {
      if (bc.parameterService.appParameters.userId && loaded === false) {
        loading = true;
        let response = await bc.metadataManager.getUserSettings(userId);
        if (response && response["value"]) {
          settings = transformSettingsArray(response["value"]);
        }
        loaded = true;
        loading = false;
      }
    },
    getUserSetting: async (settingName, defaultValue, dataviewGuid, item) => {
      if (!loaded) {
        if (!loading) {
          if (bc.parameterService.appParameters.userId) {
            loading = true;
            let response = await bc.metadataManager.getUserSettings(userId);
            if (response && response["value"]) {
              settings = transformSettingsArray(response["value"]);
            } else {
              settings = [];
            }
            loaded = true;
            loading = false;
          }
        } else {
          const timer = (ms) => new Promise((res) => setTimeout(res, ms));
          while (loading === true) {
            await timer(50);
          }
          return getUserSettingAfterLoading(settingName, defaultValue, dataviewGuid, item);
        }
      }
      return getUserSettingAfterLoading(settingName, defaultValue, dataviewGuid, item);
    },
    getUserSettingsByDataviewguid: (dataviewGuid) => {
      let dataviewSettings = settings.filter((s) => s.UG_TYPE === dataviewGuid);
      if (dataviewSettings) {
        return dataviewSettings;
      }
      return null;
    },
    setSetting: async (setting, value) => {
      let settingCopy = {...setting};
      settingCopy.UG_VALUE = value;
      if (settingCopy.UG_VALUE) {
        if (typeof settingCopy.UG_VALUE === "object") {
          settingCopy.UG_VALUE = JSON.stringify(settingCopy.UG_VALUE);
        }
        settingCopy.UG_VALUE = btoa(settingCopy.UG_VALUE);
        const response = await bc.metadataManager.setUserSetting(settingCopy);
        if (response) {
          delete response["odata.metadata"];
          const newSetting = convertSettingValue(response);
          Object.keys(setting).forEach(function(key) {
            delete setting[key];
          });
          Object.keys(newSetting).forEach(function(key) {
            setting[key] = newSetting[key];
          });
          let existed = settings.find((s) => s.UG_ID === setting.UG_ID);
          if (!existed) {
            settings = [...settings, setting];
          }
          return setting;
        } else {
          errorToast("Fehler beim speichern des Usereinstellung");
          return null;
        }
      }
      return null;
    },
    removeSetting: async (setting) => {
      try {
        const result = await bc.metadataManager.deleteUserSetting(setting.UG_ID);
        if (result) {
          settings = [...settings.filter((s) => s.UG_ID !== setting.UG_ID)];
          return true;
        }
        return false;
      } catch (error) {
        handleErrorInAlert(error);
      }
    },
    removeAllSettings: async () => {
      try {
        for (let i = 0; i < settings.length; i++) {
          let setting = settings[i];
          const result = await bc.metadataManager.deleteUserSetting(setting.UG_ID);
          if (result) {
            settings = [...settings.filter((s) => s.UG_ID !== setting.UG_ID)];
          }
        }
        return true;
      } catch (error) {
        handleErrorInAlert(error);
      }
    }
  };
};
export const userSettingManager = getUserSettingsManager(bc.parameterService.appParameters.userId);
export const allUserSettingManager = getUserSettingsManager("8ed2aee1-f709-4bdc-a268-f708e141d295");
export const handleDataviewSearchSettings = async (options) => {
  let {
    searchguid,
    dataviewguid,
    contentType,
    sourceviewguid,
    relationshipAssociation
  } = options;
  let searchParameterString = "";
  let filterUserSettings;
  let orderUserSettings;
  let dataviewSearch;
  if (searchguid) {
    searchParameterString = searchguid;
    if (bc.parameterService.get(searchParameterString, true)) {
      dataviewSearch = bc.parameterService.get(searchParameterString, true);
    } else {
      dataviewSearch = {
        orderBys: [],
        filters: []
      };
      bc.parameterService.set(searchParameterString, dataviewSearch, true);
    }
  } else {
    let metadata = await bc.parameterService.getMetadata(dataviewguid);
    if (isUserDefinedView(metadata.resourceName)) {
      searchParameterString = "DATAVIEW_SEARCH_" + dataviewguid;
      if (contentType === "Relation") {
        searchParameterString = "RELATION_DATAVIEW_SEARCH_" + dataviewguid;
        if (sourceviewguid) {
          searchParameterString = searchParameterString + "_" + sourceviewguid;
        }
        if (relationshipAssociation) {
          searchParameterString = searchParameterString + "_" + relationshipAssociation;
        }
      }
      if (bc.parameterService.appParameters.userId) {
        filterUserSettings = await userSettingManager.getUserSetting("FILTER", [{name: "default", filter: []}], dataviewguid, "");
        orderUserSettings = await userSettingManager.getUserSetting("ORDER_BY", [{name: "default", filter: []}], dataviewguid, "");
      }
      dataviewSearch = getDataviewSearch(searchParameterString, filterUserSettings, orderUserSettings);
    } else {
      throw Error("Search should have a search guid specified!");
    }
  }
  return {
    dataviewSearch,
    filterUserSettings,
    orderUserSettings,
    searchParameterString
  };
};
export const getDataviewSearch = (searchParameterString, filterUserSettings, orderUserSettings) => {
  let dataviewSearch;
  if (!bc.parameterService.get(searchParameterString)) {
    if (bc.parameterService.appParameters.userId) {
      dataviewSearch = {
        filters: filterUserSettings.UG_VALUE.find((f) => f.name === "default").filter,
        orderBys: orderUserSettings.UG_VALUE.find((f) => f.name === "default").filter
      };
    } else {
      dataviewSearch = {
        orderBys: [],
        filters: []
      };
    }
    bc.parameterService.set(searchParameterString, dataviewSearch, true);
  } else {
    dataviewSearch = bc.parameterService.get(searchParameterString, true);
  }
  return dataviewSearch;
};

/* src\components\Map\MapTools\MapAdvancedSearchDraw\MapAdvancedSearchDraw.svelte generated by Svelte v3.57.0 */
import {
	SvelteElement,
	attribute_to_object,
	dispatch_dev,
	flush,
	init,
	insert_dev,
	noop,
	safe_not_equal,
	validate_slots
} from "../../../../../web_modules/svelte/internal.js";

import bc from '../../../../bcShim.js';

import {
	createRadiusDiv,
	createVectorLayer,
	getMaxZIndex,
	roundOff
} from '../../map-utils.js';

import Draw, { createBox } from '../../../../../web_modules/ol/interaction/Draw.js';
import { Fill, Stroke, Style } from '../../../../../web_modules/ol/style.js';
import CircleStyle from '../../../../../web_modules/ol/style/Circle.js';
import { fromCircle } from '../../../../../web_modules/ol/geom/Polygon.js';
import Feature from '../../../../../web_modules/ol/Feature.js';
import { v4 as uuidv4 } from "../../../../../web_modules/uuid.js";
import { wktHelper } from "../../../../utilities/WKTHelper.js";
import { get_current_component } from "../../../../../web_modules/svelte/internal.js";
import { onMount, tick } from "../../../../../web_modules/svelte.js";
import { GeometryType } from "../../../../interfaces.js";
import { dispatchMountedEvent } from "../../../../utilities/componentUtils.js";
import { getComponentText } from "../../../../utilities/generalUtils.js";
const file = "src\\components\\Map\\MapTools\\MapAdvancedSearchDraw\\MapAdvancedSearchDraw.svelte";

function create_fragment(ctx) {
	const block = {
		c: function create() {
			this.c = noop;
		},
		l: function claim(nodes) {
			throw new Error("options.hydrate only works if the component was compiled with the `hydratable: true` option");
		},
		m: noop,
		p: noop,
		i: noop,
		o: noop,
		d: noop
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_fragment.name,
		type: "component",
		source: "",
		ctx
	});

	return block;
}

function instance($$self, $$props, $$invalidate) {
	let { $$slots: slots = {}, $$scope } = $$props;
	validate_slots('map-tool-advanced-search-draw', slots, []);
	const generalText = getComponentText('general');
	const component = get_current_component();
	let mounted = false;
	let ready = false;

	// Component variables
	//================================================================
	let vectorLayer;

	let vectorSource;
	let drawingSearchCircle = false;
	let drawInteraction;
	let radiusLabel = '';
	let searchGeometryFeature;
	let wktString = '';
	let radiusDiv;
	let map;
	let componentId = uuidv4();

	// HTML Mount & Component initialization
	//================================================================
	onMount(() => {
		tick().then(() => {
			dispatchMountedEvent(component);
			mounted = true;
		});

		return () => {
			vectorLayer = null;
			vectorSource = null;
		};
	});

	const initComponent = newMap => {
		map = newMap;
		vectorLayer = createVectorLayer('ADVANCED_SEARCH_DRAW' + componentId);
		vectorSource = vectorLayer.getSource();
		let zIndex = getMaxZIndex(map);
		vectorLayer.setZIndex(zIndex + 100);
		map.addLayer(vectorLayer);
		ready = true;
	};

	const addWKTGeometry = wkt => {
		const feature = wktHelper.readFeature(wkt);
		feature.setStyle(getStyle());
		vectorSource.addFeature(feature);
	};

	const removeWKTGeometry = () => {
		if (vectorSource) {
			vectorSource.clear();
		}
	};

	const drawSearchGeometry = data => {
		if (drawInteraction) {
			map.removeInteraction(drawInteraction);
		}

		if (vectorSource) {
			vectorSource.clear();
		}

		if (data.type === 'square') {
			if (radiusDiv && map.getTargetElement().contains(radiusDiv)) {
				map.getTargetElement().removeChild(radiusDiv);
			}

			radiusDiv = undefined;
			drawingSearchCircle = false;

			drawInteraction = new Draw({
					type: GeometryType.CIRCLE,
					source: vectorSource,
					geometryFunction: createBox(),
					style: getStyle()
				});
		} else if (data.type === 'circle') {
			// do not create, if already existing
			if (!radiusDiv) {
				radiusDiv = createRadiusDiv();
			}

			drawingSearchCircle = true;

			drawInteraction = new Draw({
					type: GeometryType.CIRCLE,
					source: vectorSource,
					style: getStyle()
				});
		} else if (data.type === 'polygon') {
			if (radiusDiv && map.getTargetElement().contains(radiusDiv)) {
				map.getTargetElement().removeChild(radiusDiv);
			}

			radiusDiv = undefined;
			drawingSearchCircle = false;

			drawInteraction = new Draw({
					type: GeometryType.POLYGON,
					source: vectorSource,
					style: getStyle()
				});
		}

		const mouseMoveEvent = event => {
			let evt = bc.parameterService.get('FEATURE');
			radiusLabel = '' + roundOff(evt.feature.getGeometry().getRadius(), 2);
			radiusDiv.innerHTML = 'Radius ' + radiusLabel + 'm';
		};

		// Reset/Start draw Inter
		drawInteraction.on('drawstart', function (evt) {
			if (radiusDiv) {
				map.getTargetElement().appendChild(radiusDiv);
			}

			vectorSource.clear();
			radiusLabel = '';

			if (data.type === 'circle') {
				bc.parameterService.set('FEATURE', evt);
				window.addEventListener('mousemove', mouseMoveEvent);
			}
		});

		drawInteraction.on('drawend', async evt => {
			let geometry = null;

			if (data.type === 'square' || data.type === 'polygon') {
				geometry = evt.feature.getGeometry();
			} else {
				window.removeEventListener('mousemove', mouseMoveEvent);
				const circle = evt.feature.getGeometry();
				geometry = fromCircle(circle, 32, 0);
				const feature = new Feature({ geometry });
				vectorSource.addFeature(feature);
			}

			searchGeometryFeature = new Feature({ geometry });
			let wkt = wktHelper.writeFeature(searchGeometryFeature);
			wktString = wkt.replace(/ /g, '+');

			let event = new Event('searchwkt',
			{
					cancelable: true,
					bubbles: false,
					composed: false
				});

			event.detail = { wkt, type: data.type };
			component.dispatchEvent(event);
		});

		map.addInteraction(drawInteraction);
	};

	const getStyle = () => {
		return new Style({
				stroke: new Stroke({ color: 'blue', width: 2, lineDash: null }),
				fill: new Fill({ color: '#1976d217' }),
				image: new CircleStyle({
						radius: 3,
						fill: new Fill({ color: '#ff9346' })
					})
			});
	};

	const writable_props = [];

	

	$$self.$capture_state = () => ({
		bc,
		createRadiusDiv,
		createVectorLayer,
		getMaxZIndex,
		roundOff,
		Draw,
		createBox,
		Fill,
		Stroke,
		Style,
		CircleStyle,
		fromCircle,
		Feature,
		uuidv4,
		wktHelper,
		get_current_component,
		onMount,
		tick,
		GeometryType,
		dispatchMountedEvent,
		getComponentText,
		generalText,
		component,
		mounted,
		ready,
		vectorLayer,
		vectorSource,
		drawingSearchCircle,
		drawInteraction,
		radiusLabel,
		searchGeometryFeature,
		wktString,
		radiusDiv,
		map,
		componentId,
		initComponent,
		addWKTGeometry,
		removeWKTGeometry,
		drawSearchGeometry,
		getStyle
	});

	$$self.$inject_state = $$props => {
		if ('mounted' in $$props) mounted = $$props.mounted;
		if ('ready' in $$props) ready = $$props.ready;
		if ('vectorLayer' in $$props) vectorLayer = $$props.vectorLayer;
		if ('vectorSource' in $$props) vectorSource = $$props.vectorSource;
		if ('drawingSearchCircle' in $$props) drawingSearchCircle = $$props.drawingSearchCircle;
		if ('drawInteraction' in $$props) drawInteraction = $$props.drawInteraction;
		if ('radiusLabel' in $$props) radiusLabel = $$props.radiusLabel;
		if ('searchGeometryFeature' in $$props) searchGeometryFeature = $$props.searchGeometryFeature;
		if ('wktString' in $$props) wktString = $$props.wktString;
		if ('radiusDiv' in $$props) radiusDiv = $$props.radiusDiv;
		if ('map' in $$props) map = $$props.map;
		if ('componentId' in $$props) componentId = $$props.componentId;
	};

	if ($$props && "$$inject" in $$props) {
		$$self.$inject_state($$props.$$inject);
	}

	return [initComponent, addWKTGeometry, drawSearchGeometry];
}

class MapAdvancedSearchDraw extends SvelteElement {
	constructor(options) {
		super();

		init(
			this,
			{
				target: this.shadowRoot,
				props: attribute_to_object(this.attributes),
				customElement: true
			},
			instance,
			create_fragment,
			safe_not_equal,
			{
				initComponent: 0,
				addWKTGeometry: 1,
				drawSearchGeometry: 2
			},
			null
		);

		if (options) {
			if (options.target) {
				insert_dev(options.target, this, options.anchor);
			}

			if (options.props) {
				this.$set(options.props);
				flush();
			}
		}
	}

	static get observedAttributes() {
		return ["initComponent", "addWKTGeometry", "drawSearchGeometry"];
	}

	get initComponent() {
		return this.$$.ctx[0];
	}

	set initComponent(value) {
		throw new Error("<map-tool-advanced-search-draw>: Cannot set read-only property 'initComponent'");
	}

	get addWKTGeometry() {
		return this.$$.ctx[1];
	}

	set addWKTGeometry(value) {
		throw new Error("<map-tool-advanced-search-draw>: Cannot set read-only property 'addWKTGeometry'");
	}

	get drawSearchGeometry() {
		return this.$$.ctx[2];
	}

	set drawSearchGeometry(value) {
		throw new Error("<map-tool-advanced-search-draw>: Cannot set read-only property 'drawSearchGeometry'");
	}
}

customElements.define("map-tool-advanced-search-draw", MapAdvancedSearchDraw);
export default MapAdvancedSearchDraw;
export const GeometryType = {
  POINT: "Point",
  LINE_STRING: "LineString",
  LINEAR_RING: "LinearRing",
  POLYGON: "Polygon",
  MULTI_POINT: "MultiPoint",
  MULTI_LINE_STRING: "MultiLineString",
  MULTI_POLYGON: "MultiPolygon",
  GEOMETRY_COLLECTION: "GeometryCollection",
  CIRCLE: "Circle"
};
let requiredStepFields = ["SSC_SUBSTANCE", "SSC_OUT"];
let validFields = [
  {
    field: "SSC_EY_PRIVATE",
    values: ["9d5d1de9-a175-420f-88a1-c3695e6213e7", "1065e607-9ba1-4d18-862c-e0c9a2f44e3f"],
    message: "Not in list of provided values"
  },
  {
    field: "SSC_EY_PRIVATE",
    values: [false],
    message: "Ist not in value!"
  }
];

import Draw from "../../../../web_modules/ol/interaction/Draw.js";
import {Fill, Stroke, Style} from "../../../../web_modules/ol/style.js";
import {Circle as CircleStyle} from "../../../../web_modules/ol/style.js";
import Feature from "../../../../web_modules/ol/Feature.js";
import Overlay from "../../../../web_modules/ol/Overlay.js";
import LineString from "../../../../web_modules/ol/geom/LineString.js";
import {getArea, getLength} from "../../../../web_modules/ol/sphere.js";
import Polygon from "../../../../web_modules/ol/geom/Polygon.js";
import bc from "../../../bcShim.js";
import {GeometryType} from "../../../interfaces.js";
let drawInfoTooltipElement;
let mesureTooltipOverlay;
let drawInfoTooltipOverlay;
let lengthOrSurface = void 0;
export const formatLength = function(line) {
  let map = bc.parameterService.get("MAP_COMPONENT");
  const length = getLength(line, {projection: map.getView().getProjection()});
  let output;
  if (length > 1e3) {
    output = Math.round(length / 1e3 * 100) / 100 + " km";
  } else {
    output = Math.round(length * 100) / 100 + " m";
  }
  return output;
};
export const formatArea = function(polygon) {
  let map = bc.parameterService.get("MAP_COMPONENT");
  const area = getArea(polygon, {projection: map.getView().getProjection()});
  let output;
  if (area > 1e5) {
    output = Math.round(area / 1e6 * 100) / 100 + " km<sup>2</sup>";
  } else {
    output = Math.round(area * 100) / 100 + " m<sup>2</sup>";
  }
  return output;
};
export const createDrawPolygonInteraction = (vectorSource, currentDrawnFeature, map) => {
  const drawPolygonInteraction = new Draw({
    source: vectorSource,
    type: GeometryType.POLYGON,
    style: new Style({
      fill: new Fill({
        color: "#00a7f787"
      }),
      stroke: new Stroke({
        color: "#0039dc",
        width: 2
      }),
      image: new CircleStyle({
        radius: 3,
        fill: new Fill({
          color: "#ff9346"
        })
      })
    })
  });
  drawPolygonInteraction.on("drawstart", function(evt) {
    cleanUpMeasureFeatures(vectorSource, map);
    currentDrawnFeature = evt.feature;
    currentDrawnFeature.on("change", function(evt2) {
      const polygon = currentDrawnFeature.getGeometry();
      let output = formatArea(polygon);
      let tooltipCoord = polygon.getInteriorPoint().getCoordinates();
      drawInfoTooltipElement.innerHTML = output;
      drawInfoTooltipOverlay.setPosition(tooltipCoord);
    });
  });
  drawPolygonInteraction.on("drawend", function(evt) {
    const geometry = evt.feature.getGeometry();
    const coordinates = geometry.getCoordinates();
    vectorSource.addFeature(new Feature({
      geometry: new Polygon(coordinates),
      name: "line"
    }));
    const measure = document.createElement("div");
    measure.classList.add("ol-tooltip");
    measure.classList.add("ol-tooltip-measure");
    measure.innerHTML = formatArea(geometry);
    lengthOrSurface = formatArea(geometry);
    mesureTooltipOverlay = new Overlay({
      element: measure,
      offset: [0, -15],
      position: geometry.getInteriorPoint().getCoordinates(),
      positioning: "bottom-center"
    });
    map.addOverlay(mesureTooltipOverlay);
  });
  return drawPolygonInteraction;
};
export const createDrawLineInteraction = (vectorSource, currentDrawnFeature, map) => {
  const drawLineInteraction = new Draw({
    source: vectorSource,
    type: GeometryType.LINE_STRING,
    style: new Style({
      stroke: new Stroke({
        color: "#0039dc",
        lineDash: [10, 10],
        width: 2
      }),
      image: new CircleStyle({
        radius: 3,
        fill: new Fill({
          color: "#ff9346"
        })
      })
    })
  });
  drawLineInteraction.on("drawstart", function(evt) {
    cleanUpMeasureFeatures(vectorSource, map);
    currentDrawnFeature = evt.feature;
    currentDrawnFeature.on("change", function(evt2) {
      const lineString = currentDrawnFeature.getGeometry();
      let output = formatLength(lineString);
      let tooltipCoord = lineString.getLastCoordinate();
      drawInfoTooltipElement.innerHTML = output;
      drawInfoTooltipOverlay.setPosition(tooltipCoord);
    });
  });
  drawLineInteraction.on("drawend", function(evt) {
    const geometry = evt.feature.getGeometry();
    const coordinates = geometry.getCoordinates();
    vectorSource.addFeature(new Feature({
      geometry: new LineString(coordinates),
      name: "line"
    }));
    const measure = document.createElement("div");
    measure.classList.add("ol-tooltip");
    measure.classList.add("ol-tooltip-measure");
    measure.innerHTML = formatLength(geometry);
    lengthOrSurface = formatLength(geometry);
    mesureTooltipOverlay = new Overlay({
      element: measure,
      offset: [0, -15],
      position: coordinates[coordinates.length - 1],
      positioning: "bottom-center"
    });
    map.addOverlay(mesureTooltipOverlay);
  });
  return drawLineInteraction;
};
export const cleanUpMeasureFeatures = (vectorSource, map) => {
  vectorSource.clear();
  if (mesureTooltipOverlay) {
    map.removeOverlay(mesureTooltipOverlay);
    mesureTooltipOverlay = void 0;
  }
  createMeasureTooltip(map);
};
export const createMeasureTooltip = (map) => {
  if (drawInfoTooltipElement && drawInfoTooltipElement.parentNode) {
    drawInfoTooltipElement.parentNode.removeChild(drawInfoTooltipElement);
  }
  drawInfoTooltipElement = document.createElement("div");
  drawInfoTooltipElement.classList.add("ol-tooltip", "ol-tooltip-measure");
  drawInfoTooltipOverlay = new Overlay({
    element: drawInfoTooltipElement,
    offset: [0, -15],
    positioning: "bottom-center"
  });
  map.addOverlay(drawInfoTooltipOverlay);
};

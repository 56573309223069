export const getDateInRawFormat = (metadata, fieldInfo, value) => {
  let locale = metadata.resource.TY_CULTURE;
  let separator;
  if (locale === "46ba9cdc-f416-421b-a38f-75715f6a1554") {
    separator = ".";
  } else {
    separator = "/";
  }
  if (fieldInfo.viewLength === 0 && fieldInfo.viewPrecision === 0 || fieldInfo.viewLength === void 0 && fieldInfo.viewPrecision === void 0) {
    let dateString = "";
    let workWeek;
    const tSplit = value.split("T");
    const fullDateArray = tSplit[0].split("-");
    const fullTimeArray = tSplit[1].split(":");
    let hours = fullTimeArray[0];
    let minutes = fullTimeArray[1];
    let date = moment(`${fullDateArray[0]}-${fullDateArray[1]}-${fullDateArray[2]}`);
    workWeek = moment(date).isoWeek();
    if (+workWeek < 10) {
      workWeek = "0" + workWeek;
    }
    workWeek = "KW" + workWeek;
    dateString = `${fullDateArray[2]}${separator}${fullDateArray[1]}${separator}${fullDateArray[0]} ${hours}:${minutes}`;
    return `${workWeek}  ${dateString}`;
  } else if (fieldInfo.viewLength === 0 && fieldInfo.viewPrecision === 8) {
    const tSplit = value.split("T");
    const fullTimeArray = tSplit[1].split(":");
    let hours = fullTimeArray[0];
    let minutes = fullTimeArray[1];
    return `${hours}:${minutes}`;
  } else if (fieldInfo.viewLength === 10 && fieldInfo.viewPrecision === 0) {
    let dateString = "";
    let workWeek;
    const tSplit = value.split("T");
    const fullDateArray = tSplit[0].split("-");
    let date = moment(`${fullDateArray[0]}-${fullDateArray[1]}-${fullDateArray[2]}`);
    workWeek = moment(date).isoWeek();
    if (+workWeek < 10) {
      workWeek = "0" + workWeek;
    }
    workWeek = "KW" + workWeek;
    dateString = `${fullDateArray[2]}${separator}${fullDateArray[1]}${separator}${fullDateArray[0]}`;
    return `${workWeek}  ${dateString}`;
  }
};
export const getDateTimeStringFromISOString = (dateString, addTime) => {
  if (dateString && dateString !== "") {
    let tempString = dateString.substring(0, 10).split("-");
    let timeString = dateString.split("T")[1].substring(0, 8);
    let clientCulture = bc.parameterService.appParameters.clientCulture;
    if (clientCulture === "de" || clientCulture === "de-CH" || clientCulture === "fr-CH" || clientCulture === "it-CH" || clientCulture === "rm-CH" || clientCulture === "de-LI" || clientCulture === "de-AT" || clientCulture === "de-DE" || clientCulture === "en-CH") {
      if (addTime) {
        return `${tempString[2]}.${tempString[1]}.${tempString[0]}  ${timeString}`;
      } else {
        return `${tempString[2]}.${tempString[1]}.${tempString[0]}`;
      }
    } else if (clientCulture === "fr" || clientCulture === "fr-FR") {
      if (addTime) {
        return `${tempString[2]}/${tempString[1]}/${tempString[0]}  ${timeString}`;
      } else {
        return `${tempString[2]}/${tempString[1]}/${tempString[0]}`;
      }
    } else if (clientCulture === "en" || clientCulture === "en-GB" || clientCulture === "en-US") {
      if (addTime) {
        return `${tempString[2]}/${tempString[1]}/${tempString[0]}  ${timeString}`;
      } else {
        return `${tempString[2]}/${tempString[1]}/${tempString[0]}`;
      }
    } else {
      if (addTime) {
        return `${tempString[2]}/${tempString[1]}/${tempString[0]}  ${timeString}`;
      } else {
        return `${tempString[2]}/${tempString[1]}/${tempString[0]}`;
      }
    }
  } else {
    return "";
  }
};
export const getTimeStringFromISOString = (dateString) => {
  if (dateString && dateString !== "") {
    let tempString = dateString.substring(0, 10).split("-");
    let timeString = dateString.split("T")[1].substring(0, 8);
    return timeString;
  }
};
export const parseISOString = (s) => {
  let b = s.split(/\D+/);
  let c = b.map((e) => +e);
  return new Date(Date.UTC(c[0], --c[1], c[2], c[3], c[4], c[5]));
};
export const getHumanReadableDateFromDate = (date) => {
  let annee = date.getFullYear();
  let month = date.getMonth();
  let day = date.getDate();
  let hour = date.getHours();
  let minutes = date.getMinutes();
  let seconds = date.getSeconds();
  month = month + 1;
  if (month < 10) {
    month = "0" + month;
  }
  if (day < 10) {
    day = "0" + day;
  }
  if (hour < 10) {
    hour = "0" + hour;
  }
  if (seconds < 10) {
    seconds = "0" + seconds;
  }
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  let returnDate = `${annee}-${month}.${day}T${hour}:${minutes}:${seconds}`;
  return getDateTimeStringFromISOString(returnDate, true);
};
export const getDateFieldFormat = (field) => {
  if (field.viewLength === 10 && field.viewPrecision === 0) {
    return "date";
  }
  if (field.viewLength === 0 && field.viewPrecision === 8) {
    return "time";
  }
  return "datetime";
};
export const parseStringDateToISO = (value) => {
  if (value && value !== "") {
    let day = "";
    let month = "";
    let year = "";
    let hours = "00";
    let minutes = "00";
    let seconds = "00";
    if (value.length === 19) {
      day = value.substring(0, 2);
      month = value.substring(3, 5);
      year = value.substring(6, 10);
      hours = value.substring(11, 13);
      minutes = value.substring(14, 16);
      seconds = value.substring(17, 19);
    }
    if (value.length === 10) {
      day = value.substring(0, 2);
      month = value.substring(3, 5);
      year = value.substring(6, 10);
    }
    const date = new Date(year + "-" + month + "-" + day);
    date.setHours(+hours);
    date.setMinutes(+minutes);
    date.setSeconds(+seconds);
    const isoDate = new Date(date.getTime() - date.getTimezoneOffset() * 6e4).toISOString();
    console.log("isoDate", isoDate);
    return isoDate;
  } else {
    return null;
  }
};
export const parseStringDateToDate = (value) => {
  if (value && value !== "") {
    let day = "";
    let month = "";
    let year = "";
    let hours = "00";
    let minutes = "00";
    let seconds = "00";
    if (value.length === 19) {
      day = value.substring(0, 2);
      month = value.substring(3, 5);
      year = value.substring(6, 10);
      hours = value.substring(11, 13);
      minutes = value.substring(14, 16);
      seconds = value.substring(17, 19);
    }
    if (value.length === 10) {
      day = value.substring(0, 2);
      month = value.substring(3, 5);
      year = value.substring(6, 10);
    }
    const date = new Date(year + "-" + month + "-" + day);
    date.setHours(+hours);
    date.setMinutes(+minutes);
    date.setSeconds(+seconds);
    console.log("parsed", new Date(date.getTime() - date.getTimezoneOffset() * 6e4));
    return new Date(date.getTime() - date.getTimezoneOffset() * 6e4);
  } else {
    return null;
  }
};

/* src\components\Map\MapTools\MapBackgroundSwitcher\MapBackgroundSwitcher.svelte generated by Svelte v3.57.0 */
import {
	SvelteElement,
	add_location,
	append_dev,
	attr_dev,
	attribute_to_object,
	detach_dev,
	dispatch_dev,
	element,
	flush,
	globals,
	init,
	insert_dev,
	listen_dev,
	noop,
	safe_not_equal,
	set_data_dev,
	space,
	text,
	toggle_class,
	validate_slots
} from "../../../../../web_modules/svelte/internal.js";

const { console: console_1 } = globals;
import bc from '../../../../bcShim.js';
import { v4 as uuidv4 } from "../../../../../web_modules/uuid.js";
import { onMount, tick } from "../../../../../web_modules/svelte.js";
import { get_current_component } from "../../../../../web_modules/svelte/internal.js";

import {
	attachNormalize,
	dispatchMountedEvent
} from "../../../../utilities/componentUtils.js";

import { getComponentText } from "../../../../utilities/generalUtils.js";
const file = "src\\components\\Map\\MapTools\\MapBackgroundSwitcher\\MapBackgroundSwitcher.svelte";

function create_fragment(ctx) {
	let div1;
	let div0;
	let i;
	let t0;
	let div3;
	let div2;
	let t1;
	let mounted;
	let dispose;

	const block = {
		c: function create() {
			div1 = element("div");
			div0 = element("div");
			i = element("i");
			t0 = space();
			div3 = element("div");
			div2 = element("div");
			t1 = text(/*layerName*/ ctx[0]);
			this.c = noop;
			attr_dev(i, "class", "fas fa-image-landscape text-white h-10 w-10 flex items-center justify-center background rounded border-solid border-white border bg-gray-600");
			add_location(i, file, 1783, 8, 32678);
			attr_dev(div0, "class", "relative");
			add_location(div0, file, 1782, 4, 32646);
			attr_dev(div1, "class", "absolute z-49 left-4 bottom-4");
			toggle_class(div1, "hidden", !/*ready*/ ctx[1]);
			add_location(div1, file, 1780, 0, 32573);
			attr_dev(div2, "class", "relative rounded border-solid border-white border bg-gray-600 flex items-center justify-center text-white p-2");
			add_location(div2, file, 1794, 4, 33122);
			attr_dev(div3, "class", "absolute z-49 left-16 bottom-4");
			toggle_class(div3, "hidden", /*layerName*/ ctx[0] === null);
			add_location(div3, file, 1792, 0, 33036);
		},
		l: function claim(nodes) {
			throw new Error("options.hydrate only works if the component was compiled with the `hydratable: true` option");
		},
		m: function mount(target, anchor) {
			insert_dev(target, div1, anchor);
			append_dev(div1, div0);
			append_dev(div0, i);
			insert_dev(target, t0, anchor);
			insert_dev(target, div3, anchor);
			append_dev(div3, div2);
			append_dev(div2, t1);

			if (!mounted) {
				dispose = listen_dev(i, "click", /*click_handler*/ ctx[5], false, false, false, false);
				mounted = true;
			}
		},
		p: function update(ctx, [dirty]) {
			if (dirty & /*ready*/ 2) {
				toggle_class(div1, "hidden", !/*ready*/ ctx[1]);
			}

			if (dirty & /*layerName*/ 1) set_data_dev(t1, /*layerName*/ ctx[0]);

			if (dirty & /*layerName*/ 1) {
				toggle_class(div3, "hidden", /*layerName*/ ctx[0] === null);
			}
		},
		i: noop,
		o: noop,
		d: function destroy(detaching) {
			if (detaching) detach_dev(div1);
			if (detaching) detach_dev(t0);
			if (detaching) detach_dev(div3);
			mounted = false;
			dispose();
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_fragment.name,
		type: "component",
		source: "",
		ctx
	});

	return block;
}

function instance($$self, $$props, $$invalidate) {
	let { $$slots: slots = {}, $$scope } = $$props;
	validate_slots('map-background-switcher', slots, []);
	let mounted = false;
	const generalText = getComponentText('general');
	const component = get_current_component();
	let ready = false;

	// Component variables
	//================================================================
	let hidden = false;

	let map;
	let componentId = uuidv4();
	let layerName = null;
	let timeout;

	// HTML Mount & Component initialization
	//================================================================
	onMount(() => {
		attachNormalize(component);

		tick().then(() => {
			dispatchMountedEvent(component);
			mounted = true;
		});

		return () => {
			
		};
	});

	const initComponent = (filtredLayers, mapComponent) => {
		$$invalidate(2, map = mapComponent);
		console.log('filtredLayers', filtredLayers);
		let layers = bc.parameterService.get('LAYERS');
		let finalLayers = [];

		if (filtredLayers.length > 0) {
			for (let i = 0; i < layers.length; i++) {
				if (filtredLayers.find(f => f.TY_ID === layers[i].TY_ID) && layers[i].TY_REMARKS && layers[i].TY_REMARKS !== '') {
					if (layers[i].TY_REMARKS.indexOf('BG') !== -1) {
						finalLayers.push(layers[i]);
					}
				}
			}
		} else {
			for (let i = 0; i < layers.length; i++) {
				if (layers[i].TY_REMARKS && layers[i].TY_REMARKS !== '') {
					if (layers[i].TY_REMARKS.indexOf('BG') !== -1) {
						finalLayers.push(layers[i]);
					}
				}
			}
		}

		$$invalidate(1, ready = finalLayers.length > 1);
	};

	const writable_props = [];

	

	const click_handler = e => {
		let layerSwitcher = map.getLayerSwitcher();
		$$invalidate(0, layerName = layerSwitcher.rotateToNextBackgroundLayer());
	};

	$$self.$capture_state = () => ({
		bc,
		uuidv4,
		onMount,
		tick,
		get_current_component,
		attachNormalize,
		dispatchMountedEvent,
		getComponentText,
		mounted,
		generalText,
		component,
		ready,
		hidden,
		map,
		componentId,
		layerName,
		timeout,
		initComponent
	});

	$$self.$inject_state = $$props => {
		if ('mounted' in $$props) mounted = $$props.mounted;
		if ('ready' in $$props) $$invalidate(1, ready = $$props.ready);
		if ('hidden' in $$props) hidden = $$props.hidden;
		if ('map' in $$props) $$invalidate(2, map = $$props.map);
		if ('componentId' in $$props) componentId = $$props.componentId;
		if ('layerName' in $$props) $$invalidate(0, layerName = $$props.layerName);
		if ('timeout' in $$props) $$invalidate(4, timeout = $$props.timeout);
	};

	if ($$props && "$$inject" in $$props) {
		$$self.$inject_state($$props.$$inject);
	}

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*layerName, timeout*/ 17) {
			// Component functions
			//================================================================
			$: if (layerName) {
				if (timeout) {
					clearTimeout(timeout);
				}

				$$invalidate(4, timeout = setTimeout(
					() => {
						$$invalidate(0, layerName = null);
					},
					3000
				));
			}
		}
	};

	return [layerName, ready, map, initComponent, timeout, click_handler];
}

class MapBackgroundSwitcher extends SvelteElement {
	constructor(options) {
		super();
		const style = document.createElement('style');
		style.textContent = `45%{-webkit-transform:scale(var(--fa-beat-scale, 1.25));transform:scale(var(--fa-beat-scale, 1.25))}45%{-webkit-transform:scale(var(--fa-beat-scale, 1.25));transform:scale(var(--fa-beat-scale, 1.25))}50%{opacity:1;-webkit-transform:scale(var(--fa-beat-fade-scale, 1.125));transform:scale(var(--fa-beat-fade-scale, 1.125))}50%{opacity:1;-webkit-transform:scale(var(--fa-beat-fade-scale, 1.125));transform:scale(var(--fa-beat-fade-scale, 1.125))}100%{-webkit-transform:rotate(360deg);transform:rotate(360deg)}100%{-webkit-transform:rotate(360deg);transform:rotate(360deg)}.fa-image-landscape::before{content:""}.fas{font-weight:900 !important}.fas{font-family:"Font Awesome 6 Pro" !important;font-style:normal !important}.bg-gray-600{--tw-bg-opacity:1;background-color:rgba(75, 85, 99, var(--tw-bg-opacity))}.border-white{--tw-border-opacity:1;border-color:rgba(255, 255, 255, var(--tw-border-opacity))}.rounded{border-radius:0.25rem}.border-solid{border-style:solid}.border{border-width:1px}.flex{display:-webkit-box;display:-ms-flexbox;display:-webkit-flex;display:flex}.hidden{display:none}.items-center{-webkit-box-align:center;-ms-flex-align:center;-webkit-align-items:center;align-items:center}.justify-center{-webkit-box-pack:center;-ms-flex-pack:center;-webkit-justify-content:center;justify-content:center}.h-10{height:2.5rem}.p-2{padding:0.5rem}.absolute{position:absolute}.relative{position:relative}.left-4{left:1rem}.bottom-4{bottom:1rem}.left-16{left:4rem}.text-white{--tw-text-opacity:1;color:rgba(255, 255, 255, var(--tw-text-opacity))}.w-10{width:2.5rem}.z-49{z-index:49}`;
		this.shadowRoot.appendChild(style);

		init(
			this,
			{
				target: this.shadowRoot,
				props: attribute_to_object(this.attributes),
				customElement: true
			},
			instance,
			create_fragment,
			safe_not_equal,
			{ initComponent: 3 },
			null
		);

		if (options) {
			if (options.target) {
				insert_dev(options.target, this, options.anchor);
			}

			if (options.props) {
				this.$set(options.props);
				flush();
			}
		}
	}

	static get observedAttributes() {
		return ["initComponent"];
	}

	get initComponent() {
		return this.$$.ctx[3];
	}

	set initComponent(value) {
		throw new Error("<map-background-switcher>: Cannot set read-only property 'initComponent'");
	}
}

customElements.define("map-background-switcher", MapBackgroundSwitcher);
export default MapBackgroundSwitcher;